import { useMemo, useState } from "react";

import {
  ITriggerProps,
  ITriggersTableContent,
} from "../../../../../../@types/Alerts";
import { Dots } from "../../../../../../assets/icons/Dots";
import { IncreaseArrow } from "../../../../../../assets/icons/IncreaseArrow";
import { Button } from "../../../../../../components/Button";
import { CustomIconButton } from "../../../../../../components/CustomIconButton";
import {
  Dropdown,
  DropdownTextItem,
} from "../../../../../../components/Dropdown";
import { Table } from "../../../../../../components/Table";
import { ActionContainer } from "../../../../../../components/TableActionDropdownButton/TableActionDropdownButton.styles";
import { TableSkeleton } from "../../../../../../components/TableSkeleton";
import { getTriggersData } from "../../../../../../hooks/queries/alerts";
import { alertsService } from "../../../../../../services/alertsService";
import { CreateTriggerModal } from "../CreateTriggerModal";
import {
  Container,
  DeleteTriggerContainer,
  ContentContainer,
  SubmitContainer,
  Title,
  CancelButton,
  Field,
  FieldContainer,
} from "./ManageTriggersModal.styles";
import { manageAlertsTableColumns } from "./ManageTriggersTableColumns";

type ManageTriggersModalProps = {
  patientId: number;
  setNotification: (
    showNotification: boolean,
    notificationMessage: string,
    notificationType: "success" | "warning" | "error",
    notificationWidth?: string
  ) => void;
};

export function ManageTriggersModal({
  setNotification,
  patientId,
}: ManageTriggersModalProps) {
  const [isCreateTrigger, setIsCreateTrigger] = useState(false);
  const [isDeleteTrigger, setIsDeleteTrigger] = useState(false);
  const [isEditTrigger, setIsEditTrigger] = useState(false);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [measurementAlertIdToEdit, setMeasurementAlertIdToEdit] = useState("");
  const [deleteTriggerData, setDeleteTriggerData] =
    useState<ITriggersTableContent>();
  const [triggerContent, setTriggerContent] = useState<ITriggerProps>();

  const { triggersTableData, isFetching, refetch, isRefetching } =
    getTriggersData(patientId);

  const handleCreateTrigger = () => {
    setIsCreateTrigger(true);
  };

  const handleDeleteTrigger = () => {
    if (deleteTriggerData && deleteTriggerData.measurementAlertId) {
      alertsService
        .deleteTrigger(deleteTriggerData.measurementAlertId)
        .then((deletedSuccessfully) => {
          const { data } = deletedSuccessfully;

          if (data) {
            refetch();
            setNotification(true, "Trigger successfully deleted", "success");
          } else {
            setNotification(
              true,
              "Error deleting trigger, please try again",
              "error"
            );
          }
        });
    }

    setIsDeleteTrigger(false);
  };

  const handleChildNotification = (
    showNotification: boolean,
    notificationMessage: string,
    notificationType: "success" | "warning" | "error",
    notificationWidth?: string
  ) => {
    refetch();

    setNotification(
      showNotification,
      notificationMessage,
      notificationType,
      notificationWidth
    );
  };

  const handleCreateTriggerClose = () => {
    setIsCreateTrigger(false);
    setIsEditTrigger(false);
  };

  const parseTriggerToTable = useMemo(() => {
    if (!triggersTableData) return [];

    const options: any[] = ["Edit trigger", "Delete trigger"];

    const handleOnChangeDropdownVisibility = () => {
      setIsDropDownOpen(!isDropDownOpen);
    };

    const editTrigger = (measurementAlertId: string) => {
      alertsService
        .getTriggerByMeasurementAlertId(measurementAlertId)
        .then((trigger) => {
          const { data } = trigger;

          setMeasurementAlertIdToEdit(measurementAlertId);
          setTriggerContent(data);
          setTimeout(() => {
            setIsEditTrigger(true);
          }, 1);
        });
    };

    const deleteTrigger = (triggerData: ITriggersTableContent) => {
      setDeleteTriggerData(triggerData);
      setIsDeleteTrigger(true);
    };

    const handleTriggerText = (triggerText: string) => {
      if (triggerText.includes("+")) {
        const triggerWithoutPlus = triggerText.replace("+", "");

        return (
          <div
            style={{
              display: "flex",
              gap: "2px",
              marginLeft: -4,
            }}
          >
            <IncreaseArrow /> {triggerWithoutPlus}
          </div>
        );
      }

      return triggerText;
    };

    return triggersTableData.map((triggerData: ITriggersTableContent) => {
      return {
        measurementAlertId: triggerData.measurementAlertId,
        measurementType: triggerData.measurementType,
        trigger: handleTriggerText(triggerData.trigger as string),
        timeFrame: triggerData.timeFrame ?? "-",
        hcpNotification: triggerData.hcpNotification,
        hcpMessage: triggerData.hcpMessage,
        created: triggerData.created,
        action: (
          <div>
            <ActionContainer
              className="actionContainer"
              style={{ height: "42px" }}
            >
              <Dropdown
                trigger={
                  <CustomIconButton>
                    <Dots />
                  </CustomIconButton>
                }
                onOpenChange={handleOnChangeDropdownVisibility}
                onValueChange={(val: string) => {
                  if (val === options[0]) {
                    editTrigger(triggerData.measurementAlertId);
                  }
                  if (val === options[1]) {
                    deleteTrigger(triggerData);
                  }
                }}
              >
                {options.map((option) => (
                  <DropdownTextItem key={option} text={option} />
                ))}
              </Dropdown>
            </ActionContainer>
          </div>
        ),
      } as ITriggersTableContent;
    });
  }, [triggersTableData]);

  const handleTable = () => {
    if (isFetching || isRefetching) {
      return <TableSkeleton columnsNumber={6} />;
    }

    return (
      <div style={{ height: "50vh" }}>
        <Table
          data={parseTriggerToTable}
          columns={manageAlertsTableColumns}
          rowPadding
          fontSize={14}
          hasBorder={false}
        />
      </div>
    );
  };

  if (isCreateTrigger || isEditTrigger) {
    return (
      <CreateTriggerModal
        content={isEditTrigger ? triggerContent : undefined}
        isEdit={isEditTrigger}
        measurementAlertId={measurementAlertIdToEdit}
        patientId={patientId}
        setParentNotification={handleChildNotification}
        onClose={handleCreateTriggerClose}
      />
    );
  }

  return !isDeleteTrigger ? (
    <Container>
      <Title>Manage triggers</Title>
      <div
        style={{
          height: "50vh",
          width: "calc(100% + 32px)",
          marginLeft: "-16px",
          fontSize: "12px !important",
        }}
      >
        {handleTable()}
      </div>
      <SubmitContainer>
        <Button label="Create" type="button" onClick={handleCreateTrigger} />
      </SubmitContainer>
    </Container>
  ) : (
    <DeleteTriggerContainer>
      <Title>Delete trigger</Title>
      <ContentContainer>
        <FieldContainer>
          <Field>
            <p style={{ fontWeight: "600" }}>Type</p>
            <p>{deleteTriggerData?.measurementType}</p>
          </Field>
          <Field>
            <p style={{ fontWeight: "600" }}>Trigger</p>
            <p>{deleteTriggerData?.trigger}</p>
          </Field>
          <Field>
            <p style={{ fontWeight: "600" }}>Time frame</p>
            <p>{deleteTriggerData?.timeFrame}</p>
          </Field>
          <Field>
            <p style={{ fontWeight: "600" }}>HCP notification</p>
            <p>{deleteTriggerData?.hcpNotification}</p>
          </Field>
        </FieldContainer>
        <Field>
          <p style={{ fontWeight: "600" }}>HCP message</p>
          <p>{deleteTriggerData?.hcpMessage}</p>
        </Field>
      </ContentContainer>
      <SubmitContainer>
        <Button label="Delete" type="button" onClick={handleDeleteTrigger} />
        <CancelButton onClick={() => setIsDeleteTrigger(false)}>
          Cancel
        </CancelButton>
      </SubmitContainer>
    </DeleteTriggerContainer>
  );
}
