import { Modal } from "antd";
import { useState } from "react";

import { Button } from "../../../../../components/Button";
import { CancelButton } from "../../../../Settings/components/SecurityModal/SecurityModal.styles";
import {
  ButtonContainer,
  confirmModalCss,
  ContentContainer,
} from "./DeleteAppointmentModal.styles";

type DeleteAppointmentModalProps = {
  onClose(confirm: boolean): void;
};

export function DeleteAppointmentModal({
  onClose,
}: DeleteAppointmentModalProps) {
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirmDelete = () => {
    setIsLoading(true);
    onClose(true);
  };

  const handleCancel = () => {
    onClose(false);
  };

  return (
    <Modal
      title="Cancel Appointment"
      open
      zIndex={3001}
      maskStyle={{ pointerEvents: "all" }}
      footer={null}
      width={500}
      onCancel={handleCancel}
      className={confirmModalCss()}
      centered
      destroyOnClose
    >
      <ContentContainer>
        <p
          style={{
            padding: "18px 0 0 0",
            fontWeight: "400",
            color: "#939393",
            marginLeft: "5px",
          }}
        >
          Are you sure you want to cancel this appointment?
        </p>
        <ButtonContainer>
          <Button
            label="Confirm"
            type="button"
            onClick={handleConfirmDelete}
            isLoading={isLoading}
          />

          <CancelButton isModal onClick={handleCancel}>
            Cancel
          </CancelButton>
        </ButtonContainer>
      </ContentContainer>
    </Modal>
  );
}
