import { Tooltip } from "antd";
import dayjs from "dayjs";
import { useState, DragEvent, useEffect, useRef } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { v4 as uuidv4 } from "uuid";

import { IPortalPreferences } from "../../../../@types/Preferences";
import { Copy } from "../../../../assets/icons/Copy";
import { DragHandle } from "../../../../assets/icons/DragHandle";
import { Minus } from "../../../../assets/icons/Minus";
import { Plus } from "../../../../assets/icons/Plus";
import { IOption, SelectDropdown } from "../../../../components/SelectDropdown";
import { TextField } from "../../../../components/TextField";
import { ToastNotification } from "../../../../components/ToastNotification";
import { AUTH_PORTAL_PREFERENCES } from "../../../../constants/localStorageKeys";
import { css } from "../../../../styles";
import { getShortFormattedDate } from "../../../../utils/dateFormatter";
import { mixpanelActions } from "../../../../utils/mixpanel";
import {
  GrabButton,
  PatientMenuCardContainer,
  PatientMenuCardContent,
  PatientMenuCardControls,
  VisibilityButton,
  CopyContainer,
} from "./PatientMenuCard.styles";

export type PatientCard = {
  name: string;
  description?: string;
  order: number;
  active: boolean;
};

type PatientMenuCardProps = {
  card: PatientCard;
  isEditing: boolean;
  isEditable: boolean;
  dropdownItems?: IOption[];
  onDragOver: (event: DragEvent<HTMLDivElement>) => void;
  onCardButtonClick: () => void;
  onPropEdit: (
    propName: string,
    newValue: string | null,
    oldValue: string
  ) => void;
  onError?: (error: boolean) => void;
};

const textFieldCss = css({
  padding: "3px 0 0 4px",
  width: "143px",
  color: "$black !important",

  input: {
    height: "20px",
    width: "calc(100% - 13px)",
    padding: "1px 4px 2px 7px",
    fontSize: "12px",
    cursor: "text",
  },

  span: {
    marginBottom: "0",
  },

  p: {
    paddingLeft: "0",
    marginLeft: "0",
  },
});

const selectCss = css({
  color: "$black !important",

  ".ant-select-selector": {
    height: `25px !important`,
    alignItems: "center",
    border: "solid 2px $gray-50 !important",
    boxShadow: "0 0 0 2px $gray-50 !important",
    margin: "4px 0 0 8px",
    padding: "0 8px !important",
    position: "initial !important",

    "&:hover": {
      borderColor: "$primary-30 !important",
    },

    span: {
      "&.ant-select-selection-item": {
        fontFamily: "Open Sans",
        fontSize: "12px",
        borderRadius: "14px !important",
      },
    },
  },

  span: {
    "&.ant-select-arrow": {
      margin: "-4px -14px 0 0",
    },
  },

  ".ant-select-item-option": {
    ".ant-select-item-option-content": {
      fontSize: "12px !important",
      fontWeight: "400",
    },
  },
});

// Multiple conditions code
// const multiSelectCss = css({
//   color: "$black !important",

//   ".ant-select-selector": {
//     height: `25px !important`,
//     alignItems: "center",
//     border: "solid 2px $gray-50 !important",
//     boxShadow: "0 0 0 2px $gray-50 !important",
//     margin: "4px 0 0 8px",
//     padding: "0 8px !important",
//     position: "initial !important",

//     "&:hover": {
//       borderColor: "$primary-30 !important",
//     },

//     span: {
//       "&.ant-select-selection-item": {
//         fontFamily: "Open Sans",
//         fontSize: "12px",
//         borderRadius: "14px !important",
//         lineHeight: "14px",
//         height: "16px",
//         marginTop: "-4px",
//         marginBottom: "3px",
//       },
//     },
//   },

//   span: {
//     "&.ant-select-arrow": {
//       margin: "-4px -14px 0 0",
//     },
//   },

//   ".ant-select-item-option": {
//     ".ant-select-item-option-content": {
//       color: "$black",
//       fontSize: "12px !important",
//     },
//   },
// });

export function PatientMenuCard({
  card,
  isEditing,
  isEditable,
  dropdownItems,
  onDragOver,
  onCardButtonClick,
  onPropEdit,
  onError,
}: PatientMenuCardProps) {
  const isActive = card.active;

  const cardRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLDivElement>(null);

  const [isOverGrab, setIsOverGrab] = useState(false);
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [isEditingCard, setIsEditingCard] = useState(false);
  const [dropdownValue, setDropdownValue] = useState<string | undefined>();
  const [countryCode, setCountryCode] = useState<string>();
  const [showNotification, setShowNotification] = useState(false);

  // Multiple conditions code
  // const [multipleDropdownValue, setMultipleDropdownValue] = useState<
  //   string[] | undefined
  // >();

  const [value, setValue] = useState(card.description);
  const [dateValue, setDateValue] = useState(dayjs(value).format("DD/MM/YYYY"));
  const [error, setError] = useState<string>("");

  const dateFormatEurope =
    /^(0?[1-9]|[12][0-9]|3[01])\/(0?[1-9]|1[0-2])\/\d{4}$/;
  const dateFormatAmerica =
    /^(0?[1-9]|1[0-2])\/(0?[1-9]|[12][0-9]|3[01])\/\d{4}$/;
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  const portalPreferences = localStorage.getItem(AUTH_PORTAL_PREFERENCES);

  const parsedPortalPreferences = portalPreferences
    ? (JSON.parse(portalPreferences) as IPortalPreferences)
    : undefined;

  if (!isEditing && !isActive) return null;

  function setDraggingOfCardRef(active: boolean) {
    cardRef.current?.setAttribute("dragging", String(active));
  }

  useEffect(() => {
    setDraggingOfCardRef(false);
    cardRef.current?.setAttribute("order", card.order.toString());
    cardRef.current?.setAttribute("name", card.name);
  }, []);

  useEffect(() => {
    if (onError) {
      onError(error !== "");
    }
  }, [error]);

  useEffect(() => {
    if (card.name === "Weight (kg)" || card.name === "Height (cm)") {
      if (parseInt(value ?? "", 10) > 0) {
        setValue(value);
      }
      return;
    }

    if (card.name === "Email") {
      const email = value;
      if (email === "") {
        setError("Email is required");
      } else if (!emailRegex.test(email ?? "")) {
        setError("Please enter a valid email");
      } else {
        setError("");
      }
    }
  }, [value]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target) &&
        dropdownItems?.length === 0 &&
        card.name !== "DOB"
      ) {
        const textFieldComponent = document.getElementsByName(
          `patient-prop-input-${card.name}`
        );

        const inputValue = (textFieldComponent[0] as HTMLInputElement).value;

        if (inputValue !== card.description) {
          onPropEdit(card.name, inputValue, card.description ?? "");
        } else {
          onPropEdit(card.name, null, card.description);
        }

        setIsEditingCard(false);
      }
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        if (
          !(event.target as HTMLElement).classList.contains(
            "ant-select-item-option-content" ||
              "ant-select-item-option" ||
              "ant-select-selection-overflow"
          )
        ) {
          if (card.name === "DOB") {
            if (parsedPortalPreferences?.countryCode !== "US") {
              const isDateValidFormat1 = dateFormatEurope.test(dateValue);

              if (isDateValidFormat1) {
                setIsEditingCard(false);
                return;
              }
            }

            if (parsedPortalPreferences?.countryCode === "US") {
              const isDateValidFormat2 = dateFormatAmerica.test(dateValue);

              if (isDateValidFormat2) {
                setIsEditingCard(false);
                return;
              }
            }

            return;
          }
          setTimeout(() => {
            setIsEditingCard(false);
          }, 100);
        }
      }
    };

    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [inputRef, dateValue]);

  useEffect(() => {
    // Multiple conditions code
    // if (card.name === "Condition" && card.description !== "-") {
    //   const currentValues = card.description?.split(", ");

    //   if (currentValues) {
    //     const selectedOptions: any[] = [];

    //     currentValues.forEach((option) => {
    //       selectedOptions.push(
    //         dropdownItems?.find((x) => x.label === option)?.value
    //       );
    //     });

    //     setMultipleDropdownValue(selectedOptions);
    //     return;
    //   }
    // }

    const currentValue = dropdownItems?.find(
      (x) => x.label === card.description
    );

    if (dropdownValue !== currentValue?.value.toString()) {
      setDropdownValue(currentValue?.value.toString());
    }

    if (
      parsedPortalPreferences &&
      parsedPortalPreferences.countryCode === "US"
    ) {
      setCountryCode(parsedPortalPreferences.countryCode);
      setDateValue(dayjs(value).format("MM/DD/YYYY"));
    }
  }, []);

  const handleDropdownValue = (value: string | string[]) => {
    if (typeof value === "string") {
      const newValue = dropdownItems?.find((x) => x.value.toString() === value);

      if (newValue && newValue?.label !== card.description) {
        onPropEdit(card.name, newValue?.label, card.description ?? "");
        setDropdownValue(newValue?.value.toString());
        setValue(newValue?.label);
      } else {
        onPropEdit(card.name, null, card.description ?? "");
      }

      setIsEditingCard(false);
    }

    // Multiple conditions code
    // else {
    //   const newValues: any[] = [];
    //   const newValuesLabels: any[] = [];

    //   if (value.length === 0) {
    //     setMultipleDropdownValue([]);
    //     setValue("-");
    //     onPropEdit(card.name, "-", card.description ?? "-");
    //   }

    //   value.forEach((singleValue) => {
    //     const singleValueData = dropdownItems?.find(
    //       (x) => x.value.toString() === singleValue
    //     );

    //     newValues.push(singleValueData?.value);
    //     newValuesLabels.push(singleValueData?.label);
    //   });

    //   if (newValuesLabels.join(", ") !== card.description) {
    //     onPropEdit(
    //       card.name,
    //       newValuesLabels.join(", "),
    //       card.description ?? ""
    //     );
    //     setMultipleDropdownValue(newValues);
    //     setValue(newValuesLabels.join(", "));
    //   } else {
    //     onPropEdit(card.name, null, card.description ?? "");
    //   }
    // }
  };

  const handleOnDragStart = () => {
    setIsEditingCard(false);

    const isValidDragging = isEditing && isOverGrab;

    if (!isValidDragging) return;

    setDraggingOfCardRef(true);
  };

  const handleOnDragEnd = () => {
    if (isEditing) setDraggingOfCardRef(false);
  };

  const handleOnDragOver = (event: DragEvent<HTMLDivElement>) => {
    if (isEditing) onDragOver(event);
  };

  const handleTextFieldKeyDown = (event: any) => {
    if (event.key === "Enter" && isEditingCard) {
      if (value !== card.description) {
        onPropEdit(card.name, value ?? "", card.description ?? "");
      }

      setIsEditingCard(false);
    }
  };

  const handleInputKeyDown = (event: any) => {
    if (event.key === "Enter" && !isEditingCard && isEditing) {
      setIsEditingCard(true);
    }
    if (event.keyCode === 27 && isEditingCard && isEditing) {
      setIsEditingCard(false);
    }
  };

  const handleShowTooltip = () => {
    if (isEditing) {
      if (!isEditable && card.active) {
        return true;
      }
    }
    return false;
  };

  const handleOnDateChange = (date: string) => {
    setDateValue(date);

    if (parsedPortalPreferences?.countryCode !== "US") {
      const isDateValidFormat1 = dateFormatEurope.test(date);

      if (isDateValidFormat1) {
        const normalizedDate = new Date(date.split("/").reverse().join("/"));
        const formattedDate = getShortFormattedDate(
          normalizedDate.toDateString()
        );

        setValue(formattedDate);

        onPropEdit(card.name, formattedDate, card.description ?? "");

        setError("");
        setIsEditingCard(false);
        return;
      }

      setError("Please enter a valid date.");
      return;
    }

    if (parsedPortalPreferences?.countryCode === "US") {
      const isDateValidFormat2 = dateFormatAmerica.test(date);

      if (isDateValidFormat2) {
        const formattedDate = getShortFormattedDate(date);
        setValue(formattedDate);

        onPropEdit(card.name, formattedDate, card.description ?? "");

        setError("");
        setIsEditingCard(false);
        return;
      }
      setError("Please enter a valid date.");
    }
  };

  const handleInputType = () => {
    if (card.name === "DOB" && value) {
      return (
        <TextField
          label=""
          placeholder={countryCode === "US" ? "mm/dd/yyyy" : "dd/mm/yyyy"}
          value={dateValue ?? "-"}
          onChange={(values) => {
            handleOnDateChange(values.target.value);
          }}
          className={textFieldCss()}
          type="text"
          name={`patient-prop-input-${card.name}`}
          errorMessage={error}
        />
      );
    }

    if (dropdownItems?.length === 0) {
      return (
        <TextField
          label=""
          className={textFieldCss()}
          value={value ?? "-"}
          onChange={(value) => setValue(value.target.value)}
          onKeyDown={handleTextFieldKeyDown}
          name={`patient-prop-input-${card.name}`}
          type={
            card.name === "Weight (kg)" || card.name === "Height (cm)"
              ? "number"
              : undefined
          }
          errorMessage={error}
        />
      );
    }

    return (
      <SelectDropdown
        key={uuidv4()}
        options={dropdownItems ?? []}
        value={dropdownValue}
        width={135}
        onValueChange={handleDropdownValue}
        className={selectCss()}

        // Multiple conditions code
        // multiple={card.name === "Condition"}
        // value={
        //   card.name !== "Condition" ? dropdownValue : multipleDropdownValue
        // }
        // className={card.name !== "Condition" ? selectCss() : multiSelectCss()}
      />
    );
  };

  const activeOverGrab = () => setIsOverGrab(true);

  const inactiveOverGrab = () => setIsOverGrab(false);

  const inputHoverCss = css({
    "&:hover": {
      backgroundColor: "rgba(233, 230, 244, 0.48)",
    },
  });

  const onCopyText = () => {
    mixpanelActions.track(`UserAction: Copy${card.name.replace(" ", "")}`);

    setShowNotification(true);
    // Reset state back after 2.5 seconds when toast closes
    setInterval(() => {
      setShowNotification(false);
    }, 2500);
  };

  const getTooltipButton = () => {
    return (
      <Tooltip
        title={handleShowTooltip() ? "Can't be edited" : undefined}
        zIndex={3002}
        placement="top"
        overlayInnerStyle={{
          height: "100%",
          width: "fit-content",
          fontFamily: "'Open Sans', sans-serif",
        }}
      >
        <button
          onClick={() =>
            isEditing && isEditable ? setIsEditingCard(true) : null
          }
          onKeyDown={handleInputKeyDown}
          type="button"
          style={{
            maxWidth: isEditing ? "160px" : "200px",
            overflow: "hidden",
            wordBreak: "break-all",
            cursor: handleShowTooltip() ? "not-allowed" : "pointer",
          }}
          className={
            !handleShowTooltip() && isEditing && isActive ? inputHoverCss() : ""
          }
        >
          {value}
        </button>
        {!isEditing && isMouseOver && (
          <CopyContainer>
            <Copy />
          </CopyContainer>
        )}
      </Tooltip>
    );
  };

  const getTooltipButtonCopy = () => {
    if (isEditing) {
      return getTooltipButton();
    }
    return (
      <CopyToClipboard text={value || "-"} onCopy={onCopyText}>
        {getTooltipButton()}
      </CopyToClipboard>
    );
  };

  return (
    <PatientMenuCardContainer
      ref={cardRef}
      isVisible={isActive}
      onDragEnd={handleOnDragEnd}
      onDragStart={handleOnDragStart}
      onDragOver={handleOnDragOver}
      draggable={isOverGrab && isActive && isEditing}
      onMouseOver={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
    >
      <PatientMenuCardContent>
        <p>{card.name}</p>
        {!isEditingCard ? (
          getTooltipButtonCopy()
        ) : (
          <div
            ref={inputRef}
            style={{
              width: "143px",
            }}
          >
            {handleInputType()}
          </div>
        )}
        {showNotification ? (
          <ToastNotification
            message="Copied to clipboard"
            type="success"
            width="230px"
            rightMargin="220px"
          />
        ) : null}
      </PatientMenuCardContent>

      {isEditing && (
        <PatientMenuCardControls>
          {isActive && isMouseOver && (
            <GrabButton
              onMouseEnter={activeOverGrab}
              onMouseLeave={inactiveOverGrab}
            >
              <DragHandle />
            </GrabButton>
          )}

          <VisibilityButton isActive={isActive} onClick={onCardButtonClick}>
            {isActive ? <Minus /> : <Plus />}
          </VisibilityButton>
        </PatientMenuCardControls>
      )}
    </PatientMenuCardContainer>
  );
}
