import { styled } from "../../styles";

export const Status = styled("div", {
  width: "72px",
  height: "26px",

  backgroundColor: "$error-light",
  borderRadius: "14px",

  fontFamily: "$openSans",
  fontWeight: 400,
  fontSize: "$text-md",

  color: "$white",

  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  lineHeight: "22px",
});
