import { createColumn } from "../../../../../../../components/Table";

export const promDetailsTableColumns = [
  createColumn({
    keyName: "total",
    header: () => "Total",
    cell: (info) => info.renderValue(),
  }),
  createColumn({
    keyName: "time",
    header: () => "Time",
    cell: (info) => info.renderValue(),
    size: 200,
  }),
  createColumn({
    keyName: "rightSpace",
    header: () => "",
    cell: (info) => info.renderValue(),
    size: 1,
  }),
];

export const promQuestionsAndAnswersTableColumns = [
  createColumn({
    keyName: "leftSpace",
    header: () => "",
    cell: (info) => info.renderValue(),
    size: 78,
  }),
  createColumn({
    keyName: "question",
    header: () => "Question",
    cell: (info) => info.renderValue(),
    size: 690,
  }),
  createColumn({
    keyName: "answer",
    header: () => "Answer",
    cell: (info) => info.renderValue(),
    size: 250,
  }),
  createColumn({
    keyName: "rightSpace",
    header: () => "",
    cell: (info) => info.renderValue(),
    size: 40,
  }),
];
