import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";

import { Calendar } from "../../../../assets/icons/Calendar";
import { DatePickerInput } from "../../../../components/DatePicker";
import { OptionButton, OptionsBar } from "../../../../components/OptionsBar";
import { mixpanelActions } from "../../../../utils/mixpanel";
import { DateFilterOptionType } from "../../../PatientPage/components/MeasurementModal/components/CustomOptionsBar";
import { calendarDateFilterOptions } from "./calendarDateFilterOptions";
import {
  DatepickerContainer,
  OptionsContainer,
} from "./CalendarOptionsBar.styles";

type CalendarCustomOptionsBarType = {
  onChange: (selectedDateFilter: DateFilterOptionType) => void;
  selectedFilterDate: DateFilterOptionType;
};

export function CalendarCustomOptionsBar({
  onChange,
  selectedFilterDate,
}: CalendarCustomOptionsBarType) {
  const [calendarOpen, setCalendarOpen] = useState(false);

  const customDateFiterOption = "custom";
  const wrapperRef = useRef<any>(null);
  const calendarButtonRef = useRef<any>(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        calendarOpen &&
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        !calendarButtonRef.current.contains(event.target)
      ) {
        setCalendarOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [calendarOpen]);

  const handleOnChangeDateWithDatePicker = (dateRange: any) => {
    if (dateRange.startDate && dateRange.endDate) {
      setCalendarOpen(false);
    } else {
      return;
    }

    onChange({
      label: customDateFiterOption,
      startDate: dayjs(dateRange.startDate),
      endDate: dayjs(dateRange.endDate).endOf("day"),
    });
  };

  return (
    <OptionsContainer>
      <OptionsBar>
        <div
          style={{ backgroundColor: "white", borderRadius: "8px 0 0 8px" }}
          ref={calendarButtonRef}
        >
          <OptionButton
            key="calendar_customDate"
            text="Custom"
            icon={<Calendar />}
            selected={selectedFilterDate.label === customDateFiterOption}
            onClick={() => {
              mixpanelActions.track(`User Action: CalendarCustomDateFilter`);
              setCalendarOpen((prevState) => !prevState);
            }}
          />
        </div>

        {calendarDateFilterOptions.map((dateFilterOption, index) => {
          return (
            <OptionButton
              key={`calendar_${dateFilterOption.label}_${index}`}
              text={dateFilterOption.label}
              selected={dateFilterOption.label === selectedFilterDate.label}
              onClick={() => {
                mixpanelActions.track(
                  `User Action: Changed Calendar Date Range ${dateFilterOption.label}`
                );

                const currentDate = dayjs();
                onChange({
                  endDate: dateFilterOption.value(currentDate).endOf("month"),
                  label: dateFilterOption.label,
                  startDate: dateFilterOption.value(currentDate),
                });
              }}
            />
          );
        })}
      </OptionsBar>

      {calendarOpen && (
        <DatepickerContainer ref={wrapperRef}>
          <DatePickerInput
            initialDateRange={{
              startDate: selectedFilterDate.startDate.toDate(),
              endDate: selectedFilterDate.endDate.toDate(),
            }}
            onDateChange={handleOnChangeDateWithDatePicker}
          />
        </DatepickerContainer>
      )}
    </OptionsContainer>
  );
}
