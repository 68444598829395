import { styled } from "../../styles";

export const StyledTooltip = styled("div", {
  borderRadius: "4px",
  padding: "12px 16px",

  display: "flex",
  flexDirection: "column",

  backgroundColor: "$charcoal",
  textAlign: "center",

  h4: {
    color: "$white",

    fontFamily: "$openSans",
    fontSize: "$text-md",

    lineHeight: "16px",
    marginBottom: "4px",

    fontWeight: 500,

    "&:last-child": {
      marginBottom: "8px",
    },
  },

  p: {
    color: "$gray-60",

    fontWeight: 500,
    fontFamily: "$openSans",
    fontSize: "$text-md",

    lineHeight: "16px",
  },

  variants: {
    singleValue: {
      true: {
        alignItems: "center",
      },
    },
  },
});
