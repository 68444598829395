import { styled } from "../../styles";

export const ButtonStyled = styled("div", {
  padding: 0,
  border: "none",
  borderRadius: "$8",

  "&:focus-visible": {
    outline: "none",
  },
});

export const ButtonText = styled("p", {
  margin: 0,
  padding: 0,
});

export const ButtonContainer = styled("button", {
  height: "$13",
  padding: "0 $13",

  borderRadius: "20px",
  border: "2px solid $primary",

  backgroundColor: "$primary",
  boxSizing: "border-box",

  gap: "$4",

  display: "flex",
  alignItems: "center",

  color: "$white",
  fontWeight: 600,
  lineHeight: "22px",
  fontSize: "$text-lg",
  fontFamily: "$openSans",

  cursor: "pointer",

  "&:focus-visible": {
    outline: "none",
    border: "2px solid $primary-30",
  },

  "&:focus": {
    outline: "none",
    border: "2px solid $primary-30",
  },

  "&:hover": {
    backgroundColor: "$primary-60",
    border: "2px solid $primary-60",
  },

  "&:active": {
    backgroundColor: "$primary-90",
  },

  variants: {
    disabledStyle: {
      true: {
        opacity: 0.38,

        color: "$white",
        cursor: "not-allowed",
      },
    },

    isLoading: {
      true: {
        padding: "$4 $9 $4 $8",

        cursor: "not-allowed",

        backgroundColor: "$primary-90",
        border: "2px solid $primary-90",

        "&:hover": {
          backgroundColor: "$primary-90",
          border: "2px solid $primary-90",
        },
      },
    },

    variant: {
      secondary: {
        backgroundColor: "$black",
        border: "2px solid $black",

        "&:hover": {
          backgroundColor: "$charcoal",
          border: "2px solid $charcoal",
        },

        "&:active": {
          backgroundColor: "$gray-90",
          border: "2px solid $gray-90",
        },
      },
    },

    size: {
      small: {
        height: "26px",
        padding: "0 12px",

        fontSize: "$text-md",
        lineHeight: "16px",
      },
    },
  },

  compoundVariants: [
    {
      variant: "secondary",
      isLoading: true,
      css: {
        backgroundColor: "$gray-90",
        border: "2px solid $gray-90",

        "&:hover": {
          backgroundColor: "$gray-90",
          border: "2px solid $gray-90",
        },
      },
    },
    {
      variant: "secondary",
      disabledStyle: true,
      css: {
        backgroundColor: "$black",
        border: "2px solid $black",

        "&:hover": {
          backgroundColor: "$black",
          border: "2px solid $black",
        },

        "&:active": {
          backgroundColor: "$black",
          border: "2px solid $black",
        },
      },
    },
    {
      size: "small",
      isLoading: true,
      css: {
        padding: "0 18px 0 12px",
      },
    },
  ],
});
