import { styled, css } from "../../../../styles";

export const ModalContainer = styled("div", {
  height: "100%",
  width: "1120px",

  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",

  padding: "24px 26px",

  fontFamily: "$openSans !important",

  "@media only screen and (max-width: 1250px) and (min-width: 768px)": {
    width: "768px",
  },

  "@media only screen and (max-width: 767px)": {
    width: "100%",
  },
});

export const SectionContainer = styled("div", {
  backgroundColor: "rgba(248, 248, 248, 0.8)",
  height: "100%",
  borderRadius: "8px",

  padding: "20px 21px",

  overflowY: "auto",

  display: "flex",
  flexWrap: "wrap",

  p: {
    color: "#757575",
    fontSize: "14px",
    fontWeight: "500",
  },

  "@sm": {
    padding: "20px 12px 21px",
    p: {
      width: "0px",
    },
  },

  "@media(max-width:1100px)": {
    width: "700px",
  },
});

export const FormField = styled("div", {
  height: "42px",
  flexBasis: "50%",
  minWidth: "50%",

  marginBottom: "30px",

  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  padding: "0 30px 0 30px",
  position: "relative",

  outline: "none !important",

  ".ant-select-selection-placeholder": {
    color: "$gray-70 !important",
  },

  ".ant-select": {
    height: "42px !important",
  },

  p: {
    width: "155px",
    marginRight: "26px",

    color: "$charcoal",

    fontSize: "14px",
    lineHeight: "16px",
    fontFamily: "$openSans",
  },

  "@media only screen and (max-width: 1250px) and (min-width: 768px)": {
    flexBasis: "100%",
    minWidth: "100%",
  },
});

export const DatePickerContainer = styled("div", {
  right: 16,
  zIndex: 3000,
  borderRadius: "16px",
  position: "absolute",
  boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.22)",
  border: "1px solid var(--colors-gray-50)",

  ".react-datepicker__navigation": {
    height: "20px !important",
  },

  ".react-datepicker__day--selected": {
    "&:hover": {
      color: "$black !important",
      backgroundColor: "$white",
      fontWeight: "400 !important",
    },
  },

  ".react-datepicker__day--today": {
    color: "$secondary !important",

    "&:hover": {
      color: "$black !important",
      backgroundColor: "$white",
      fontWeight: "400 !important",
    },
  },
});

export const DatePickerInputConainer = styled("div", {
  width: "410px",
  height: "42px",

  backgroundColor: "$white",
  borderRadius: "6px",
  border: "2px solid $gray-50",

  display: "flex",
  alignItems: "center",

  paddingLeft: "11px",
  paddingRight: "11px",

  fontSize: "14px",
  fontWeight: "400",
  fontFamily: "$openSans",

  cursor: "pointer",

  svg: {
    width: "21px",
    height: "21px",

    marginLeft: "auto",
  },

  "&:hover": {
    border: "2px solid $primary-30",
  },

  "&:focus": {
    outline: "none !important",
    border: "2px solid #BDB4DD !important",
  },

  variants: {
    placeholder: {
      true: {
        color: "$gray-70",
      },
      false: {
        color: "$black",
      },
    },
  },
});

export const SubmitContainer = styled("div", {
  height: "70px",
  display: "flex",
  gap: "40px",
  padding: "26px 12px 0px 0px",
  alignItems: "center",
});

export const ErrorMessage = styled("p", {
  position: "absolute",

  fontSize: "12px !important",
  fontWeight: "600 !important",

  color: "$error-light !important",

  width: "max-content !important",
  bottom: -18,
  left: 240,

  "@media only screen and (max-width: 1250px) and (min-width: 768px)": {
    bottom: -18,
    left: 388,
  },
});

// css

export const inputCss = css({
  width: "248px",
  height: "42px",

  span: {
    margin: "0px",
  },

  p: {
    width: "100% !important",
  },

  input: {
    border: "2px solid $gray-50",
    borderRadius: "6px",
    paddingLeft: "11px",
    paddingRight: "11px",

    "&:hover": {
      border: "2px solid $primary-30",
    },

    "&:focus": {
      border: "2px solid $primary-30",
    },
  },
});

export const DateInputcontainer = styled("div", {
  width: "248px",
  display: "flex",
  justifyContent: "space-between",
});

export const dateInputCss = css({
  width: "65px",
  height: "42px",
  textAlign: "center",

  span: {
    margin: "0px",
  },

  p: {
    width: "100% !important",
  },

  input: {
    border: "2px solid $gray-50",
    borderRadius: "6px",
    paddingLeft: "11px",
    paddingRight: "11px",

    "&:hover": {
      border: "2px solid $primary-30",
    },

    "&:focus": {
      border: "2px solid $primary-30",
    },
  },
});

export const reactPhoneInputCss = css({
  width: "248px !important",
  height: "42px !important",
  zIndex: "2000",

  input: {
    border: "2px solid $gray-50 !important",
    borderRadius: "6px",
    paddingLeft: "11px",
    paddingRight: "11px",

    color: "$gray-90 !important",
    fontFamily: "$openSans !important",
    fontSize: "14px !important",
    fontWeight: "500 !important",
    lineHeight: "22px !important",

    "&:hover": {
      borderColor: "$primary-30 !important",
    },

    "&:focus": {
      border: "2px solid $primary-30 !important",
    },
  },

  ".form-control": {
    width: "100% !important",
    height: "100% !important",

    "&:focus": {
      boxShadow: "none !important",
    },
  },

  ".open": {
    width: "100% !important",
    height: "100% !important",

    "&:focus": {
      boxShadow: "none !important",
    },
  },

  ".selected-flag": {
    "&::before": {
      borderColor: "transparent !important",
      boxShadow: "none !important",
    },
  },

  ".flag-dropdown": {
    ".country-list": {
      width: "248px !important",
      bottom: "40px !important",

      ".search": {
        ".search-box": {
          width: "218px",
          paddingTop: "5px",

          "&::placeholder": {
            color: "$gray-80",
          },
        },
      },

      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
  },
});

export const dropdownCss = css({
  width: "248px !important",
  fontFamily: "$openSans",

  ".ant-select-selector": {
    border: "2px solid $gray-50 !important",
    borderRadius: "6px",
    ".ant-select-selection-search": {
      ".ant-select-selection-search-input": {
        fontFamily: "$openSans",
      },

      "&::placehholder": {
        fontFamily: "$openSans",
      },
    },
    "&:focus-within": {
      border: "2px solid $primary-30 !important",
      boxShadow: "none !important",
    },

    "&:hover": {
      border: "2px solid $primary-30 !important",
      boxShadow: "none !important",
    },
  },

  "&:focus": {
    border: "2px solid $primary-30 !important",
  },
});

export const dropdownPopUpCss = css({
  zIndex: 3001,
  pointerEvents: "auto",

  ".ant-select-item-option-selected": {
    backgroundColor: "$primary-20 !important",

    svg: {
      color: "$primary",
    },
  },

  ".ant-select-item-option-content": {
    fontFamily: "$openSans",
    color: "$black !important",
    fontWeight: "500 !important",
  },
  ".rc-virtual-list-holder": {
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
      borderRadius: "6px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "$gray-50",
      borderRadius: "6px",
    },
  },
});
