import { styled, css } from "../../../../../../styles";

// Components

export const Container = styled("div", {
  width: 325,
  height: "max-content",
  borderRadius: 8,

  backgroundColor: "$white",
  boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.20)",

  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  gap: 10,

  position: "relative",

  padding: "22px 20px",
});

export const Title = styled("div", {
  width: "100%",
  textAlign: "center",

  color: "$black",
  fontFamily: "$openSans",
  fontSize: 14,
  fontWeight: 500,

  marginBottom: 10,
});

export const BottomContainer = styled("div", {
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  position: "sticky",
  bottom: "20px",

  paddingTop: "10px",
});

export const TrueOrFalseBox = styled("div", {
  width: "132px",
  height: "32px",
  borderRadius: 6,

  color: "$success-dark",
  backgroundColor: "rgba(10, 171, 81, 0.2)",

  fontFamily: "$openSans",
  fontSize: "12px",
  fontWeight: 500,

  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  variants: {
    isRed: {
      true: {
        backgroundColor: "rgba(225, 29, 63, 0.2)",
        color: "$error-darker",
      },
    },
  },
});

export const SelectContainer = styled("div", {
  width: "100%",

  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  gap: 8,

  p: {
    color: "$charcoal",
    fontFamily: "$openSans",
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: "500",
  },

  ".ant-select-single": {
    height: "42px!important",
  },

  ".ant-select": {
    width: "100% !important",
  },

  variants: {
    isError: {
      true: {
        ".ant-select-selector": {
          borderColor: "$error-lighter !important",

          "&:hover": {
            borderColor: "$error-lighter !important",
          },
        },

        input: {
          borderColor: "$error-lighter!important",
        },
      },
    },
  },
});

export const ExcludeBlowsContainer = styled("div", {
  width: "100%",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-start",

  paddingTop: "10px",
  height: "52px",

  p: {
    marginLeft: 7,
    color: "$gray-90",
    fontFamily: "$openSans",
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: "400",
  },
});

// CSS

export const TextFieldCss = css({
  width: "90px",
});

export const popupOptionsCss = css({
  zIndex: 3001,
  pointerEvents: "auto",
  fontFamily: "$openSans !important",

  ".ant-select-item-option-selected": {
    backgroundColor: "$primary-20 !important",

    svg: {
      color: "$primary",
    },
  },

  ".ant-select-item-option-content": {
    fontFamily: "$openSans",
    color: "$black !important",
    fontWeight: "500 !important",
  },
});
