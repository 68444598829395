import { styled, css } from "../../../../../../styles";

export const ModalContainer = styled("div", {
  padding: "32px 28px",
});

export const ContentContainer = styled("div", {
  width: "100%",
  height: "100%",

  margin: "40px 0 10px 0",
  padding: "28px 32px 28px",
  borderRadius: "8px",
  backgroundColor: "$gray-20",

  display: "flex",
  flexDirection: "column",
  gap: "36px",

  variants: {
    isNote: {
      true: {
        margin: "2px 0 10px 0",
        backgroundColor: "rgba(233, 230, 244, 0.60)",
      },
    },
  },
});

export const Row = styled("div", {
  width: "100%",

  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "40px",
});

export const Column = styled("div", {
  width: "max-content",

  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: "4px",

  p: {
    color: "$charcoal",
    fontFamily: "$openSans",
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: "400",
  },

  variants: {
    isPrimaryColor: {
      true: {
        p: {
          color: "$primary-70",
        },
      },
    },
  },
});

export const SubmitContainer = styled("div", {
  height: "70px",
  display: "flex",
  gap: "40px",
  padding: "26px 12px 0px 0px",
  alignItems: "center",
});

// css

export const archiveButtonCss = css({
  button: {
    width: "100%",

    p: {
      margin: "auto",
    },
  },
});
