import { styled } from "../../styles";

export const Container = styled("div", {
  padding: "$8 $10",
  borderRadius: "$4",
  border: "1px solid $gray-40",

  "&:focus-within": {
    border: "1px solid primary-30",
  },

  "@sm": {
    border: "none",
    borderRadius: "unset",
  },
});
