import { useState } from "react";

import { IDevice } from "../../../../../../@types/Devices";
import { Notification } from "../../../../../../@types/Notification";
import { Button } from "../../../../../../components/Button";
import { devicesService } from "../../../../../../services/devicesService";
import {
  DeleteDeviceCancelButton,
  RemoveDeviceTitle,
  InputRow,
  InputsContainer,
  DeleteDeviceModalContainer,
  SubmitContainer,
  ContentValue,
} from "./DeleteDeviceModal.styles";

interface IDeleteDeviceModal {
  content: IDevice;
  onClose: (notification?: Notification, refetchTable?: boolean) => void;
}

export function DeleteDeviceModal({ content, onClose }: IDeleteDeviceModal) {
  const [isLoading, setIsLoading] = useState(false);

  const handleOnDeviceDelete = async () => {
    setIsLoading(true);

    const result = await devicesService.deleteDevice(content.identifier);

    setIsLoading(false);

    const notification: Notification = {
      show: true,
      message: "",
      type: "success",
      width: "max-content",
    };

    let refetchTable = false;

    if (result.status >= 200 && result.status < 300) {
      notification.message = "Device successfully deleted";
      refetchTable = true;
    } else {
      notification.message = "Error deleting device, please try again";
      notification.type = "error";
    }

    onClose(notification, refetchTable);
  };

  return (
    <DeleteDeviceModalContainer>
      <RemoveDeviceTitle>
        <p>Delete device</p>
      </RemoveDeviceTitle>
      <InputsContainer>
        <InputRow>
          <p>Type</p>
          <ContentValue>{content.name}</ContentValue>
        </InputRow>
        <InputRow>
          <p>ID</p>
          <ContentValue>{content.identifier}</ContentValue>
        </InputRow>
        <InputRow>
          <p>Last used</p>
          <ContentValue>{content.lastUsed ?? "-"}</ContentValue>
        </InputRow>
      </InputsContainer>
      <SubmitContainer>
        <Button
          type="button"
          label="Confirm"
          isLoading={isLoading}
          onClick={handleOnDeviceDelete}
        />
        <DeleteDeviceCancelButton onClick={() => onClose()}>
          Cancel
        </DeleteDeviceCancelButton>
      </SubmitContainer>
    </DeleteDeviceModalContainer>
  );
}
