import { ButtonContainer } from "./LinkButton.styles";

export type LinkButtonProps = {
  label: string;
  color?: "gray" | "black";
  disabled?: boolean;
  size?: "lg" | "md";
  className?: string;
  fontWeight?: "bold" | "semiBold";
  onClick: () => void;
  underline?: boolean;
  hoverColor?: "secondary";
};

export function LinkButton({
  size,
  label,
  color,
  onClick,
  disabled,
  underline,
  className,
  hoverColor,
  fontWeight,
}: LinkButtonProps) {
  return (
    <ButtonContainer
      size={size}
      color={color}
      onClick={onClick}
      disabled={disabled}
      className={className}
      underline={underline}
      hoverColor={hoverColor}
      fontWeight={fontWeight}
      disabledStyle={disabled}
    >
      <span>{label}</span>
    </ButtonContainer>
  );
}
