import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import pmpLogoName from "../../assets/images/pmp-logo-name.png";
import { Button } from "../../components/Button";
import { TextField } from "../../components/TextField";
import { ToastNotification } from "../../components/ToastNotification";
import { useAuth } from "../../hooks/useAuth";
import {
  FooterLinksMobile,
  FooterLinksDesktop,
} from "../../layout/components/FooterLinks";
import { authService } from "../../services/authService";
import { mixpanelActions } from "../../utils/mixpanel";
import {
  Logo,
  Container,
  StyledTile,
  ContentContainer,
  LoadingOverlay,
  loginButtonStyles,
  CodeSubtitle,
  ResendCodeHyperlink,
  CodeDescription,
} from "./MFA.styles";

export function MFAPage() {
  document.title = "MFA - patientMpower";

  const navigate = useNavigate();
  const {
    authenticate,
    isAuthenticated,
    saveDataInLocalStorage,
    loginDetails,
    mfaDetails,
  } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [wasResendSuccessful, setWasResendSuccessful] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");

  useEffect(() => {
    if (isAuthenticated || mfaDetails === undefined) {
      navigate("/");
    }
  }, []);

  const handleOtpSubmit = async () => {
    setIsLoading(true);

    const request = {
      email: mfaDetails !== undefined ? mfaDetails?.email : "",
      code: otp,
    };

    const confirmLoginMfaResponse = await authService.confirmLoginMfa(request);

    setIsLoading(false);

    if (confirmLoginMfaResponse.status === 400) {
      setOtpError(confirmLoginMfaResponse.data);
      return;
    }

    const {
      token,
      preferences,
      portalPreferences,
      intercomDetails,
      userDetails,
      hospitalConfiguration,
      staffPreferences,
      wardConfiguration,
    } = confirmLoginMfaResponse.data;

    if (token !== undefined) {
      localStorage.clear();
      saveDataInLocalStorage({
        token,
        preferences,
        portalPreferences,
        intercomDetails,
        userDetails,
        hospitalConfiguration,
        staffPreferences,
        wardConfiguration,
      });
    }
  };

  const handleOTPChange = (e: any) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setOtp(e.target.value);
    }
  };

  const handleResend = async () => {
    if (loginDetails === undefined) return;

    const { email, password } = loginDetails;

    const responseFromAPI = await authenticate({
      email,
      password,
    });

    if (responseFromAPI.errors === undefined) {
      setWasResendSuccessful(true);
      mixpanelActions.track("User Action: MFAResendCode");
    }

    setTimeout(() => {
      setWasResendSuccessful(false);
    }, 3500);
  };

  const handleButtonDisable = () => {
    if (otp.length !== 6) {
      return true;
    }

    return false;
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter" && !handleButtonDisable()) {
      handleOtpSubmit();
    }
  };

  return (
    <Container>
      <StyledTile>
        {isLoading && <LoadingOverlay />}

        <ContentContainer>
          <Logo src={pmpLogoName} alt="patientMpower logo" />

          <div>
            <CodeDescription>
              <p>
                We have sent a code to <b>{mfaDetails?.hiddenMfaContact}</b>
              </p>
              <p>Please enter it below to confirm your account.</p>
            </CodeDescription>
            <TextField
              name="confirmationCode"
              label="Code"
              value={otp}
              onChange={handleOTPChange}
              onKeyDown={handleKeyDown}
              placeholder="00-00-00"
              errorMessage={otpError}
              backgroudColor="white"
              maxLength={6}
            />
            <div>
              <CodeSubtitle>
                Didn’t receive a code?
                <ResendCodeHyperlink onClick={handleResend}>
                  Resend it.
                </ResendCodeHyperlink>
              </CodeSubtitle>
              {wasResendSuccessful && (
                <ToastNotification
                  message={`New code sent to ${mfaDetails?.hiddenMfaContact}`}
                  type="success"
                  width="450px"
                  rightMargin="45%"
                />
              )}
            </div>
          </div>
          <Button
            type="submit"
            label="Continue"
            isLoading={isLoading}
            className={loginButtonStyles()}
            disabled={handleButtonDisable()}
            onClick={handleOtpSubmit}
          />
        </ContentContainer>
        <FooterLinksMobile />
      </StyledTile>
      <FooterLinksDesktop />
    </Container>
  );
}
