import { styled } from "../../../../../../styles";

export const ModalSkeletonContainer = styled("div", {
  marginLeft: "28px",
  marginTop: "24px",

  minWidth: "834px",

  "@media (max-width: 1530px)": {
    minWidth: "664px",
  },

  variants: {
    isPinnedGraph: {
      true: {
        minWidth: "100%",
      },
    },
  },
});
