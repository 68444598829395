import { useState } from "react";

import { PatientPropChange } from "../../../../../@types/Patient";
import { ArrowForward } from "../../../../../assets/icons/ArrowForward";
import { Button } from "../../../../../components/Button";
import { Checkbox } from "../../../../../components/Checkbox";
import { CancelButton } from "../../PatientAlertsTable/components/ManageTriggersModal/ManageTriggersModal.styles";
import {
  PropsChangesContainer,
  Container,
  PropContainer,
  Subtitle,
  Title,
  PropTitle,
  Content,
  SubmitContainer,
  SendEnrolmentEmailContainer,
  SendEnrolmentEmailText,
} from "./ConfirmChangesModal.styles";

type ConfirmChangesProps = {
  propsChanged: PatientPropChange[];
  onClose: () => void;
  onSave: (onlyCardsOrder: boolean, sendEnrolmentEmail: boolean) => void;
};

export function ConfirmChangesModal({
  propsChanged,
  onClose,
  onSave,
}: ConfirmChangesProps) {
  const [sendEnrolmentEmail, setSendEnrolmentEmail] = useState(true);

  const checkEmailChange = (): boolean => {
    const emailChange = propsChanged.find(
      (change) => change.propName === "Email"
    );

    if (emailChange === undefined) return false;

    return true;
  };

  const handleOnSave = () => {
    if (propsChanged.length === 0) {
      onSave(true, false);
    } else {
      onSave(false, sendEnrolmentEmail);
    }
  };

  if (propsChanged.length === 0) {
    return (
      <Container>
        <Title>Confirm changes</Title>
        <Subtitle larger>
          These configuration changes are saved to only your profile. To change
          for all staff members, please contact your admin.
        </Subtitle>
        <SubmitContainer>
          <Button label="Confirm" type="submit" onClick={handleOnSave} />
          <CancelButton onClick={onClose} style={{ marginLeft: "10px" }}>
            Cancel
          </CancelButton>
        </SubmitContainer>
      </Container>
    );
  }

  const handlePropsToDisplay = () => {
    const propsToDisplay: any[] = [];

    propsChanged.forEach((prop) => {
      propsToDisplay.push(
        <PropContainer key={`prop-container-${prop.propName}`}>
          <PropTitle>{prop.propName}</PropTitle>
          <Content>
            {prop.oldValue !== "-" ? (
              <>
                <p>{prop.oldValue}</p>
                <div
                  style={{
                    margin: "0 15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <ArrowForward />
                </div>
              </>
            ) : null}
            <p>{prop.newValue}</p>
          </Content>
        </PropContainer>
      );
    });

    return propsToDisplay;
  };

  return (
    <Container>
      <Title>Confirm changes</Title>
      <Subtitle>
        Please confirm the changes to the patient details as outlined below.
      </Subtitle>

      <PropsChangesContainer>{handlePropsToDisplay()}</PropsChangesContainer>
      {checkEmailChange() && (
        <SendEnrolmentEmailContainer>
          <Checkbox
            label=""
            checked={sendEnrolmentEmail}
            onChange={(isChecked) => {
              setSendEnrolmentEmail(isChecked);
            }}
          />
          <SendEnrolmentEmailText highlighted>
            Send new enrolment email
          </SendEnrolmentEmailText>
          &nbsp;
          <SendEnrolmentEmailText>
            to the new address above.
          </SendEnrolmentEmailText>
        </SendEnrolmentEmailContainer>
      )}
      <SubmitContainer>
        <Button label="Confirm" type="button" onClick={handleOnSave} />

        <CancelButton onClick={onClose} style={{ marginLeft: "10px" }}>
          Cancel
        </CancelButton>
      </SubmitContainer>
    </Container>
  );
}
