import { styled } from "../../styles";

export const SearchBarContainer = styled("div", {
  display: "flex",
  height: "100%",
  width: "100%",
  padding: "0 25px 0 25px",
  alignItems: "center",
  justifyContent: "flex-end",

  "@media screen and (max-width: 1100px)": {
    padding: "0 10px 0 10px",
  },
});

export const SearchIcon = styled("div", {
  width: "38px",
  height: "38px",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#F4F4F4",
});

export const MobileSearchBarContainer = styled("div", {
  width: "100%",
  height: "62px",
  backgroundColor: "white",
  position: "absolute",
  left: 0,
  top: 0,
  display: "flex",
  justifyContent: "space-evenly",
  alignItems: "center",
});
