import { Select } from "antd";
import React from "react";
import { v4 as uuidv4 } from "uuid";

import { ArrowDown } from "../../assets/icons/ArrowDown";
import { css } from "../../styles";

export interface IOption {
  label: string;
  value: string;
  key: string;
}

type SelectDropdownProps = {
  label?: string;
  innerRef?: any;
  disabled?: boolean;
  options: IOption[];
  placeholder?: string;
  multiple?: boolean;
  width?: number;
  height?: number;
  value?: string | string[];
  className?: string;
  popUpCsss?: string;
  isOpen?: boolean;
  showSearch?: boolean;
  optionFilterProp?: string;
  onValueChange?: (selected: string | string[]) => void;
  handleOnDropdownVisibleChange?: (open: boolean) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
};

const popupOptionsCss = css({
  zIndex: 3001,
  pointerEvents: "auto",

  ".ant-select-item-option-selected": {
    backgroundColor: "$primary-20 !important",

    svg: {
      color: "$primary",
    },
  },

  ".ant-select-item-option-content": {
    fontFamily: "$openSans",
    color: "$black !important",
    fontWeight: "500 !important",
  },
});

export function SelectDropdown({
  innerRef,
  label,
  disabled,
  options,
  placeholder,
  value,
  width = 175,
  height = 42,
  multiple = false,
  className,
  popUpCsss,
  isOpen,
  showSearch = false,
  optionFilterProp,
  onValueChange,
  handleOnDropdownVisibleChange,
  onFocus,
}: SelectDropdownProps) {
  const selectCss = css({
    ".ant-select-selector": {
      height: `${height}px !important`,
      alignItems: "center",
      border: "solid 1px $gray-50 !important",
      boxShadow: "0 0 0 2px $gray-50 !important",
      fontFamily: "$openSans !important",

      "&:focus-within": {
        borderColor: "$primary-30 !important",
      },

      "&:hover": {
        borderColor: "$primary-30 !important",
      },

      ".ant-select-selection-overflow-item": {
        span: {
          "&.ant-select-selection-item": {
            borderRadius: "14px !important",
          },
        },
      },
    },
  });

  return (
    <Select
      aria-label={label ?? ""}
      showSearch={showSearch}
      ref={innerRef}
      disabled={disabled}
      key={uuidv4()}
      placeholder={placeholder}
      suffixIcon={<ArrowDown />}
      mode={multiple ? "multiple" : undefined}
      options={options}
      defaultValue={value}
      maxTagCount="responsive"
      optionFilterProp={optionFilterProp}
      popupClassName={popUpCsss ?? popupOptionsCss()}
      className={className ?? selectCss()}
      open={isOpen}
      onDropdownVisibleChange={handleOnDropdownVisibleChange}
      onChange={onValueChange}
      onFocus={onFocus}
      popupMatchSelectWidth={false}
      tabIndex={0}
      style={{
        width,
        height: `${height}px !important`,
        fontSize: "14px !important",
      }}
    />
  );
}
