import { styled } from "../../../../../../styles";

export const ContentContainer = styled("div", {
  width: "100%",
  padding: "46px 68px 50px 68px",

  borderRadius: "8px",
  backgroundColor: "rgba(248, 248, 248, 0.80)",

  display: "flex",
  flexDirection: "column",
  gap: "28px",
});

export const ContentRow = styled("div", {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",

  p: {
    width: "127px",
    color: "$charcoal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
  },
});

export const ContentSubRow = styled("div", {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  marginRight: "40px",
  height: "40px",
});

export const DescriptionText = styled("span", {
  width: "208px",
  color: "$charcoal",
  fontFamily: "$openSans",
  fontWeight: "300",
  fontSize: "14px",
  lineHeight: "22px",

  svg: {
    marginTop: "4px",
  },
});

export const RulesButton = styled("div", {
  width: "208px",
  height: "40px",
  borderRadius: "12px",
  backgroundColor: "$gray-40",

  color: "$black",
  fontSize: "14px",
  fontFamily: "$openSans",
  fontWeight: "500",
  lineHeight: "22px",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  cursor: "not-allowed",
  opacity: "0.5",
});
