import * as HoverCard from "@radix-ui/react-hover-card";
import { useEffect, useState } from "react";

import { ArtiQAccepted } from "../../../../../../assets/icons/ArtiQAccepted";
import { ArtiQRejected } from "../../../../../../assets/icons/ArtiQRejected";
import { ArtiQUsableAccepted } from "../../../../../../assets/icons/ArtiQUsableAccepted";
import { Toggle } from "../../../../../../components/Toggle";
import { artiQAcceptabilityStatus } from "../../../../../../constants/spirometryConstants";
import useIsMobile from "../../../../../../hooks/useIsMobile";
import { SpirometryListTitle } from "../../MeasurementModal.styles";
import {
  BlowColorDot,
  ListBlowHoverCardContent,
  ListBlowItem,
} from "./BlowAcceptabilitySection.styles";

export type BlowAcceptabilityProps = {
  fvcAcceptabilityReason?: string[];
  fev1Acceptability?: string;
  fvcAcceptability?: string;
  checked?: boolean;
};

export interface IBlowAcceptability {
  blows?: BlowAcceptabilityProps[];
  hideBlow: (index: number) => void;
  highlightedBlowIndex?: number;
  onRowHover?: (index?: number) => void;
}

const criteriasConstants = {
  EOFE_FALSE: "Failure of EOFE criteria",
  BEV_FALSE_1: "Excessive hesitation causing failure of BEV criteria",
  BEV_FALSE_2: "Artefact (P=",
};

export function BlowAcceptabilitySection({
  blows,
  hideBlow,
  highlightedBlowIndex,
  onRowHover,
}: IBlowAcceptability) {
  const [blow1, setBlow1] = useState(true);
  const [blow2, setBlow2] = useState(true);
  const [blow3, setBlow3] = useState(true);
  const [blow4, setBlow4] = useState(true);
  const [blow5, setBlow5] = useState(true);
  const [blow6, setBlow6] = useState(true);
  const [blow7, setBlow7] = useState(true);
  const [blow8, setBlow8] = useState(true);

  const [highlightedBlow, setHighlightedBlow] = useState<number | undefined>(
    undefined
  );

  const { isMobile } = useIsMobile();

  useEffect(() => {
    setBlow1(true);
    setBlow2(true);
    setBlow3(true);
    setBlow4(true);
    setBlow5(true);
    setBlow6(true);
    setBlow7(true);
    setBlow8(true);
  }, [blows]);

  const acceptabilityCriteria = (fvcAcceptabilityReason: any) => {
    const criteria = {
      EOFE: "True",
      BEV: "True",
    };

    const acceptabilityReason = fvcAcceptabilityReason;

    if (!acceptabilityReason) {
      criteria.BEV = "-";
      criteria.EOFE = "-";

      return criteria;
    }

    if (
      acceptabilityReason.some((reason: string) =>
        reason.includes(criteriasConstants.EOFE_FALSE)
      )
    ) {
      criteria.EOFE = "False";
    }

    if (
      acceptabilityReason.some((reason: string) =>
        reason.includes(criteriasConstants.BEV_FALSE_1)
      ) ||
      acceptabilityReason.some((reason: string) =>
        reason.includes(criteriasConstants.BEV_FALSE_2)
      )
    ) {
      criteria.BEV = "False";
    }

    return criteria;
  };

  const blowStatus = (fev1Acceptability: any, fvcAcceptability: any) => {
    if (!fev1Acceptability || !fvcAcceptability)
      return { icon: null, status: "-" };

    if (
      fev1Acceptability === artiQAcceptabilityStatus.ACCEPTABLE &&
      fvcAcceptability === artiQAcceptabilityStatus.ACCEPTABLE
    ) {
      return { icon: <ArtiQAccepted />, status: "Accepted" };
    }

    if (
      (fev1Acceptability === artiQAcceptabilityStatus.REJECTED &&
        fvcAcceptability === artiQAcceptabilityStatus.ACCEPTABLE) ||
      (fev1Acceptability === artiQAcceptabilityStatus.REJECTED &&
        fvcAcceptability === artiQAcceptabilityStatus.USABLE) ||
      (fev1Acceptability === artiQAcceptabilityStatus.REJECTED &&
        fvcAcceptability === artiQAcceptabilityStatus.REJECTED)
    ) {
      return { icon: <ArtiQRejected />, status: "Rejected" };
    }

    return { icon: <ArtiQUsableAccepted />, status: "Usable" };
  };

  const handleCheckChange = (index: number) => {
    hideBlow(index);
    switch (index) {
      case 0:
        return setBlow1(!blow1);
      case 1:
        return setBlow2(!blow2);
      case 2:
        return setBlow3(!blow3);
      case 3:
        return setBlow4(!blow4);
      case 4:
        return setBlow5(!blow5);
      case 5:
        return setBlow6(!blow6);
      case 6:
        return setBlow7(!blow7);
      case 7:
        return setBlow8(!blow8);
      default:
        return true;
    }
  };

  const handleChecked = (index: number) => {
    switch (index) {
      case 0:
        return blow1;
      case 1:
        return blow2;
      case 2:
        return blow3;
      case 3:
        return blow4;
      case 4:
        return blow5;
      case 5:
        return blow6;
      case 6:
        return blow7;
      case 7:
        return blow8;
      default:
        return true;
    }
  };

  const lineColors = [
    "#8974D4",
    "#69B6E1",
    "#DAA676",
    "#89D7AB",
    "#CE75C5",
    "#F5B0C0",
    "#FDDF94",
    "#939393",
  ];

  useEffect(() => {
    setHighlightedBlow(highlightedBlowIndex);
  }, [highlightedBlowIndex]);

  return (
    <div style={{ position: "relative" }}>
      <SpirometryListTitle>
        {isMobile ? (
          <div
            style={{
              width: "calc(50% - 25px)",
            }}
          />
        ) : null}
        <p>Accept.</p>
        <p>Included</p>
      </SpirometryListTitle>
      {blows !== undefined
        ? blows.map((blow, index) => (
            <HoverCard.Root key={index} open>
              <HoverCard.Trigger>
                <ListBlowItem
                  isHovered={
                    index === highlightedBlow || index === highlightedBlowIndex
                  }
                  isOpaque={
                    highlightedBlowIndex !== undefined &&
                    !(
                      index === highlightedBlow ||
                      index === highlightedBlowIndex
                    )
                  }
                  onMouseEnter={() => {
                    setHighlightedBlow(index);
                    if (onRowHover) {
                      onRowHover(index);
                    }
                  }}
                  onMouseLeave={() => {
                    setHighlightedBlow(undefined);
                    if (onRowHover) {
                      onRowHover(undefined);
                    }
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingRight: "10px",
                      width: "50%",
                    }}
                  >
                    <p>Blow {index + 1}</p>
                    <BlowColorDot
                      style={{ backgroundColor: lineColors[index] }}
                    />
                  </div>
                  {
                    blowStatus(blow.fev1Acceptability, blow.fvcAcceptability)
                      .icon
                  }
                  <Toggle
                    checked={handleChecked(index)}
                    onCheckedChange={() => handleCheckChange(index)}
                  />
                </ListBlowItem>
              </HoverCard.Trigger>
              {index === highlightedBlow || index === highlightedBlowIndex ? (
                <ListBlowHoverCardContent>
                  <p>
                    Status:{" "}
                    {
                      blowStatus(blow.fev1Acceptability, blow.fvcAcceptability)
                        .status
                    }
                  </p>
                  <p>
                    BEV criteria:{" "}
                    {acceptabilityCriteria(blow.fvcAcceptabilityReason).BEV}
                  </p>
                  <p>
                    EOFE criteria:{" "}
                    {acceptabilityCriteria(blow.fvcAcceptabilityReason).EOFE}
                  </p>
                </ListBlowHoverCardContent>
              ) : null}
            </HoverCard.Root>
          ))
        : null}
    </div>
  );
}
