import { styled } from "../../../styles";

// Components
export const SurveyAnswerContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  width: "100%",
  padding: "0 10px 0 10px",
});
