import React from "react";

import { Container } from "./Tile.styles";

export type TileProps = React.ComponentProps<typeof Container> & {
  children: React.ReactNode;
};

export function Tile({ children, ...props }: TileProps) {
  return <Container {...props}>{children}</Container>;
}
