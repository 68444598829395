import React from "react";

import { ButtonContainer, IconContainer } from "./IconButton.styles";

export type IconButtonProps = {
  disabled?: boolean;
  className?: string;
  onClick: () => void;
  children: React.ReactNode;
};

export function IconButton({
  onClick,
  children,
  disabled,
  className,
}: IconButtonProps) {
  return (
    <ButtonContainer
      onClick={onClick}
      disabled={disabled}
      className={className}
      disabledStyle={disabled}
      data-testid="icon-button"
    >
      <IconContainer disabledStyle={disabled}>{children}</IconContainer>
    </ButtonContainer>
  );
}
