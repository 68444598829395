import { styled } from "../../../../styles";

export const ModalContainer = styled("div", {
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  padding: "24px 26px",

  "@sm": {
    height: "350px",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    padding: "0",
  },
});

export const Title = styled("p", {
  color: "$black",
  fontFamily: "$openSans",
  fontSize: "16px",
  lineHeight: "22px",
  fontWeight: "500",

  marginBottom: "32px",
  paddingLeft: "16px",
});

export const SectionContainer = styled("div", {
  backgroundColor: "rgba(248, 248, 248, 0.8)",
  width: "100%",
  height: "100%",
  borderRadius: "8px",

  padding: "20px 21px",

  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",

  gap: "25px",

  p: {
    color: "#757575",
    fontSize: "14px",
    fontWeight: "500",
    width: "66px",
    marginRight: "94px",
  },

  "@sm": {
    padding: "20px 12px 21px",
    p: {
      width: "0px",
    },
  },
});

export const Row = styled("div", {
  width: "100%",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",

  p: {
    fontSize: "14px",
    fontFamily: "$openSans",
    color: "$charcoal",
    fontWeight: "400",
    width: "100%",

    "&:first-child": {
      width: "200px !important",
      color: "#757575",
      fontWeight: "500",
    },
  },
});

export const SubmitContainer = styled("div", {
  height: "70px",
  display: "flex",
  gap: "40px",
  padding: "26px 12px 0px 0px",
  alignItems: "center",
});

export const CancelButton = styled("button", {
  height: "$13",
  padding: "0 $13",
  marginLeft: "5px",

  borderRadius: "20px",
  border: "2px solid $white",

  backgroundColor: "$white",

  gap: "$4",

  display: "flex",
  alignItems: "center",

  color: "$black",
  fontWeight: 600,
  lineHeight: "22px",
  fontSize: "$text-lg",
  fontFamily: "$openSans",

  cursor: "pointer",

  "&:focus": {
    outline: "none",
    border: "2px solid $primary-30",
  },

  "@sm": {
    width: "80px",
    height: "26px",
    fontSize: "12px",
    marginLeft: "40px",
    paddingLeft: "19px",
  },
});

export const CheckboxContainer = styled("div", {
  width: "100%",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "8px",

  margin: "20px 0 0 12px",

  p: {
    color: "$gray-90",
    fontFamily: "$openSans",
    fontSize: "14px",
    height: "19px",
  },

  ".ant-checkbox-inner": {
    borderColor: "$gray-100 !important",
  },

  ".ant-checkbox-checked .ant-checkbox-inner": {
    borderColor: "$primary !important",
  },
});
