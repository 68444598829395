import { useState } from "react";

import { PatientNotesTableContent } from "../../../../@types/Patient";
import { ReactComponent as NoNotesImage } from "../../../../assets/images/woman-computer.svg";
import { Table } from "../../../../components/Table";
import { TableSkeleton } from "../../../../components/TableSkeleton";
import { ToastNotification } from "../../../../components/ToastNotification";
import { getPatientNotesData } from "../../../../hooks/queries/notes";
import { useModal } from "../../../../hooks/useModal";
import { desktopTableColumns } from "../NotesSection/patientNotesTableColumns";
import { CreateNoteModal } from "./components/CreateNoteModal";
import { ViewNoteModal } from "./components/ViewNoteModal";
import {
  ImageContainer,
  ManageNotesTitle,
  NoContentMessage,
  NotesTableContainer,
  NotesTableInnerContainer,
} from "./PatientNotesTable.style";

type PatientNotesTableProps = {
  patientId: number;
  content: PatientNotesTableContent[];
  isLoading?: boolean;
  isError?: boolean;
  highlightedRows: boolean[];
  refreshTable: () => void;
  handleEditNote: (noteId: number) => void;
  isDropDownOpen?: boolean;
  showAddNoteButton: boolean;
};

type Notification = {
  show: boolean;
  message: string;
  type: "success" | "info" | "warning" | "error";
  width?: string;
};

export function PatientNotesTable({
  patientId,
  isLoading,
  isError,
  content,
  highlightedRows,
  refreshTable,
  handleEditNote,
  showAddNoteButton,
  isDropDownOpen,
}: PatientNotesTableProps) {
  if (isLoading) {
    return <TableSkeleton columnsNumber={4} />;
  }

  if (isError) {
    return (
      <ImageContainer>
        <p>
          Error getting notes, please{" "}
          <button type="button" onClick={refreshTable}>
            try again
          </button>
          . If the issue continues contact support.
        </p>
      </ImageContainer>
    );
  }
  const { openModal, closeModal } = useModal();
  const { refetch } = getPatientNotesData(patientId); // Used to refresh if data added/edited/removed
  const [notification, setNotification] = useState<Notification>({
    show: false,
    message: "",
    type: "error",
    width: undefined,
  });

  const handleCreateNoteClose = () => {
    refetch();
    closeModal();
  };

  const handleCloseModal = (
    showNotification: boolean,
    notificationMessage: string,
    notificationType: "success" | "warning" | "error",
    notificationWidth?: string
  ) => {
    if (showNotification && notificationMessage && notificationType) {
      setNotification({
        show: showNotification,
        message: notificationMessage,
        type: notificationType,
        width: notificationWidth,
      });
      setTimeout(() => {
        setNotification({
          show: false,
          message: "notificationMessage",
          type: "error",
          width: undefined,
        });
      }, 3500);
    }

    closeModal();
  };

  const handleNewNote = () => {
    openModal(
      <CreateNoteModal
        patientId={patientId}
        setNotification={handleCloseModal}
        onClose={handleCreateNoteClose}
      />,
      {
        width: "auto",
        height: "auto",
        showCloseButton: true,
      }
    );
  };

  const handleOpenEditModal = (noteId: number) => {
    // Pass back to parent
    handleEditNote(noteId);
  };

  const handleRowClick = (patientNoteContent: PatientNotesTableContent) => {
    if (isDropDownOpen) {
      // Do not open the view notes modal when the dropdown (three dots) is open with options
      return;
    }
    openModal(
      <ViewNoteModal
        noteId={patientNoteContent.id}
        onEditClick={handleOpenEditModal}
      />,
      {
        width: "auto",
        height: "auto",
        showCloseButton: true,
      }
    );
  };

  if (content && content.length > 0) {
    return (
      <>
        {showAddNoteButton && (
          <ManageNotesTitle onClick={handleNewNote}>Add note</ManageNotesTitle>
        )}
        <NotesTableContainer>
          <NotesTableInnerContainer>
            <Table
              data={content}
              columns={desktopTableColumns}
              onRowClick={handleRowClick}
              highlightedRows={highlightedRows}
              highlightColor="rgba(233, 230, 244, .48)"
            />
          </NotesTableInnerContainer>
        </NotesTableContainer>
        {notification.show ? (
          <ToastNotification
            message={notification.message}
            type={notification.type}
            width={notification.width}
          />
        ) : null}
      </>
    );
  }

  return (
    <>
      {showAddNoteButton && (
        <ManageNotesTitle onClick={handleNewNote}> Add note </ManageNotesTitle>
      )}
      <ImageContainer>
        <NoNotesImage width={181} height={158} />
        <NoContentMessage>
          You have not added any notes yet. Notes can be used to track billing
          codes and more.
        </NoContentMessage>
      </ImageContainer>
    </>
  );
}
