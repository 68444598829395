import { FormikProps } from "formik";
import { useEffect, useMemo, useState } from "react";

import { IArticle } from "../../../../../../@types/Articles";
import { Close } from "../../../../../../assets/icons/Close";
import { RichTextEditor } from "../../../../../../components/RichTextEditor";
import {
  IOption,
  SelectDropdown,
} from "../../../../../../components/SelectDropdown";
import { SkeletonLoading } from "../../../../../../components/Skeleton";
import { TextField } from "../../../../../../components/TextField";
import { useModal } from "../../../../../../hooks/useModal";
import { mixpanelActions } from "../../../../../../utils/mixpanel";
import { AddLinkModal } from "./components/AddLinkModal";
import {
  CardImageSelector,
  ContentContainer,
  ContentRow,
  ContentSubRow,
  ErrorMessage,
  inputCss,
  MainContainer,
  RicheTextEditorContainer,
  SelectedImageContainer,
  selectCss,
  AddLinkText,
  URLPreviewContainer,
  CloseContainer,
  URLLink,
} from "./ContentSection.styles";

interface IContentSectionProps {
  formik: FormikProps<IArticle>;
  wardOptions: IOption[];
  tagOptions: IOption[];
  openImageLibrary: () => void;
}

export function ContentSection({
  formik,
  wardOptions,
  tagOptions,
  openImageLibrary,
}: IContentSectionProps) {
  const { openModal, closeModal } = useModal();

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);

    return () => {
      setIsMounted(false);
    };
  }, [formik]);

  const tagsDropdown = useMemo(() => {
    if (!isMounted) return null;

    return (
      <SelectDropdown
        placeholder="Select tags"
        className={selectCss()}
        multiple
        width={216}
        height={42}
        value={formik.values.tags}
        onValueChange={(values) => {
          formik.setFieldValue("tags", values as string[]);
        }}
        options={tagOptions}
        onFocus={() => mixpanelActions.track("UserAtcion: Tags")}
      />
    );
  }, [tagOptions, isMounted]);

  const wardsDropdown = useMemo(() => {
    if (!isMounted) return null;

    return (
      <SelectDropdown
        placeholder="Select wards"
        className={selectCss()}
        multiple
        width={216}
        height={42}
        value={formik.values.wards?.map((ward) => {
          return ward.toString();
        })}
        onValueChange={(values) => {
          formik.setFieldValue("wards", values as string[]);
        }}
        options={wardOptions}
      />
    );
  }, [wardOptions, isMounted]);

  const handleRemoveImage = () => {
    formik.setFieldValue("imageName", undefined);
  };

  const shouldShowErrorMessage = (
    field: "title" | "wards" | "imageName" | "articleText"
  ) => {
    return formik.touched[field] ? formik.errors[field] ?? "" : "";
  };

  const handleAddLinkOnClose = (url?: string) => {
    if (url) {
      formik.setFieldValue("articleUrl", url);
      formik.setFieldValue("articleText", "");
    }

    closeModal();
  };

  const handleAddLink = () => {
    mixpanelActions.track("User Action: AddURLInsteadButton");

    openModal(<AddLinkModal onClose={handleAddLinkOnClose} />, {
      width: "100%",
      height: "534px",
      showCloseButton: true,
    });
  };

  const handleRemoveLink = () => {
    formik.setFieldValue("articleUrl", undefined);
  };

  return (
    <MainContainer>
      <ContentContainer>
        <ContentRow>
          <p>Title *</p>
          <TextField
            label=""
            name="title"
            value={formik.values.title}
            placeholder="Article title"
            onChange={formik.handleChange}
            backgroudColor="white"
            className={inputCss()}
            maxLength={50}
            errorMessage={shouldShowErrorMessage("title")}
            onFocus={() => mixpanelActions.track("UserAction: Title")}
          />
        </ContentRow>
        <ContentRow>
          <p>Card description</p>
          <TextField
            label=""
            name="description"
            placeholder="Describe your article"
            value={formik.values.description}
            onChange={formik.handleChange}
            backgroudColor="white"
            className={inputCss()}
            maxLength={90}
            onFocus={() => mixpanelActions.track("UserAction: Description")}
          />
        </ContentRow>
        <ContentRow isSmallInput>
          <ContentSubRow isError={shouldShowErrorMessage("wards").length > 0}>
            <p>Wards *</p>
            {wardOptions ? (
              wardsDropdown
            ) : (
              <SkeletonLoading width={216} height={42} />
            )}
            <ErrorMessage>{shouldShowErrorMessage("wards")}</ErrorMessage>
          </ContentSubRow>
          <ContentSubRow>
            <p>Tags</p>
            {tagOptions ? (
              tagsDropdown
            ) : (
              <SkeletonLoading width={216} height={42} />
            )}
          </ContentSubRow>
        </ContentRow>
        <ContentRow>
          <p>Image</p>
          {formik.values.imageName === undefined ? (
            <CardImageSelector
              onClick={() => {
                mixpanelActions.track("UserAction: ChooseImage");
                openImageLibrary();
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  mixpanelActions.track("UserAction: ChooseImage");
                  openImageLibrary();
                }
              }}
              tabIndex={0}
            >
              Choose card image
            </CardImageSelector>
          ) : (
            <CardImageSelector>
              <SelectedImageContainer>
                <p>{formik.values.imageName}</p>
                <div
                  aria-label="Image"
                  style={{ marginLeft: "8px" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemoveImage();
                  }}
                  onKeyDown={(e) => {
                    e.stopPropagation();
                    handleRemoveImage();
                  }}
                  role="button"
                  tabIndex={0}
                >
                  <Close />
                </div>
              </SelectedImageContainer>
            </CardImageSelector>
          )}
          <ErrorMessage>{shouldShowErrorMessage("imageName")}</ErrorMessage>
        </ContentRow>
      </ContentContainer>

      {formik.values.articleUrl === undefined ? (
        <RicheTextEditorContainer
          isError={shouldShowErrorMessage("articleText").length > 1}
        >
          <AddLinkText onClick={() => handleAddLink()}>
            Add link instead
          </AddLinkText>
          <RichTextEditor
            placeholderTxt="Add your article text here, view how it looks in app from the preview tab"
            height={415}
            value={formik.values.articleText}
            onValueChange={(value) => {
              if (value === "<p><br></p>") {
                formik.setFieldValue("articleText", "");
                return;
              }
              formik.setFieldValue("articleText", value);
            }}
            onFocus={() => mixpanelActions.track("UserAction: Text")}
          />
          <ErrorMessage isArticleText>
            {shouldShowErrorMessage("articleText")}
          </ErrorMessage>
        </RicheTextEditorContainer>
      ) : (
        <URLPreviewContainer>
          <URLLink
            onClick={() => window.open(formik.values.articleUrl, "_blank")}
          >
            {formik.values.articleUrl}
          </URLLink>
          <CloseContainer onClick={handleRemoveLink}>
            <Close />
          </CloseContainer>
        </URLPreviewContainer>
      )}
    </MainContainer>
  );
}
