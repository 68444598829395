import React, { useState, useEffect } from "react";

import { Burger } from "../../assets/icons/Burger";
import { Close } from "../../assets/icons/Close";
import { PMPLogo } from "../../assets/icons/PMPLogo";
import {
  Container,
  LogoContainer,
  MobileMenuButton,
} from "./TopPanelMenu.styles";

export type TopPanelMenuComponentProps = {
  onLogoClick?: () => void;
  children?: React.ReactNode;
};

export type TopBarMobileMenuButtonComponentProps = {
  onClick: (isMenuOpen: boolean) => void;
  isMobileLeftPanelMenuOpen?: boolean;
  smallScreenWidth?: number;
};

function TopPanelMenuMobileButtonComponent({
  onClick,
  isMobileLeftPanelMenuOpen = false,
  smallScreenWidth = 768,
}: TopBarMobileMenuButtonComponentProps) {
  const [isSmallScreen, setIsSmallScreen] = useState(
    window.innerWidth <= smallScreenWidth
  );

  const handleOnClickOverButton = () => {
    onClick(isMobileLeftPanelMenuOpen);
  };

  useEffect(() => {
    const onResize = () => {
      setIsSmallScreen(window.innerWidth <= smallScreenWidth);
    };
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  // Only display for small screens
  if (!isSmallScreen) {
    return <MobileMenuButton style={{ display: "none" }} />;
  }
  return (
    <MobileMenuButton onClick={handleOnClickOverButton}>
      {isMobileLeftPanelMenuOpen ? <Close /> : <Burger />}
    </MobileMenuButton>
  );
}

function TopPanelMenuComponent({
  children,
  onLogoClick,
}: TopPanelMenuComponentProps) {
  return (
    <Container>
      <LogoContainer
        role="button"
        onClick={onLogoClick}
        aria-label="patientMpower logo"
      >
        <PMPLogo />
      </LogoContainer>

      {children}
    </Container>
  );
}

export { TopPanelMenuComponent, TopPanelMenuMobileButtonComponent };
