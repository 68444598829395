import { styled } from "../../styles";

export const Container = styled("nav", {
  width: "100%",
  height: "62px",
  padding: "0 40px",

  display: "flex",

  position: "fixed",
  top: 0,

  zIndex: 2000,

  backgroundColor: "$white",
  boxShadow: "0px 0px 4px 1px rgba(0, 0, 0, 0.16)",

  a: {
    height: "100%",
    padding: "0 12px",

    position: "relative",
    marginRight: "20px",

    display: "flex",
    alignItems: "center",

    color: "$gray-90",
    fontFamily: "$openSans",

    fontWeight: 600,
    lineHeight: "22px",
    textDecoration: "none",

    boxSizing: "border-box",

    borderLeft: "2px solid transparent",
    borderRight: "2px solid transparent",

    "&.active:after": {
      content: "",

      left: 0,
      right: 0,
      bottom: 0,
      position: "absolute",

      backgroundColor: "$secondary-lighter",

      height: "5px",
      borderRadius: "2px",

      "&:focus-visible": {
        bottom: "-14px",
      },
    },

    "&:hover:not(.active):after": {
      content: "",

      left: 0,
      right: 0,
      bottom: 0,
      position: "absolute",

      backgroundColor: "$secondary-lightest",

      height: "5px",
      borderRadius: "2px",
    },

    "&:last-child": {
      marginRight: "0",
    },

    "&:focus-visible": {
      outline: "none",
      border: "2px solid $primary-30",

      height: "38px",

      padding: "8px 12px",
      margin: "12px 24px 12px 0",
      borderRadius: "12px",

      "&.active:after": {
        content: "",

        left: 0,
        right: 0,
        bottom: "-14px",
        position: "absolute",

        backgroundColor: "$secondary-lighter",

        height: "5px",
        borderRadius: "2px",

        "&:focus-visible": {
          bottom: "-14px",
        },
      },

      "&:hover:not(.active):after": {
        content: "",

        left: 0,
        right: 0,
        bottom: "-14px",
        position: "absolute",

        backgroundColor: "$secondary-lightest",

        height: "5px",
        borderRadius: "2px",

        "&:focus-visible": {
          bottom: "-14px",
        },
      },
    },
  },

  "@sm": {
    padding: "0 18px",
  },
});

export const MobileMenuButton = styled("button", {
  display: "flex",
  alignItems: "center",

  border: "none",
  backgroundColor: "transparent",
});

export const LogoContainer = styled("div", {
  minWidth: "36px",
  minHeight: "36px",

  margin: "13px 46px 13px 0",
  cursor: "pointer",

  "@sm": {
    margin: "12px 0",

    width: "40px",
    height: "40px",
  },
});
