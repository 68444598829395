export function CircleChevron({ ...rest }) {
  return (
    <svg
      {...rest}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        r="9.5"
        transform="matrix(0 -1 -1 0 10 10)"
        fill="white"
        stroke="#ECECEC"
      />
      <path
        d="M13.1835 7.99313C13.354 7.81181 13.6304 7.81181 13.8008 7.99313C13.9713 8.17444 13.9713 8.46841 13.8008 8.64973L10.3088 12.364C10.1383 12.5453 9.86191 12.5453 9.69145 12.364L6.19938 8.64973C6.02892 8.46841 6.02892 8.17444 6.19938 7.99313C6.36985 7.81181 6.64623 7.81181 6.8167 7.99313L10.0001 11.3791L13.1835 7.99313Z"
        fill="#3C3C3C"
      />
    </svg>
  );
}
