import * as AccordionPrimitive from "@radix-ui/react-accordion";

import { CircleChevron } from "../../assets/icons/CircleChevron";
import { styled, keyframes } from "../../styles";

// Effects
export const slideDown = keyframes({
  from: { height: 0 },
  to: { height: "var(--radix-accordion-content-height)" },
});

export const slideUp = keyframes({
  from: { height: "var(--radix-accordion-content-height)" },
  to: { height: 0 },
});

// Components
export const StyledAccordion = styled(AccordionPrimitive.Root, {
  width: "100%",
});

export const StyledItem = styled(AccordionPrimitive.Item, {
  overflow: "hidden",
  marginTop: 1,

  "&:focus-within": {
    position: "relative",
    zIndex: 1,
  },
});

export const StyledHeader = styled(AccordionPrimitive.Header, {
  all: "unset",
  display: "flex",
});

export const StyledTrigger = styled(AccordionPrimitive.Trigger, {
  all: "unset",
  boxSizing: "border-box",

  height: "100%",
  width: "100%",

  padding: "12px 44px",

  backgroundColor: "white",

  display: "flex",
  justifyContent: "space-between",

  variants: {
    customColor: {
      true: {
        backgroundColor: "$gray-20",
      },
    },
  },

  "&:focus-visible": {
    outline: "none",
    backgroundColor: "rgba(233, 230, 244, .48)",

    border: "2px solid $primary-30",
  },
});

export const StyledAccordionTitle = styled("p", {
  fontFamily: "$openSans",
  margin: 0,

  color: "$black",

  fontSize: "14px",
  lineHeight: "22px",
});

export const StyledAccordionSubtitle = styled("p", {
  fontFamily: "$openSans",
  margin: 0,

  color: "$black",

  fontSize: "10px",
  lineHeight: "16px",
});

export const StyledContent = styled(AccordionPrimitive.Content, {
  overflow: "hidden",

  '&[data-state="open"]': {
    animation: `${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
  },
  '&[data-state="closed"]': {
    animation: `${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
  },
});

export const StyledChevron = styled(CircleChevron, {
  marginRight: "14px",
  marginTop: "2px",

  transition: "transform 300ms cubic-bezier(0.87, 0, 0.13, 1)",

  "[data-state=open] &": { transform: "rotate(180deg)" },
});

// Header Components
export const HeaderComponentContainer = styled("div", {
  display: "flex",
  alignItems: "center",
});

// Content Components
export const AccordionContentContainer = styled("div", {
  backgroundColor: "$white",
  padding: "12px 78px 38px",
});

export const AccordionContentItem = styled("div", {
  display: "grid",
  marginBottom: "14px",

  gridTemplateColumns: "1fr 1fr",

  "&:last-child": {
    marginBottom: "0",
  },
});

export const AccordionContentItemKey = styled("p", {
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "16px",

  color: "$gray-80",
});

export const AccordionContentItemValue = styled("p", {
  color: "$black",

  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "16px",
});
