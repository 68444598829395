import React from "react";

export function MagnifyingGlass({ ...rest }) {
  return (
    <svg
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M16.5643 18.7518L12.9162 15.0995C12.4442 15.5002 11.8923 15.8127 11.2604 16.0372C10.6286 16.2616 9.95326 16.3738 9.23448 16.3738C7.49251 16.3738 6.01794 15.7696 4.81076 14.5611C3.60359 13.3527 3 11.8946 3 10.1869C3 8.4792 3.60423 7.02112 4.81269 5.81267C6.02113 4.60422 7.48317 4 9.19879 4C10.9144 4 12.3725 4.60422 13.573 5.81267C14.7735 7.02112 15.3738 8.48039 15.3738 10.1905C15.3738 10.8776 15.2656 11.5356 15.0493 12.1643C14.8329 12.793 14.5084 13.383 14.0757 13.9343L17.7714 17.5923C17.9331 17.7489 18.014 17.9379 18.014 18.1592C18.014 18.3805 17.9259 18.5773 17.7497 18.7497C17.5855 18.9166 17.3856 19 17.1499 19C16.9142 19 16.719 18.9173 16.5643 18.7518ZM9.21271 14.753C10.4772 14.753 11.5501 14.307 12.4312 13.4149C13.3124 12.5229 13.753 11.4469 13.753 10.1869C13.753 8.92689 13.3121 7.85088 12.4304 6.95886C11.5487 6.06682 10.4761 5.6208 9.21271 5.6208C7.93609 5.6208 6.85161 6.06682 5.95929 6.95886C5.06696 7.85088 4.6208 8.92689 4.6208 10.1869C4.6208 11.4469 5.06668 12.5229 5.95845 13.4149C6.85021 14.307 7.93496 14.753 9.21271 14.753Z"
        fill="#B5B5B6"
      />
    </svg>
  );
}
