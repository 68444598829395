import { styled } from "../../styles";

export const ButtonContainer = styled("button", {
  margin: 0,
  padding: 0,
  border: "none",
  fontWeight: 400,
  color: "$primary",
  cursor: "pointer",
  lineHeight: "16px",
  fontSize: "$text-sm",
  fontFamily: "$openSans",
  backgroundColor: "transparent",

  "&:hover": {
    color: "$primary-70",
  },

  variants: {
    color: {
      gray: {
        color: "$gray-80",
      },
      black: {
        color: "$black",
      },
    },
    hoverColor: {
      secondary: {
        "&:hover": {
          color: "$secondary",
        },
      },
    },
    fontWeight: {
      bold: {
        fontWeight: 600,
      },
      semiBold: {
        fontWeight: 500,
      },
    },
    size: {
      md: {
        fontSize: "$text-md",
      },
      lg: {
        fontSize: "$text-lg",
      },
    },
    underline: {
      true: {
        ":hover": {
          textDecoration: "underline",
        },
      },
    },
    disabledStyle: {
      true: {
        opacity: 0.36,
        cursor: "not-allowed",
      },
    },
  },
});
