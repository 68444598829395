import { styled } from "../../../../../../styles";

export const DeleteDeviceModalContainer = styled("div", {
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  padding: "24px",
});

export const RemoveDeviceTitle = styled("div", {
  color: "#212121",
  fontFamily: "$openSans",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "600",
  lineHeight: "22px",

  paddingLeft: "16px",
  marginBottom: "15px",
});

export const InputsContainer = styled("div", {
  height: "100%",
  width: "100%",
  borderRadius: "8px",
  background: "rgba(248, 248, 248, 0.80)",
  marginBottom: "28px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  padding: "20px",
  gap: "9px",

  fontFamily: "$openSans",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "600",
  lineHeight: "22px",
});

export const InputRow = styled("div", {
  width: "100%",
  height: "42px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  position: "relative",

  p: {
    width: "150px",
    color: "#757575",
  },

  ".ant-select-selection-placeholder": {
    fontWeight: "400 !important",
  },

  ".ant-select-selector": {
    borderWidth: "2px !important",
  },

  ".ant-select-arrow": {
    top: "66%",
  },
});

export const SubmitContainer = styled("div", {
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
});

export const DeleteDeviceCancelButton = styled("button", {
  height: "$13",
  padding: "0 $13",
  marginLeft: "5px",

  borderRadius: "20px",
  border: "2px solid $white",

  backgroundColor: "$white",

  gap: "$4",

  display: "flex",
  alignItems: "center",

  color: "$black",
  fontWeight: 600,
  lineHeight: "22px",
  fontSize: "$text-lg",
  fontFamily: "$openSans",

  cursor: "pointer",

  "&:focus": {
    outline: "none",
    border: "2px solid $primary-30",
  },

  "@sm": {
    width: "80px",
    height: "26px",
    fontSize: "12px",
    marginLeft: "40px",
    paddingLeft: "19px",
  },
});

export const ContentValue = styled("p", {
  color: "$charcoal !important",
  fontWeight: "400 !important",
});
