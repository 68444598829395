import { styled } from "../../../../styles";

export const AppointmentsContainer = styled("div", {
  width: "100%",
  minHeight: "220px",
  height: "100%",

  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  flexWrap: "wrap",

  backgroundColor: "$white",
  fontSize: "14px",
  fontWeight: "500",
  color: "#666666",

  marginTop: "6px",

  th: {
    zIndex: 1,
  },
});

export const TagContainer = styled("div", {
  width: "100%",
  display: "flex",
  justifyContent: "center",

  div: {
    position: "initial",
    width: "129px",
    justifyContent: "center",
    fontSize: "12px",
  },
});

export const DeleteAppointmentButton = styled("div", {
  width: "28px",
  height: "28px",

  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  visibility: "hidden",

  borderRadius: "$round",

  "&:hover": {
    backgroundColor: "rgba(217, 217, 217, 0.4)",
  },
});
