import { css, styled } from "../../styles";

export const ArticlesContainer = styled("div", {
  display: "flex",
  position: "relative",
  flexDirection: "column",
});

export const ArticlesTableContainer = styled("div", {
  marginTop: "$4",

  height: "calc(100vh - 300px)",
  maxHeight: "800px",

  position: "relative",
});

export const AddArticleButton = styled("div", {
  width: "90px",
  height: "26px",
  padding: "5px 11px",

  borderRadius: "12px",
  backgroundColor: "$black",

  color: "$white",
  textAlign: "center",
  fontFamily: "$openSans",
  fontSize: "12px",
  fontWeight: "500",
  lineHeight: "16px",

  position: "absolute",
  right: "0",
  top: "-32px",

  cursor: "pointer",

  "&:hover": {
    backgroundColor: "$charcoal",
  },
});

export const PageTitleContainer = styled("div", {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "12px",
});

export const PMPHospitalGroupSelect = styled("div", {
  width: "fit-content",
  position: "absolute",
  top: -32,
  right: 100,
});

// CSS

export const HospitalGroupSelectCss = css({
  width: "fit-content !important",
  maxWidth: "200px !important",
  minWidth: "100px !important",
  fontFamily: "$openSans !important",

  ".ant-select-selector": {
    borderRadius: "12px !important",
    border: "1.5px $gray-70 solid !important",
    height: "26px !important",
  },

  ".ant-select-arrow": {
    top: "43% !important",
    insetInlineEnd: "6px !important",
  },

  ".ant-select-selection-item": {
    paddingInlineEnd: "26px !important",
    fontSize: "12px !important",
    fontWeight: "500 !important",
    color: "$gray-80 !important",
  },

  ".ant-select-selection-placeholder": {
    paddingInlineEnd: "20px !important",
    paddingInlineStart: "6px !important",
    fontSize: "12px !important",
    fontWeight: "500 !important",
    color: "$gray-80 !important",
  },
});
