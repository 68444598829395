import { Checkbox as AntdCheckbox } from "antd";

import { CheckboxLabel, antdCheckboxStyles } from "./Checkbox.styles";

type CheckboxProps = {
  label: string;
  onChange: (isChecked: boolean) => void;
  defaultChecked?: boolean;
  checked: boolean;
  id?: string;
  disabled?: boolean;
  labelPosition?: string;
  spacing?: string;
};

export function Checkbox({
  defaultChecked,
  onChange,
  label,
  checked,
  id,
  disabled = false,
  labelPosition = "before",
  spacing = "0px",
}: CheckboxProps) {
  if (labelPosition === "after") {
    return (
      <CheckboxLabel>
        <AntdCheckbox
          id={id}
          defaultChecked={defaultChecked}
          onChange={(e) => onChange(e.target.checked)}
          className={antdCheckboxStyles()}
          disabled={disabled}
          checked={checked}
        />
        <span
          style={{
            marginLeft: spacing,
          }}
        >
          {label}
        </span>
      </CheckboxLabel>
    );
  }

  return (
    <CheckboxLabel>
      <span
        style={{
          marginRight: spacing,
        }}
      >
        {label}
      </span>
      <AntdCheckbox
        id={id}
        defaultChecked={defaultChecked}
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
        className={antdCheckboxStyles()}
        disabled={disabled}
      />
    </CheckboxLabel>
  );
}
