import dayjs, { Dayjs } from "dayjs";
import { useFormik } from "formik";
import { useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";

import { ICreateAppointmentRequest } from "../../../../@types/Appointment";
import { Notification } from "../../../../@types/Notification";
import { Button } from "../../../../components/Button";
import { DateTimePickerInput } from "../../../../components/DateTimePicker";
import { IOption, SelectDropdown } from "../../../../components/SelectDropdown";
import { TextField } from "../../../../components/TextField";
import { usePatientList } from "../../../../hooks/queries/patients";
import { getTableHospitalStaff } from "../../../../hooks/queries/staff";
import { appointmentsService } from "../../../../services/appointmentsService";
import { mixpanelActions } from "../../../../utils/mixpanel";
import { CancelButton } from "../../../Settings/components/SecurityModal/SecurityModal.styles";
import {
  ContentContainer,
  ErrorMessage,
  ModalContainer,
  Row,
  submitButtonCss,
  SubmitContainer,
  Title,
  urlInputCss,
} from "./CreateAppointmentModal.styles";

type CreateAppointmentModalProps = {
  onClose: (notification?: Notification, refetchTable?: boolean) => void;
};

export function CreateAppointmentModal({
  onClose,
}: CreateAppointmentModalProps) {
  const { patients } = usePatientList({ variant: "active" }, false);
  const { staff } = getTableHospitalStaff();

  const [isLoading, setIsLoading] = useState(false);

  const handleOnSubmitForm = async ({
    patientId,
    staffId,
    duration,
    appointmentUrl,
    date,
  }: any) => {
    setIsLoading(true);

    const request: ICreateAppointmentRequest = {
      patientId: parseInt(patientId, 10),
      staffId: parseInt(staffId, 10),
      durationInMinutes: parseInt(duration, 10),
      appointmentUrl,
      date,
    };

    const notification: Notification = {
      show: true,
      message: "",
      type: "success",
      width: "max-content",
    };

    let refetchTable = false;

    const result = await appointmentsService.createAppointment(request);

    setIsLoading(false);

    if (result.status >= 200 && result.status < 300) {
      mixpanelActions.track("Event: AppointmentCreated");
      notification.message = "Appointment successfully created";
      refetchTable = true;
      onClose(notification, refetchTable);
    } else {
      notification.message = "Error creating appointment, please try again";
      notification.type = "error";
      onClose(notification, refetchTable);
    }
  };

  const formValidationSchema = yup.object().shape({
    patientId: yup.string().required("Please select a patient."),
    staffId: yup.string().required("Please select a HCP."),
    duration: yup.string().required("Please select a duration."),
    appointmentUrl: yup.string().required("Please enter a appointment url"),
    date: yup.date().required("Please enter a date"),
  });

  const formik = useFormik({
    initialValues: {
      patientId: undefined,
      staffId: undefined,
      duration: undefined,
      appointmentUrl: undefined,
      date: dayjs(),
    },
    onSubmit: handleOnSubmitForm,
    validationSchema: formValidationSchema,
  });

  const patientsSelect = useMemo(() => {
    const patientOptions = patients?.map((patient) => {
      return {
        label: patient.name,
        value: patient.id.toString(),
        key: uuidv4(),
      } as IOption;
    });

    return (
      <SelectDropdown
        showSearch
        options={patientOptions ?? []}
        width={240}
        placeholder="Select patient"
        optionFilterProp="label"
        value={formik.values.patientId}
        onValueChange={(value: string | string[]) => {
          formik.setFieldValue("patientId", value.toString());
        }}
      />
    );
  }, [patients]);

  const hospitalStaffSelect = useMemo(() => {
    const staffOptions = staff?.map((staff) => {
      return {
        label: staff.name,
        value: staff.id.toString(),
        key: uuidv4(),
      } as IOption;
    });

    return (
      <SelectDropdown
        showSearch
        options={staffOptions ?? []}
        width={240}
        placeholder="Select HCP"
        optionFilterProp="label"
        value={formik.values.staffId}
        onValueChange={(value: string | string[]) => {
          formik.setFieldValue("staffId", value.toString());
        }}
      />
    );
  }, [staff]);

  const durationSelect = useMemo(() => {
    const durationOptions: IOption[] = [
      {
        label: "15 minutes",
        value: "15",
        key: uuidv4(),
      },
      {
        label: "30 minutes",
        value: "30",
        key: uuidv4(),
      },
      {
        label: "45 minutes",
        value: "45",
        key: uuidv4(),
      },
      {
        label: "60 minutes",
        value: "60",
        key: uuidv4(),
      },
    ];

    return (
      <SelectDropdown
        options={durationOptions}
        width={240}
        placeholder="Select duration"
        value={formik.values.duration}
        onValueChange={(value: string | string[]) => {
          formik.setFieldValue("duration", value.toString());
        }}
      />
    );
  }, []);

  const shouldShowErrorMessage = (
    field: "staffId" | "patientId" | "duration" | "date" | "appointmentUrl"
  ): string => {
    let error = "";

    if (formik.errors[field] !== undefined) {
      error = ((formik.errors[field] as string) ?? "").toString();
    }
    return formik.touched[field] ? error : "";
  };

  return (
    <ModalContainer>
      <Title>Create appointment</Title>

      <ContentContainer>
        <Row>
          <p>HCP</p>
          {hospitalStaffSelect}
          <ErrorMessage>{shouldShowErrorMessage("staffId")}</ErrorMessage>
        </Row>
        <Row>
          <p>Patient</p>
          {patientsSelect}
          <ErrorMessage>{shouldShowErrorMessage("patientId")}</ErrorMessage>
        </Row>
        <Row>
          <p>Duration</p>
          {durationSelect}
          <ErrorMessage>{shouldShowErrorMessage("duration")}</ErrorMessage>
        </Row>
        <Row>
          <p>Appoint. URL</p>
          <TextField
            label=""
            onChange={formik.handleChange}
            name="appointmentUrl"
            placeholder="Paste URL here"
            backgroudColor="white"
            value={formik.values.appointmentUrl}
            className={urlInputCss()}
          />
          <ErrorMessage>
            {shouldShowErrorMessage("appointmentUrl")}
          </ErrorMessage>
        </Row>
        <Row>
          <p>Date</p>
          <DateTimePickerInput
            defaultValue={formik.values.date}
            onChange={(value: Dayjs) => formik.setFieldValue("date", value)}
            width="240px"
          />
          <ErrorMessage>{shouldShowErrorMessage("date")}</ErrorMessage>
        </Row>
      </ContentContainer>

      <SubmitContainer>
        <Button
          label="Create"
          type="submit"
          onClick={formik.submitForm}
          isLoading={isLoading}
          className={submitButtonCss()}
        />

        <CancelButton
          onClick={() => {
            onClose();
            mixpanelActions.track("Event: CreateCancelled");
          }}
          style={{ marginLeft: "-35px", padding: "0 30px" }}
        >
          Cancel
        </CancelButton>
      </SubmitContainer>
    </ModalContainer>
  );
}
