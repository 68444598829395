import { createColumn } from "../../../../../../components/Table";

export const devicesTableColumns = [
  createColumn({
    keyName: "name",
    header: () => "Device",
    cell: (info) => info.renderValue(),
    size: 200,
  }),
  createColumn({
    keyName: "added",
    header: () => "Added",
    cell: (info) => info.renderValue(),
  }),

  createColumn({
    keyName: "identifier",
    header: () => "Identifier",
    cell: (info) => info.renderValue(),
  }),

  createColumn({
    keyName: "firstUsed",
    header: () => "First Used",
    cell: (info) => info.renderValue(),
  }),

  createColumn({
    keyName: "lastUsed",
    header: () => "Last Used",
    cell: (info) => info.renderValue(),
  }),

  createColumn({
    keyName: "action",
    header: () => "",
    cell: (info) => info.renderValue(),
    size: 10,
  }),
];

export const mobileAccordionListColumns = [
  { key: "Hospital ID", value: "hospitalId" },
  { key: "Survey ID", value: "id" },
];
