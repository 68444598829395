import { styled } from "../../../styles";

export const ChartValueContainer = styled("div", {
  display: "flex",
  width: "85%",

  justifyContent: "space-between",

  marginTop: "24px",
});

export const Label = styled("p", {
  fontSize: "$text-md",
  lineHeight: "16px",

  color: "$gray-100",
});

export const Value = styled("p", {
  fontWeight: 600,

  fontSize: "$text-lg",
  lineHeight: "22px",

  color: "$gray-100",
});
