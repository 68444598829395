import { styled } from "../../../../styles";

export const ModalContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",

  padding: "24px 26px",

  fontFamily: "$openSans !important",

  "@sm": {
    borderRadius: "8px",
    padding: "24px 0px 22px 0px",
  },
});

export const Title = styled("p", {
  color: "$black",
  fontFamily: "$openSans",
  fontSize: "16px",
  lineHeight: "22px",
  fontWeight: "600",

  marginBottom: "18px",
  paddingLeft: "16px",
});

export const SectionContainer = styled("div", {
  backgroundColor: "rgba(248, 248, 248, 0.8)",
  width: "100%",
  height: "100%",
  borderRadius: "8px",

  padding: "20px 21px",

  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",

  gap: "25px",

  p: {
    color: "#757575",
    fontSize: "14px",
    fontWeight: "500",
    width: "66px",
    marginRight: "94px",
  },

  "@sm": {
    padding: "20px 12px 21px",
    p: {
      width: "0px",
    },
  },
});

export const ContainerRow = styled("div", {
  width: "80%",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",

  ".ant-select": {
    height: "42px !important",
  },

  "@sm": {
    width: "100%",
  },

  variants: {
    disabled: {
      true: {
        opacity: "0.38",
        pointerEvents: "none",
      },
    },
  },
});

export const UnitParagraph = styled("p", {
  paddingLeft: "4px",
});

export const NoteSection = styled("div", {
  width: "100%",
  height: "120px",
  borderRadius: "8px",
  backgroundColor: "rgba(233, 230, 244, 0.60)",
  padding: "20px 21px 27px 21px",
  fontSize: "14px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",

  p: {
    color: "$primary-70",
  },

  "@sm": {
    height: "150px",
  },
});
