import { styled } from "../../../../../../styles";

export const ListBlowItem = styled("div", {
  width: "212px",
  padding: "8px 16px 8px 16px",

  borderRadius: "8px",

  fontFamily: "$openSans",
  fontSize: "$text-md",

  color: "$gray-80",

  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  cursor: "pointer",

  p: {
    paddingRight: "6px",
  },

  "@sm": {
    paddingLeft: "1px",
    width: "100%",
    justifyContent: "flex-start",
    background: "$white !important",

    svg: {
      marginRight: "30px",
      width: "16px !important",
    },
  },

  variants: {
    isHovered: {
      true: {
        background: "$gray-30",
      },
    },
    isOpaque: {
      true: {
        opacity: 0.28,
      },
    },
  },
});

export const ListBlowHoverCardContent = styled("div", {
  maxWidth: "216px",
  zIndex: "10",
  width: "172px",

  position: "absolute",

  marginTop: "14px",

  backgroundColor: "$charcoal",
  overflowWrap: "break-word",

  color: "$white",
  fontFamily: "$openSans",

  fontWeight: 500,
  fontSize: "$text-md",
  lineHeight: "16px",

  borderRadius: "4px",

  padding: "18px 32px",

  p: {
    marginBottom: "6px",

    "&:last-child": {
      marginBottom: 0,
    },
  },
});

export const BlowColorDot = styled("div", {
  width: "6px",
  height: "6px",

  borderRadius: "50%",
});
