import { styled } from "../../styles";

export const Counter = styled("div", {
  width: "26px",
  height: "26px",

  backgroundColor: "$error-lightest",
  borderRadius: "14px",

  fontFamily: "$openSans",
  fontWeight: 600,
  fontSize: "$text-sm",

  color: "$error-dark",

  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  lineHeight: "16px",
});
