import { styled } from "../../../styles";

export const MainContainer = styled("div", {
  width: "100%",
  height: "100vh",

  paddingLef: "300px",

  display: "flex",
  flexDirection: "column",
});

export const Header = styled("header", {
  width: "100%",
  minHeight: "81px",
  paddingLeft: "300px",

  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",

  backgroundColor: "$gray-30",
  boxShadow: "0px 3px 1px rgba(237, 237, 237, 1)",

  "@media (max-width: 1100px)": {
    paddingLeft: "150px",
  },

  "@sm": {
    paddingLeft: "20px",
  },
});

export const Logo = styled("img", {
  height: "50px",
  width: "200px",
});

export const ChildrenContainer = styled("div", {
  width: "100%",
  height: "100%",
});

export const Footer = styled("footer", {
  width: "100%",
  minHeight: "250px",
  padding: "30px 300px 0px 300px",

  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",

  rowGap: "20px",

  backgroundColor: "$gray-30",

  "@media (max-width: 1100px)": {
    padding: "20px 150px 0px 150px",
  },

  "@sm": {
    padding: "20px",
    minHeight: "auto",
  },
});

export const InfoRow = styled("div", {
  width: "100%",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  columnGap: "25%",

  "@media (max-width: 1100px)": {
    columnGap: "15%",
    flexWrap: "wrap",
    rowGap: "20px",
  },

  "@sm": {
    justifyContent: "center",
    // columnGap: "20px",
  },
});

export const InfoContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

export const InfoTitle = styled("p", {
  color: "#666666",
  fontSize: "16px",
  fontFamily: "$openSans",
  fontWeight: "700",
  paddingBottom: "20px",
});

export const InfoText = styled("p", {
  color: "#666666",
  fontSize: "14px",
  fontFamily: "$openSans",
  fontWeight: "400",
});

export const MarkingRow = styled("div", {
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
});

export const MarkingContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  rowGap: "20px",
});

export const MarkingImmage = styled("img", {
  height: "50px",
  width: "200px",
});
