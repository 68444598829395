import { styled } from "../../../styles";

export const DatePickerContainer = styled("div", {
  position: "relative",
});

export const DatePickerHeader = styled("div", {
  zIndex: "$2",

  display: "flex",
  alignItems: "center",
  color: "$gray-80",
});

export const DatePickerCalendar = styled("div", {
  position: "absolute",
  top: "28px",
  left: "4px",
});

export const SelectedRangeButton = styled("button", {
  fontFamily: "$openSans",
  fontSize: "$text-md",
  fontWeight: 600,
  color: "$gray-100",

  cursor: "pointer",

  border: "none",
  backgroundColor: "transparent",
});

export const SelectRangeControls = styled("div", {
  display: "flex",

  columnGap: "$4",

  marginLeft: "$10",
});

export const ArrowButton = styled("button", {
  width: 20,
  height: 20,

  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  border: "none",
  borderRadius: "$round",
  backgroundColor: "transparent",

  "&:first-child": {
    transform: "rotate(180deg)",
  },

  variants: {
    disabledStyle: {
      true: {
        cursor: "not-allowed",
        opacity: 0.5,
      },
      false: {
        cursor: "pointer",
        opacity: 1,
      },
    },
  },
});
