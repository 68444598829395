import { ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";
import * as SelectPrimitive from "@radix-ui/react-select";

import { TriangleDown } from "../../assets/icons/TriangleDown";
import {
  StyledContent,
  StyledDropdownContainer,
  StyledOutsidePlaceholder,
  StyledScrollDownButton,
  StyledScrollUpButton,
  StyledSelectItem,
  StyledSelectTrigger,
} from "./Dropdown.styles";
import {
  IDropdownItemProps,
  IDropdownProps,
  ITextSelectTriggerProps,
} from "./Dropdown.types";

const DropdownContainer = StyledDropdownContainer;
const SelectElement = SelectPrimitive.Select;
const SelectTrigger = StyledSelectTrigger;
const SelectValue = SelectPrimitive.Value;
const SelectPortal = SelectPrimitive.Portal;
const SelectContent = StyledContent;
const SelectViewport = SelectPrimitive.Viewport;
const SelectGroup = SelectPrimitive.Group;
const SelectItem = StyledSelectItem;
const SelectItemText = SelectPrimitive.ItemText;
const SelectScrollUpButton = StyledScrollUpButton;
const SelectScrollDownButton = StyledScrollDownButton;
const OutsidePlaceholder = StyledOutsidePlaceholder;

export function TextSelectTrigger({
  variant,
  skeleton,
  disabled,
  triggerLabel,
  backgroundColor,
  fontColor,
  size,
}: ITextSelectTriggerProps) {
  return (
    <SelectTrigger
      styleDisabled={disabled}
      styleError={variant === "Error"}
      styledSkeleton={skeleton}
      disabled={disabled || skeleton}
      style={
        size === "small"
          ? {
              backgroundColor,
              color: fontColor,
              height: 24,
              padding: "0 12px",
              gap: "4px",
              fontSize: "12px",
            }
          : { backgroundColor, color: fontColor }
      }
    >
      <SelectValue placeholder={triggerLabel} />

      {!skeleton && <TriangleDown />}
    </SelectTrigger>
  );
}

export function NodeSelectTrigger({ children }: any) {
  return (
    <SelectTrigger styledNodeWrapper>
      <SelectValue>{children}</SelectValue>
    </SelectTrigger>
  );
}

export function DropdownTextItem({ text, value }: IDropdownItemProps) {
  return (
    <SelectItem value={value || text} style={{ paddingLeft: "12px" }}>
      <SelectItemText>{text}</SelectItemText>
    </SelectItem>
  );
}

export function Dropdown({
  open,
  value,
  trigger,
  variant,
  disabled,
  skeleton,
  children,
  onOpenChange,
  onValueChange,
  outsidePlaceholder,
  backgroundColor,
  fontColor,
  size = "default",
}: IDropdownProps) {
  const isTextualTrigger = typeof trigger === "string";

  return (
    <DropdownContainer>
      <SelectElement
        open={open}
        value={value}
        onOpenChange={onOpenChange}
        onValueChange={onValueChange}
      >
        {isTextualTrigger ? (
          <TextSelectTrigger
            disabled={disabled}
            variant={variant}
            skeleton={skeleton}
            triggerLabel={trigger}
            backgroundColor={backgroundColor}
            fontColor={fontColor}
            size={size}
          />
        ) : (
          <NodeSelectTrigger>{trigger}</NodeSelectTrigger>
        )}
        <SelectPortal style={{ pointerEvents: "all" }}>
          <SelectContent position="popper">
            <SelectScrollUpButton>
              <ChevronUpIcon />
            </SelectScrollUpButton>

            <SelectViewport>
              <SelectGroup>{children}</SelectGroup>
            </SelectViewport>

            <SelectScrollDownButton>
              <ChevronDownIcon />
            </SelectScrollDownButton>
          </SelectContent>
        </SelectPortal>
      </SelectElement>

      {outsidePlaceholder && !skeleton && (
        <OutsidePlaceholder
          styleDisabled={disabled}
          styleError={variant === "Error"}
          styleWarning={variant === "Warning"}
        >
          {outsidePlaceholder}
        </OutsidePlaceholder>
      )}
    </DropdownContainer>
  );
}

export default Dropdown;
