import { useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { SelectMeasurementPayloadType } from "../../../../../../@types/Measurements";
import { Tag } from "../../../../../../components/Tag";
import { useArtiQSpirometryData } from "../../../../../../hooks/queries/spirometry";
import useIsMobile from "../../../../../../hooks/useIsMobile";
import { mixpanelActions } from "../../../../../../utils/mixpanel";
import {
  HeaderContainer,
  Title,
  Subtitle,
} from "../../MeasurementModal.styles";
import { BlowAcceptabilitySection } from "../BlowAcceptabilitySection";
import { SpirometryValuesSection } from "../SpirometryValuesSection";
import {
  ArtiQContainer,
  ChartContainer,
  ContentContainer,
  SpirometryContainer,
  SubtitleGradeContainer,
} from "./SpirometrySection.styles";
import { SpirometrySectionChart } from "./SpirometrySectionChart";

type SpirometrySectionProps = {
  selectedMeasurement: SelectMeasurementPayloadType;
  isPinnedGraph?: boolean;
};

type TagVariants = "error" | "success" | "warning";

export type GradeTagColor = {
  fvc: TagVariants;
  fev1: TagVariants;
};

export function SpirometrySection({
  selectedMeasurement,
  isPinnedGraph,
}: SpirometrySectionProps) {
  const { widthView } = useIsMobile();
  const { artiQData, isFetching } = useArtiQSpirometryData(
    selectedMeasurement?.spirometryTestIds || []
  );

  const [linesToShow, setLinesToShow] = useState([
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
  ]);
  const [highlightedLine, setHighlightedLine] = useState<number>();
  const [graphSectionKey, setGraphSectionKey] = useState(uuidv4());

  useEffect(() => {
    setLinesToShow([true, true, true, true, true, true, true, true]);
  }, [selectedMeasurement]);

  const fvcGrade = artiQData?.fvcGrade;
  const fev1Grade = artiQData?.fev1Grade;

  const spirometryGradesTagColor = useMemo<GradeTagColor>(() => {
    const fvcGrade = artiQData?.fvcGrade;
    const fev1Grade = artiQData?.fev1Grade;

    const grades: GradeTagColor = {
      fvc: "error",
      fev1: "error",
    };

    if (fvcGrade === "A") {
      grades.fvc = "success";
    }

    if (fev1Grade === "A") {
      grades.fev1 = "success";
    }

    if (fvcGrade === "B" || fvcGrade === "C") {
      grades.fvc = "warning";
    }

    if (fev1Grade === "B" || fev1Grade === "C") {
      grades.fev1 = "warning";
    }

    return grades;
  }, [artiQData?.fev1Grade, artiQData?.fvcGrade]);

  useEffect(() => {
    mixpanelActions.track("User Action: Clicked Spirometry Datapoint");
  }, [selectedMeasurement]);

  const volumeFlowValues = artiQData?.flowVolumeAxes;
  const timeVolumeValues = artiQData?.timeVolumeAxes;

  const measurementChartWidth = useMemo(() => {
    return widthView > 1530 ? 252 : 252;
  }, [widthView]);

  const yLabelConfig = (value: string) => ({
    value,
    angle: -90,
    fontSize: "12px",
    position: "insideLeft",
  });

  const xLabelConfig = (value: string) => ({
    value,
    fontSize: "12px",
    position: "insideBottomLeft",
  });

  const handleHideBlow = (index: number) => {
    const newLinesToShow = linesToShow.map((line, i) => {
      if (i === index) {
        return !line;
      }

      return line;
    });

    setLinesToShow(newLinesToShow);
  };

  return (
    <SpirometryContainer isPinnedGraph={isPinnedGraph}>
      <HeaderContainer css={{ marginLeft: 0, marginBottom: 38 }}>
        <Title>Selected session</Title>

        <SubtitleGradeContainer>
          <Subtitle css={{ marginTop: 0, marginBottom: 0 }}>
            {selectedMeasurement.time}
          </Subtitle>

          {fvcGrade && (
            <Tag variant={spirometryGradesTagColor.fvc}>
              FVC = {artiQData?.fvcGrade}
            </Tag>
          )}

          {fev1Grade && (
            <Tag variant={spirometryGradesTagColor.fev1}>
              FEV1 = {artiQData?.fev1Grade}
            </Tag>
          )}

          {artiQData?.percentPredictedWarning !== "" && !isFetching ? (
            <Tag variant="error">{artiQData?.percentPredictedWarning}</Tag>
          ) : null}
        </SubtitleGradeContainer>
      </HeaderContainer>

      <ContentContainer isPinnedGraph={isPinnedGraph}>
        <ChartContainer
          css={{ width: measurementChartWidth }}
          key={graphSectionKey}
        >
          <SpirometrySectionChart
            xConfig={{
              dataKey: "x",
              type: "number",
              minTickGap: 2,
              allowDecimals: false,
              label: xLabelConfig("Vol (L)"),
              tickFormatter(value) {
                return Number.parseFloat(value).toFixed(1);
              },
            }}
            yConfig={{
              axisSize: 42,
              label: yLabelConfig("Flow (L/s)"),
              domain: [0, (dataMax: any) => Math.ceil(dataMax)],
            }}
            chartConfig={[
              {
                yKey: "y",
                dot: false,
                color: "#7562A8",
                lineType: "monotone",
              },
            ]}
            width="100%"
            height={207}
            data={volumeFlowValues ?? []}
            linesToShow={linesToShow}
            highlightedLine={highlightedLine}
            onMouseEnter={(index) => {
              setHighlightedLine(index);
              setGraphSectionKey(uuidv4());
            }}
            onMouseLeave={() => {
              setHighlightedLine(undefined);
              setGraphSectionKey(uuidv4());
            }}
          />

          <SpirometrySectionChart
            xConfig={{
              dataKey: "x",
              type: "number",
              minTickGap: 2,
              allowDecimals: false,
              label: xLabelConfig("Time (s)"),
              tickFormatter(value) {
                return Number.parseFloat(value).toFixed(1);
              },
            }}
            yConfig={{
              axisSize: 42,
              label: yLabelConfig("Vol (L)"),
            }}
            chartConfig={[
              {
                yKey: "y",
                dot: false,
                color: "#7562A8",
                lineType: "monotone",
              },
            ]}
            width="100%"
            height={207}
            data={timeVolumeValues ?? []}
            linesToShow={linesToShow}
            highlightedLine={highlightedLine}
            onMouseEnter={(index) => {
              setHighlightedLine(index);
              setGraphSectionKey(uuidv4());
            }}
            onMouseLeave={() => {
              setHighlightedLine(undefined);
              setGraphSectionKey(uuidv4());
            }}
          />
        </ChartContainer>

        <ArtiQContainer>
          <SpirometryValuesSection bestValues={artiQData?.bestValues} />
          <BlowAcceptabilitySection
            blows={artiQData?.blowAcceptability}
            hideBlow={handleHideBlow}
            highlightedBlowIndex={highlightedLine}
            onRowHover={(index) => {
              setHighlightedLine(index);
              setGraphSectionKey(uuidv4());
            }}
          />
        </ArtiQContainer>
      </ContentContainer>
    </SpirometryContainer>
  );
}
