import { styled } from "../../../../styles";

export const AddDeviceButton = styled("div", {
  color: "$charcoal",
  fontWeight: "600",
  fontSize: "12px",
  lineHeight: "17px",
  cursor: "pointer",
  position: "absolute",
  top: "8px",
  right: "10px",
  borderRadius: "6px",
  padding: "0 2px",

  "&:hover": {
    color: "$primary",
  },
});

export const TitleContainer = styled("div", {
  display: "flex",
  alignItems: "center",
});

export const NewDataCOntainer = styled("div", {
  marginLeft: "5px",
  color: "$gray-80",

  fontFamily: "$nunito",
  fontWeight: "600px",
  fontSize: "16px",
  lineHeight: "34px",
});
