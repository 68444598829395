import { createColumn } from "../../../../../../components/Table";

export const hospitalTableColumns = [
  createColumn({
    keyName: "hospitalId",
    header: () => "Hospital Id",
    cell: (info) => info.renderValue(),
    size: 90,
  }),
  createColumn({
    keyName: "hospitalName",
    header: () => "Hospital name",
    cell: (info) => info.renderValue(),
    size: 300,
  }),
  createColumn({
    keyName: "action",
    header: () => "Assign Hospital",
    cell: (info) => info.renderValue(),
    size: 30,
  }),
];
