import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";

import { IWardConfiguration } from "../../../../@types/Login";
import { Notification } from "../../../../@types/Notification";
import {
  IPortalPreferences,
  IUserPreferences,
} from "../../../../@types/Preferences";
import { Button } from "../../../../components/Button";
import { IOption, SelectDropdown } from "../../../../components/SelectDropdown";
import { SkeletonLoading } from "../../../../components/Skeleton";
import { TextField } from "../../../../components/TextField";
import {
  AUTH_PORTAL_PREFERENCES,
  AUTH_UNIT_PREFERENCES,
  AUTH_WARD_CONFIGURATION,
} from "../../../../constants/localStorageKeys";
import { usePatientDetailsDropdownItems } from "../../../../hooks/queries/patients";
import { useAvailableWards } from "../../../../hooks/queries/wards";
import { useArrowsListennerHandler } from "../../../../hooks/useArrowsListennerHandler";
import { patientService } from "../../../../services/patientService";
import { Title } from "../../../PatientPage/components/AddMeasurementModal/AddMeasurementModal.styles";
import { CancelButton } from "../../../Settings/components/SecurityModal/SecurityModal.styles";
import {
  DateInputcontainer,
  dateInputCss,
  dropdownCss,
  ErrorMessage,
  FormField,
  ModalContainer,
  inputCss,
  reactPhoneInputCss,
  SectionContainer,
  SubmitContainer,
  dropdownPopUpCss,
} from "./AddPatientModal.styles";
import "react-phone-input-2/lib/bootstrap.css";

type AddPatientProps = {
  onClose(notification?: Notification): void;
};

export function AddPatientModal({ onClose }: AddPatientProps) {
  const { dropdownItems, refetch } = usePatientDetailsDropdownItems({
    patientId: "0",
  });
  const { availableWards, isWardsFetching, isWardsError } = useAvailableWards();
  const { disablePatientListArrowNav } = useArrowsListennerHandler();

  const [conditionSelected, setConditionSelected] = useState<string>();
  const [sexSelected, setSexSelected] = useState<string>();
  const [ethnicitySelected, setEthnicitySelected] = useState<string>();
  const [phoneWithCountryCode, setPhoneWithCountryCode] = useState<string>("");

  const [wardOptions] = useState<IOption[]>([]);
  const [wardSelected, setWardSelected] = useState<string>();
  const [showWards, setShowWards] = useState<boolean>(false);

  const [countryCode, setCountryCode] = useState<string>();
  const [heightUnit, setHeightUnit] = useState<string>();
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
  const [responseError, setResponseError] = useState<string>("");

  const [hasNoAppEnrolment, setHasNoAppEnrolment] = useState<boolean>(false);
  const [hasEmailValue, setHasEmailValue] = useState<string>();

  const containerRef = useRef(null);
  const sectionRef = useRef(null);

  const emailRef = useRef<HTMLDivElement>(null);
  const confirmEmailRef = useRef<HTMLDivElement>(null);
  const firstNameRef = useRef<HTMLDivElement>(null);
  const lastNameRef = useRef<HTMLDivElement>(null);
  const conditionRef = useRef<HTMLDivElement>(null);
  const wardRef = useRef<HTMLDivElement>(null);
  const hospitalIDRef = useRef<HTMLDivElement>(null);
  const sexRef = useRef<HTMLDivElement>(null);
  const ethinicityRef = useRef<HTMLDivElement>(null);
  const heightRef = useRef<HTMLDivElement>(null);
  const phoneNumberRef = useRef<HTMLDivElement>(null);

  const emailOptions: IOption[] = [
    { label: "Patient has email", value: "email", key: uuidv4() },
    { label: "Patient doesn't have email", value: "noEmail", key: uuidv4() },
  ];

  const sexOptions: IOption[] = [
    { label: "Female", value: "f", key: uuidv4() },
    { label: "Male", value: "m", key: uuidv4() },
  ];

  const ethnicityOptions: IOption[] = [
    { label: "White", value: "white", key: uuidv4() },
    { label: "Black", value: "black", key: uuidv4() },
    { label: "Asian", value: "asian", key: uuidv4() },
    { label: "Other", value: "other", key: uuidv4() },
  ];

  const conditionOptions: IOption[] = [];

  dropdownItems?.conditionItems?.forEach((item, index) => {
    conditionOptions.push({
      label: item.itemName,
      value: item.itemId.toString(),
      key: `${item.itemName}-${item.itemId}-${index}`,
    });
  });

  const verifyNoAppEnrolment = () => {
    const wardConfig = localStorage.getItem(AUTH_WARD_CONFIGURATION);

    const parsedWardConfig = wardConfig
      ? (JSON.parse(wardConfig) as IWardConfiguration[])
      : undefined;

    parsedWardConfig?.forEach((wardConfig) => {
      if (wardConfig.enableNoAppEnrolment) {
        setHasNoAppEnrolment(true);
      }
    });
  };

  useEffect(() => {
    disablePatientListArrowNav();
    refetch();

    const portalPreferences = localStorage.getItem(AUTH_PORTAL_PREFERENCES);

    if (portalPreferences) {
      const parsedPortalPreferences = portalPreferences
        ? (JSON.parse(portalPreferences) as IPortalPreferences)
        : undefined;

      if (parsedPortalPreferences) {
        setCountryCode(parsedPortalPreferences.countryCode);
      }
    }

    const preferences = localStorage.getItem(AUTH_UNIT_PREFERENCES);

    const parsedPreferences = preferences
      ? (JSON.parse(preferences) as IUserPreferences)
      : undefined;

    if (parsedPreferences) {
      setHeightUnit(parsedPreferences.heightUnit);
    }
    verifyNoAppEnrolment();
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      (containerRef.current as HTMLDivElement).click();
    }
  }, [containerRef.current]);

  const handleOnSubmitForm = async ({
    email,
    firstName,
    lastName,
    condition,
    dateOfBirth,
    sex,
    ethnicity,
    ward,
    hospitalId,
    phoneNumber,
    height,
  }: any) => {
    const patient = {
      email: hasEmailValue === "noEmail" ? null : email,
      firstName,
      lastName,
      conditionTypeId: parseInt(condition, 10),
      dateOfBirth: new Date(
        `${dateOfBirth.year}-${dateOfBirth.month}-${dateOfBirth.day}`
      ).toISOString(),
      sex,
      ethnicity,
      hospitalPatientId: hospitalId,
      phoneNumber,
      hospitalId: parseInt(ward, 10),
      height,
    };

    setIsLoadingSubmit(true);

    const result = await patientService.addPatient(patient);

    if (result.status >= 200 && result.status < 300) {
      onClose({
        show: true,
        message: "Patient successfully added",
        type: "success",
        width: "max-content",
        rightMargin: "25%",
      });
      return;
    }

    setResponseError((result.data as any).errors[0].message);
    setIsLoadingSubmit(false);
  };

  const formValidationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Please enter a valid email address.")
      .required("Please enter a valid email address."),
    confirmEmail: yup
      .string()
      .oneOf([yup.ref("email"), null], "The entered values do not match.")
      .required("Please enter a valid email address."),
    firstName: yup.string().required("Please you need to enter a name."),
    lastName: yup.string().required("Please you need to enter a name."),
    condition: yup
      .string()
      .required("Please enter a value before you proceed."),
    ward: yup.string().required("Please enter a value before you proceed."),
    hospitalId: yup.string().required("This ID is required."),
    sex: yup.string().required("Please enter a value before you proceed."),
    height: yup
      .number()
      .test(
        "valid",
        heightUnit === "ft"
          ? "Please enter a height between 0 ft and 8.8 ft"
          : "Please enter a height between 0 cm and 270 cm",
        (val) => {
          if (val === undefined) return true;

          if (heightUnit === "ft") {
            return val > 0 && val <= 8.8;
          }

          return val > 0 && val <= 270;
        }
      ),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      confirmEmail: "",
      firstName: "",
      lastName: "",
      condition: "",
      ward: "",
      hospitalId: "",
      height: "",
      dateOfBirth: {
        day: "",
        month: "",
        year: "",
      },
      sex: "",
      ethnicity: "",
      phoneNumber: "",
    },
    onSubmit: handleOnSubmitForm,
    validationSchema: formValidationSchema,
  });

  useEffect(() => {
    if (!isWardsFetching && !isWardsError) {
      if (availableWards.length > 1) {
        setShowWards(true);

        availableWards.forEach((ward) => {
          wardOptions.push({
            label: ward.hospitalName,
            value: ward.hospitalId.toString(),
            key: uuidv4(),
          });
        });
      } else if (availableWards.length === 1) {
        formik.setFieldValue("ward", availableWards[0].hospitalId.toString());
      }
    }
  }, [isWardsFetching]);

  useEffect(() => {
    if (!isWardsFetching && !isWardsError) {
      if (hasEmailValue !== "noEmail") {
        const myInput = document.getElementsByName(
          "confirmEmail"
        )[0] as HTMLInputElement;
        myInput.onpaste = (e) => e.preventDefault();
        formik.setFieldValue("email", "");
        formik.setFieldValue("confirmEmail", "");
      } else {
        formik.setFieldValue("email", "dummy@pmp.pt");
        formik.setFieldValue("confirmEmail", "dummy@pmp.pt");
      }
    }
  }, [hasEmailValue, isWardsFetching]);

  const shouldShowErrorMessage = (
    field:
      | "email"
      | "confirmEmail"
      | "firstName"
      | "lastName"
      | "condition"
      | "ward"
      | "hospitalId"
      | "sex"
      | "phoneNumber"
      | "height"
  ) => {
    return formik.touched[field] ? formik.errors[field] ?? "" : "";
  };

  const shouldShowDateErrorMessage = () => {
    if (!formik.touched.dateOfBirth) {
      return "";
    }

    const { day, month, year } = formik.values.dateOfBirth;

    if (day === "" && month === "" && year === "") {
      return "Please enter a date before you proceed.";
    }

    const date = new Date(`${year}-${month}-${day}`);

    if (date > new Date()) {
      return "Please enter a past date.";
    }

    if (!dayjs(date).isValid() || day === "" || month === "" || year === "") {
      return "Please enter a valid date.";
    }

    return "";
  };

  const pressTab = () => {
    const { activeElement } = document;

    const allFocusableElements = document.querySelectorAll(
      'input, [tabindex]:not([tabindex="-1"])'
    );

    const currentIndex = Array.from(allFocusableElements).indexOf(
      activeElement as Element
    );

    const nextIndex = (currentIndex + 1) % allFocusableElements.length;
    const nextElement = allFocusableElements[nextIndex];

    if (nextElement) {
      (nextElement as HTMLElement).focus();
    }
  };

  useEffect(() => {
    if (formik.values.dateOfBirth.day.length === 2) {
      pressTab();
    }
  }, [formik.values.dateOfBirth.day]);

  useEffect(() => {
    if (formik.values.dateOfBirth.month.length === 2) {
      pressTab();
    }
  }, [formik.values.dateOfBirth.month]);

  const errorFieldRefs = {
    email: emailRef,
    confirmEmail: confirmEmailRef,
    firstName: firstNameRef,
    lastName: lastNameRef,
    condition: conditionRef,
    ward: wardRef,
    hospitalId: hospitalIDRef,
    sex: sexRef,
    phoneNumber: phoneNumberRef,
    height: heightRef,
  };

  const scrollToFirstError = () => {
    const errorFields = Object.keys(formik.errors);

    const errorRef =
      errorFieldRefs[
        errorFields[0] as
          | "email"
          | "confirmEmail"
          | "firstName"
          | "lastName"
          | "condition"
          | "ward"
          | "hospitalId"
          | "sex"
          | "phoneNumber"
          | "height"
      ];

    if (errorRef.current) {
      errorRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (formik.errors && Object.keys(formik.errors).length > 0) {
      scrollToFirstError();
    }
  }, [formik.isSubmitting]);

  const handleCountryChange = () => {
    const inputElement = document.getElementById("phone-input");
    if (inputElement) {
      inputElement.focus();
    }
  };

  const handlePhoneInput = (country: any, formattedValue: string) => {
    const formattedPhoneNumber = formattedValue.replace(/ /g, "");
    const formattedDialCode = `+${country.dialCode}`;

    if (formattedPhoneNumber !== formattedDialCode) {
      formik.setFieldValue("phoneNumber", formattedPhoneNumber);
    } else {
      formik.setFieldValue("phoneNumber", "");
    }

    setPhoneWithCountryCode(formattedValue);
  };

  return (
    <ModalContainer style={{ height: "100%" }} ref={containerRef}>
      <Title>Add Patient</Title>
      <br />

      {!isWardsFetching ? (
        <SectionContainer ref={sectionRef}>
          {hasNoAppEnrolment && (
            <FormField>
              <p>Patient setup</p>
              <SelectDropdown
                popUpCsss={dropdownPopUpCss()}
                className={dropdownCss()}
                options={emailOptions}
                width={410}
                value={hasEmailValue}
                placeholder="Patient has email"
                onValueChange={(value: string | string[]) => {
                  setHasEmailValue(value.toString());
                }}
              />
            </FormField>
          )}
          {hasEmailValue !== "noEmail" && (
            <>
              <FormField ref={emailRef}>
                <p>Email address *</p>
                <TextField
                  autofocus
                  label=""
                  name="email"
                  placeholder="jane@example.com"
                  value={formik.values.email}
                  onChange={(event) =>
                    formik.setFieldValue(
                      "email",
                      event.target.value.toLowerCase()
                    )
                  }
                  backgroudColor="white"
                  className={inputCss()}
                  errorMessage={shouldShowErrorMessage("email")}
                />
              </FormField>

              <FormField ref={confirmEmailRef}>
                <p style={{ whiteSpace: "nowrap" }}>Confirm email address *</p>
                <TextField
                  label=""
                  name="confirmEmail"
                  placeholder="Confirm email address"
                  value={formik.values.confirmEmail}
                  onChange={(event) =>
                    formik.setFieldValue(
                      "confirmEmail",
                      event.target.value.toLowerCase()
                    )
                  }
                  backgroudColor="white"
                  className={inputCss()}
                  errorMessage={shouldShowErrorMessage("confirmEmail")}
                />
              </FormField>
            </>
          )}

          <FormField ref={firstNameRef}>
            <p>First name *</p>
            <TextField
              label=""
              name="firstName"
              placeholder="First name"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              backgroudColor="white"
              className={inputCss()}
              errorMessage={shouldShowErrorMessage("firstName")}
            />
          </FormField>

          <FormField ref={lastNameRef}>
            <p>Last name *</p>
            <TextField
              label=""
              name="lastName"
              placeholder="Last name"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              backgroudColor="white"
              className={inputCss()}
              errorMessage={shouldShowErrorMessage("lastName")}
            />
          </FormField>

          <FormField ref={conditionRef}>
            <p>Select condition *</p>
            <SelectDropdown
              popUpCsss={dropdownPopUpCss()}
              className={dropdownCss()}
              options={conditionOptions}
              width={410}
              placeholder="Choose condition"
              value={conditionSelected}
              onValueChange={(value: string | string[]) => {
                setConditionSelected(value.toString());
                formik.setFieldValue("condition", value.toString());
              }}
            />
            <ErrorMessage>{shouldShowErrorMessage("condition")}</ErrorMessage>
          </FormField>

          <FormField ref={hospitalIDRef}>
            <p>Hospital ID *</p>
            <TextField
              className={inputCss()}
              label=""
              name="hospitalId"
              placeholder="Hospital ID"
              value={formik.values.hospitalId}
              onChange={formik.handleChange}
              backgroudColor="white"
              errorMessage={shouldShowErrorMessage("hospitalId")}
              auxiliarButtonLabel="Click"
            />
          </FormField>

          <FormField>
            <p>Date of birth *</p>
            <DateInputcontainer>
              <TextField
                label=""
                name={
                  countryCode === "US" ? "dateOfBirth.month" : "dateOfBirth.day"
                }
                placeholder={countryCode === "US" ? "MM" : "DD"}
                value={
                  countryCode === "US"
                    ? formik.values.dateOfBirth.month
                    : formik.values.dateOfBirth.day
                }
                onChange={formik.handleChange}
                backgroudColor="white"
                className={dateInputCss()}
                maxLength={2}
              />
              <TextField
                label=""
                name={
                  countryCode === "US" ? "dateOfBirth.day" : "dateOfBirth.month"
                }
                placeholder={countryCode === "US" ? "DD" : "MM"}
                value={
                  countryCode === "US"
                    ? formik.values.dateOfBirth.day
                    : formik.values.dateOfBirth.month
                }
                onChange={formik.handleChange}
                backgroudColor="white"
                className={dateInputCss()}
                maxLength={2}
              />
              <TextField
                label=""
                name="dateOfBirth.year"
                placeholder="YYYY"
                value={formik.values.dateOfBirth.year}
                onChange={formik.handleChange}
                backgroudColor="white"
                className={dateInputCss()}
                maxLength={4}
              />
            </DateInputcontainer>
            <ErrorMessage>{shouldShowDateErrorMessage()}</ErrorMessage>
          </FormField>

          <FormField ref={sexRef}>
            <p>Sex *</p>
            <SelectDropdown
              popUpCsss={dropdownPopUpCss()}
              className={dropdownCss()}
              options={sexOptions}
              width={410}
              placeholder="Choose sex"
              value={sexSelected}
              onValueChange={(value: string | string[]) => {
                setSexSelected(value.toString());
                formik.setFieldValue("sex", value.toString());
              }}
            />
            <ErrorMessage>{shouldShowErrorMessage("sex")}</ErrorMessage>
          </FormField>

          <FormField ref={ethinicityRef}>
            <p>Ethnicity</p>
            <SelectDropdown
              popUpCsss={dropdownPopUpCss()}
              className={dropdownCss()}
              options={ethnicityOptions}
              width={410}
              placeholder="Choose ethnicity"
              value={ethnicitySelected}
              onValueChange={(value: string | string[]) => {
                setEthnicitySelected(value.toString());
                formik.setFieldValue("ethnicity", value.toString());
              }}
            />
          </FormField>

          {showWards && (
            <FormField ref={wardRef}>
              <p>Select ward *</p>
              <SelectDropdown
                popUpCsss={dropdownPopUpCss()}
                className={dropdownCss()}
                options={wardOptions}
                width={410}
                placeholder="Choose ward"
                value={wardSelected}
                onValueChange={(value: string | string[]) => {
                  setWardSelected(value.toString());
                  formik.setFieldValue("ward", value.toString());
                }}
              />
              <ErrorMessage>{shouldShowErrorMessage("ward")}</ErrorMessage>
            </FormField>
          )}

          <FormField ref={heightRef}>
            <p>Height</p>
            <TextField
              label=""
              name="height"
              type="number"
              placeholder={`Height (${heightUnit})`}
              value={formik.values.height}
              onChange={formik.handleChange}
              onKeyDown={(evt) =>
                ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
              }
              backgroudColor="white"
              className={inputCss()}
              errorMessage={shouldShowErrorMessage("height")}
            />
          </FormField>

          <FormField ref={phoneNumberRef}>
            <p>Phone number</p>

            <PhoneInput
              inputProps={{
                id: "phone-input",
              }}
              searchPlaceholder="Search"
              country={countryCode?.toLowerCase()}
              containerClass={reactPhoneInputCss()}
              enableSearch
              value={phoneWithCountryCode}
              onChange={(value, country, e, formattedValue) => {
                handleCountryChange();
                handlePhoneInput(country, formattedValue);
              }}
              countryCodeEditable={false}
            />
          </FormField>
        </SectionContainer>
      ) : (
        <SkeletonLoading height="432px" />
      )}

      <ErrorMessage
        style={{ position: "relative", left: "0px", marginBottom: "16px" }}
      >
        {responseError}
      </ErrorMessage>

      <SubmitContainer>
        <Button
          label={hasEmailValue === "noEmail" ? "Save" : "Save and send email"}
          type="submit"
          onClick={formik.submitForm}
          isLoading={isLoadingSubmit}
        />

        <CancelButton onClick={() => onClose()} style={{ marginLeft: "-30px" }}>
          Cancel
        </CancelButton>
      </SubmitContainer>
    </ModalContainer>
  );
}
