import { useState } from "react";

import { Button } from "../../../../components/Button";
import { LinkButton } from "../../../../components/LinkButton";
import { TextArea } from "../../../../components/TextArea";
import { resolveTrigger } from "../../../../hooks/queries/patients";
import {
  Component,
  ComponentTitle,
  Container,
  ResolveTriggerContainer,
  Row,
  SubmitContainer,
  Title,
} from "./ResolveTriggerModal.styles";

type ResolveTriggerModalProps = {
  patientId: number;
  alertIds: string[];
  type: string;
  triggered: string;
  onClose: (showNotification: boolean, success?: boolean) => void;
};

export function ResolveTriggerModal({
  patientId,
  alertIds,
  type,
  triggered,
  onClose,
}: ResolveTriggerModalProps) {
  const [note, setNote] = useState<string>();

  const handleClose = () => {
    onClose(false);
  };

  const submitResolveTrigger = async () => {
    if (alertIds.length > 0) {
      const resp = await resolveTrigger(alertIds, patientId, null, note);
      onClose(true, resp.success);
    } else {
      handleClose();
    }
  };

  return (
    <Container>
      <Title>Resolve alert</Title>
      <ResolveTriggerContainer>
        <Row>
          <ComponentTitle>Type</ComponentTitle>
          <Component>{type}</Component>
        </Row>
        <Row>
          <ComponentTitle>Triggered</ComponentTitle>
          <Component>{triggered}</Component>
        </Row>
        {type !== "Multiple selected" ? (
          <Row>
            <ComponentTitle>Note</ComponentTitle>
            <Component>
              <TextArea
                placeholder="Add optional note here"
                onChange={(event) => setNote(event.target.value)}
                backgroudColor="white"
                rows={4}
              />
            </Component>
          </Row>
        ) : null}
      </ResolveTriggerContainer>
      <SubmitContainer>
        <Button label="Resolve" type="button" onClick={submitResolveTrigger} />
        <LinkButton
          label="Cancel"
          size="lg"
          color="black"
          fontWeight="semiBold"
          onClick={handleClose}
        />
      </SubmitContainer>
    </Container>
  );
}
