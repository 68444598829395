export function Eye({ ...rest }) {
  return (
    <svg
      {...rest}
      width="22"
      height="15"
      viewBox="0 0 22 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 7.5C1.73 3.11 6 0 11 0C16 0 20.27 3.11 22 7.5C20.27 11.89 16 15 11 15C6 15 1.73 11.89 0 7.5ZM19.82 7.5C18.17 4.13 14.79 2 11 2C7.21 2 3.83 4.13 2.18 7.5C3.83 10.87 7.21 13 11 13C14.79 13 18.17 10.87 19.82 7.5ZM11 5C12.38 5 13.5 6.12 13.5 7.5C13.5 8.88 12.38 10 11 10C9.62 10 8.5 8.88 8.5 7.5C8.5 6.12 9.62 5 11 5ZM6.5 7.5C6.5 5.02 8.52 3 11 3C13.48 3 15.5 5.02 15.5 7.5C15.5 9.98 13.48 12 11 12C8.52 12 6.5 9.98 6.5 7.5Z"
        fill="#B5B5B6"
      />
    </svg>
  );
}
