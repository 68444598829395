import { css } from "../../styles";

export const quillCss = css({
  ".ql-toolbar": {
    border: "0px !important",
    width: "fit-content",
    borderRadius: "6px",
    boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.20)",
    padding: "4px 16px 4px 16px !important",

    ".ql-formats": {
      marginRight: "0px !important",
      display: "flex !important",
      gap: "10px",

      button: {
        width: "28px",
        height: "28px",
        display: "flex",

        justifyContent: "center",
        alignItems: "center",

        borderRadius: "4px",

        "&:hover": {
          ".ql-stroke": {
            stroke: "#727272 !important",
            color: "#727272 !important",
          },
          ".ql-fill": {
            stroke: "#727272 !important",
            color: "#727272 !important",
            fill: "none",
          },
        },

        "&:focus": {
          outline: "2px solid #9182C6 !important",

          ".ql-stroke": {
            stroke: "#9182C6 !important",
            color: "#9182C6 !important",
          },
          ".ql-fill": {
            stroke: "#9182C6 !important",
            color: "#9182C6 !important",
            fill: "none",
          },
        },

        "&.ql-active": {
          backgroundColor: "#E9E6F4",

          ".ql-stroke": {
            stroke: "#9182C6 !important",
            color: "#9182C6 !important",
          },
          ".ql-fill": {
            stroke: "#9182C6 !important",
            color: "#9182C6 !important",
            fill: "none",
          },
        },
      },
    },
  },

  ".ql-container": {
    fontSize: "inherit !important",
  },

  ".ql-stroke": {
    fill: "none",
    stroke: "#939393 !important",
  },

  ".ql-toolbar .ql-fill": {
    fill: "none",
    stroke: "#939393",
  },

  ".ql-toolbar .ql-picker": {
    color: "#939393",
  },

  ".ql-editor": {
    fontFamily: "$openSans !important",
    color: "#212121",
    fontSize: "inherit",
  },

  ".ql-blank::before": {
    fontStyle: "normal !important",
  },
});

export const mobileQuilllCss = css(quillCss, {
  ".ql-container": {
    backgroundColor: "#FFFFFF !important",
    border: "none !important",
  },
});
