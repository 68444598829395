import { styled } from "../../../../../../../styles";

// components
export const SubQuestionContainer = styled("div", {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "20px",
});

export const SubQestionDescription = styled("div", {
  width: "100%",
  display: "flex",
  justifyContent: "center",
  color: "$charcoal",
  fontSize: "16px",
  lineHeight: "22px",
  fontFamily: "$openSans",
  padding: "5px",
  marginBottom: "20px",
});

export const FiveQuestionsOptionsSelectionContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  width: "100%",
  marginBottom: "10px",

  p: {
    marginRight: "10px",
  },
});

export const ClickableOption = styled("div", {
  padding: "8px 30px 8px 0",
  cursor: "pointer",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
});
