import { styled, css } from "../../../../styles";

export const ProtocolBuilderContainer = styled("div", {
  width: "100vw",
  height: "100vh",
  position: "relative",
  marginTop: "-62px",
  zIndex: 2001,
  backgroundColor: "$gray-20",

  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

export const Header = styled("div", {
  width: "100%",
  height: "62px",

  position: "absolute",

  backgroundColor: "$primary-80",
  padding: "0 40px 0 68px",
  boxShadow: "0px 0px 4px 1px rgba(0, 0, 0, 0.16)",

  display: "flex",
  alignItems: "center",

  p: {
    color: "$white",
    fontSize: "16px",
    fontWeight: "00",
    lineHeight: "22px",
  },
});

export const ButtonsContainer = styled("div", {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: "12px",

  position: "absolute",
  right: "40px",
});

export const CancelButton = styled("div", {
  width: "110px !important",
  height: "38px",

  padding: "0px 40px",
  borderRadius: "20px",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "22px",
  color: "$white",

  cursor: "pointer",

  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.15)",
  },

  "&:focus-visible": {
    outline: "2px solid white !important",
  },
});

export const ScrollableContainer = styled("div", {
  width: "100vw",
  height: "calc(100vh - 62px)",
  overflowY: "scroll",

  display: "flex",
  justifyContent: "center",

  position: "relative",
  top: "62px",
});

export const BodyContainer = styled("div", {
  minWidth: "1020px",
  width: "60%",
  height: "max-content",
  minHeight: "calc(100vh - 62px)",

  backgroundColor: "$white",
  border: "1px solid $gray-40",

  padding: "50px 48px 48px 48px",
});

export const Section = styled("div", {
  width: "100%",

  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",

  borderRadius: "8px",
  backgroundColor: "rgba(248, 248, 248, 0.8)",

  padding: "28px 48px",

  gap: "24px",
  flexWrap: "wrap",

  p: {
    width: "fit-content",
    color: "$charcoal",
    fontFamily: "$openSans",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "16px",
  },
});

export const SectionItem = styled("div", {
  width: "48%",

  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",

  gap: "40px",
});

export const SectionTitle = styled("p", {
  color: "$black",
  fontFamily: "$openSans",
  fontSize: "16px",
  fontWeight: "500",
  lineHeight: "22px",

  marginBottom: "30px",
  marginLeft: "17px",

  variants: {
    isActionTitle: {
      true: {
        margin: "30px 0 20px 17px",
      },
    },
  },
});

export const AddActionButton = styled("div", {
  width: "146px",
  height: "32px",

  padding: "5px 31px 5px 13px",
  marginTop: "22px",

  borderRadius: "8px",
  backgroundColor: "$black",
  cursor: "pointer",

  color: "$white",
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "22px",

  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",

  p: {
    fontSize: "22px",
    fontWeight: "500",

    marginRight: "7px",
  },

  "&:focus-visible": {
    outline: "2px solid $primary-30 !important",
  },
});

export const LoadingContainer = styled("div", {
  width: "100%",
  height: "300px",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

// CSS

export const whiteButton = css({
  button: {
    backgroundColor: "$white !important",
    borderColor: "$white !important",

    p: {
      color: "$primary-70 !important",
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "22px",
    },

    "&:hover": {
      backgroundColor: "$gray-40 !important",
    },

    "&:focus-visible": {
      outline: "2px solid $primary-30 !important",
    },
  },
});

export const purpleButton = css({
  button: {
    backgroundColor: "$primary-40 !important",
    borderColor: "$primary-40 !important",

    p: {
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "22px",
    },

    "&:hover": {
      backgroundColor: "$primary-30 !important",
    },

    "&:focus-visible": {
      outline: "2px solid white !important",
    },
  },
});

export const textFieldClassName = css({
  width: "248px !important",

  input: {
    border: "2px solid $gray-50",
    borderRadius: "6px",
    paddingLeft: "11px",
    paddingRight: "11px",

    "&:hover": {
      border: "2px solid $primary-30",
    },

    "&:focus-visible": {
      border: "2px solid $primary-30",
    },
  },
});

export const selectCss = css({
  width: "300px",
  height: "41px",

  ".ant-select-selector": {
    height: "42px !important",
    alignItems: "center",
    border: "solid 2px $gray-50 !important",
    boxShadow: "0 0 0 1px $gray-50 !important",
    fontFamily: "$openSans !important",

    "&:focus-within": {
      borderColor: "$primary-30 !important",
    },

    "&:hover": {
      borderColor: "$primary-30 !important",
    },

    ".ant-select-selection-overflow-item": {
      span: {
        "&.ant-select-selection-item": {
          borderRadius: "14px !important",
        },
      },
    },

    ".ant-select-selection-search-input": {
      height: "100% !important",
    },
  },
});
