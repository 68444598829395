import { useState } from "react";

import { Notification } from "../../../../../../@types/Notification";
import { IStaffTableData } from "../../../../../../@types/Staff";
import { Button } from "../../../../../../components/Button";
import { staffService } from "../../../../../../services/staffService";
import { mixpanelActions } from "../../../../../../utils/mixpanel";
import {
  CancelButton,
  ModalTitle,
} from "../../../SecurityModal/SecurityModal.styles";
import {
  ModalContainer,
  archiveButtonCss,
  SubmitContainer,
  ContentContainer,
  Row,
  Column,
} from "./ArchiveStaffModal.styles";

type ArchiveStaffModalProps = {
  staff: IStaffTableData;
  onClose: (notification?: Notification, refetchTable?: boolean) => void;
};

export function ArchiveStaffModal({ staff, onClose }: ArchiveStaffModalProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleArchiveStaff = async () => {
    setIsLoading(true);

    let message = "";
    let type: "success" | "info" | "warning" | "error" = "error";
    let refetchTable = false;

    if (staff.isActive === "Yes") {
      mixpanelActions.track("User action: ArchiveStaffMember");

      const result = await staffService.archiveHospitlStaff(staff.id);

      if (result.status >= 200 && result.status < 300 && result.data) {
        message = "User successfully archived";
        type = "success";
        refetchTable = true;
      } else {
        message = "Error archiving user, please try again";
        type = "error";
      }
    } else {
      mixpanelActions.track("User action: ReactivateStaffMember");

      const result = await staffService.reactivateHospitlStaff(staff.id);

      if (result.status >= 200 && result.status < 300 && result.data) {
        message = "User successfully reactivated";
        type = "success";
        refetchTable = true;
      } else {
        message = "Error reactivating user, please try again";
        type = "error";
      }
    }

    onClose(
      {
        show: true,
        message,
        type,
        width: "max-content",
      },
      refetchTable
    );
  };

  return (
    <ModalContainer>
      <ModalTitle>
        {staff.isActive === "Yes" ? "Archive" : "Reactivate"} user
      </ModalTitle>

      <ContentContainer>
        <Row>
          <Column>
            <p style={{ fontWeight: "500" }}>Name</p>
            <p>{staff.name}</p>
          </Column>
          <Column>
            <p style={{ fontWeight: "500" }}>Email</p>
            <p>{staff.email}</p>
          </Column>
          <Column>
            <p style={{ fontWeight: "500" }}>Admin</p>
            <p>{staff.isAdmin}</p>
          </Column>
        </Row>
      </ContentContainer>

      {staff.isActive === "Yes" ? (
        <ContentContainer isNote>
          <Row>
            <Column isPrimaryColor>
              <p style={{ fontWeight: "500" }}>Please note</p>
              <p>This user will no longer receive patient alerts.</p>
            </Column>
          </Row>
        </ContentContainer>
      ) : null}

      <SubmitContainer>
        <Button
          label={staff.isActive === "Yes" ? "Archive" : "Reactivate"}
          type="button"
          onClick={handleArchiveStaff}
          isLoading={isLoading}
          className={archiveButtonCss()}
        />

        <CancelButton
          onClick={() => onClose()}
          style={{ marginLeft: "-35px", padding: "0 30px" }}
        >
          Cancel
        </CancelButton>
      </SubmitContainer>
    </ModalContainer>
  );
}
