import { AccessorKeyColumnDef } from "@tanstack/react-table";
import { ReactElement } from "react";

import { IArrhythmiaStatus } from "../../../../../../@types/Measurements";
import { ReactComponent as NoMessagesImage } from "../../../../../../assets/images/woman-computer.svg";
import { createColumn, Table } from "../../../../../../components/Table";
import { Tag } from "../../../../../../components/Tag";
import {
  fibriCheckColorDictionary,
  tooltipTitles,
} from "../../../../../../constants/measurements";
import {
  ImageContainer,
  NoContentMessage,
  TableContainer,
} from "./ModalMeasurementsTable.styles";

interface IModalMeasurements {
  data: Map<string, any[]>;
  measurementType: string;
  isPinnedGraph?: boolean;
}

interface IMeasuremntTableData {
  measurement: string;
  source: string;
  time: string;
  unformattedTime: number;
  arrhythmia_status?: ReactElement;
}

export function ModalMeasurementsTable({
  data,
  measurementType,
  isPinnedGraph,
}: IModalMeasurements) {
  if (data.size < 1) {
    return (
      <ImageContainer>
        <NoMessagesImage width={181} height={158} />
        <NoContentMessage>
          {measurementType === "arrhythmia_status"
            ? "No measurements to show with the current data range."
            : "No measurements to show with the current filters and/or data range."}
        </NoContentMessage>
      </ImageContainer>
    );
  }

  const mapKeys = Array.from(data.keys());
  let header = tooltipTitles.get(mapKeys[0].split("|")[0]) ?? "";
  const tableData: IMeasuremntTableData[] = [];

  const modalMeasurementsTableColumns: AccessorKeyColumnDef<any, string>[] = [
    createColumn({
      keyName: "measurement",
      header: () => header,
      cell: (info) => info.renderValue(),
      size: 100,
    }),
    createColumn({
      keyName: "source",
      header: () => "Source",
      cell: (info) => info.renderValue(),
      size: 100,
    }),
    createColumn({
      keyName: "time",
      header: () => "Date",
      cell: (info) => info.renderValue(),
      size: 120,
    }),
  ];

  const handleArrhythmiaTag = (measurement: any) => {
    const parsedObject = JSON.parse(measurement.arrhythmia_status);

    const arrhythmiaStatus: IArrhythmiaStatus = {
      key: parsedObject.Key,
      color: parsedObject.Color,
    };

    const formattedKey = arrhythmiaStatus.key
      .replace(/_/g, " ")
      .replace(/^./, (char) => char.toUpperCase());

    const tagColor = fibriCheckColorDictionary[arrhythmiaStatus.color] as
      | "success"
      | "info"
      | "tertiary"
      | "error"
      | "warning";

    return <Tag variant={tagColor}>{formattedKey}</Tag>;
  };

  mapKeys.forEach((key) => {
    const measurementType = key.split("|")[0];

    if (measurementType === "arrhythmia_status" && header !== "BPM") {
      header = "BPM";

      modalMeasurementsTableColumns.push(
        createColumn({
          keyName: "arrhythmia_status",
          header: () => "Status",
          cell: (info) => info.renderValue(),
          size: 200,
        })
      );
    }

    data.get(key)?.forEach((measurement) => {
      if (measurementType === "bp_systolic") {
        header = "Systolic/Diastolic";

        const DiastolicMeasurement = data
          .get(`bp_diastolic|${measurement.source.toLowerCase()}`)
          ?.find((element) => element.id === measurement.id);

        tableData.push({
          measurement: `${measurement[measurementType]}/${
            DiastolicMeasurement ? DiastolicMeasurement.bp_diastolic : "-"
          }`,
          source: measurement.source,
          time: measurement.time,
          unformattedTime: measurement.unformattedTime,
        });

        return;
      }

      if (measurementType === "bp_diastolic") {
        return;
      }

      tableData.push({
        measurement:
          measurementType !== "arrhythmia_status"
            ? measurement[key.split("|")[0]]
            : measurement.heart_rate,
        source: measurement.source,
        time: measurement.time,
        unformattedTime: measurement.unformattedTime,
        arrhythmia_status:
          measurementType === "arrhythmia_status"
            ? handleArrhythmiaTag(measurement)
            : undefined,
      });
    });
  });

  tableData.sort((a, b) => b.unformattedTime - a.unformattedTime);

  return (
    <TableContainer isPinnedGraph={isPinnedGraph}>
      <Table
        fontWeight={400}
        hasBorder={false}
        data={tableData}
        columns={modalMeasurementsTableColumns}
      />
    </TableContainer>
  );
}
