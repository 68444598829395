import { useEffect, useState } from "react";

import { RichTextEditor } from "../../../../../../../components/RichTextEditor";
import { FormAnswerField } from "./InformativeQuestionDisplay.styles";

interface IInformativeQuestionDisplayProps {
  HTMLText: string;
}

export function InformativeQuestionDisplay({
  HTMLText,
}: IInformativeQuestionDisplayProps) {
  const [value, setValue] = useState<string>("");
  const [heigth, setHeight] = useState<number>(0);

  useEffect(() => {
    setValue(HTMLText);
    const parentDivHeight = document.documentElement.clientHeight;
    setHeight(parentDivHeight ? parentDivHeight - 225 : 0);
  }, []);

  return (
    <FormAnswerField id="parentDiv">
      <RichTextEditor
        height={heigth}
        mobile
        readOnly
        value={value}
        onValueChange={() => {}}
      />
    </FormAnswerField>
  );
}
