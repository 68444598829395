import React from "react";

export function PMPLogo({ ...rest }) {
  return (
    <svg
      {...rest}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.6763 19.8047H37.1076C32.8919 19.8047 29.4606 16.2753 29.4606 12.0596V5.88312C29.4606 5.29488 28.9704 4.80469 28.3821 4.80469C27.7939 4.80469 27.3037 5.29488 27.3037 5.88312V11.9616C27.3037 17.4517 31.7155 21.9615 37.1076 21.9615H43.6763C44.2645 21.9615 44.7547 21.4714 44.7547 20.8831C44.7547 20.2949 44.2645 19.8047 43.6763 19.8047Z"
        fill="#16A8A9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.072 25.2949C49.072 24.7067 48.5818 24.2165 47.9935 24.2165H46.9151H40.5426H37.1112C30.4445 24.2165 25.1504 18.7263 25.1504 11.9616V1.37335C25.0524 0.785118 24.5622 0.294922 23.9739 0.294922C23.3857 0.294922 22.8955 0.785118 22.8955 1.37335V11.9616C22.8955 19.9028 29.2681 26.4714 37.1112 26.4714H47.9935C48.5818 26.4714 49.072 25.9812 49.072 25.2949Z"
        fill="#16A8A9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.6759 28.7263H37.1073C32.8916 28.7263 29.0681 27.0596 26.1269 24.4125C25.5386 23.9223 25.0484 23.4321 24.4602 23.04C24.3622 22.9419 24.1661 22.8439 23.97 22.6478C23.872 22.5498 23.6759 22.4517 23.5779 22.3537C23.0877 21.9615 22.4994 21.5694 22.0092 21.2753C21.0288 20.687 20.4406 20.491 20.4406 20.491C18.3818 19.6086 16.0288 19.1184 13.2837 19.1184H6.22492C5.63668 19.1184 5.14648 19.6086 5.14648 20.1968C5.14648 20.7851 5.63668 21.2753 6.22492 21.2753H13.3818C17.3033 21.2753 20.9308 22.7459 23.7739 25.0988H23.872C23.97 25.1968 24.0681 25.3929 24.2641 25.491C25.7347 26.9615 27.4994 28.2361 29.4602 29.1184C31.8132 30.1968 34.3622 30.7851 37.1073 30.7851H43.6759C44.2641 30.7851 44.7543 30.2949 44.7543 29.7066C44.7543 29.2164 44.2641 28.7263 43.6759 28.7263Z"
        fill="#785CB3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.1075 18.6282C21.2252 16.5694 20.735 14.3145 20.735 11.9616V5.88312C20.735 5.29488 20.2448 4.80469 19.6566 4.80469C19.0683 4.80469 18.5781 5.29488 18.5781 5.88312V11.9616C18.5781 13.8243 18.8722 15.687 19.3624 17.4517C20.2448 17.7459 21.2252 18.138 22.1075 18.6282Z"
        fill="#785CB3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.7935 31.1772C28.6758 33.2361 29.166 35.491 29.166 37.8439V43.9223C29.166 44.5106 29.6562 45.0008 30.2444 45.0008C30.8327 45.0008 31.3229 44.5106 31.3229 43.9223V37.8439C31.3229 35.9812 31.0288 34.1184 30.5386 32.3537C29.5582 32.0596 28.6758 31.6674 27.7935 31.1772Z"
        fill="#785CB3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6955 28.04H6.22492C5.63668 28.04 5.14648 28.5302 5.14648 29.1185C5.14648 29.7067 5.63668 30.1969 6.22492 30.1969H12.7935C17.0092 30.1969 20.4406 33.7263 20.4406 37.942V44.0204C20.4406 44.6087 20.9308 45.0989 21.519 45.0989C22.1073 45.0989 22.5975 44.6087 22.5975 44.0204V37.942C22.5975 32.4518 18.1857 28.04 12.6955 28.04Z"
        fill="#16A8A9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.9113 38.0401C26.9113 30.0989 20.5388 23.5303 12.6956 23.5303H1.81329C1.22506 23.5303 0.734863 24.0205 0.734863 24.6087C0.734863 25.1969 1.22506 25.7852 1.81329 25.7852H2.89173H9.26427H12.6956C19.3623 25.7852 24.6564 31.2754 24.6564 38.0401V48.6283C24.6564 49.2165 25.1466 49.7067 25.7349 49.7067C26.4211 49.7067 26.9113 49.2165 26.9113 48.6283V38.0401Z"
        fill="#16A8A9"
      />
    </svg>
  );
}
