import * as LabelPrimitive from "@radix-ui/react-label";

import { css, styled } from "../../styles";

export const StyledLabel = styled(LabelPrimitive.Root, {
  fontWeight: "600",
  color: "$gray-90",
  lineHeight: "22px",
  userSelect: "none",
  fontSize: "$text-lg",
  margin: "0 0 8px 6px",
  fontFamily: "$openSans",

  variants: {
    styleDisabled: {
      true: {
        opacity: "0.38",
      },
    },
  },
});

export const TextFieldContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  position: "relative",
});

export const StyledInput = styled("input", {
  width: 200,
  height: 42,
  all: "unset",
  padding: "$5 $8",
  borderRadius: "$2",
  fontSize: "$text-lg",
  caretColor: "$gray-70",
  fontFamily: "$openSans",
  border: "1px solid $gray-50",

  backgroundColor: "rgba(233, 230, 244, 0.16)",

  "&::placeholder": {
    color: "$gray-70",
  },

  "&:focus": {
    borderColor: "primary-30",
    backgroundColor: "$white",
  },

  variants: {
    styleDisabled: {
      true: {
        opacity: "0.38",
      },
    },

    error: {
      true: {
        backgroundColor: "$red-lightest",
        border: "1px solid $error-lighter",

        "&:focus": {
          backgroundColor: "$red-lightest",
          border: "1px solid $error-lighter",
        },
      },
    },
  },
});

export const AlertText = styled("p", {
  fontWeight: "600 !important",
  lineHeight: "16px !important",
  padding: "$1 0 0 $3 !important",
  fontSize: "$text-md !important",
  fontFamily: "$openSans !important",

  variants: {
    type: {
      error: {
        color: "$error-light !important",
      },
      warning: {
        color: "$warning-text !important",
      },
      mobileError: {
        color: "$error-light !important",
        fontSize: "16px !important",
      },
      mobileWarning: {
        color: "$warning-text !important",
        fontSize: "16px !important",
      },
    },
  },
});

export const StyledIconButton = styled("button", {
  margin: 0,
  padding: 4,
  top: "33px",
  right: "13px",
  border: "none",
  display: "flex",
  cursor: "pointer",
  position: "absolute",
  alignItems: "center",
  borderRadius: "$round",
  justifyContent: "center",
  backgroundColor: "transparent",

  "&:hover": {
    backgroundColor: "$gray-30",
  },

  svg: {
    width: "$11",
    height: "$11",
  },
});

export const StyledAuxiliarButton = styled("button", {
  margin: 0,
  padding: 0,
  border: "none",
  fontWeight: 600,
  color: "$primary",
  lineHeight: "22px",
  fontSize: "$text-md",
  fontFamily: "$openSans",
  backgroundColor: "transparent",

  cursor: "pointer",
  position: "absolute",
  top: "8px",
  right: 0,

  "&:hover": {
    color: "$primary-60",
  },
});

export const skeletonContainerStyles = css({
  gap: "$4",
  display: "flex",
  flexDirection: "column",
});
