import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Notification } from "../../../../@types/Notification";
import { IProtocolResponse } from "../../../../@types/Protocol";
import { ProtocolsEmptyState } from "../../../../assets/icons/ProtocolsEmptyState";
import { Table } from "../../../../components/Table";
import { TableSkeleton } from "../../../../components/TableSkeleton";
import { ToastNotification } from "../../../../components/ToastNotification";
import { NOTIFICATION } from "../../../../constants/localStorageKeys";
import { ImageContainer } from "../../../PatientPage/components/PatientAlertsTable/PatientAlertsTable.style";
import { AddProtocolButton } from "../../ProtocolList.styles";
import { protocolListColumns } from "../../ProtocolListColumns";

type ProtocolListTableProps = {
  content: IProtocolResponse[];
  isLoading?: boolean;
  refetch(): void;
};

export function ProtocolListTable({
  content,
  isLoading,
  refetch,
}: ProtocolListTableProps) {
  const [notification, setNotification] = useState<Notification>({
    show: false,
    message: "",
    type: "error",
    width: "max-content",
    rightMargin: "50%",
  });

  const navigate = useNavigate();

  const handleRowClick = async (content: any) => {
    navigate(`/protocol/builder/${content.id}`);
  };

  const handleAddProtocol = () => {
    navigate("/protocol/builder");
  };

  useEffect(() => {
    const notification = localStorage.getItem(NOTIFICATION);

    if (notification) {
      refetch();
      setNotification(JSON.parse(notification));
      localStorage.removeItem(NOTIFICATION);

      setTimeout(() => {
        setNotification({
          show: false,
          message: "notificationMessage",
          type: "error",
          width: undefined,
        });
      }, 3500);
    }
  }, []);

  if (isLoading) {
    return <TableSkeleton columnsNumber={8} />;
  }

  if (content && content.length > 0) {
    return (
      <>
        <AddProtocolButton onClick={handleAddProtocol}>
          Create protocol
        </AddProtocolButton>
        <Table
          data={content}
          onRowClick={handleRowClick}
          emptyStateText="No protocols"
          columns={protocolListColumns}
          showMaxContent
        />
        {notification.show ? (
          <ToastNotification
            message={notification.message}
            type={notification.type}
            width={notification.width}
            rightMargin="35%"
          />
        ) : null}
      </>
    );
  }

  return (
    <>
      <AddProtocolButton onClick={handleAddProtocol}>
        Create protocol
      </AddProtocolButton>
      <ImageContainer style={{ height: "800px", minHeight: "30px" }}>
        <ProtocolsEmptyState />
        <p>
          You have not added any protocols yet. <span>Add protocols </span>here
          to increase patient engagement with PROMs and spirometry.
        </p>
      </ImageContainer>
    </>
  );
}

ProtocolListTable.defaultProps = {
  isLoading: false,
};
