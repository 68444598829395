import { styled, css } from "../../../../../../../../../styles";

// Components

export const SliderContainer = styled("div", {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

export const OptionnRowContainer = styled("div", {
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "top",
  justifyContent: "center",
  marginBottom: "5px",
});

export const OptionRowLeft = styled("div", {
  position: "relative",
  display: "flex",
  flexDirection: "row",
  alignItems: "top",
  justifyContent: "flex-end",

  width: "330px",
  height: "75px",

  p: {
    marginRight: "15px",
  },
});

export const OptionRowRight = styled("div", {
  position: "relative",
  marginLeft: "15px",
  display: "flex",
  flexDirection: "row",
  alignItems: "top",
  justifyContent: "flex-end",

  width: "220px",
  heigth: "75px",

  p: {
    marginRight: "15px",
  },
});

export const ValueParagraph = styled("div", {
  marginRight: "15px",
  position: "absolute",
  right: "63px",
  top: "8.5px",
});

export const LabelParagraph = styled("div", {
  marginRight: "15px",
  position: "absolute",
  right: "193px",
  top: "8.5px",
});

export const DropdownRow = styled("div", {
  position: "relative",
  display: "flex",
  flexDirection: "row",
  alignItems: "top",
  justifyContent: "flex-end",

  ".ant-select": {
    height: "42px !important",
  },

  width: "330px",
  height: "42px",

  p: {
    marginRight: "15px",
  },
});

export const ScoreContainer = styled("div", {
  position: "relative",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "16px",
});

export const ScoreInterval = styled("div", {
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "baseline",
  gap: "40px",
});

export const IntervalsErrorMessage = styled("p", {
  fontSize: "12px !important",
  fontWeight: "600 !important",

  lineHeight: "16px !important",

  color: "$error-light !important",

  width: "100% !important",
  position: "absolute",
  bottom: "15px",
  left: "200px",
  pointerEvents: "none",
});

// CSS

export const LabelInputCss = css({
  width: "200px",
});

export const ValueInputCss = css({
  width: "70px",
});

export const IntervalInputCss = css({
  width: "110px",
});
