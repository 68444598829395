import { css, styled } from "../../styles";

export const defaultCss = css({
  margin: "0px !important",
  height: "20px !important",

  ".ant-slider-rail": {
    backgroundColor: "$secondary-lighter !important",
    bottom: " 8px",

    "&:hover": {
      backgroundColor: "$secondary-lighter !important",
    },
  },

  ".ant-slider-step": {
    top: " 8px",
  },

  ".ant-slider-handle": {
    boxShadow: "none",
    top: " 5px",

    "&::after": {
      backgroundColor: "$primary-40 !important",
      boxShadow: "0 0 0 2px $gray-20 !important",
    },

    "&::before": {
      zIndex: "1071",

      width: "35px",
      heigth: "75px !important",
      minHeight: "75px !important",

      insetInlineStart: "-13px",
      insetBlockStart: "-50px",
    },

    "&:focus": {
      "&::before": {
        zIndex: "1071",

        width: "35px",
        heigth: "75px !important",
        minHeight: "75px !important",

        insetInlineStart: "-13px",
        insetBlockStart: "-50px",
      },
    },

    "&:hover": {
      "&::before": {
        zIndex: "1071",

        width: "35px",
        heigth: "75px !important",
        minHeight: "75px !important",

        insetInlineStart: "-13px",
        insetBlockStart: "-50px",
      },
    },

    "&:active": {
      "&::before": {
        zIndex: "1071",

        width: "35px",
        heigth: "75px !important",
        minHeight: "75px !important",

        insetInlineStart: "-13px",
        insetBlockStart: "-50px",
      },
    },
  },
});

export const SliderContainer = styled("div", {
  width: "100% ",
  margin: "11px 5px",

  ".ant-tooltip-inner": {
    textAlign: "center",
    minWidth: "32px !important",
  },
});
