import * as PrimitiveDialog from "@radix-ui/react-dialog";

import { styled } from "../../styles";
import { Tile } from "../Tile";

export const StyledDialogOverlay = styled(PrimitiveDialog.Overlay, {
  backgroundColor: "rgba(33, 33, 33, 0.58)",

  position: "fixed",
  animation: "overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1)",

  inset: 0,
  zIndex: 3000,

  display: "flex",

  justifyContent: "center",
  alignItems: "center",
});

export const StyledDialogContent = styled(PrimitiveDialog.Content, {
  "&:focus": {
    outline: "none",
  },

  display: "flex",
  gap: "18px",
});

export const StyledTile = styled(Tile, {
  backgroundColor: "white",
  position: "relative",

  "@sm": {
    width: "100vw",
    height: "100vh",
  },
});

export const StyledDialogClose = styled(PrimitiveDialog.Close, {
  position: "absolute",
  right: 8,
  top: 8,
  zIndex: "1",

  backgroundColor: "transparent",
  border: "none",

  "&:focus": {},

  variants: {
    outline: {
      true: {
        "&:focus": {
          borderRadius: "2px",
          outline: "2px #295FCC solid",
        },
      },
      false: {
        "&:focus": {
          outline: "none !important",
        },
      },
    },
  },
});
