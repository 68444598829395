import { useEffect } from "react";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";

import { mobileQuilllCss, quillCss } from "./RichTextEditor.styles";

type RichTextEditorProps = {
  placeholderTxt?: string;
  height?: number;
  value?: string;
  readOnly?: boolean;
  hideToolbar?: boolean;
  onValueChange: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  mobile?: boolean;
};

export function RichTextEditor({
  placeholderTxt = "Enter your text here...",
  value,
  height = 300,
  hideToolbar = false,
  readOnly = false,
  onValueChange,
  mobile,
  onFocus,
  onBlur,
}: RichTextEditorProps) {
  const toolbar =
    readOnly || hideToolbar
      ? false
      : [["bold", "strike", "underline", "italic", "image", "link"]];
  const placeholder = readOnly ? "" : placeholderTxt;
  const modules = {
    toolbar,
  };
  const toolBarHeight = readOnly ? 0 : 42;

  const { quill, quillRef, Quill } = useQuill({
    modules,
    placeholder,
    readOnly,
  });

  useEffect(() => {
    if (quill) {
      quill.on("text-change", () => {
        onValueChange(quill.root.innerHTML);
      });
      quill.on("selection-change", (range: any, oldRange: any) => {
        if (range === null && oldRange !== null) {
          if (onBlur) {
            onBlur();
          }
        } else if (range !== null && oldRange === null) {
          if (onFocus) {
            onFocus();
          }
        }
      });
      quill.root.innerHTML = value ?? "";
    }
  }, [quill]);

  if (Quill && !quill) {
    // Add https to link if https is not present
    const Link = Quill.import("formats/link");
    Link.sanitize = function sanitize(url: string) {
      // quill by default creates relative links if scheme is missing.
      if (!url.startsWith("http://") && !url.startsWith("https://")) {
        return `http://${url}`;
      }
      return url;
    };
    Quill.register(Link, true);
  }

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          height,
          fontSize: mobile ? "18px" : "14px",
        }}
        // className={mobile ? mobileQuilllCss() : quillCss()}
        className={mobile ? mobileQuilllCss() : quillCss()}
      >
        <div
          style={{
            width: "calc(100% - 40)",
            height: height - toolBarHeight,
            backgroundColor: "rgba(233, 230, 244, 0.16)",
            borderRadius: "6px",
            marginTop: "20px",
            border: "1px solid #E6E6E6",
            color: "rgba(33, 33, 33, 0.50)",
          }}
          ref={quillRef}
        />
      </div>
    </div>
  );
}
