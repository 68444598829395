import { Tooltip } from "antd";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import {
  ICreateTriggerProps,
  ITriggerProps,
} from "../../../../../../@types/Alerts";
import { IUserPreferences } from "../../../../../../@types/Preferences";
import { BlackMinus } from "../../../../../../assets/icons/BlackMinus";
import { BlackPlus } from "../../../../../../assets/icons/BlackPlus";
import { Button } from "../../../../../../components/Button";
import { SelectDropdown } from "../../../../../../components/SelectDropdown";
import { TextField } from "../../../../../../components/TextField";
import { AUTH_UNIT_PREFERENCES } from "../../../../../../constants/localStorageKeys";
import { alertsService } from "../../../../../../services/alertsService";
import { mixpanelActions } from "../../../../../../utils/mixpanel";
import { CancelButton } from "../ManageTriggersModal/ManageTriggersModal.styles";
import {
  AdditionalButton,
  Container,
  ContentContainer,
  Field,
  HCPMessage,
  NoteSection,
  ScrollableContainer,
  SelectSection,
  SubmitContainer,
  Title,
} from "./CreateTriggerModal.styles";

type CreateTriggerModalProps = {
  content?: ITriggerProps;
  isEdit?: boolean;
  measurementAlertId?: string;
  patientId: number;
  setParentNotification: (
    showNotification: boolean,
    notificationMessage: string,
    notificationType: "success" | "warning" | "error",
    notificationWidth?: string
  ) => void;
  onClose: () => void;
};

const baseComparatorsOptions = [
  {
    label: "weight gain over time",
    value: "weight_gain",
    key: uuidv4(),
    disabled: true,
  },
  {
    label: "greater than (>)",
    value: "greater_than",
    key: uuidv4(),
    disabled: false,
  },
  {
    label: "greater than or equal to (>=)",
    value: "greater_or_equal",
    key: uuidv4(),
    disabled: false,
  },
  {
    label: "less than (<)",
    value: "less_than",
    key: uuidv4(),
    disabled: false,
  },
  {
    label: "less than or equal to (<=)",
    value: "less_or_equal",
    key: uuidv4(),
    disabled: false,
  },
  {
    label: "percentage decrease from set value",
    value: "percentage_decrease",
    key: uuidv4(),
    disabled: false,
  },
  {
    label: "percentage increase from set value",
    value: "percentage_increase",
    key: uuidv4(),
    disabled: false,
  },
];

const optionsAlerts = [
  { label: "On any new reading", value: "any_reading", key: uuidv4() },
  {
    label: "The highest measurement reading in a day",
    value: "highest_of_day",
    key: uuidv4(),
  },
  {
    label: "Consecutive highest measurement readings over 3 days",
    value: "consecutive_highest_3_days",
    key: uuidv4(),
  },
  {
    label: "Consecutive highest measurement readings over 7 days",
    value: "consecutive_highest_7_days",
    key: uuidv4(),
  },
];

const optionsNotification = [
  { label: "Email", value: "Email", key: uuidv4() },
  { label: "SMS", value: "SMS", key: uuidv4() },
  { label: "Call", value: "Call", key: uuidv4() },
];

const optionsType = [
  { label: "Weight", value: "weight", key: uuidv4() },
  { label: "Height", value: "height", key: uuidv4() },
  { label: "BP Systolic", value: "bp_systolic", key: uuidv4() },
  { label: "BP Diastolic", value: "bp_diastolic", key: uuidv4() },
  { label: "Hemoglobin", value: "hemoglobin", key: uuidv4() },
  { label: "Tacrolimus", value: "cni_trough", key: uuidv4() },
  { label: "Blood Glucose", value: "blood_glucose", key: uuidv4() },
  { label: "Heart Rate", value: "heart_rate", key: uuidv4() },
  { label: "Oxygen Saturation", value: "pulse_ox", key: uuidv4() },
  { label: "Temperature", value: "temperature", key: uuidv4() },
  { label: "Breathlessness", value: "dyspnea", key: uuidv4() },
  { label: "FVC", value: "fvc", key: uuidv4() },
  { label: "FEV1", value: "fev1", key: uuidv4() },
  { label: "FEF25/75", value: "fev25_75", key: uuidv4() },
];

export function CreateTriggerModal({
  content,
  isEdit = false,
  measurementAlertId,
  setParentNotification,
  onClose,
  patientId,
}: CreateTriggerModalProps) {
  const [thresholdUnit, setThresholdUnit] = useState("");
  const [thresholdUnit2, setThresholdUnit2] = useState("");
  const [additionalMeasurement, setAdditionalMeasurement] = useState(false);

  const [type, setType] = useState("");
  const [comparator, setComparator] = useState("");
  const [threshold, setThreshold] = useState("");
  const [percentage, setPercentage] = useState("");
  const [timeframe, setTimeframe] = useState("");
  const [type2, setType2] = useState("");
  const [comparator2, setComparator2] = useState("");
  const [threshold2, setThreshold2] = useState("");
  const [percentage2, setPercentage2] = useState("");
  const [timeframe2, setTimeframe2] = useState("");
  const [alert, setAlert] = useState("");
  const [notification, setNotification] = useState<string[]>([]);
  const [hcpMessage, setHcpMessage] = useState("");

  const [optionsComparator, setOptionsComparator] = useState(
    baseComparatorsOptions
  );

  const setWeightGainDisabledStatus = (status: boolean) => {
    setOptionsComparator((prevState) => {
      return prevState.map((comparator) =>
        comparator.value === "weight_gain"
          ? { ...comparator, disabled: status }
          : comparator
      );
    });
  };

  let mounted = true;

  const handleSetAlert = (days: number | undefined, comparator?: string) => {
    if (comparator === "+" && days !== undefined) {
      setTimeframe(days.toString());
      return;
    }

    switch (days) {
      case 1:
        setAlert("highest_of_day");
        break;
      case 3:
        setAlert("consecutive_highest_3_days");
        break;
      case 7:
        setAlert("consecutive_highest_7_days");
        break;
      default:
        setAlert("any_reading");
        break;
    }
  };

  const handleSetComparator = (comparator: string, isPercent: boolean) => {
    switch (comparator) {
      case ">":
        if (isPercent) {
          return "percentage_increase";
        }
        return "greater_than";
      case "<":
        if (isPercent) {
          return "percentage_decrease";
        }
        return "less_than";
      case ">=":
        return "greater_or_equal";
      case "<=":
        return "less_or_equal";
      case "+":
        setWeightGainDisabledStatus(false);
        return "weight_gain";
      default:
        return "";
    }
  };

  const handleThresholdUnit = (value: string, isSecondTrigger: boolean) => {
    if (!mounted) return;

    const setUnit = (value: string) => {
      if (isSecondTrigger) {
        setThresholdUnit2(value);
        return;
      }
      setThresholdUnit(value);
    };

    const userUnitPreferences = localStorage.getItem(AUTH_UNIT_PREFERENCES);

    const parsedUserUnitPreferences = userUnitPreferences
      ? (JSON.parse(userUnitPreferences) as IUserPreferences)
      : undefined;

    switch (value) {
      case "weight":
        setUnit(parsedUserUnitPreferences?.weightUnit ?? "kg");
        break;
      case "height":
        setUnit("cm");
        break;
      case "bp_systolic":
      case "bp_diastolic":
        setUnit("mmHg");
        break;
      case "hemoglobin":
        setUnit("g/dL");
        break;
      case "cni_trough":
        setUnit("ng/ml");
        break;
      case "blood_glucose":
        setUnit("mmol/L");
        break;
      case "heart_rate":
        setUnit("BPM");
        break;
      case "pulse_ox":
        setUnit("%");
        break;
      case "temperature":
        setUnit("°C");
        break;
      case "dyspnea":
        setUnit("0-4");
        break;
      case "fvc":
      case "fev1":
        setUnit("L");
        break;
      case "fev25_75":
        setUnit("L/s");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    mounted = true;

    if (content !== undefined && content !== null && isEdit && mounted) {
      setType(content.measurementField);
      setNotification(
        content.hcpNotification !== undefined
          ? content.hcpNotification.split(", ")
          : []
      );
      setComparator(
        handleSetComparator(content.comparator, content.baseline !== undefined)
      );
      handleSetAlert(content.timeframeDays, content.comparator);
      handleThresholdUnit(content.measurementField, false);

      if (
        content.percentChange !== undefined &&
        content.baseline !== undefined
      ) {
        setThreshold(content.baseline.toString());
        setPercentage(content.percentChange.toString());
      } else {
        setThreshold(
          content.threshold !== undefined ? content.threshold.toString() : ""
        );
      }

      if (content.hasSecondTrigger) {
        setAdditionalMeasurement(true);
        setType2(
          content.measurementField2 !== undefined
            ? content.measurementField2
            : ""
        );
        setComparator2(
          handleSetComparator(
            content.comparator2 !== undefined ? content.comparator2 : "",
            content.baseline !== undefined
          )
        );
        handleThresholdUnit(
          content.measurementField2 !== undefined
            ? content.measurementField2.toString()
            : "",
          true
        );

        if (
          content.percentChange2 !== undefined &&
          content.baseline2 !== undefined
        ) {
          setThreshold2(content.baseline2.toString());
          setPercentage2(content.percentChange2.toString());
        } else {
          setThreshold2(
            content.threshold2 !== undefined
              ? content.threshold2.toString()
              : ""
          );
        }
      }
    }

    return () => {
      mounted = false;
    };
  }, [content, isEdit]);

  const handleTimeFrame = () => {
    if (comparator === "weight_gain") {
      return parseInt(timeframe, 10);
    }

    switch (alert) {
      case "highest_of_day":
        return 1;
      case "consecutive_highest_3_days":
        return 3;
      case "consecutive_highest_7_days":
        return 7;
      default:
        return undefined;
    }
  };

  const handleComparator = (secondTrigger: boolean) => {
    switch (!secondTrigger ? comparator : comparator2) {
      case "weight_gain":
        return "+";
      case "greater_than":
        return ">";
      case "greater_or_equal":
        return ">=";
      case "less_than":
        return "<";
      case "less_or_equal":
        return "<=";
      case "percentage_decrease":
        return "<";
      case "percentage_increase":
        return ">";
      default:
        return "";
    }
  };

  const handleCreateTrigger = () => {
    const dataToCreateTrigger: ICreateTriggerProps = {
      measurementType: type,
      unit: thresholdUnit,
      comparator: handleComparator(false),
      threshold:
        comparator !== "percentage_decrease" &&
        comparator !== "percentage_increase"
          ? Number.parseFloat(threshold)
          : undefined,
      baseline:
        comparator === "percentage_decrease" ||
        comparator === "percentage_increase"
          ? Number.parseFloat(threshold)
          : undefined,
      percent:
        comparator === "percentage_decrease" ||
        comparator === "percentage_increase"
          ? Number.parseFloat(percentage)
          : undefined,
      secondTrigger: additionalMeasurement,
      measurementType2: additionalMeasurement ? type2 : undefined,
      comparator2: additionalMeasurement ? handleComparator(true) : undefined,
      threshold2:
        additionalMeasurement &&
        comparator2 !== "percentage_decrease" &&
        comparator2 !== "percentage_increase"
          ? Number.parseFloat(threshold2)
          : undefined,
      baseline2:
        additionalMeasurement &&
        (comparator2 === "percentage_decrease" ||
          comparator2 === "percentage_increase")
          ? Number.parseFloat(threshold2)
          : undefined,
      percent2:
        additionalMeasurement &&
        (comparator2 === "percentage_decrease" ||
          comparator2 === "percentage_increase")
          ? Number.parseFloat(percentage2)
          : undefined,
      hcpNotification: notification,
      hcpMessage,
      timeFrameDays: handleTimeFrame(),
      patientId,
    };

    if (isEdit) {
      alertsService
        .editTrigger(dataToCreateTrigger, measurementAlertId)
        .then((editedSuccessfully) => {
          const { data } = editedSuccessfully;

          if (data) {
            setParentNotification(
              true,
              "Trigger successfully edited",
              "success"
            );
          } else {
            setParentNotification(
              true,
              "Error editing trigger, please try again",
              "error"
            );
          }

          onClose();
        });
    } else {
      alertsService
        .createTrigger(dataToCreateTrigger)
        .then((createdSuccessfully) => {
          const { data } = createdSuccessfully;

          if (data) {
            if (type === "weight" && comparator === "weight_gain") {
              mixpanelActions.track("User Action: Weight gain alert created");
            }

            setParentNotification(
              true,
              "Trigger successfully created",
              "success"
            );
          } else {
            setParentNotification(
              true,
              "Error creating trigger, please try again",
              "error"
            );
          }

          onClose();
        });
    }
  };

  const handleCancelClick = () => {
    onClose();
  };

  const handleValueChange = (
    value: string | string[],
    select: string,
    secondTrigger = false
  ) => {
    if (!mounted) return;

    if (typeof value === "string") {
      if (select === "type") {
        if (!secondTrigger && type !== value) {
          if (value === "weight") {
            setWeightGainDisabledStatus(false);

            setType("");
          } else {
            setWeightGainDisabledStatus(true);
          }

          setType(value);
          setComparator("");
          setPercentage("");
          setThreshold("");
          handleThresholdUnit(value, false);
          return;
        }

        if (secondTrigger && type2 !== value) {
          setType2(value);
          setComparator2("");
          setPercentage2("");
          setThreshold2("");
          handleThresholdUnit(value, true);
          return;
        }
      }
      if (select === "comparator") {
        if (!secondTrigger && comparator !== value) {
          setComparator(value);
          return;
        }
        if (secondTrigger && comparator2 !== value) {
          setComparator2(value);
          return;
        }
      }
      if (select === "threshold") {
        if (!secondTrigger && threshold !== value) {
          setThreshold(value);
          return;
        }
        if (secondTrigger && threshold2 !== value) {
          setThreshold2(value);
          return;
        }
      }
      if (select === "alert" && alert !== value) {
        setAlert(value);
        return;
      }
      if (select === "percentage") {
        const parsesdNumber = Number.parseInt(value, 10);
        if ((parsesdNumber >= 0 && parsesdNumber <= 100) || value === "") {
          if (!secondTrigger && percentage !== value) {
            setPercentage(value);
            return;
          }
          if (secondTrigger && percentage2 !== value) {
            setPercentage2(value);
            return;
          }
        }
      }
      if (select === "timeframe") {
        if (!secondTrigger && timeframe !== value) {
          setTimeframe(value);
          return;
        }
        if (secondTrigger && timeframe2 !== value) {
          setTimeframe2(value);
        }
      }
    } else {
      setNotification(value);
    }
  };

  const handleAdditionalMeasurementMessage = () => {
    let baseMessage = " and ";
    const typeToAdd2 = optionsType.find((option) => option.value === type2);

    if (typeToAdd2) {
      baseMessage +=
        alert === "any_reading"
          ? `${typeToAdd2.label} is `
          : `highest ${typeToAdd2.label} is `;
    }

    const comparatorToAdd2 = optionsComparator.find(
      (option) => option.value === comparator2
    );

    if (
      comparatorToAdd2 &&
      comparator2 !== "percentage_decrease" &&
      comparator2 !== "percentage_increase"
    ) {
      baseMessage += `${comparatorToAdd2.label.replace(
        / *\([^)]*\) */g,
        ""
      )} ${threshold2}${type2 !== "dyspnea" ? thresholdUnit2 : ""}`;
    } else {
      baseMessage += `at least ${percentage2}% ${
        comparator2 === "percentage_decrease" ? "less" : "greater"
      } than their baseline of ${threshold2}${
        type2 !== "dyspnea" ? thresholdUnit2 : ""
      }`;
    }

    return baseMessage;
  };

  const buildHCPMessage = () => {
    if (!mounted) return "";

    let baseMessage = "An alert will be sent when your patient’s ";
    const typeToAdd = optionsType.find((option) => option.value === type);

    if (typeToAdd && comparator !== "weight_gain") {
      baseMessage +=
        alert === "any_reading"
          ? `${typeToAdd.label} is `
          : `highest ${typeToAdd.label} is `;
    }

    const comparatorToAdd = optionsComparator.find(
      (option) => option.value === comparator
    );

    if (comparator === "weight_gain") {
      baseMessage = baseMessage.slice(0, baseMessage.length - 3);

      baseMessage += ` gains ${threshold}${thresholdUnit} or more in ${type} over ${timeframe} ${
        parseInt(timeframe, 10) === 1 ? "day" : "days"
      }`;

      if (hcpMessage !== `${baseMessage}.`) {
        setHcpMessage(`${baseMessage}.`);
      }

      return `${baseMessage}.`;
    }

    if (
      comparatorToAdd &&
      comparator !== "percentage_decrease" &&
      comparator !== "percentage_increase"
    ) {
      baseMessage += `${comparatorToAdd.label.replace(
        / *\([^)]*\) */g,
        ""
      )} ${threshold}${type !== "dyspnea" ? thresholdUnit : ""}`;

      if (additionalMeasurement) {
        baseMessage += handleAdditionalMeasurementMessage();
      }

      if (alert === "consecutive_highest_3_days") {
        baseMessage += " each day for 3 consecutive days";
      }

      if (alert === "consecutive_highest_7_days") {
        baseMessage += " each day for 7 consecutive days";
      }
    } else {
      baseMessage += `at least ${percentage}% ${
        comparator === "percentage_decrease" ? "less" : "greater"
      } than their baseline of ${threshold}${
        type !== "dyspnea" ? thresholdUnit : ""
      }`;

      if (additionalMeasurement) {
        baseMessage += handleAdditionalMeasurementMessage();
      }

      if (alert === "consecutive_highest_3_days") {
        baseMessage += ", for 3 consecutive days";
      }

      if (alert === "consecutive_highest_7_days") {
        baseMessage += ", for 7 consecutive days";
      }
    }

    if (alert === "highest_of_day") {
      baseMessage += " for 1 day";
    }

    if (hcpMessage !== `${baseMessage}.`) {
      setHcpMessage(`${baseMessage}.`);
    }

    return `${baseMessage}.`;
  };

  const handleDisableButton = () => {
    if (!additionalMeasurement) {
      if (comparator === "weight_gain") {
        return (
          type.length === 0 ||
          comparator.length === 0 ||
          threshold.length === 0 ||
          timeframe.length === 0
        );
      }

      return (
        type.length === 0 ||
        comparator.length === 0 ||
        threshold.length === 0 ||
        (comparator !== "percentage_decrease" &&
        comparator !== "percentage_increase"
          ? false
          : percentage.length === 0) ||
        alert.length === 0
      );
    }

    return (
      type.length === 0 ||
      comparator.length === 0 ||
      threshold.length === 0 ||
      (comparator !== "percentage_decrease" &&
      comparator !== "percentage_increase"
        ? false
        : percentage.length === 0) ||
      alert.length === 0 ||
      type2.length === 0 ||
      threshold2.length === 0 ||
      (comparator2 !== "percentage_decrease" &&
      comparator2 !== "percentage_increase"
        ? false
        : percentage2.length === 0)
    );
  };

  return (
    <Container>
      <Title>{isEdit ? "Edit" : "Create"} trigger </Title>
      <ScrollableContainer>
        <ContentContainer>
          <SelectSection>
            <Field>
              <p>Type</p>
              <SelectDropdown
                options={optionsType}
                placeholder="FVC"
                value={type === "" ? undefined : type}
                onValueChange={(value: string | string[]) => {
                  handleValueChange(value, "type");
                }}
              />
            </Field>

            {type !== "" ? (
              <Field>
                <p>Comparator</p>
                <SelectDropdown
                  options={optionsComparator.filter(
                    (comparator) => !comparator.disabled
                  )}
                  placeholder="greater than (>)"
                  value={comparator === "" ? undefined : comparator}
                  onValueChange={(value: string | string[]) => {
                    handleValueChange(value, "comparator");
                  }}
                />
              </Field>
            ) : null}

            {comparator !== "" ? (
              <>
                {comparator === "percentage_decrease" ||
                comparator === "percentage_increase" ? (
                  <Field>
                    <TextField
                      placeholder="0"
                      label={`Percentage ${
                        comparator === "percentage_decrease"
                          ? "decrease"
                          : "increase"
                      } (%)`}
                      value={percentage ?? ""}
                      onChange={(event: any) => {
                        handleValueChange(event.target.value, "percentage");
                      }}
                      backgroudColor="white"
                      type="number"
                    />
                  </Field>
                ) : null}
                {comparator !== "weight_gain" ? (
                  <>
                    <Field>
                      <TextField
                        placeholder="0"
                        label={
                          comparator === "percentage_decrease" ||
                          comparator === "percentage_increase"
                            ? `Baseline (${thresholdUnit})`
                            : `Threshold (${thresholdUnit})`
                        }
                        value={threshold ?? ""}
                        onChange={(event: any) => {
                          handleValueChange(event.target.value, "threshold");
                        }}
                        backgroudColor="white"
                        type="number"
                      />
                    </Field>
                    <Field>
                      <p>Alert triggers</p>
                      <SelectDropdown
                        options={optionsAlerts}
                        placeholder="On any new reading"
                        value={alert ?? ""}
                        onValueChange={(value: string | string[]) => {
                          handleValueChange(value, "alert");
                        }}
                      />
                    </Field>
                  </>
                ) : (
                  <>
                    <Field>
                      <TextField
                        placeholder="0"
                        label={`Change in weight (${thresholdUnit})`}
                        value={threshold ?? ""}
                        onChange={(event: any) => {
                          handleValueChange(event.target.value, "threshold");
                        }}
                        backgroudColor="white"
                        type="number"
                      />
                    </Field>
                    <Field>
                      <TextField
                        placeholder="1"
                        label="Timeframe (days)"
                        value={timeframe ?? ""}
                        onChange={(event: any) => {
                          if (
                            parseInt(event.target.value, 10) < 1 ||
                            parseInt(event.target.value, 10) > 30
                          ) {
                            return;
                          }

                          handleValueChange(event.target.value, "timeframe");
                        }}
                        backgroudColor="white"
                        type="number"
                      />
                    </Field>
                  </>
                )}
                <Field>
                  <p>HCP notification</p>
                  <SelectDropdown
                    options={optionsNotification}
                    placeholder="Email, SMS, Call"
                    multiple
                    value={notification ?? ""}
                    onValueChange={(value: string | string[]) => {
                      handleValueChange(value, "notification");
                    }}
                  />
                </Field>
              </>
            ) : null}
          </SelectSection>

          {!additionalMeasurement &&
          type !== "" &&
          comparator !== "" &&
          (alert !== "" || timeframe !== "") &&
          threshold !== "" ? (
            <Field isHCPMessage>
              <p>Summary</p>
              <HCPMessage>{buildHCPMessage()}</HCPMessage>
            </Field>
          ) : null}
        </ContentContainer>

        {additionalMeasurement ? (
          <>
            <p
              style={{
                margin: "5px 0 5px 50px",
                color: "#24484A",
                fontSize: "14px",
                fontWeight: "600",
              }}
            >
              AND
            </p>

            <ContentContainer>
              <SelectSection>
                <Field>
                  <p>Type</p>
                  <SelectDropdown
                    options={optionsType}
                    placeholder="FVC"
                    value={type2 === "" ? undefined : type2}
                    onValueChange={(value: string | string[]) => {
                      handleValueChange(value, "type", true);
                    }}
                  />
                </Field>
                {type2 !== "" ? (
                  <Field>
                    <p>Comparator</p>
                    <SelectDropdown
                      options={baseComparatorsOptions.filter(
                        (comparator) => !comparator.disabled
                      )}
                      placeholder="greater than (>)"
                      value={comparator2 === "" ? undefined : comparator2}
                      onValueChange={(value: string | string[]) => {
                        handleValueChange(value, "comparator", true);
                      }}
                    />
                  </Field>
                ) : null}
                {comparator2 !== "" ? (
                  <>
                    {comparator2 === "percentage_decrease" ||
                    comparator2 === "percentage_increase" ? (
                      <Field>
                        <TextField
                          placeholder="0"
                          label={`Percentage ${
                            comparator2 === "percentage_decrease"
                              ? "decrease"
                              : "increase"
                          } (%)`}
                          value={percentage2 ?? ""}
                          onChange={(event: any) => {
                            handleValueChange(
                              event.target.value,
                              "percentage",
                              true
                            );
                          }}
                          backgroudColor="white"
                          type="number"
                        />
                      </Field>
                    ) : null}
                    <Field>
                      <TextField
                        placeholder="0"
                        label={
                          comparator2 === "percentage_decrease" ||
                          comparator2 === "percentage_increase"
                            ? `Baseline (${thresholdUnit2})`
                            : `Threshold (${thresholdUnit2})`
                        }
                        value={threshold2 ?? ""}
                        onChange={(event: any) => {
                          handleValueChange(
                            event.target.value,
                            "threshold",
                            true
                          );
                        }}
                        backgroudColor="white"
                        type="number"
                      />
                    </Field>
                  </>
                ) : null}
              </SelectSection>

              {comparator !== "" &&
              type !== "" &&
              alert !== "" &&
              threshold !== "" &&
              additionalMeasurement ? (
                <Field isHCPMessage>
                  <p>Summary</p>
                  <HCPMessage>{buildHCPMessage()}</HCPMessage>
                </Field>
              ) : null}
            </ContentContainer>
          </>
        ) : null}

        <Tooltip
          title={
            comparator === "weight_gain"
              ? "Additional measurements cannot be currently added to weight gain alerts"
              : ""
          }
          zIndex={3002}
          color="#4B4B4B"
          overlayInnerStyle={{
            minHeight: "40px",
            minWidth: "220px",
            borderRadius: "4px !important",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <AdditionalButton
            onClick={() => {
              if (comparator !== "weight_gain") {
                setWeightGainDisabledStatus(!additionalMeasurement);
                setAdditionalMeasurement(!additionalMeasurement);
              }
            }}
            disabled={comparator === "weight_gain"}
          >
            {!additionalMeasurement ? (
              <>
                <BlackPlus />
                <p>Add additional measurement</p>
              </>
            ) : (
              <>
                <BlackMinus />
                <p>Remove additional measurement </p>
              </>
            )}
          </AdditionalButton>
        </Tooltip>

        {additionalMeasurement ? (
          <NoteSection>
            <p style={{ fontWeight: "600" }}>Please note</p>
            <p>
              You will only be notified when{" "}
              <b style={{ fontWeight: "500" }}>BOTH</b> measurements above are
              triggered.
            </p>
          </NoteSection>
        ) : null}
      </ScrollableContainer>

      <SubmitContainer>
        <Button
          label="Save trigger"
          type="button"
          onClick={handleCreateTrigger}
          disabled={handleDisableButton()}
        />
        <CancelButton onClick={handleCancelClick}>Cancel</CancelButton>
      </SubmitContainer>
    </Container>
  );
}
