import { styled } from "../../../../../../styles";

export const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
  height: "auto",
  width: "100%",
  gap: "12px",
});

export const Title = styled("h1", {
  fontWeight: 600,
  fontSize: "$text-xl",
  lineHeight: "22px",
  padding: "24px 22px 12px 22px",
  color: "$black",
});

export const BackgroundContainer = styled("div", {
  backgroundColor: "#FAFAFA",
  borderRadius: "8px",
  marginLeft: "28px",
  marginRight: "28px",
  marginTop: "10px",
  marginBottom: "10px",
  width: "647px",
  minHeight: "230px",

  "@sm": {
    width: "100%",
    margin: "0px",
  },
});

export const TopContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  paddingLeft: "0px",

  "@sm": {
    flexDirection: "column",
  },
});

export const ChildOneContainer = styled("div", {
  width: "100%",
  padding: "28px",

  "@sm": {
    paddingLeft: "0px",
    paddingTop: "20px",
    paddingBottom: "20px",
  },
});

export const LabelWeight = styled("p", {
  color: "$gray-100",
  lineHeight: "22px",
  fontSize: "14px",
  fontWeight: "500",
});

export const Label = styled("p", {
  color: "$gray-100",
  lineHeight: "22px",
  fontSize: "14px",
  fontWeight: "400",
  maxHeight: "45px",
  overflow: "auto",
});

export const LabelNotes = styled("p", {
  color: "$gray-100",
  lineHeight: "22px",
  fontSize: "14px",
  fontWeight: "400",
  maxHeight: "60px",
  overflow: "auto",
});

export const ContentContainer = styled("div", {
  padding: "28px",

  "@sm": {
    paddingLeft: "0px",
    paddingTop: "20px",
    paddingBottom: "20px",
  },
});

export const SubmitContainer = styled("div", {
  display: "flex",
  gap: "40px",
  paddingTop: "10px",
  paddingRight: "22px",
  paddingBottom: "24px",
  paddingLeft: "22px",
  alignItems: "center",
});
