import { styled } from "../../styles";

export const DisabledOverlay = styled("div", {
  width: "100%",
  height: "100%",

  borderRadius: "12px",

  position: "absolute",
  top: 0,
  left: 0,

  cursor: "not-allowed",
  boxSizing: "border-box",

  backgroundColor: "$white",
  opacity: 0.6,
});

export const Container = styled("div", {
  height: "20px",
  width: "fit-content",

  borderRadius: "12px",

  position: "relative",
});

export const StyledTag = styled("div", {
  padding: "0 12px",
  borderRadius: "12px",

  boxSizing: "border-box",

  display: "flex",
  alignItems: "center",

  fontFamily: "$openSans",
  fontSize: "$text-sm",
  fontWeight: 600,
  lineHeight: "16px",

  border: "2px solid $primary-20",
  backgroundColor: "$primary-20",
  color: "$primary-90",

  gap: "4px",

  "&:focus-visible": {
    outline: "none",
    border: "2px solid $primary-30",
  },

  "&:focus": {
    outline: "none",
  },

  variants: {
    variant: {
      error: {
        color: "$error-dark",

        backgroundColor: "$error-lightest",
        border: "2px solid $error-lightest",
      },

      warning: {
        color: "$brown-dark",
        backgroundColor: "$brown-lightest",
        border: "2px solid $brown-lightest",
      },

      success: {
        color: "$secondary-dark",
        backgroundColor: "$secondary-lightest",
        border: "2px solid $secondary-lightest",
      },

      tertiary: {
        color: "$warning-darkest",
        backgroundColor: "$warning-lightest",
        border: "2px solid $warning-lightest",
      },

      info: {
        color: "$white",
        backgroundColor: "$gray-70",
        border: "2px solid $gray-70",
      },
    },

    clickable: {
      true: {
        cursor: "pointer",

        "&:hover": {
          border: "2px solid $primary-30",
          backgroundColor: "$primary-30",

          color: "$primary-100",
        },

        "&:active": {
          border: "2px solid $primary-20",
          backgroundColor: "$primary-20",

          color: "$primary-100",
        },
      },
    },

    closeButtonLocation: {
      left: {
        padding: "0 12px 0 6px",
      },

      right: {
        padding: "0 6px 0 12px",
        flexDirection: "row-reverse",
      },
    },
  },

  compoundVariants: [
    {
      clickable: true,
      variant: "error",
      css: {
        "&:hover": {
          border: "2px solid $red-light",
          backgroundColor: "$red-light",
          color: "$red-dark",
        },

        "&:active": {
          border: "2px solid $red",
          backgroundColor: "$red",
          color: "$red-dark",
        },
      },
    },
    {
      clickable: true,
      variant: "warning",
      css: {
        "&:hover": {
          border: "2px solid $brown-lighter",
          backgroundColor: "$brown-lighter",
          color: "$brown-darkest",
        },

        "&:active": {
          border: "2px solid $brown-light",
          backgroundColor: "$brown-light",
          color: "$brown-darker",
        },
      },
    },
    {
      clickable: true,
      variant: "success",
      css: {
        "&:hover": {
          border: "2px solid $secondary-lighter",
          backgroundColor: "$secondary-lighter",
          color: "$secondary-darker",
        },

        "&:active": {
          border: "2px solid $secondary-lightest",
          backgroundColor: "$secondary-lightest",
          color: "$secondary-dark",
        },
      },
    },
    {
      clickable: true,
      variant: "tertiary",
      css: {
        "&:hover": {
          border: "2px solid $warning-lighter",
          backgroundColor: "$warning-lighter",
          color: "$warning-dark",
        },

        "&:active": {
          border: "2px solid $warning-light",
          backgroundColor: "$warning-light",
          color: "$brown",
        },
      },
    },
    {
      clickable: true,
      variant: "info",
      css: {
        "&:hover": {
          color: "$white",
          backgroundColor: "$gray-50",
          border: "2px solid $gray-50",
        },

        "&:active": {
          color: "$white",
          backgroundColor: "$gray-60",
          border: "2px solid $gray-60",
        },
      },
    },
  ],
});

export const CloseButton = styled("button", {
  all: "unset",

  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  cursor: "pointer",

  height: "16px",
  width: "16px",

  borderRadius: "50%",

  svg: {
    fill: "$primary-100",
  },

  variants: {
    variant: {
      error: {
        svg: {
          fill: "$error-dark",
        },
      },

      warning: {
        svg: {
          fill: "$black",
        },
      },

      success: {
        svg: {
          fill: "$black",
        },
      },

      tertiary: {
        svg: {
          fill: "$black",
        },
      },

      info: {
        svg: {
          fill: "$black",
        },
      },
    },
  },

  "&:hover": {
    backgroundColor: "rgb(33, 33, 33, .12)",
  },
});
