import { Tooltip } from "antd";
import { useMemo, useState } from "react";

import { Notification } from "../../../../@types/Notification";
import { IStaffTableData } from "../../../../@types/Staff";
import { Dots } from "../../../../assets/icons/Dots";
import { CustomIconButton } from "../../../../components/CustomIconButton";
import { Dropdown, DropdownTextItem } from "../../../../components/Dropdown";
import { ActionContainer } from "../../../../components/TableActionDropdownButton/TableActionDropdownButton.styles";
import { ToastNotification } from "../../../../components/ToastNotification";
import { getTableHospitalStaff } from "../../../../hooks/queries/staff";
import { useModal } from "../../../../hooks/useModal";
import { PageTitle } from "../../../../layout/components/PageTitle";
import { staffService } from "../../../../services/staffService";
import { mixpanelActions } from "../../../../utils/mixpanel";
import { AddEditStaffModal } from "./components/AddEditStaffModal";
import { ArchiveStaffModal } from "./components/ArchiveStaffModal";
import { ManageStaffTable } from "./components/ManageStaffTable";

export function StaffSection() {
  const { openModal, closeModal } = useModal();
  const { staff, isFetching, isRefetching, refetch } = getTableHospitalStaff();

  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [dropdownValue, setDropdownValue] = useState("");
  const [notification, setNotification] = useState<Notification>({
    show: false,
    message: "",
    type: "error",
    width: undefined,
  });

  const handleOnClose = (
    notification?: Notification,
    refetchTable?: boolean
  ) => {
    if (notification !== undefined) {
      setNotification(notification);

      setTimeout(() => {
        setNotification({
          show: false,
          message: "notificationMessage",
          type: "error",
          width: undefined,
        });
      }, 3500);
    }

    if (refetchTable) {
      refetch();
    }

    closeModal();
  };

  const handleAddStaff = () => {
    mixpanelActions.track("Visited Screen: AddStaffModal");

    openModal(<AddEditStaffModal onClose={handleOnClose} />, {
      width: "100%",
      showCloseButton: true,
    });
  };

  const handleEditStaff = async (staffData: IStaffTableData) => {
    const staffResult = await staffService.getHospitalStaffById(staffData.id);

    mixpanelActions.track("Visited Screen: EditStaffModal");

    openModal(
      <AddEditStaffModal
        staffId={staffData.id}
        content={staffResult.data}
        onClose={handleOnClose}
      />,
      {
        showCloseButton: true,
        width: "100%",
      }
    );
  };

  const handleArchiveStaff = (staffData: IStaffTableData) => {
    openModal(
      <ArchiveStaffModal
        staff={staffData}
        onClose={(notification, refetchTable) =>
          handleOnClose(notification, refetchTable)
        }
      />,
      {
        width: "745px",
        showCloseButton: true,
      }
    );
  };

  const parsedStaffData = useMemo(() => {
    if (!staff) return [];

    const options: any[] = ["Edit user", "Archive user", "Reactivate user"];

    const handleOnChangeDropdownVisibility = () => {
      setIsDropDownOpen(!isDropDownOpen);

      if (!isDropDownOpen) {
        setDropdownValue("");
      }
    };

    const handleMultipleLinesTooltip = (
      multipleLinesText?: string,
      width = "200px",
      maxHeight = "39px",
      wordBreak: "break-word" | "break-all" | "keep-all" = "break-word",
      lines = 2
    ) => {
      if (multipleLinesText === null || multipleLinesText === undefined) {
        return "-";
      }

      return (
        <div
          style={{
            width,
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: lines,
            maxHeight,
            wordBreak,
          }}
        >
          <Tooltip
            title={<div>{multipleLinesText}</div>}
            arrow={false}
            placement="bottom"
            overlayInnerStyle={{
              padding: "6px 12px",
              minHeight: "40px",
              width: "fit-content",
              maxWidth: "300px",
              borderRadius: "4px !important",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontFamily: "'Open Sans', sans-serif",
            }}
          >
            <p style={{ height: maxHeight }}>{multipleLinesText}</p>
          </Tooltip>
        </div>
      );
    };

    const handleTextMaxCaracters = (text?: string) => {
      if (text === null || text === undefined) {
        return "-";
      }

      return (
        <div
          style={{
            width: "calc(100% - 30px)",
            textOverflow: "ellipsis",
            overflow: "hidden",
            wordBreak: "keep-all",
          }}
        >
          <Tooltip
            title={<div>{text}</div>}
            arrow={false}
            placement="bottom"
            overlayInnerStyle={{
              padding: "6px 12px",
              minHeight: "40px",
              width: "max-content",
              borderRadius: "4px !important",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontFamily: "'Open Sans', sans-serif",
            }}
          >
            {text}
          </Tooltip>
        </div>
      );
    };

    return staff.map((staffData: IStaffTableData) => {
      return {
        id: staffData.id,
        name: handleTextMaxCaracters(staffData.name),
        email: handleTextMaxCaracters(staffData.email),
        isAdmin: staffData.isAdmin,
        conditionTypes: handleMultipleLinesTooltip(
          staffData.conditionTypes,
          "200px",
          "39px",
          "break-all"
        ),
        isActive: staffData.isActive,
        addedBy: handleTextMaxCaracters(staffData.addedBy),
        wards: handleMultipleLinesTooltip(
          staffData.wards,
          "130px",
          "19px",
          "break-all",
          1
        ),
        action: (
          <ActionContainer
            className="actionContainer"
            onClick={(e) => e.stopPropagation()}
            style={{ height: "42px" }}
          >
            <Dropdown
              trigger={
                <CustomIconButton>
                  <Dots />
                </CustomIconButton>
              }
              value={dropdownValue}
              onOpenChange={handleOnChangeDropdownVisibility}
              onValueChange={(val: string) => {
                if (val === options[0]) {
                  handleEditStaff(staffData);
                }
                if (val === options[1] || val === options[2]) {
                  handleArchiveStaff(staffData);
                }
              }}
            >
              <DropdownTextItem key={options[0]} text={options[0]} />
              {staffData.isActive === "Yes" ? (
                <DropdownTextItem key={options[1]} text={options[1]} />
              ) : (
                <DropdownTextItem key={options[2]} text={options[2]} />
              )}
            </Dropdown>
          </ActionContainer>
        ),
      } as IStaffTableData;
    });
  }, [staff]);

  return (
    <div style={{ paddingTop: "16px" }}>
      <PageTitle>Staff users</PageTitle>

      <ManageStaffTable
        content={parsedStaffData}
        isLoading={isFetching || isRefetching}
        handleAddStaff={handleAddStaff}
        handleOnRowClick={handleEditStaff}
      />

      {notification.show ? (
        <ToastNotification
          message={notification.message}
          type={notification.type}
          width={notification.width}
          rightMargin="25%"
        />
      ) : null}
    </div>
  );
}
