import { css, styled } from "../../styles";

export const TextAreaContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  position: "relative",
});

export const StyledTextAreaContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  position: "relative",
});

export const StyledTextArea = styled("textarea", {
  all: "unset",
  padding: "$5 $8",
  borderRadius: "$2",
  fontSize: "$text-lg",
  caretColor: "$gray-70",
  fontFamily: "$openSans",
  border: "1px solid $gray-50",
  lineHeight: 1.5,
  whiteSpace: "pre-wrap",

  backgroundColor: "rgba(233, 230, 244, 0.16)",

  "&::placeholder": {
    color: "$gray-70",
  },

  "&:focus": {
    borderColor: "primary-30",
    backgroundColor: "$white",
  },

  variants: {
    styleDisabled: {
      true: {
        opacity: "0.38",
      },
    },

    error: {
      true: {
        backgroundColor: "$red-lightest",
        border: "1px solid $error-lighter",

        "&:focus": {
          backgroundColor: "$red-lightest",
          border: "1px solid $error-lighter",
        },
      },
    },
  },
});

export const AlertText = styled("p", {
  fontWeight: 600,
  lineHeight: "16px",
  padding: "$1 0 0 $3",
  fontSize: "$text-md",
  fontFamily: "$openSans",

  variants: {
    type: {
      error: {
        color: "$error-light",
      },
      warning: {
        color: "$warning-text",
      },
      mobileError: {
        color: "$error-light !important",
        fontSize: "16px !important",
      },
      mobileWarning: {
        color: "$warning-text !important",
        fontSize: "16px !important",
      },
    },
  },
});

export const skeletonContainerStyles = css({
  gap: "$4",
  display: "flex",
  flexDirection: "column",
});
