import { css, styled } from "../../../../../../styles";

export const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  height: "100%",
  width: "925px",
  gap: "12px",

  "@media screen and (max-width: 1000px)": {
    width: "calc(100vw - 80px)",
    overflow: "scroll",
    height: "calc(100vh - 80px)",
  },
});

export const ContentContainer = styled("div", {
  height: "auto",
  width: "auto",
  margin: "0 22px 0 22px",
  padding: "28px 28px 16px 24px",
  fontWeight: 400,
});

export const SelectSection = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  flexWrap: "wrap",
  marginBottom: "20px",
});

export const Title = styled("h1", {
  fontWeight: 500,
  fontSize: "$text-xl",
  lineHeight: "22px",
  padding: "24px 22px 0px 45px",
  color: "$black",
  marginBottom: "2px",
});

export const SubmitContainer = styled("div", {
  display: "flex",
  gap: "40px",
  padding: "24px 22px 24px 45px",
  alignItems: "center",
});

export const CancelButton = styled("button", {
  height: "$13",
  padding: "0 $13",
  marginLeft: "-38px",

  borderRadius: "20px",
  border: "2px solid $white",

  backgroundColor: "$white",

  gap: "$4",

  display: "flex",
  alignItems: "center",

  color: "$black",
  fontWeight: 600,
  lineHeight: "22px",
  fontSize: "$text-lg",
  fontFamily: "$openSans",

  cursor: "pointer",

  "&:focus": {
    outline: "none",
    border: "2px solid $primary-30",
  },

  "@sm": {
    width: "80px",
    height: "26px",
    fontSize: "12px",
    marginLeft: "40px",
    paddingLeft: "19px",
  },
});

export const FirstRowContainer = styled("div", {
  display: "flex",
});

export const TextFieldContainer = styled("div", {
  paddingLeft: "45px",
  paddingRight: "33px",
  marginBottom: "5px",
  width: "100%",
});

export const DropdownContainer = styled("div", {
  alignContent: "center",
  paddingLeft: "10px",
  alignItems: "center",
  justifyContent: "flex-center",
  width: "260px",

  ".ant-select": {
    height: "42px !important",
  },
});

export const TextAreaContainer = styled("div", {
  paddingLeft: "45px",
  paddingRight: "33px",
  marginBottom: "20px",
});

export const BottomDropdownContainer = styled("div", {
  paddingLeft: "45px",
  display: "flex",
});

export const OptionsContainer = styled("div", {
  paddingLeft: "45px",
});

export const OptionsContainerScroll = styled("div", {
  maxHeight: "140px",
  overflow: "auto",
});

export const DeleteButton = styled("div", {
  height: "26px",
  borderRadius: "12px",
  color: "$black",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: "5px 5px",
  fontSize: "12px",
  lineHeight: "16px",
  fontWeight: "600",
  margin: "0px 0px 0px 5px",
  cursor: "pointer",

  "&:hover": {
    backgroundColor: "$gray-50",
  },
});

export const OptionLabel = styled("label", {
  fontWeight: "600",
  color: "$gray-90",
  lineHeight: "22px",
  userSelect: "none",
  fontSize: "$text-lg",
  margin: "0 0 0px 0px",
  fontFamily: "$openSans",
  paddingRight: "5px",
  display: "flex",
  flexWrap: "wrap",
  alignContent: "center",

  variants: {
    styleDisabled: {
      true: {
        opacity: "0.38",
      },
    },
  },
});

export const OptionsRowContainer = styled("div", {
  display: "flex",
  alignItems: "center",
  padding: "0px",
  height: "35px",
  marginBottom: "4px",

  "&:last-child": {
    marginBottom: "0px",
  },
});

export const NewOptionButton = styled("div", {
  width: "fit-content",
  height: "26px",
  borderRadius: "0px",
  color: "#4B4B4BAD",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: "0px 1px",
  fontSize: "14px",
  lineHeight: "16px",
  fontWeight: "400",
  margin: "5px 0 5px 0px",
  cursor: "default",

  p: {
    marginLeft: "15px",
    borderBottom: "2px solid transparent",
    paddingBottom: "1px",
  },

  "&:hover": {
    p: {
      borderBottom: "2px solid $primary-30",
      cursor: "auto",
      paddingBottom: "1px",
    },
  },
});

export const SendMethodDropdown = styled("div", {
  marginLeft: "10px",
});

export const optionTextFieldStyles = css({
  width: "80%",
});

export const ErrorMessage = styled("p", {
  color: "$error-light !important",
  fontSize: "12px !important",
  fontWeight: "600 !important",
});
