import { Table } from "../../../../components/Table";
import { TableSkeleton } from "../../../../components/TableSkeleton";
import useIsMobile from "../../../../hooks/useIsMobile";
import { surveyTableColumns } from "./tableColumns";

type SurveyListTableProps = {
  content: any;
  isLoading?: boolean;
};

export function AssignedSurveysTable({
  content,
  isLoading,
}: SurveyListTableProps) {
  const { isMobile } = useIsMobile();

  if (isLoading) {
    return <TableSkeleton columnsNumber={8} />;
  }

  if (isMobile) {
    return <div>...</div>;
  }

  return (
    <Table
      data={content}
      emptyStateText="no data"
      columns={surveyTableColumns}
    />
  );
}
