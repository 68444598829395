import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { SelectMeasurementPayloadType } from "../../../../@types/Measurements";
import { HorizontalDots } from "../../../../assets/icons/HorizontalDots";
import { Toggle } from "../../../../components/Toggle";
import { useModal } from "../../../../hooks/useModal";
import { useUserPreferences } from "../../../../hooks/useUserPreferences";
import { userService } from "../../../../services/userService";
import { MeasurementSection } from "./components/MeasurementSection";
import { SpirometrySection } from "./components/SpirometrySection";
import {
  Container,
  DotsContainer,
  OptionsContainer,
  PinnedToogleContainer,
} from "./MeasurementModal.styles";
import { MeasurementModalProps } from "./MeasurementModal.types";

export function MeasurementModal({
  cardTitle,
  patientId,
  measurementType,
  minValue,
  maxValue,
  isSpirometry,
  unit,
}: MeasurementModalProps) {
  const { portalPreferences, changePortalPreferences } = useUserPreferences();

  const { closeModal } = useModal();

  const [
    selectedSpirometryMeasurementValue,
    setSelectedSpirometryMeasurementValue,
  ] = useState<SelectMeasurementPayloadType | null>(null);

  const [hideSpirometrySection, setHideSpirometrySection] = useState(false);
  const [isPinned, setIsPinned] = useState(false);
  const [areOptionsOpen, setAreOptionsOpen] = useState(false);

  const optionsWrapperRef = useRef<any>(null);
  const optionsButtonRef = useRef<any>(null);

  const [searchParams] = useSearchParams();
  useEffect(() => {
    const spirometryTestId = searchParams.get("spirometryTestId");
    if (spirometryTestId) {
      setSelectedSpirometryMeasurementValue({
        unformattedTime: "",
        time: "",
        fvc: 0,
        spirometryTestIds: [parseInt(spirometryTestId, 10)],
      });
    }
  }, [searchParams]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        areOptionsOpen &&
        optionsWrapperRef.current &&
        !optionsWrapperRef.current.contains(event.target) &&
        !optionsButtonRef.current.contains(event.target)
      ) {
        setAreOptionsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [areOptionsOpen]);

  const handlePinnedGraphChange = async (isPinnedGraph: boolean) => {
    setIsPinned(isPinnedGraph);

    setTimeout(() => {
      setAreOptionsOpen(false);
    }, 200);

    await userService.updatePinnedMeasurementType(measurementType);

    const portalPrefenrecesToChange = { ...portalPreferences };
    portalPrefenrecesToChange.pinnedMeasurementType = measurementType;
    changePortalPreferences(portalPrefenrecesToChange);

    closeModal();
  };

  return (
    <Container>
      <MeasurementSection
        cardTitle={cardTitle}
        unit={unit}
        patientId={patientId}
        measurementType={measurementType}
        minValue={minValue}
        maxValue={maxValue}
        onSelectChartValue={(selectedChartValue) => {
          // Show a snackbar if the spirometry value is undefined
          setSelectedSpirometryMeasurementValue(selectedChartValue);
        }}
        onSelectedTableView={(showMeasurementsTable) => {
          setHideSpirometrySection(showMeasurementsTable);
        }}
        isSpirometry={isSpirometry}
      />

      {selectedSpirometryMeasurementValue?.spirometryTestIds &&
        !hideSpirometrySection && (
          <SpirometrySection
            selectedMeasurement={selectedSpirometryMeasurementValue}
          />
        )}

      <OptionsContainer>
        <DotsContainer
          onClick={() => setAreOptionsOpen((prevState) => !prevState)}
          ref={optionsButtonRef}
        >
          <HorizontalDots />
        </DotsContainer>

        {areOptionsOpen ? (
          <PinnedToogleContainer ref={optionsWrapperRef}>
            <span>Pin graph to patient page</span>
            <Toggle
              checked={isPinned}
              onCheckedChange={handlePinnedGraphChange}
            />
          </PinnedToogleContainer>
        ) : null}
      </OptionsContainer>
    </Container>
  );
}
