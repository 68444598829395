import { styled } from "../../styles";

export const ButtonContainer = styled("button", {
  padding: 0,
  width: "28px",
  height: "28px",
  display: "flex",
  cursor: "pointer",
  alignItems: "center",
  borderRadius: "$round",
  boxSizing: "border-box",
  justifyContent: "center",
  backgroundColor: "$white",
  border: "1px solid $gray-40",

  "&:hover": {
    backgroundColor: "$gray-50",
    border: "1px solid $gray-50",
  },

  variants: {
    disabledStyle: {
      true: {
        backgroundColor: "$white",
        border: "1px solid $gray-40",
        cursor: "not-allowed",

        "&:hover": {
          backgroundColor: "$white",
        },
      },
    },
  },
});

export const IconContainer = styled("div", {
  width: "28px",
  padding: "$4",
  height: "28px",
  display: "flex",
  alignItems: "center",
  borderRadius: "$round",
  boxSizing: "border-box",
  justifyContent: "center",

  svg: {
    width: "$6",
    height: "$6",
  },

  variants: {
    disabledStyle: {
      true: {
        opacity: 0.38,
      },
    },
  },
});
