import { LinkButton } from "../../../components/LinkButton";
import { mixpanelActions } from "../../../utils/mixpanel";
import {
  FooterLinksContainerDesktop,
  FooterLinksContainerMobile,
} from "./FooterLinks.styles";

const footerLinks = [
  {
    name: "termsAndConditions",
    label: "Terms & conditions",
    url: "https://patientmpower.com/terms-of-use/",
    mixpanelAction: "UserAction: Clicked Terms and Conditions Link",
  },
  {
    name: "privayPolicy",
    label: "Privacy policy",
    url: "https://patientmpower.com/privacy-policy/",
    mixpanelAction: "UserAction: Clicked Privacy Policy Link",
  },
  {
    name: "contactSupports",
    label: "Contact support",
    url: "https://patientmpower.com/support/",
    mixpanelAction: "UserAction: Clicked Support Link",
  },
];

const handleOnClickOverFooterLinks = (currentLink: any) => {
  mixpanelActions.track(currentLink.mixpanelAction);
  window.open(currentLink.url, "_blank");
};

export function FooterLinksMobile() {
  return (
    <FooterLinksContainerMobile>
      {footerLinks.map((currentLink) => (
        <LinkButton
          size="md"
          color="gray"
          fontWeight="bold"
          hoverColor="secondary"
          label={currentLink.label}
          key={currentLink.name}
          onClick={() => handleOnClickOverFooterLinks(currentLink)}
        />
      ))}
    </FooterLinksContainerMobile>
  );
}

export function FooterLinksDesktop() {
  return (
    <FooterLinksContainerDesktop>
      {footerLinks.map((currentLink) => (
        <LinkButton
          size="md"
          color="gray"
          fontWeight="bold"
          hoverColor="secondary"
          label={currentLink.label}
          key={`${Math.random() * 1000}-${currentLink.name}`}
          onClick={() => handleOnClickOverFooterLinks(currentLink)}
        />
      ))}
    </FooterLinksContainerDesktop>
  );
}
