import { styled } from "../../../../../../styles";

export const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
  height: "auto",
  width: "925px",
  gap: "12px",

  "@media screen and (max-width: 1100px)": {
    width: "calc(100vw - 80px)",
    overflow: "scroll",
  },
});

export const Title = styled("h1", {
  fontWeight: 500,
  fontSize: "$text-xl",
  lineHeight: "22px",
  padding: "24px 22px 0px 22px",
  color: "$black",
});

export const SubmitContainer = styled("div", {
  display: "flex",
  gap: "40px",
  padding: "24px 22px 24px 22px",
  alignItems: "center",
});

export const CancelButton = styled("button", {
  height: "$13",
  padding: "0 $13",
  marginLeft: "-38px",

  borderRadius: "20px",
  border: "2px solid $white",

  backgroundColor: "$white",

  gap: "$4",

  display: "flex",
  alignItems: "center",

  color: "$black",
  fontWeight: 600,
  lineHeight: "22px",
  fontSize: "$text-lg",
  fontFamily: "$openSans",

  cursor: "pointer",

  "&:focus": {
    outline: "none",
    border: "2px solid $primary-30",
  },

  "@sm": {
    width: "80px",
    height: "26px",
    fontSize: "12px",
    marginLeft: "40px",
    paddingLeft: "19px",
  },
});

export const Label = styled("p", {
  fontSize: "$text-md",
  lineHeight: "16px",
  paddingLeft: 22,
  color: "$gray-100",
});

export const TextAreaContainer = styled("div", {
  paddingLeft: "22px",
  paddingRight: "22px",
  width: "100%",
  height: "410px",
});
