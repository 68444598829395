import { useMemo, useState } from "react";

import { Button } from "../../../../../../components/Button";
import { RichTextEditor } from "../../../../../../components/RichTextEditor";
import { getNoteData } from "../../../../../../hooks/queries/notes";
import { useModal } from "../../../../../../hooks/useModal";
import { getLongFormattedDate } from "../../../../../../utils/dateFormatter";
import {
  EditButton,
  Container,
  SubmitContainer,
  TextAreaContainer,
  Title,
  Label,
} from "./ViewNoteModal.styles";

type ViewNoteModalProps = {
  noteId: number;
  onEditClick: (noteId: number) => void;
};

export function ViewNoteModal({ noteId, onEditClick }: ViewNoteModalProps) {
  const [noteContent, setNoteContent] = useState("");
  const [labelContent, setLabelContent] = useState("");
  const { closeModal } = useModal();

  const { patientNoteData, isFetching, isError, refetch, isRefetching } =
    getNoteData(noteId);

  useMemo(() => {
    if (!isFetching && !isError && !isRefetching && patientNoteData) {
      setNoteContent(patientNoteData.notes);
      const timeStr = getLongFormattedDate(patientNoteData.time);
      const labelStr = `${timeStr} - ${patientNoteData.reviewedByEmail}`;
      setLabelContent(labelStr);
    }
  }, [patientNoteData, isFetching, isError, isRefetching]);

  const handleInputChange = (event: any) => {
    setNoteContent(event.target.value);
  };

  const handleEditNoteClose = async () => {
    await closeModal();
    onEditClick(noteId);
  };

  const refreshData = () => {
    refetch();
  };

  const handleDoneClick = () => {
    closeModal();
  };

  if (isError) {
    return (
      <Container>
        <p>
          Error getting note data, please{" "}
          <button type="button" onClick={refreshData}>
            try again
          </button>
          . If the issue continues contact support.
        </p>
      </Container>
    );
  }

  if (isFetching) {
    return (
      <Container>
        <Title>View Note</Title>
        <Label>{labelContent}</Label>
        <TextAreaContainer>
          <div style={{ height: "300px" }}> </div>
        </TextAreaContainer>
        <SubmitContainer>
          <Button label="Done" type="button" onClick={handleDoneClick} />
          <EditButton onClick={handleEditNoteClose}>Edit</EditButton>
        </SubmitContainer>
      </Container>
    );
  }

  return (
    <Container>
      <Title>View Note</Title>
      <Label>{labelContent}</Label>
      <TextAreaContainer>
        <RichTextEditor
          value={noteContent}
          placeholderTxt="Enter your note here..."
          onValueChange={(value) => handleInputChange(value)}
          height={300}
          readOnly
        />
      </TextAreaContainer>
      <SubmitContainer>
        <Button label="Done" type="button" onClick={handleDoneClick} />
        {patientNoteData.editable && (
          <EditButton onClick={handleEditNoteClose}>Edit</EditButton>
        )}
      </SubmitContainer>
    </Container>
  );
}
