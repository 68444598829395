import { css, styled } from "../../styles";

export const CheckboxLabel = styled("label", {
  color: "#4B4B4B",
  fontFamily: "$openSans",
  fontSize: "10px",
  lineHeight: "16px",
  fontStyle: "normal",
  fontWeight: "400",

  display: "flex",
  alignItems: "center",
});

export const antdCheckboxStyles = css({
  ".ant-checkbox .ant-checkbox-inner": {
    borderRadius: "1.2px",
    borderWidth: "1.5px",
  },

  ".ant-checkbox-checked .ant-checkbox-inner": {
    backgroundColor: "transparent",
    borderColor: "#7562A8",
  },

  ".ant-checkbox-checked .ant-checkbox-inner:after": {
    borderColor: "#7562A8 !important",
    borderWidth: "1.5px",
  },

  ".ant-checkbox:hover .ant-checkbox-inner": {
    backgroundColor: "transparent !important",
    borderColor: "#7562A8 !important",
  },

  ".ant-checkbox, span": {
    backgroundColor: "transparent !important",
  },
});
