import { FieldArray, FormikProvider, getIn, useFormik } from "formik";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";

import {
  IQuestionInfoForConditionalJump,
  ISurveyQuestion,
} from "../../../../../../../../../@types/Surveys";
import { BlackPlus } from "../../../../../../../../../assets/icons/BlackPlus";
import { Button } from "../../../../../../../../../components/Button";
import { CustomIconButton } from "../../../../../../../../../components/CustomIconButton";
import {
  IOption,
  SelectDropdown,
} from "../../../../../../../../../components/SelectDropdown";
import { TextField } from "../../../../../../../../../components/TextField";
import {
  MultiSelectContainer,
  Option,
  textInputCss,
  RemoveButtonCss,
  AddOptionContainer,
  LabelsHeader,
  ValuesHeader,
  valueInputCss,
  HeadersRow,
  ConditionalHeaders,
  ConditionalSelectContainer,
  RemoveButtonContainer,
} from "./OptionSelectQuestion.styles";

interface IOptionSelectQuestionProps {
  onOptionsChange: any;
  questionToEdit?: ISurveyQuestion;
  typeSelected: string;
  triggerValidation: boolean | undefined;
  setOptionsQuestionDetailsAreValidated: any;
  hasConditionalJump: boolean;
  questionListforConditional: IQuestionInfoForConditionalJump[];
}

export function OptionSelectQuestion({
  onOptionsChange,
  questionToEdit,
  typeSelected,
  triggerValidation,
  setOptionsQuestionDetailsAreValidated,
  hasConditionalJump,
  questionListforConditional,
}: IOptionSelectQuestionProps) {
  const [questionListToConditionalJump, setQuestionListToConditionalJump] =
    useState<IOption[]>([]);
  const [selectedQuestionList, setSelectedQuestionList] = useState<string[]>([
    "",
    "",
  ]);

  useEffect(() => {
    if (!hasConditionalJump) {
      const updatedSelectedQuestionList = selectedQuestionList;
      selectedQuestionList.forEach((selected, index) => {
        updatedSelectedQuestionList[index] = "";
      });
      setSelectedQuestionList(updatedSelectedQuestionList);
    }
  }, [hasConditionalJump]);

  const initialFormikValues = {
    options: [
      {
        order: 0,
        label: "",
        value: "",
        nextQuestionId: "",
      },
      {
        order: 1,
        label: "",
        value: "",
        nextQuestionId: "",
      },
    ],
  };

  const optionsSelectValidation = yup.object().shape({
    options: yup.array().of(
      yup.object().shape({
        order: yup.number(),
        label: yup.string().required("Required"),
        value: yup.number().typeError("Must be a number").required("Required"),
      })
    ),
  });

  const optionsSelectFormik = useFormik({
    initialValues: initialFormikValues,
    validationSchema: optionsSelectValidation,
    onSubmit: () => {},
  });

  useEffect(() => {
    const updatedQuestionListToConditionalJump: IOption[] = [
      {
        label: "",
        value: "",
        key: uuidv4(),
      },
    ];
    let skip = true;
    questionListforConditional.forEach((questionFromList) => {
      if (!skip) {
        updatedQuestionListToConditionalJump.push({
          label: questionFromList.question,
          value: questionFromList.id,
          key: uuidv4(),
        });
      }
      if (questionToEdit?.id === questionFromList.id) {
        skip = false;
      }
    });
    setQuestionListToConditionalJump(updatedQuestionListToConditionalJump);
    if (questionToEdit) {
      const updatedSelectedQuestionList: string[] = [];
      optionsSelectFormik.setFieldValue("options", questionToEdit.options);
      questionToEdit.options?.forEach((option) => {
        updatedSelectedQuestionList.push(option.nextQuestionId ?? "");
      });
      setSelectedQuestionList(updatedSelectedQuestionList);
    }
  }, [typeSelected] || []);

  useEffect(() => {
    onOptionsChange(optionsSelectFormik.values.options);
  }, [optionsSelectFormik.values.options]);

  const shouldShowErrorMessage = (index: number, field: "label" | "value") => {
    if (optionsSelectFormik.touched.options)
      if (field === "label")
        return optionsSelectFormik.touched.options[index]
          ? getIn(optionsSelectFormik.errors.options?.[index], "label")
          : "";
      else {
        return optionsSelectFormik.touched.options[index]
          ? getIn(optionsSelectFormik.errors.options?.[index], "value")
          : "";
      }

    return "";
  };

  useEffect(() => {
    if (triggerValidation !== undefined) {
      optionsSelectFormik.submitForm();
    }
  }, [triggerValidation]);

  useEffect(() => {
    if (optionsSelectFormik.dirty) {
      if (optionsSelectFormik.errors.options !== undefined) {
        setOptionsQuestionDetailsAreValidated(false);
      } else {
        setOptionsQuestionDetailsAreValidated(true);
      }
    }
  }, [optionsSelectFormik.errors.options || optionsSelectFormik.values]);

  const updateSelectedQuestionList = (index: number, value: string) => {
    const updatedSelectedQuestionList = [...selectedQuestionList];
    updatedSelectedQuestionList[index] = value;
    setSelectedQuestionList(updatedSelectedQuestionList);
  };

  return (
    <MultiSelectContainer>
      <FormikProvider value={optionsSelectFormik}>
        <FieldArray name="options">
          {(arrayHelpers) => (
            <>
              <HeadersRow
                style={
                  optionsSelectFormik.values.options.length > 2
                    ? { transform: "translate(-14px)" }
                    : {}
                }
              >
                <LabelsHeader>Labels</LabelsHeader>
                <ValuesHeader>Values</ValuesHeader>
                {hasConditionalJump && (
                  <ConditionalHeaders>Jump to</ConditionalHeaders>
                )}
              </HeadersRow>
              {optionsSelectFormik.values.options.map((option, index) => (
                <Option key={index}>
                  <TextField
                    maxLength={200}
                    label=""
                    name={`options[${index}].label`}
                    placeholder="..."
                    value={option.label}
                    onChange={optionsSelectFormik.handleChange}
                    backgroudColor="white"
                    className={textInputCss()}
                    errorMessage={shouldShowErrorMessage(index, "label")}
                  />

                  <TextField
                    maxLength={2}
                    label=""
                    name={`options[${index}].value`}
                    placeholder="..."
                    value={option.value}
                    onChange={optionsSelectFormik.handleChange}
                    backgroudColor="white"
                    className={valueInputCss()}
                    onBlur={optionsSelectFormik.handleBlur}
                    errorMessage={shouldShowErrorMessage(index, "value")}
                  />
                  {hasConditionalJump && (
                    <ConditionalSelectContainer>
                      <SelectDropdown
                        options={questionListToConditionalJump}
                        width={210}
                        height={42}
                        placeholder="Choose a question"
                        value={selectedQuestionList[index]}
                        onValueChange={(value: string | string[]) => {
                          optionsSelectFormik.setFieldValue(
                            `options[${index}].nextQuestionId`,
                            value.toString()
                          );
                          updateSelectedQuestionList(index, value.toString());
                        }}
                      />
                    </ConditionalSelectContainer>
                  )}
                  {optionsSelectFormik.values.options.length > 2 && (
                    <RemoveButtonContainer>
                      <Button
                        label="x"
                        type="button"
                        size="small"
                        className={RemoveButtonCss()}
                        onClick={() => {
                          arrayHelpers.remove(index);
                        }}
                      />
                    </RemoveButtonContainer>
                  )}
                </Option>
              ))}
              {optionsSelectFormik.values.options.length <= 15 && (
                <AddOptionContainer key={uuidv4()}>
                  <CustomIconButton
                    onClick={() => {
                      arrayHelpers.push({
                        order:
                          optionsSelectFormik.values.options[
                            optionsSelectFormik.values.options.length - 1
                          ].order + 1,
                        label: "",
                        value: undefined,
                        nextQuestionId: "",
                      });
                      setSelectedQuestionList((selectedQuestionList) => [
                        ...selectedQuestionList,
                        "",
                      ]);
                    }}
                  >
                    <BlackPlus />
                  </CustomIconButton>
                </AddOptionContainer>
              )}
            </>
          )}
        </FieldArray>
      </FormikProvider>
    </MultiSelectContainer>
  );
}
