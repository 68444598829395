import { useEffect, useMemo, useState } from "react";

import { SelectMeasurementPayloadType } from "../../../../../../@types/Measurements";
import { SkeletonLoading } from "../../../../../../components/Skeleton";
import { Tag } from "../../../../../../components/Tag";
import { useArtiQSpirometryData } from "../../../../../../hooks/queries/spirometry";
import { BlowAcceptabilitySection } from "../../../MeasurementModal/components/BlowAcceptabilitySection";
import { GradeTagColor } from "../../../MeasurementModal/components/SpirometrySection";
import {
  ArtiQContainer,
  SubtitleGradeContainer,
} from "../../../MeasurementModal/components/SpirometrySection/SpirometrySection.styles";
import { SpirometrySectionChart } from "../../../MeasurementModal/components/SpirometrySection/SpirometrySectionChart";
import { SpirometryValuesSection } from "../../../MeasurementModal/components/SpirometryValuesSection";
import {
  Title,
  Subtitle,
} from "../../../MeasurementModal/MeasurementModal.styles";
import { SessionGraphContainer } from "./SessionDetailsMobile.styles";

type SessionDetailsMobileProps = {
  selectedMeasurement: SelectMeasurementPayloadType;
  // onClose(): void;
};

export function SessionDetailsMobile({
  selectedMeasurement,
}: SessionDetailsMobileProps) {
  const [linesToShow, setLinesToShow] = useState([
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
  ]);

  useEffect(() => {
    setLinesToShow([true, true, true, true, true, true, true, true]);
  }, [selectedMeasurement]);

  const { artiQData, isFetching } = useArtiQSpirometryData(
    selectedMeasurement?.spirometryTestIds || []
  );

  const fvcGrade = artiQData?.fvcGrade;
  const fev1Grade = artiQData?.fev1Grade;
  const volumeFlowValues = artiQData?.flowVolumeAxes;
  const timeVolumeValues = artiQData?.timeVolumeAxes;

  const spirometryGradesTagColor = useMemo<GradeTagColor>(() => {
    const fvcGrade = artiQData?.fvcGrade;
    const fev1Grade = artiQData?.fev1Grade;

    const grades: GradeTagColor = {
      fvc: "error",
      fev1: "error",
    };

    if (fvcGrade === "A") {
      grades.fvc = "success";
    }

    if (fev1Grade === "A") {
      grades.fev1 = "success";
    }

    if (fvcGrade === "B" || fvcGrade === "C") {
      grades.fvc = "warning";
    }

    if (fev1Grade === "B" || fev1Grade === "C") {
      grades.fev1 = "warning";
    }

    return grades;
  }, [artiQData?.fev1Grade, artiQData?.fvcGrade]);

  const handleHideBlow = (index: number) => {
    const newLinesToShow = linesToShow.map((line, i) => {
      if (i === index) {
        return !line;
      }

      return line;
    });

    setLinesToShow(newLinesToShow);
  };

  return (
    <>
      <Title>Selected session</Title>
      <SubtitleGradeContainer>
        <Subtitle css={{ marginTop: 0, marginBottom: 0 }}>
          {selectedMeasurement.time}
        </Subtitle>
      </SubtitleGradeContainer>

      <SubtitleGradeContainer>
        {fvcGrade && (
          <Tag variant={spirometryGradesTagColor.fvc}>
            FVC = {artiQData?.fvcGrade}
          </Tag>
        )}

        {fev1Grade && (
          <Tag variant={spirometryGradesTagColor.fev1}>
            FEV1 = {artiQData?.fev1Grade}
          </Tag>
        )}
      </SubtitleGradeContainer>

      <SubtitleGradeContainer>
        {artiQData?.percentPredictedWarning !== "" && !isFetching ? (
          <Tag variant="error">{artiQData?.percentPredictedWarning}</Tag>
        ) : null}
      </SubtitleGradeContainer>

      <SessionGraphContainer>
        {volumeFlowValues ? (
          <SpirometrySectionChart
            xConfig={{
              dataKey: "x",
              type: "number",
              minTickGap: 2,
              allowDecimals: false,
              tickFormatter(value) {
                return Number.parseFloat(value).toFixed(1);
              },
            }}
            yConfig={{
              axisSize: 42,
              domain: [0, (dataMax: any) => Math.ceil(dataMax)],
            }}
            chartConfig={[
              {
                yKey: "y",
                dot: false,
                color: "#7562A8",
                lineType: "monotone",
              },
            ]}
            width="100%"
            height={207}
            data={volumeFlowValues ?? []}
            linesToShow={linesToShow}
          />
        ) : (
          <div style={{ width: "90%", marginLeft: "30px" }}>
            <SkeletonLoading height="200px" width="100%" borderRadius="0px" />
          </div>
        )}
      </SessionGraphContainer>

      <SessionGraphContainer>
        {timeVolumeValues ? (
          <SpirometrySectionChart
            xConfig={{
              dataKey: "x",
              type: "number",
              minTickGap: 2,
              allowDecimals: false,
              tickFormatter(value) {
                return Number.parseFloat(value).toFixed(1);
              },
            }}
            yConfig={{
              axisSize: 42,
            }}
            chartConfig={[
              {
                yKey: "y",
                dot: false,
                color: "#7562A8",
                lineType: "monotone",
              },
            ]}
            width="100%"
            height={207}
            data={timeVolumeValues ?? []}
            linesToShow={linesToShow}
          />
        ) : (
          <div style={{ width: "90%", marginLeft: "30px" }}>
            <SkeletonLoading height="200px" width="100%" borderRadius="0px" />
          </div>
        )}
      </SessionGraphContainer>

      <ArtiQContainer>
        <SpirometryValuesSection bestValues={artiQData?.bestValues} />
        <BlowAcceptabilitySection
          blows={artiQData?.blowAcceptability}
          hideBlow={handleHideBlow}
        />
      </ArtiQContainer>
    </>
  );
}
