import React from "react";
import { v4 as uuidv4 } from "uuid";

import {
  StyledHeader,
  StyledTrigger,
  StyledChevron,
  StyledAccordionTitle,
  StyledAccordionSubtitle,
  HeaderComponentContainer,
  StyledItem,
  StyledContent,
  StyledAccordion,
  AccordionContentContainer,
  AccordionContentItem,
  AccordionContentItemKey,
  AccordionContentItemValue,
} from "./AccordionList.styles";

type AccordionListHeaderProps = IAccordionList;

export interface IAccordionList {
  title: string | React.ReactNode;
  subtitle: string;
  index?: number;
  children: React.ReactNode;
}

export interface IAccordionListItemProps extends IAccordionList {
  headerComponents?: React.ReactNode;
  value: string;
}

function AccordionListHeader({
  title,
  index,
  subtitle,
  children,
}: AccordionListHeaderProps) {
  const isEvenElemet = () => Boolean(index && index % 2 === 0);

  return (
    <StyledHeader>
      <StyledTrigger customColor={isEvenElemet()}>
        <div style={{ display: "flex" }}>
          <StyledChevron aria-hidden />

          <div>
            <StyledAccordionTitle>{title}</StyledAccordionTitle>
            <StyledAccordionSubtitle>{subtitle}</StyledAccordionSubtitle>
          </div>
        </div>

        <HeaderComponentContainer>{children}</HeaderComponentContainer>
      </StyledTrigger>
    </StyledHeader>
  );
}

export interface IAccordionListItemDefaultContentProps {
  content: any;
  columns: { key: string; value: string }[];
}

export function AccordionListItemDefaultContent({
  content,
  columns,
}: IAccordionListItemDefaultContentProps) {
  return (
    <AccordionContentContainer>
      {columns.map((column) => (
        <AccordionContentItem key={uuidv4()}>
          <AccordionContentItemKey>{column.key}</AccordionContentItemKey>

          <AccordionContentItemValue>
            {content[column.value]}
          </AccordionContentItemValue>
        </AccordionContentItem>
      ))}
    </AccordionContentContainer>
  );
}

export function AccordionListItem({
  title,
  subtitle,
  headerComponents,
  children,
  index,
  value,
  ...props
}: IAccordionListItemProps) {
  return (
    <StyledItem value={value} {...props}>
      <AccordionListHeader index={index} title={title} subtitle={subtitle}>
        {headerComponents}
      </AccordionListHeader>

      <StyledContent>{children}</StyledContent>
    </StyledItem>
  );
}

export const AccordionList = StyledAccordion;
