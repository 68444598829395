export function HideEye({ ...rest }) {
  return (
    <svg
      {...rest}
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.69 4.1L1.01 1.42L2.42 0L20.15 17.74L18.74 19.15L15.32 15.73C13.98 16.26 12.52 16.55 11 16.55C6 16.55 1.73 13.44 0 9.05C0.77 7.08 2.06 5.38 3.69 4.1ZM11 3.55C14.79 3.55 18.17 5.68 19.82 9.05C19.23 10.27 18.4 11.32 17.41 12.17L18.82 13.58C20.21 12.35 21.31 10.81 22 9.05C20.27 4.66 16 1.55 11 1.55C9.73 1.55 8.51 1.75 7.36 2.12L9.01 3.77C9.66 3.64 10.32 3.55 11 3.55ZM9.93 4.69L12 6.76C12.57 7.01 13.03 7.47 13.28 8.04L15.35 10.11C15.43 9.77 15.49 9.41 15.49 9.04C15.5 6.56 13.48 4.55 11 4.55C10.63 4.55 10.28 4.6 9.93 4.69ZM8.51 8.92L11.12 11.53C11.08 11.54 11.04 11.55 11 11.55C9.62 11.55 8.5 10.43 8.5 9.05C8.5 9.02501 8.5025 9.00501 8.505 8.98502L8.505 8.985L8.505 8.98498C8.5075 8.96499 8.51 8.94499 8.51 8.92ZM6.86 7.27L5.11 5.52C3.9 6.44 2.88 7.62 2.18 9.05C3.83 12.42 7.21 14.55 11 14.55C11.95 14.55 12.87 14.41 13.75 14.17L12.77 13.19C12.23 13.42 11.63 13.55 11 13.55C8.52 13.55 6.5 11.53 6.5 9.05C6.5 8.42 6.63 7.82 6.86 7.27Z"
        fill="#B5B5B6"
      />
    </svg>
  );
}
