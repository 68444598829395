import { styled } from "../../styles";

export const TableContainer = styled("div", {
  overflowY: "overlay",
  maxHeight: "100%",
  height: "100%",

  zIndex: 1000,

  borderRadius: "8px",

  width: "100%",
  minWidth: "100%",

  backgroundColor: "$white",

  boxSizing: "border-box",

  "&::-webkit-scrollbar": {
    width: "$4",
    height: "$4",
  },

  "&:hover": {
    "&::-webkit-scrollbar": {
      width: "$4",
      height: "$4",
      backgroundColor: "transparent",
    },

    "&::-webkit-scrollbar-track": {
      marginTop: "80px",
    },

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "$gray-50",
      borderRadius: "10px",
    },
  },

  variants: {
    emptyState: {
      true: {
        height: "100%",
        position: "relative",
      },
    },
    hasBorder: {
      true: {
        border: "1px solid #ECECEC",
      },
    },
    maxContent: {
      true: {
        width: "max-content",
      },
    },
  },
});

export const TableElement = styled("table", {
  width: "100%",
  background: "$white",

  borderRadius: "$1",
  borderSpacing: "0px",
  borderCollapse: "collapse",
  borderLeft: "none",
  borderRight: "none",

  thead: {
    borderBottom: "2px solid $gray-20",
  },

  tfoot: {
    height: "26px",
  },
});

export const Th = styled("th", {
  background: "$white",
  color: "$charcoal",

  textAlign: "left",
  fontFamily: "$openSans",
  fontStyle: "normal",

  fontWeight: "600",

  paddingTop: "$13",
  paddingBottom: "$9",

  position: "sticky",
  top: "0px",

  "&:first-child": {
    paddingLeft: "$14",
  },

  "&:last-child": {
    paddingRight: "$14",
  },
});

export const Tr = styled("tr", {
  height: "42px",

  fontFamily: "$openSans",

  fontStyle: "normal",
  fontWeight: "400",

  color: "$charcoal",

  td: {
    "&:first-child": {
      paddingLeft: "$14",
    },
    ".actionContainer": {
      visibility: "hidden",
    },
  },

  "&:nth-child(odd)": {
    backgroundColor: "$gray-20",
  },

  "&:hover": {
    backgroundColor: "rgba(233, 230, 244, .48)",
    transition: "background-color .1s",

    cursor: "pointer",

    td: {
      ".actionContainer": {
        visibility: "visible",
      },
    },
  },

  "&:active": {
    td: {
      ".actionContainer": {
        visibility: "visible",
      },
    },
  },

  "&:focus-visible": {
    outline: "none",
    backgroundColor: "rgba(233, 230, 244, .48)",
    boxShadow: "0px 0px 0px 2px #BDB4DD inset",
  },

  variants: {
    alert: {
      true: {
        "&:hover": {
          backgroundColor: "rgba(250, 209, 217, 0.14) !important",
        },
      },
    },
  },
});

export const EmptyStateContainer = styled("div", {
  position: "absolute",

  top: "46%",
  left: "50%",

  transform: "translate(-50%,-50%)",

  p: {
    fontFamily: "$openSans",

    fontSize: "$text-lg",
    fontWeight: 400,

    color: "$charcoal",
  },
});
