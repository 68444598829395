import { Tile } from "../../../../components/Tile";
import { styled } from "../../../../styles";

export const SecurityContainer = styled(Tile, {
  width: "100%",
  height: "160px",
  display: "flex",
  alignItems: "center",
  justifyContent: "left",
  backgroundColor: "$white",
  marginTop: "6px",
  padding: "0 0 30px 55px",
  fontSize: "14px",
  fontWeight: "500",
  color: "#666666",
});

export const EnabledButton = styled("button", {
  width: "124px",
  height: "36px",
  marginLeft: "8%",
  borderRadius: "18px",
  border: "1px solid #E6E6E6",
  fontSize: "14px",
  fontWeight: "400",
  cursor: "pointer",
  color: "#666666",
  background: "$white",
  fontFamily: "$openSans",

  "&:focus": {
    outline: "none",
    border: "2px solid $primary-30",
  },
});
