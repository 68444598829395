import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";

import { IAddDeviceRequest } from "../../../../../../@types/Devices";
import { Notification } from "../../../../../../@types/Notification";
import { Button } from "../../../../../../components/Button";
import {
  IOption,
  SelectDropdown,
} from "../../../../../../components/SelectDropdown";
import { TextField } from "../../../../../../components/TextField";
import { devicesService } from "../../../../../../services/devicesService";
import {
  AddDeviceCancelButton,
  AddDeviceTitle,
  ErrorMessage,
  inputCss,
  InputRow,
  InputsContainer,
  AddDeviceModalContainer,
  SubmitContainer,
} from "./AddDeviceModal.styles";

interface IAddDeviceModalProps {
  patientId: number;
  onClose: (notification?: Notification, refetchTable?: boolean) => void;
}

export function AddDeviceModal({ patientId, onClose }: IAddDeviceModalProps) {
  const [typesOptions, setTypesOptions] = useState<IOption[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [identifierError, setIdentifierError] = useState("");

  const identifierRef = useRef<HTMLDivElement>(null);

  const formValidationSchema = yup.object().shape({
    deviceTypeId: yup.string().required("Please select a device type."),
    identifier: yup
      .string()
      .test("number", "Please enter a valid identifier.", (val) => {
        if (val === undefined) return true;
        const reg = /^\d+$/;
        return reg.test(val);
      })
      .test("len", "Must be exactly 15 characters.", (val) => {
        if (val === undefined) return true;
        return val.length === 15;
      })
      .required("Please enter the device identifier."),
  });

  const handleOnSubmitForm = async ({
    deviceTypeId,
    identifier,
    patientId,
  }: IAddDeviceRequest) => {
    setIsLoading(true);
    setIdentifierError("");

    const request: IAddDeviceRequest = {
      deviceTypeId,
      identifier,
      patientId,
    };

    const result = await devicesService.addDevice(request);

    setIsLoading(false);

    const notification: Notification = {
      show: true,
      message: "",
      type: "success",
      width: "max-content",
    };

    let refetchTable = false;

    if (result.status >= 200 && result.status < 300) {
      notification.message = "Device successfully added";
      refetchTable = true;
    } else {
      if (result.status === 500) {
        setIdentifierError(result.data.errors[0].message);
        return;
      }

      notification.message = "Error adding device, please try again";
      notification.type = "error";
    }

    onClose(notification, refetchTable);
  };

  const formik = useFormik({
    initialValues: {
      deviceTypeId: undefined,
      identifier: "",
      patientId,
    },
    onSubmit: handleOnSubmitForm,
    validationSchema: formValidationSchema,
  });

  useEffect(() => {
    devicesService.getDeviceTypes().then((types) => {
      if (types === undefined) return;

      const tempTypes: IOption[] = [];

      types.data.forEach((type) => {
        tempTypes.push({
          label: type.name,
          value: type.id,
          key: uuidv4(),
        });
      });

      setTypesOptions(tempTypes);
    });
  }, []);

  const shouldShowErrorMessage = (
    field: "deviceTypeId" | "identifier"
  ): string => {
    let error = "";

    if (formik.errors[field] !== undefined) {
      error = ((formik.errors[field] as string) ?? "").toString();
    }
    return formik.touched[field] ? error : "";
  };

  useEffect(() => {
    formik.setFieldError("identifier", identifierError);
  }, [identifierError]);

  return (
    <AddDeviceModalContainer>
      <AddDeviceTitle>
        <p>Add device</p>
      </AddDeviceTitle>
      <InputsContainer>
        <InputRow>
          <p> Type </p>
          <SelectDropdown
            placeholder="Select"
            options={typesOptions}
            width={225}
            height={42}
            value={formik.values.deviceTypeId}
            onValueChange={(value) => {
              formik.setFieldValue("deviceTypeId", value.toString());
              identifierRef.current?.focus();
            }}
          />

          <ErrorMessage>{shouldShowErrorMessage("deviceTypeId")}</ErrorMessage>
        </InputRow>
        <InputRow>
          <p> ID </p>
          <TextField
            name="identifier"
            placeholder="Identifier"
            label=""
            backgroudColor="white"
            className={inputCss()}
            onChange={formik.handleChange}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                formik.submitForm();
              }
            }}
            errorMessage={shouldShowErrorMessage("identifier")}
            maxLength={15}
            innerRef={identifierRef}
          />
        </InputRow>
      </InputsContainer>
      <SubmitContainer>
        <Button
          type="button"
          label="Confirm"
          isLoading={isLoading}
          onClick={formik.submitForm}
        />
        <AddDeviceCancelButton onClick={() => onClose()}>
          Cancel
        </AddDeviceCancelButton>
      </SubmitContainer>
    </AddDeviceModalContainer>
  );
}
