import { Tile } from "../../../../components/Tile";
import { styled } from "../../../../styles";

export const SectionContainer = styled("div", {
  paddingTop: "48px",
});

export const PreferencesContainer = styled(Tile, {
  width: "100%",
  minHeight: "220px",
  height: "100%",

  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  flexWrap: "wrap",

  backgroundColor: "$white",
  marginTop: "6px",
  padding: "28px 200px 30px 55px",
  fontSize: "14px",
  fontWeight: "500",
  color: "#666666",
});

export const UnitContainer = styled("div", {
  minWidth: "450px",
  height: "48px",

  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "16px",

  margin: "6px 10px 6px 0",
});

export const UnitTitle = styled("p", {
  width: "170px",
});
