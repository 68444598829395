import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import "react-loading-skeleton/dist/skeleton.css";
import { colors } from "../../styles";

export type SkeletonProps = {
  width?: number | string;
  height?: number | string;
  borderRadius?: string;
};

export function SkeletonLoading({
  width,
  height,
  borderRadius,
}: SkeletonProps) {
  return (
    <SkeletonTheme
      width={width}
      height={height}
      borderRadius={borderRadius || "6px"}
      baseColor={colors["gray-30"]}
    >
      <Skeleton />
    </SkeletonTheme>
  );
}
