import { styled } from "../../../styles";

export const FooterLinksContainerDesktop = styled("div", {
  display: "flex",

  gap: "18px",
  marginTop: "$5",

  "@sm": {
    display: "none",
  },
});

export const FooterLinksContainerMobile = styled("div", {
  display: "none",

  "@sm": {
    width: "100%",

    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",

    gap: "$5",

    marginTop: "88px",
    paddingTop: "$8",

    borderTop: "1px solid $gray-50",
  },
});
