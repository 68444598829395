import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "../../components/Button";
import { CustomIconButton } from "../../components/CustomIconButton";
import { TextField } from "../../components/TextField";
import { PageLayout } from "../../layout/components/PageLayout";
import { surveysService } from "../../services/surveysService";
import { SurveyContainer } from "../Surveys/CreateSurvey/CreateSurveyPage.styles";
import { AssignedSurveysTable } from "./Components/AssignedSurveysTable";
import { TokenContainer } from "./MobileUserSurveyList.styles";

export function MobileUserSurveyList() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [tableData, setTableData] = useState<any>();
  const [token, setToken] = useState<string>();
  const navigate = useNavigate();

  const handleAnswerClick = (survey: any) => {
    navigate(`/surveys/answer/${survey.id}`);
  };

  const parseDataToTable = (data: any) => {
    const formatttedDataTable: any[] = [];
    data.map((survey: any, index: number) =>
      formatttedDataTable.push({
        name: survey.surveyName,
        createdBy: survey.createdBy,
        action: (
          <div>
            <CustomIconButton
              style={{
                width: "fit-content",
                borderRadius: "20px",
              }}
              key={index}
              onClick={() => {
                handleAnswerClick(survey);
              }}
            >
              Answer
            </CustomIconButton>
          </div>
        ),
      })
    );
    return formatttedDataTable;
  };

  const updateToken = async () => {
    const tokenJson = `{"tokenType":"Bearer","idToken":"${token}"}`;
    window.localStorage.setItem("tokenResponse", tokenJson);
    const { data } = await surveysService.getAssignedSurveysMobile();
    const formattedData = parseDataToTable(data);
    setTableData(formattedData);
    setIsLoading(false);
  };

  return (
    <PageLayout>
      <SurveyContainer>
        <TokenContainer>
          <TextField
            name="token"
            label=""
            backgroudColor="white"
            onChange={(e) => {
              setToken(e.target.value.toString());
            }}
          />
          <Button
            onClick={() => {
              updateToken();
            }}
            label="Inject Token"
            type="button"
          />
        </TokenContainer>
        <AssignedSurveysTable isLoading={isLoading} content={tableData} />
      </SurveyContainer>
    </PageLayout>
  );
}
