import { styled } from "../../styles";

export const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "fit-content",
});

export const StyledLegend = styled("li", {
  listStyleType: "none",

  gap: "8px",
  display: "flex",
  alignItems: "center",

  color: "$gray-90",

  lineHeight: "18px",
  fontSize: "$text-md",

  marginLeft: "-2px",
});
