import { Progress } from "antd";

import { DefaultCss } from "./ProgressBar.styles";

type CustomProgressBarProps = {
  percent: number;
  maxWidth?: number;
};

export function ProgressBar({
  percent,
  maxWidth = 262,
}: CustomProgressBarProps): any {
  return (
    <Progress
      style={{ width: "100%", maxWidth }}
      percent={percent}
      strokeColor="#BDB4DD"
      trailColor="#E9E6F4"
      className={DefaultCss()}
      strokeLinecap="round"
    />
  );
}
