import { IWorkflowsTableContent } from "../../../../@types/Worflows";
import { Table } from "../../../../components/Table";
import { TableSkeleton } from "../../../../components/TableSkeleton";
import { worflowsListColumns } from "./workflowsListColumns";

type WorkflowsTableProps = {
  content: IWorkflowsTableContent[];
  isLoading?: boolean;
  onRowClick(rowContent: IWorkflowsTableContent): void;
};

export function WorflowListTable({
  content,
  isLoading,
  onRowClick,
}: WorkflowsTableProps) {
  if (isLoading) {
    return <TableSkeleton columnsNumber={8} rowsNumber={6} />;
  }

  return (
    <Table
      data={content}
      emptyStateText="No workflows found."
      columns={worflowsListColumns}
      showMaxContent
      onRowClick={onRowClick}
    />
  );
}

WorflowListTable.defaultProps = {
  isLoading: false,
};
