import { styled } from "../../styles";

export const ProtocolListContainer = styled("div", {
  display: "flex",
  position: "relative",
  flexDirection: "column",
});

export const ProtocolListTableContainer = styled("div", {
  marginTop: "$4",

  height: "calc(100vh - 200px)",
  maxHeight: "800px",
});

export const AddProtocolButton = styled("div", {
  color: "$charcoal",
  fontWeight: "600",
  fontSize: "12px",
  lineHeight: "17px",
  cursor: "pointer",
  position: "absolute",
  top: "14px",
  right: "10px",
  borderRadius: "6px",
  padding: "0 2px",

  "&:hover": {
    color: "$primary",
  },
});
