import React from "react";

import { StyledToggleRoot, StyledToggleThumb } from "./Toggle.styles";

type ToggleProps = React.ComponentProps<typeof StyledToggleRoot>;

export function Toggle({ ...props }: ToggleProps) {
  return (
    <StyledToggleRoot {...props}>
      <StyledToggleThumb />
    </StyledToggleRoot>
  );
}
