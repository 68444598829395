import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { ISearchBarPatient } from "../../@types/Patient";
import { ReturnArrow } from "../../assets/icons/ReturnArrow";
import { usePatientList } from "../../hooks/queries/patients";
import { useArrowsListennerHandler } from "../../hooks/useArrowsListennerHandler";
import useIsMobile from "../../hooks/useIsMobile";
import { SearchField } from "../SearchField";
import { IOption } from "../SelectDropdown";
import {
  MobileSearchBarContainer,
  SearchBarContainer,
  SearchIcon,
} from "./SearchBar.styles";

export function SearchBar() {
  const [searchValue, setSearchValue] = useState(null);
  const [patientList, setPatientList] = useState<any[]>();
  const [optionsList, setOptionsList] = useState<IOption[] | undefined>(
    undefined
  );
  const [isMobileSearchActive, setIsMobileSearchActive] = useState(false);

  const navigate = useNavigate();
  const { isMobile } = useIsMobile();
  const { patients, isLoading, isRefetching } = usePatientList(
    {
      variant: "active",
    },
    false
  );
  const { disablePatientListArrowNav, enablePatientListArrowNav } =
    useArrowsListennerHandler();

  async function populateOptions() {
    const temporaryList: IOption[] = [];

    patientList?.forEach((patient: ISearchBarPatient) => {
      const patientBirthDate = dayjs(patient.dob);
      const patientAge = dayjs().diff(patientBirthDate, "year");

      const birthAndGender = `(${patientAge ?? ""}${
        patient.gender?.toUpperCase() ?? ""
      })`;

      const patientToOption: IOption = {
        label: `${patient.name} ${
          patient.dob !== undefined && patient.gender !== undefined
            ? birthAndGender
            : ""
        }`,
        value: patient.id.toString(),
        key: uuidv4(),
      };

      temporaryList.push(patientToOption);
    });

    setOptionsList(temporaryList);
  }

  useEffect(() => {
    if (!isLoading && !isRefetching) {
      setPatientList(patients);
    }
  }, [isLoading, isRefetching]);

  useEffect(() => {
    populateOptions();
  }, [patientList]);

  const handleRedirect = (id: string) => {
    setSearchValue(null);
    populateOptions();
    navigate(`/patients/${id}/overview`);
  };

  const handleMobileIconClick = () => {
    setIsMobileSearchActive(true);
  };

  const handleSearchBarClose = () => {
    setIsMobileSearchActive(false);
  };

  if (isMobile) {
    if (isMobileSearchActive) {
      return (
        <MobileSearchBarContainer>
          <ReturnArrow onClick={handleSearchBarClose} />
          <SearchBarContainer style={{ width: "80%" }}>
            {optionsList ? (
              <SearchField
                options={optionsList ?? []}
                placeholder="Search for patients"
                onValueChange={handleRedirect}
                value={searchValue}
              />
            ) : null}
          </SearchBarContainer>
        </MobileSearchBarContainer>
      );
    }
    return (
      <SearchBarContainer>
        <SearchIcon onClick={handleMobileIconClick}>
          <MagnifyingGlassIcon />
        </SearchIcon>
      </SearchBarContainer>
    );
  }

  return (
    <SearchBarContainer>
      {optionsList ? (
        <SearchField
          onFocus={disablePatientListArrowNav}
          onBlur={enablePatientListArrowNav}
          options={optionsList ?? []}
          placeholder="Search for patients"
          onValueChange={handleRedirect}
          value={searchValue}
        />
      ) : null}
    </SearchBarContainer>
  );
}
