import { styled, css } from "../../styles";

export const PatientListTableContainer = styled("div", {
  marginTop: "$4",

  position: "relative",
  height: "100%",

  width: "100%",
  overflowX: "auto",

  "@sm": {
    marginTop: 0,
  },

  "::-webkit-scrollbar": {
    display: "none",
  },
});

export const PatientCountContainer = styled("div", {
  color: "$gray-70",
  fontFamily: "$nunito",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "34px",
  marginLeft: "5px",
});

export const AddPatientButton = styled("div", {
  width: "90px",
  height: "26px",
  padding: "5px 11px",

  backgroundColor: "#212121",
  borderRadius: "12px",
  color: "$white",

  fontSize: "12px",
  lineHeight: "16px",
  fontFamily: "$openSans",
  fontWeight: "500",

  position: "absolute",
  top: "8px",
  right: "1px",

  cursor: "pointer",

  "&:hover": {
    backgroundColor: "$charcoal",
  },
});

export const PatientListHeaderContainer = styled("div", {
  display: "flex",
  position: "relative",

  svg: {
    margin: "6px 6px 0 6px",
    height: "21px",
    width: "21px",
  },
});

export const TableSettingsContainer = styled("div", {
  marginTop: "3px",
  marginLeft: "2px",
  borderRadius: "50%",
  cursor: "pointer",

  "&:hover": {
    backgroundColor: "rgba(147, 147, 147, 0.12)",
  },

  "&:focus-visible": {
    outline: "none !important",
    border: "2px solid $primary-30 !important",
  },
});

export const TagContainer = styled("div", {
  div: {
    position: "inherit",
    height: "26px",
  },
});

export const LayoutCss = css({
  ".page-container": {
    display: "flex",
    flexDirection: "column",
    margin: "0px !important",

    width: "96%",
    minHeight: "calc(100% - 96px)",

    "@media(max-width: 1420px)": {
      width: "100%",
    },

    "@sm": {
      width: "100%",
      margin: 0,
    },
  },
});
