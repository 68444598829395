import { Spin } from "antd";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import * as yup from "yup";

import {
  IPasswordPolicy,
  ISetPasswordFormValues,
} from "../../@types/SetPassword";
import pmpLogoName from "../../assets/images/pmp-logo-name.png";
import { Button } from "../../components/Button";
import { TextField } from "../../components/TextField";
import {
  FooterLinksDesktop,
  FooterLinksMobile,
} from "../../layout/components/FooterLinks";
import { authService } from "../../services/authService";
import { mixpanelActions } from "../../utils/mixpanel";
import { InvalidPassLinkPage } from "./InvalidPassLinkPage";
import {
  Logo,
  Container,
  StyledTile,
  createPasswordButtonStyles,
  passwordTextFieldStyles,
  InfoMessage,
  Form,
  LoadingOverlay,
  PasswordRequirementContainer,
  listStyles,
  confirmPasswordTextFieldStyles,
  ErrorMessage,
  spinCss,
} from "./SetPasswordPage.styles";

export function SetPasswordPage() {
  const defaultPasswordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\^$*.[\]{}()?"!@#%&\\/,><'':;|_~`=+-]).{8,256}/;

  const requiredPasswordValidationMessage = "Please enter your new password.";
  const passwordRequirementsValidationMessage =
    "New password must meet the criteria above";
  const passwordDoesntMatchValidationMessage = "Passwords entered do not match";

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [isLinkValid, setIsLinkValid] = useState(false);
  const [isLoadingLinkValidation, setIsLoadingLinkValidation] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [isPatient, setIsPatient] = useState<boolean>(false);
  const [code, setCode] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [capsLockEnabled, setCapsLockEnabled] = useState(false);
  const [globalError, setGlobalError] = useState("");
  const [passwordPolicy, setPasswordPolicy] = useState({
    minimumLength: 8,
    requireUppercase: true,
    requireLowercase: true,
    requireNumbers: true,
    requireSymbols: true,
  } as IPasswordPolicy);

  const fetchPasswordPolicy = async () => {
    setIsLoading(true);

    const policy = await authService.getPasswordPolicy();
    if (policy) {
      setPasswordPolicy(policy);
    }
    setIsLoading(false);
  };

  const verifyIfPassTokenIsValid = async (email: string, code: string) => {
    const { data } = await authService.validateTokenCode(email, code);

    if (data === true) {
      setIsLinkValid(true);
    }
    setIsLoadingLinkValidation(false);
  };

  useEffect(() => {
    mixpanelActions.track("Visited Screen: CreateNewPassword");

    const emailParam = searchParams.get("email");
    const codeParam = searchParams.get("code");
    const isPatient = searchParams.get("user") === "patient";
    setIsPatient(isPatient);
    if (codeParam && emailParam) {
      verifyIfPassTokenIsValid(emailParam, codeParam);
      setEmail(emailParam);
      setCode(codeParam);
      fetchPasswordPolicy();
    } else {
      navigate("/login");
    }
  }, []);

  const handleOnSubmitForm = async ({
    newPassword,
  }: ISetPasswordFormValues) => {
    mixpanelActions.track("User Action: SubmitNewPassword");

    setIsLoading(true);

    try {
      const result = await authService.setPassword({
        password: newPassword,
        email,
        code,
      });

      if (result.status >= 200 && result.status < 300) {
        let searchParams = {};
        if (result.data.isStaff === true) {
          searchParams = { isHcp: "true" };
        } else {
          searchParams = { isHcp: "false" };
        }
        navigate({
          pathname: "/set-password-success",
          search: createSearchParams(searchParams).toString(),
        });
      } else {
        setGlobalError(result.data.errors[0].message);
      }
    } catch (error) {
      setGlobalError("Sorry, something went wrong. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const passwordRegex = passwordPolicy.regex
    ? new RegExp(passwordPolicy.regex)
    : defaultPasswordRegex;

  const formValidationSchema = yup.object().shape({
    newPassword: yup
      .string()
      .required(requiredPasswordValidationMessage)
      .matches(passwordRegex, passwordRequirementsValidationMessage),
    confirmNewPassword: yup
      .string()
      .required(requiredPasswordValidationMessage)
      .oneOf(
        [yup.ref("newPassword"), null],
        passwordDoesntMatchValidationMessage
      )
      .matches(passwordRegex, passwordRequirementsValidationMessage),
  });

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmNewPassword: "",
    },
    onSubmit: handleOnSubmitForm,
    validationSchema: formValidationSchema,
  });

  const handleCheckCapsLockOnKeyUp = (event: any) => {
    const capsEnabled =
      (event.getModifierState && event.getModifierState("CapsLock")) || false;

    setCapsLockEnabled(capsEnabled);
  };

  const shouldShowErrorMessage = (
    field: "newPassword" | "confirmNewPassword"
  ) => {
    return formik.touched[field] ? formik.errors[field] : "";
  };

  if (isLoadingLinkValidation) {
    return <Spin className={spinCss()} fullscreen size="large" />;
  }
  return isLinkValid ? (
    <Container>
      <StyledTile>
        {isLoading && <LoadingOverlay />}
        <Form onSubmit={formik.handleSubmit}>
          <Logo src={pmpLogoName} alt="patientMpower logo" />
          <InfoMessage>Choose a new password.</InfoMessage>
          <PasswordRequirementContainer>
            <p>Password should have at least: </p>
            <ul className={listStyles()}>
              <li>{passwordPolicy.minimumLength} characters</li>
              {passwordPolicy.requireUppercase && <li>Upper-case letter</li>}
              {passwordPolicy.requireLowercase && (
                <li>One lower-case letter</li>
              )}
              {passwordPolicy.requireNumbers && <li>One number</li>}
              {passwordPolicy.requireSymbols && <li>One special character</li>}
            </ul>
          </PasswordRequirementContainer>
          <div>
            <TextField
              isPassword
              name="newPassword"
              label="New password"
              placeholder="●●●●●●●●●●●●"
              value={formik.values.newPassword}
              onChange={formik.handleChange}
              className={passwordTextFieldStyles()}
              onKeyDown={handleCheckCapsLockOnKeyUp}
              errorMessage={shouldShowErrorMessage("newPassword")}
              warningMessage={capsLockEnabled ? "Caps lock is on." : undefined}
            />
            <TextField
              isPassword
              name="confirmNewPassword"
              label="Confirm new password"
              placeholder="●●●●●●●●●●●●"
              value={formik.values.confirmNewPassword}
              onChange={formik.handleChange}
              className={confirmPasswordTextFieldStyles()}
              onKeyDown={handleCheckCapsLockOnKeyUp}
              errorMessage={shouldShowErrorMessage("confirmNewPassword")}
              warningMessage={capsLockEnabled ? "Caps lock is on." : undefined}
            />
            {globalError && <ErrorMessage>{globalError}</ErrorMessage>}
            <Button
              type="submit"
              label="Create password"
              isLoading={isLoading}
              className={createPasswordButtonStyles()}
            />
          </div>
        </Form>
        <FooterLinksMobile />
      </StyledTile>
      <FooterLinksDesktop />
    </Container>
  ) : (
    <InvalidPassLinkPage isPatient={isPatient} email={email} />
  );
}
