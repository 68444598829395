import { styled } from "../../../../styles";

export const Container = styled("div", {
  padding: "0 5px 0px 10px",
  marginBottom: "-22px",
  width: "100%",
  height: "100%",
  overflowX: "auto",
  overflowY: "auto",
  overflowScrolling: "touch !important",
  WebkitOverflowScrolling: "touch !important",
  webkitOverflowScrolling: "touch !important",
  touchAction: "pan-y !important",

  "&::-webkit-scrollbar": {
    backgroundColor: "transparent",
  },
});

export const ChartCoantiner = styled("div", {
  position: "relative",
  right: "38px",
  top: "30px",
  height: "100%",
  width: "calc(100% + 70px)",
});

export const Title = styled("p", {
  color: "$black",
  fontFamily: "$nunito",
  fontSize: "16px",
  fontWeight: "700",
  lineHeight: "normal",
});

export const OptionsContainer = styled("div", {
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});
