import { styled } from "../../../../../../styles";

export const TableContainer = styled("div", {
  width: "100%",
  height: "100%",
  overflowY: "auto",

  marginTop: "20px",
});

export const EmptyStateContainer = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  height: "100%",
  paddingBottom: "30%",

  p: {
    fontFamily: "$openSans",
    fontSize: "$text-lg",
    fontWeight: 400,

    color: "$charcoal",
  },
});

export const AccordionTitleButton = styled("a", {
  all: "unset",

  textDecoration: "underline",

  fontSize: "$text-lg",
  lineHeight: "22px",
});
