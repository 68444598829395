import React from "react";

export function TriangleDown({ ...rest }) {
  return (
    <svg
      {...rest}
      width="10"
      height="5"
      viewBox="0 0 10 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0L5 5L10 0H0Z" fill="#666666" />
    </svg>
  );
}
