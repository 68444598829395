import dayjs, { Dayjs } from "dayjs";
import localeData from "dayjs/plugin/localeData";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import { Form, Formik, FormikProps } from "formik";
import { useMemo, useState, useEffect, forwardRef } from "react";
import * as yup from "yup";

import "dayjs/locale/en-ie";
import { IProtocolActions } from "../../../../../../@types/Protocol";
import { Close } from "../../../../../../assets/icons/Close";
import { DateTimePickerInput } from "../../../../../../components/DateTimePicker";
import {
  IOption,
  SelectDropdown,
} from "../../../../../../components/SelectDropdown";
import { TextField } from "../../../../../../components/TextField";
import { IOptionGroup } from "../../ProtocolBuilder";
import {
  ActiveBar,
  CloseContainer,
  ErrorMessage,
  Section,
  SectionItem,
  selectCss,
  textFieldClassName,
} from "./ActionSection.styles";

dayjs.extend(LocalizedFormat);
dayjs.extend(localeData);

interface IActionSectionProps {
  id: number;
  hospitalId?: number;
  intervalOptions: IOption[];
  typeOptions: IOption[];
  actionOptions: IOptionGroup[];
  longDateFormat?: string;
  isCloseButtonVisible: boolean;
  isSectionActive: boolean;
  disableNotEditableFields?: boolean;
  onRemove(id: number): void;
  handleActiveSection(id: number): void;
}

interface IActionFormikSectionProps extends IActionSectionProps {
  content?: IProtocolActions;
}

interface IActionSectionChildProps extends IActionSectionProps {
  formik: FormikProps<IProtocolActions>;
}

function ActionSectionChild({
  formik,
  id,
  hospitalId,
  intervalOptions,
  typeOptions,
  actionOptions,
  longDateFormat,
  isCloseButtonVisible,
  isSectionActive,
  disableNotEditableFields,
  onRemove,
  handleActiveSection,
}: IActionSectionChildProps) {
  const [isMounted, setIsMounted] = useState(false);

  const [intervalIterationLabel, setIntervalIterationLabel] =
    useState<string>("X Days");

  useEffect(() => {
    setIsMounted(true);

    return () => {
      setIsMounted(false);
    };
  }, []);

  const pinToHomeScreenOption = intervalOptions.find(
    (x) => x.label === "Pin to app home screen"
  );

  const onceOffIntervalOption = intervalOptions.find(
    (x) => x.label === "Once-off"
  );

  const minDate =
    formik.values.actionIntervalId === onceOffIntervalOption?.value
      ? dayjs().add(-1, "day")
      : undefined;

  const shouldShowErrorMessage = (
    field:
      | "actionName"
      | "actionType"
      | "actionValue"
      | "intervalIterations"
      | "startDate"
      | "endDate"
      | "actionIntervalId"
  ) => {
    return formik?.touched[field] ? formik?.errors[field] ?? "" : "";
  };

  const typesDropdown = useMemo(() => {
    if (!isMounted) return null;

    return (
      <SelectDropdown
        placeholder="Select type"
        className={selectCss()}
        width={248}
        height={42}
        value={formik?.values.actionType}
        onValueChange={(value) => {
          formik?.setFieldValue("actionType", value as string);
          formik?.setFieldValue("actionValue", undefined);
          formik?.setFieldValue("actionIntervalId", undefined);
        }}
        options={typeOptions}
        disabled={!disableNotEditableFields}
      />
    );
  }, [typeOptions, hospitalId, isMounted]);

  const actionsDropdown = useMemo(() => {
    if (!isMounted) return null;

    return (
      <>
        <p style={{ width: "110px" }}>
          {formik?.values.actionType !== undefined
            ? formik?.values.actionType
            : "Action"}{" "}
          *
        </p>

        <SelectDropdown
          placeholder={`Select ${
            formik?.values.actionType !== undefined
              ? formik.values.actionType.toLowerCase()
              : "action"
          }`}
          className={selectCss()}
          width={248}
          height={42}
          showSearch
          optionFilterProp="label"
          value={formik?.values.actionValue}
          onValueChange={(value) => {
            formik?.setFieldValue("actionValue", value as string);

            let actionLabel = "";

            actionOptions.forEach((group) => {
              const label = group.options.find((x) => x.value === value)?.label;

              if (label) {
                actionLabel = label;
              }
            });

            if (actionLabel !== "") {
              formik?.setFieldValue("actionName", actionLabel);
            }
          }}
          options={
            actionOptions.find(
              formik?.values.actionType === "Survey"
                ? (x) =>
                    x.label === `${formik?.values.actionType} ${hospitalId}`
                : (x) => x.label === formik?.values.actionType
            )?.options ?? []
          }
          disabled={!disableNotEditableFields}
        />
      </>
    );
  }, [
    typeOptions,
    formik?.values.actionType,
    hospitalId,
    isMounted,
    actionOptions,
  ]);

  const intervalDropdown = useMemo(() => {
    if (!isMounted) return null;

    const options =
      formik?.values.actionType === "Survey"
        ? intervalOptions
        : intervalOptions.filter(
            (x) => x.value !== pinToHomeScreenOption?.value
          );

    return (
      <SelectDropdown
        placeholder="Select frequency"
        className={selectCss()}
        width={248}
        height={42}
        value={formik?.values.actionIntervalId}
        onValueChange={(value) => {
          formik?.setFieldValue("actionIntervalId", value as string);

          const intervalOption = options.find((i) => i.value === value);

          if (intervalOption) {
            if (intervalOption.label.startsWith("Every")) {
              setIntervalIterationLabel(
                intervalOption.label.slice("Every ".length)
              );
            }

            if (intervalOption.value === onceOffIntervalOption?.value) {
              const today = dayjs().startOf("day");

              if (
                formik?.values?.startDate != null &&
                formik.values.startDate < today
              ) {
                formik?.setFieldValue("startDate", today);
              }

              formik?.setFieldValue("endDate", null);
            }
          }
        }}
        options={options}
      />
    );
  }, [formik?.values.actionType, intervalOptions, hospitalId, isMounted]);

  return (
    <Section isInactive={!isSectionActive}>
      {isSectionActive ? <ActiveBar /> : null}

      {isCloseButtonVisible ? (
        <CloseContainer onClick={() => onRemove(id)}>
          <Close />
        </CloseContainer>
      ) : null}

      <SectionItem onClick={() => handleActiveSection(id)}>
        <p style={{ width: "110px" }}>Type *</p>
        {typesDropdown}
        <ErrorMessage>{shouldShowErrorMessage("actionType")}</ErrorMessage>
      </SectionItem>

      <SectionItem onClick={() => handleActiveSection(id)}>
        {actionsDropdown}
        <ErrorMessage>{shouldShowErrorMessage("actionValue")}</ErrorMessage>
      </SectionItem>

      <SectionItem onClick={() => handleActiveSection(id)}>
        <p style={{ width: "110px" }}>Frequency *</p>
        {intervalDropdown}
        <ErrorMessage>
          {shouldShowErrorMessage("actionIntervalId")}
        </ErrorMessage>
      </SectionItem>

      {intervalOptions
        .find((i) => i.value === formik.values.actionIntervalId)
        ?.label.startsWith("Every") ? (
        <SectionItem onClick={() => handleActiveSection(id)}>
          <p style={{ width: "110px" }}>{intervalIterationLabel} *</p>
          <TextField
            label=""
            type="number"
            placeholder={intervalIterationLabel}
            name="intervalIterations"
            value={formik.values.intervalIterations}
            onChange={(event) => {
              formik.setFieldValue("intervalIterations", event.target.value);
            }}
            className={textFieldClassName()}
            backgroudColor="white"
            errorMessage={shouldShowErrorMessage("intervalIterations")}
          />
        </SectionItem>
      ) : null}

      <SectionItem onClick={() => handleActiveSection(id)}>
        <p style={{ width: "110px" }}>Start date *</p>
        <DateTimePickerInput
          value={formik.values.startDate}
          format={longDateFormat}
          onChange={(value: Dayjs) => formik.setFieldValue("startDate", value)}
          width="248px"
          hideNowButton
          minuteStep={15}
          disabled={!disableNotEditableFields}
          minDate={minDate}
        />

        <ErrorMessage>{shouldShowErrorMessage("startDate")}</ErrorMessage>
      </SectionItem>

      <SectionItem onClick={() => handleActiveSection(id)}>
        <p style={{ width: "110px" }}>End date</p>
        <DateTimePickerInput
          placeholder="Select date (optional)"
          value={formik.values.endDate}
          format={longDateFormat}
          onChange={(value: Dayjs) => formik.setFieldValue("endDate", value)}
          width="248px"
          hideNowButton
          minuteStep={15}
          disabled={
            formik.values.actionIntervalId === onceOffIntervalOption?.value
          }
        />

        <ErrorMessage>{shouldShowErrorMessage("endDate")}</ErrorMessage>
      </SectionItem>
    </Section>
  );
}

export const ActionSection = forwardRef<
  FormikProps<IProtocolActions>,
  IActionFormikSectionProps
>(
  (
    {
      id,
      hospitalId,
      intervalOptions,
      typeOptions,
      actionOptions,
      longDateFormat,
      isCloseButtonVisible,
      isSectionActive,
      content,
      disableNotEditableFields,
      onRemove,
      handleActiveSection,
    },
    ref
  ) => {
    const handleOnSubmitForm = () => {};

    const formValidationSchema = yup.object().shape({
      actionType: yup.string().required("Please select a type."),
      actionValue: yup.string().required("Please select an action."),
      intervalIterations: yup.number().when("actionIntervalId", {
        is: (actionIntervalId: string) => {
          const intervalLabel = intervalOptions.find(
            (i) => i.value === actionIntervalId
          )?.label;
          return intervalLabel?.startsWith("Every");
        },
        then: yup
          .number()
          .min(1, "Please insert a number bigger than 0.")
          .required("Please enter a value."),
        otherwise: yup.number(),
      }),
      startDate: yup
        .string()
        .required("Please select a start date.")
        .nullable(),
      actionIntervalId: yup.string().required("Please select a frequency."),
    });

    return (
      <Formik<IProtocolActions>
        innerRef={ref}
        initialValues={{
          id: content?.id,
          actionName: content?.actionName,
          actionType: content?.actionType,
          actionValue: content?.actionValue,
          intervalIterations: content?.intervalIterations ?? 1,
          startDate: content?.startDate ? dayjs(content?.startDate) : undefined,
          endDate: content?.endDate ? dayjs(content?.endDate) : undefined,
          actionIntervalId: content?.actionIntervalId,
        }}
        onSubmit={handleOnSubmitForm}
        validationSchema={formValidationSchema}
        enableReinitialize
      >
        {(formik) => (
          <Form>
            <ActionSectionChild
              formik={formik}
              id={id}
              hospitalId={hospitalId}
              intervalOptions={intervalOptions}
              typeOptions={typeOptions}
              actionOptions={actionOptions}
              longDateFormat={longDateFormat}
              isCloseButtonVisible={isCloseButtonVisible}
              isSectionActive={isSectionActive}
              disableNotEditableFields={disableNotEditableFields}
              onRemove={onRemove}
              handleActiveSection={handleActiveSection}
            />
          </Form>
        )}
      </Formik>
    );
  }
);
