import { styled, css } from "../../../../../../styles";

export const Section = styled("div", {
  width: "100%",

  position: "relative",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",

  borderRadius: "8px",
  backgroundColor: "rgba(248, 248, 248, 0.8)",

  padding: "28px 48px",

  gap: "24px",
  flexWrap: "wrap",

  marginBottom: "22px",

  p: {
    color: "$charcoal",
    fontFamily: "$openSans",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "16px",
  },

  variants: {
    isInactive: {
      true: {
        opacity: 0.68,
      },
    },
  },
});

export const SectionItem = styled("div", {
  width: "48%",

  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",

  gap: "40px",
});

export const ActiveBar = styled("div", {
  width: "3px",
  height: "100%",

  position: "absolute",
  left: "-7px",

  borderRadius: "12px",

  backgroundColor: "$secondary",
});

export const CloseContainer = styled("div", {
  position: "absolute",

  cursor: "pointer",

  top: 16,
  right: 16,
});

export const ErrorMessage = styled("p", {
  zIndex: 3002,
  position: "absolute",
  left: "153px",
  bottom: "-18px",

  fontSize: "12px !important",
  fontWeight: "600 !important",

  color: "$error-light !important",

  width: "max-content !important",

  variants: {
    isWards: {
      true: {
        left: "93px !important",
      },
    },
  },
});

// CSS

export const textFieldClassName = css({
  width: "248px !important",

  input: {
    border: "2px solid $gray-50",
    borderRadius: "6px",
    paddingLeft: "11px",
    paddingRight: "11px",

    "&:hover": {
      border: "2px solid $primary-30",
    },
  },
});

export const selectCss = css({
  width: "300px",
  height: "41px",

  ".ant-select-selector": {
    height: "42px !important",
    alignItems: "center",
    border: "solid 2px $gray-50 !important",
    boxShadow: "0 0 0 1px $gray-50 !important",
    fontFamily: "$openSans !important",

    "&:focus-within": {
      borderColor: "$primary-30 !important",
    },

    "&:hover": {
      borderColor: "$primary-30 !important",
    },

    ".ant-select-selection-overflow-item": {
      span: {
        "&.ant-select-selection-item": {
          borderRadius: "14px !important",
        },
      },
    },

    ".ant-select-selection-search-input": {
      height: "100% !important",
    },
  },
});
