import { globalCss } from "../../styles";

export const globalStyles = globalCss({
  "*": {
    margin: 0,
    padding: 0,
    boxSizing: "border-box",
  },

  div: {
    "&[data-rsbs-header]": {
      "&:before": {
        display: "none",
      },
    },
  },
});
