import { SkeletonLoading } from "../../../../../../components/Skeleton";
import { ModalSkeletonContainer } from "./ModalSkeleton.styles";

type MdoalSkeletonProps = {
  isPinnedGraph?: boolean;
};

export function ModalSkeleton({ isPinnedGraph }: MdoalSkeletonProps) {
  return (
    <ModalSkeletonContainer isPinnedGraph={isPinnedGraph}>
      <SkeletonLoading width="120px" height="22px" />

      <div
        style={{
          marginTop: "4px",
          marginBottom: "2px",
        }}
      >
        <SkeletonLoading height="16px" width="180px" />
      </div>

      <SkeletonLoading height="22px" width="380px" />

      <div style={{ marginTop: "28px", width: "100%" }}>
        <SkeletonLoading height="480px" width="95%" />
      </div>
    </ModalSkeletonContainer>
  );
}

export function ModalSkeletonMobile() {
  return (
    <ModalSkeletonContainer>
      <SkeletonLoading width="220px" height="20px" />
      <br />
      <SkeletonLoading width="100px" height="22px" />

      <div
        style={{
          marginTop: "4px",
          marginBottom: "2px",
        }}
      >
        <SkeletonLoading height="16px" width="180px" />
      </div>

      <SkeletonLoading height="22px" width="280px" />

      <div style={{ marginTop: "28px" }}>
        <SkeletonLoading height="480px" width="95%" />
      </div>
    </ModalSkeletonContainer>
  );
}
