import { createColumn } from "../../components/Table";

export const protocolListColumns = [
  createColumn({
    keyName: "protocolName",
    header: () => "Protocol name",
    cell: (info) => info.renderValue(),
    size: 250,
  }),
  createColumn({
    keyName: "actionNames",
    header: () => "Actions",
    cell: (info) => info.renderValue(),
    size: 250,
  }),
  createColumn({
    keyName: "hospitalName",
    header: () => "Patient Group",
    cell: (info) => info.renderValue(),
    size: 200,
  }),
  createColumn({
    keyName: "status",
    header: () => "Status",
    cell: (info) => info.renderValue(),
    size: 50,
  }),
  createColumn({
    keyName: "action",
    header: () => "",
    cell: (info) => info.renderValue(),
    size: 50,
  }),
];
