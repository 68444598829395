import { styled } from "../../../../styles";

export const LocationLink = styled("p", {
  variants: {
    underline: {
      true: {
        textDecoration: "underline",
      },
    },
  },
});
