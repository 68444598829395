import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { Eye } from "../../assets/icons/Eye";
import { HideEye } from "../../assets/icons/HideEye";
import { SkeletonLoading } from "../Skeleton";
import {
  AlertText,
  StyledInput,
  StyledLabel,
  StyledIconButton,
  TextFieldContainer,
  skeletonContainerStyles,
  StyledAuxiliarButton,
} from "./TextField.styles";

export type TextFieldProps = {
  innerRef?: any;
  autofocus?: boolean;
  name?: string;
  label: string;
  className?: string;
  maxLength?: number;
  disabled?: boolean;
  isLoading?: boolean;
  isPassword?: boolean;
  placeholder?: string;
  errorMessage?: string;
  value?: string | number;
  warningMessage?: string;
  type?: "email" | "text" | "number" | "date";
  auxiliarButtonLabel?: string;
  defaultValue?: number | string;
  backgroudColor?: string;
  inputPadding?: string;
  inputBorder?: string;
  isMobile?: boolean;
  enterKeyHint?:
    | "done"
    | "enter"
    | "go"
    | "next"
    | "previous"
    | "search"
    | "send";
  onClickAuxiliarButton?: () => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onTouchEnd?: (event: React.TouchEvent<HTMLInputElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onPaste?: (event: React.ClipboardEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
};

export function TextField({
  innerRef,
  autofocus,
  type,
  name,
  enterKeyHint = "done",
  label,
  value,
  maxLength,
  onBlur,
  disabled,
  onChange,
  isLoading,
  className,
  onKeyDown,
  onKeyUp,
  onTouchEnd,
  isPassword,
  placeholder,
  defaultValue,
  errorMessage,
  warningMessage,
  isMobile,
  auxiliarButtonLabel,
  onClickAuxiliarButton,
  backgroudColor,
  inputPadding,
  inputBorder,
  onPaste,
  onFocus,
}: TextFieldProps) {
  const [showPassword, setShowPassword] = useState(false);
  const [inputValue, setInputValue] = useState<string | undefined>("");
  const [inputLength, setInputLength] = useState(0);

  useEffect(() => {
    setInputValue(value?.toString());
  }, [value]);

  const getInputType = () => {
    if (isPassword && !showPassword) {
      return "password";
    }

    if (type === "date") {
      return "text";
    }

    return type;
  };

  const handleOnClickShowHidePassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();

    setShowPassword(!showPassword);
  };

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const eventToChange = event;
    setInputValue(eventToChange.target.value);

    if (
      type === "date" &&
      (eventToChange.target.value.length === 2 ||
        eventToChange.target.value.length === 5) &&
      eventToChange.target.value.length > inputLength
    ) {
      eventToChange.target.value = `${eventToChange.target.value.slice(
        0,
        eventToChange.target.value.length
      )}/${eventToChange.target.value.slice(
        eventToChange.target.value.length
      )}`;
      setInputValue(eventToChange.target.value);
    }

    setInputLength(eventToChange.target.value.length);
    onChange(eventToChange);
  };

  const inputId = uuidv4();

  if (isLoading) {
    return (
      <div className={skeletonContainerStyles()}>
        <SkeletonLoading width="72px" height="16px" />
        <SkeletonLoading height="42px" />
      </div>
    );
  }

  return (
    <TextFieldContainer className={className}>
      {label !== "" && (
        <StyledLabel styleDisabled={disabled} htmlFor={name}>
          {label}
        </StyledLabel>
      )}

      <StyledInput
        aria-label={label}
        enterKeyHint={enterKeyHint}
        ref={innerRef}
        autoFocus={autofocus}
        name={name}
        value={type === "date" ? inputValue : value}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={handleValueChange}
        onPaste={onPaste}
        disabled={disabled}
        data-lpignore="true"
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onTouchEnd={onTouchEnd}
        type={getInputType()}
        styleDisabled={disabled}
        placeholder={placeholder}
        id={`${name}-${inputId}`}
        defaultValue={defaultValue}
        error={Boolean(errorMessage)}
        data-testid="text-field-component"
        maxLength={maxLength}
        style={{
          backgroundColor: backgroudColor,
          padding: inputPadding,
          border: inputBorder,
        }}
      />

      {isPassword && (
        <StyledIconButton
          type="button"
          data-testid="show-and-hide-password-button"
          onClick={handleOnClickShowHidePassword}
        >
          {!showPassword ? (
            <Eye />
          ) : (
            <HideEye
              style={{ marginTop: 1 }}
              data-testid="hide-password-icon"
            />
          )}
        </StyledIconButton>
      )}

      {auxiliarButtonLabel && onClickAuxiliarButton && (
        <StyledAuxiliarButton type="button" onClick={onClickAuxiliarButton}>
          {auxiliarButtonLabel}
        </StyledAuxiliarButton>
      )}

      {errorMessage && (
        <AlertText type={isMobile ? "mobileError" : "error"}>
          {errorMessage}
        </AlertText>
      )}

      {warningMessage && !errorMessage && (
        <AlertText type={isMobile ? "mobileWarning" : "warning"}>
          {warningMessage}
        </AlertText>
      )}
    </TextFieldContainer>
  );
}
