import { Modal } from "antd";
import { useState } from "react";

import { ArrowForward } from "../../../../../../../../assets/icons/ArrowForward";
import { Button } from "../../../../../../../../components/Button";
import {
  ButtonContainer,
  CancelButton,
  confirmModalCss,
  ContentContainer,
  ModalSubtitle,
} from "../../CustomOptionsBar.styles";
import {
  ChangeContainer,
  Content,
  NoteContainer,
  NoteSubtitle,
  NoteTitle,
  PropTitle,
} from "./ConfirmBaselineChangeModal.styles";

type ConfirmBaselineChangeModalProps = {
  unit?: string;
  oldBaseline: string;
  newBaseline: string;
  onClose(confirm: boolean, unit?: string): void;
};

export function ConfirmBaselineChangeModal({
  unit,
  oldBaseline,
  newBaseline,
  onClose,
}: ConfirmBaselineChangeModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [unitToSave] = useState(unit);

  const handleConfirmUpdate = () => {
    setIsLoading(true);

    onClose(true, unitToSave);
  };

  const handleCancel = () => {
    onClose(false);
  };

  return (
    <Modal
      title="Confirm changes"
      open
      zIndex={3001}
      footer={null}
      width={600}
      height="400px !important"
      onCancel={handleCancel}
      className={confirmModalCss()}
      centered
      destroyOnClose
    >
      <ContentContainer>
        <ModalSubtitle>
          Please confirm the changes to the FVC baseline below.
        </ModalSubtitle>

        <ChangeContainer>
          <PropTitle>Baseline</PropTitle>
          <Content>
            <p>{oldBaseline}</p>
            <div
              style={{
                margin: "0 8px",
                alignItems: "center",
                display: "flex",
              }}
            >
              <ArrowForward />
            </div>
            <p>{newBaseline}</p>
          </Content>
        </ChangeContainer>

        <NoteContainer>
          <NoteTitle>Please note</NoteTitle>
          <NoteSubtitle>
            This new value will be applied to workflows that use baselines.
            <b style={{ fontWeight: 500 }}>It will not affect Alerts.</b>
          </NoteSubtitle>
        </NoteContainer>

        <ButtonContainer>
          <Button
            label="Confirm"
            type="button"
            onClick={handleConfirmUpdate}
            isLoading={isLoading}
          />
          <CancelButton isModal onClick={handleCancel}>
            Cancel
          </CancelButton>
        </ButtonContainer>
      </ContentContainer>
    </Modal>
  );
}
