import React from "react";
import { v4 as uuidv4 } from "uuid";

import { SkeletonLoading } from "../Skeleton";
import {
  AlertText,
  StyledTextArea,
  TextAreaContainer,
  StyledTextAreaContainer,
  skeletonContainerStyles,
} from "./TextArea.styles";

export type TextAreaProps = {
  name?: string;
  className?: string;
  maxLength?: number;
  disabled?: boolean;
  isLoading?: boolean;
  placeholder?: string;
  errorMessage?: string;
  value?: string | number;
  warningMessage?: string;
  defaultValue?: number | string;
  backgroudColor?: string;
  rows?: number;
  cols?: number;
  autofocus?: boolean;
  readonly?: boolean;
  required?: boolean;
  isMobile?: boolean;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  onKeyUp?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  onTouchEnd?: (event: React.TouchEvent<HTMLTextAreaElement>) => void;
};

export function TextArea({
  name,
  value,
  maxLength,
  disabled,
  isLoading,
  className,
  placeholder,
  defaultValue,
  errorMessage,
  warningMessage,
  backgroudColor,
  rows,
  cols,
  isMobile,
  autofocus,
  readonly,
  required,
  onChange,
  onBlur,
  onKeyUp,
  onTouchEnd,
}: TextAreaProps) {
  const inputId = uuidv4();

  if (isLoading) {
    return (
      <div className={skeletonContainerStyles()}>
        <SkeletonLoading height="250px" />
      </div>
    );
  }

  return (
    <TextAreaContainer className={className}>
      <StyledTextAreaContainer>
        <StyledTextArea
          name={name}
          value={value}
          disabled={disabled}
          data-lpignore="true"
          styleDisabled={disabled}
          placeholder={placeholder}
          id={`${name}-${inputId}`}
          defaultValue={defaultValue}
          error={Boolean(errorMessage)}
          data-testid="text-area-component"
          maxLength={maxLength}
          style={{ backgroundColor: backgroudColor }}
          rows={rows}
          cols={cols}
          autoFocus={autofocus}
          readOnly={readonly}
          required={required}
          onChange={onChange}
          onBlur={onBlur}
          onKeyUp={onKeyUp}
          onTouchEnd={onTouchEnd}
        />
      </StyledTextAreaContainer>

      {errorMessage && (
        <AlertText type={isMobile ? "mobileError" : "error"}>
          {errorMessage}
        </AlertText>
      )}

      {warningMessage && !errorMessage && (
        <AlertText type={isMobile ? "mobileWarning" : "warning"}>
          {warningMessage}
        </AlertText>
      )}
    </TextAreaContainer>
  );
}
