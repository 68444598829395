import { useState } from "react";

import { Button } from "../../../components/Button";
import { SetPasswordLayout } from "../../../layout/components/SetPasswordLayout";
import { authService } from "../../../services/authService";
import {
  buttonCss,
  InfoMessage,
  MainContainer,
  TitleMessage,
} from "./InvalidPassLinkPage.styles";

interface IInvalidPassLinkPageProps {
  email: string | null;
  isPatient: boolean;
}

export function InvalidPassLinkPage({
  email,
  isPatient,
}: IInvalidPassLinkPageProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessMessage, setShowSucessMessage] = useState(false);

  const handleRequestNewLink = async () => {
    setIsLoading(true);
    if (email) {
      try {
        if (isPatient) {
          await authService.sendPatientForgotPasswordRequest({ email });
        } else {
          await authService.sendStaffForgotPasswordRequest({ email });
        }
      } finally {
        setIsLoading(false);
        setShowSucessMessage(true);
      }
    }
  };

  return (
    <SetPasswordLayout>
      <MainContainer>
        {!showSuccessMessage ? (
          <>
            <TitleMessage>Set password link invalid</TitleMessage>
            <InfoMessage>
              The password reset link was invalid, possibly because it has
              already been used or it is expired.
            </InfoMessage>
            <InfoMessage>Please request a password reset here.</InfoMessage>
            <Button
              isLoading={isLoading}
              className={buttonCss()}
              label="Request new link"
              onClick={handleRequestNewLink}
              type="button"
            />
          </>
        ) : (
          <>
            <TitleMessage>Check your email</TitleMessage>
            <InfoMessage>
              A password reset email has been sent to {email}.
            </InfoMessage>
            <InfoMessage>
              Please follow the instructions sent in the email.
            </InfoMessage>
          </>
        )}
      </MainContainer>
    </SetPasswordLayout>
  );
}
