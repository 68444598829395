import { styled } from "../../../../../../styles";

export const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
  height: "70vh",
  width: "75vw",
  gap: "12px",
});

export const DeleteTriggerContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  height: "auto",
  width: "50vw",
  gap: "12px",
});

export const ContentContainer = styled("div", {
  height: "auto",
  width: "auto",
  margin: "0 22px 0 22px",
  padding: "28px 28px 32px 28px",
  backgroundColor: "$gray-20",
  borderRadius: "8px",

  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
});

export const Field = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  paddingRight: "60px",

  p: {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "22px",
    color: "$charcoal",
    marginTop: "4px",
  },
});

export const FieldContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  flexWrap: "wrap",
  marginBottom: "32px",
});

export const SubmitContainer = styled("div", {
  display: "flex",
  gap: "40px",
  padding: "24px 22px 24px 22px",
  alignItems: "center",
});

export const Row = styled("div", {
  display: "flex",
  justifyContent: "flex-start",
  lineHeight: "30px",
});

export const ComponentTitle = styled("div", {
  display: "flex",
  alignSelf: "flex-start",
  width: "32%",
  paddingLeft: "10px",
  color: "#757575",
  fontSize: "14px",
  fontWeight: "500",
});

export const Component = styled("div", {
  display: "flex",
  alignSelf: "flex-start",
  width: "68%",
  paddingLeft: "10px",
  color: "#4B4B4B",
  fontSize: "14px",
  fontWeight: "400",
});

export const Title = styled("h1", {
  fontWeight: 500,
  fontSize: "$text-xl",
  lineHeight: "22px",
  padding: "24px 22px 0 22px",
  color: "$black",
});

export const CancelButton = styled("button", {
  height: "$13",
  padding: "0 $13",
  marginLeft: "-38px",

  borderRadius: "20px",
  border: "2px solid $white",

  backgroundColor: "$white",

  gap: "$4",

  display: "flex",
  alignItems: "center",

  color: "$black",
  fontWeight: 600,
  lineHeight: "22px",
  fontSize: "$text-lg",
  fontFamily: "$openSans",

  cursor: "pointer",

  "&:focus": {
    outline: "none",
    border: "2px solid $primary-30",
  },

  "@sm": {
    width: "80px",
    height: "26px",
    fontSize: "12px",
    marginLeft: "40px",
    paddingLeft: "19px",
  },
});
