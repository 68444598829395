import { createColumn } from "../../../../../components/Table";

export const surveyTableColumns = [
  createColumn({
    keyName: "name",
    header: () => "Name",
    cell: (info) => info.renderValue(),
    size: 300,
  }),
  createColumn({
    keyName: "createdBy",
    header: () => "Created by",
    cell: (info) => info.renderValue(),
    size: 80,
  }),

  createColumn({
    keyName: "assignToHospital",
    header: () => "Assign Hospital",
    cell: (info) => info.renderValue(),
    size: 70,
  }),

  createColumn({
    keyName: "edit",
    header: () => "Edit",
    cell: (info) => info.renderValue(),
    size: 11,
  }),
];

export const mobileAccordionListColumns = [
  { key: "Hospital ID", value: "hospitalId" },
  { key: "Survey ID", value: "id" },
];
