import { styled, css } from "../../../../../../styles";

export const ContentContainer = styled("div", {
  width: "100%",
  padding: "46px 68px 50px 68px",

  borderRadius: "8px",
  backgroundColor: "rgba(248, 248, 248, 0.80)",

  display: "flex",
  flexDirection: "column",
  gap: "28px",
});

export const ContentRow = styled("div", {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  position: "relative",

  p: {
    width: "140px",
    color: "$charcoal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
  },

  variants: {
    isSmallInput: {
      true: {
        justifyContent: "space-between",
        width: "690px",
      },
    },
  },
});

export const ContentSubRow = styled("div", {
  height: "41px",

  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",

  position: "relative",

  "&:last-child": {
    marginLeft: "8px",

    p: {
      width: "59px",
    },
  },

  variants: {
    isError: {
      true: {
        ".ant-select-selector": {
          borderColor: "$error-lighter !important",

          "&:hover": {
            borderColor: "$error-lighter !important",
          },
        },
      },
    },
  },
});

export const CardImageSelector = styled("div", {
  minWidth: "208px",
  width: "fit-content",
  height: "40px",
  borderRadius: "12px",
  backgroundColor: "$gray-40",
  padding: "0px 12px",

  color: "$black",
  fontSize: "14px",
  fontFamily: "$openSans",
  fontWeight: "500",
  lineHeight: "22px",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  cursor: "pointer",

  "&:hover": {
    backgroundColor: "$gray-50",
  },

  "&:focus": {
    outline: "none",
    border: "2px solid $primary-30 !important",
  },
});

export const RicheTextEditorContainer = styled("div", {
  position: "relative",
  marginTop: "48px",

  ".ql-container": {
    backgroundColor: "$white !important",

    ".ql-editor.ql-blank::before": {
      color: "$gray-70",
    },

    ".ql-editor img": {
      width: "300px !important",
    },
  },

  variants: {
    isError: {
      true: {
        ".ql-container": {
          borderColor: "$error-lighter !important",
        },
      },
    },
  },
});

export const MainContainer = styled("div", {
  height: "100%",
  position: "relative",
});

export const SelectedImageContainer = styled("div", {
  color: "rgba(33, 33, 33, 0.70)",
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "22px",
  textAlign: "center",
  width: "fit-conten%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  svg: {
    marginTop: "6px",
  },

  p: {
    width: "fit-content",
  },
});

export const ErrorMessage = styled("p", {
  zIndex: 3002,
  position: "absolute",
  left: "147px",
  bottom: "-18px",

  fontSize: "12px !important",
  fontWeight: "600 !important",

  color: "$error-light !important",

  width: "max-content !important",

  variants: {
    isArticleText: {
      true: {
        left: "4px !important",
        bottom: "-34px !important",
      },
    },
  },
});

export const AddLinkText = styled("div", {
  color: "$primary",
  fontSize: "14px",
  fontWeight: "500",
  textDecoration: "underline",

  position: "absolute",
  top: 35,
  right: 9,

  cursor: "pointer",

  "&:hover": {
    color: "$primary-70",
  },
});

export const URLPreviewContainer = styled("div", {
  width: "fit-content",
  maxWidth: "500px",
  height: "40px",

  padding: "9px 16px 9px 20px",
  margin: "77px 0 0 16px",

  backgroundColor: "$gray-40",
  borderRadius: "12px",

  position: "relative",
  display: "flex",
  alignItems: "center",

  color: "rgba(33, 33, 33, 0.70)",
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "22px",

  cursor: "pointer",

  "&:hover": {
    backgroundColor: "$gray-50",
  },
});

export const CloseContainer = styled("div", {
  display: "contents",
  alignItems: "center",
  position: "absolute",
  right: 16,

  svg: {
    cursor: "pointer",
    width: "22px !important",
    height: "22px !important",
  },
});

export const URLLink = styled("p", {
  width: "-webkit-fill-available",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
});

// CSS

export const smallInputCss = css({
  width: "208px",
});

export const inputCss = css({
  width: "550px",

  input: {
    border: "2px solid $gray-50",
    borderRadius: "6px",
    paddingLeft: "11px",
    paddingRight: "11px",

    "&:hover": {
      border: "2px solid $primary-30",
    },

    "&:focus": {
      border: "2px solid $primary-30",
    },
  },
});

export const selectCss = css({
  width: "300px",
  height: "41px",

  ".ant-select-selector": {
    height: "42px !important",
    alignItems: "center",
    border: "solid 2px $gray-50 !important",
    boxShadow: "0 0 0 1px $gray-50 !important",
    fontFamily: "$openSans !important",

    "&:focus-within": {
      borderColor: "$primary-30 !important",
    },

    "&:hover": {
      borderColor: "$primary-30 !important",
    },

    ".ant-select-selection-overflow-item": {
      span: {
        "&.ant-select-selection-item": {
          borderRadius: "14px !important",
        },
      },
    },

    ".ant-select-selection-search-input": {
      height: "100% !important",
    },
  },
});
