import { styled } from "../../../../../../../styles";
// Components

export const FormAnswerField = styled("div", {
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  marginBottom: "20px",
  marginTop: "30px",
});
