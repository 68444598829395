import { styled } from "../../styles";

export const OptionButtonContainer = styled("button", {
  fontFamily: "$openSans",
  fontSize: "$text-sm",
  fontWeight: 600,

  lineHeight: "16px",

  color: "$gray-80",
  backgroundColor: "$white",

  display: "flex",
  alignItems: "center",

  height: 22,
  border: "none",

  padding: "$2 $4",
  gap: "$4",

  svg: {
    width: 12,
    marginLeft: "5px",
  },

  "&:first-child": {
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px",
  },

  "&:last-child": {
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "8px",
  },

  "&:first-child:last-child": {
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "8px",
  },

  "&:hover": {
    cursor: "pointer",
    backgroundColor: "$gray-30",
    color: "$gray-90",

    "&:last-child": {
      borderTopRightRadius: "8px",
      borderBottomRightRadius: "8px",
    },
  },

  variants: {
    selected: {
      true: {
        backgroundColor: "$gray-30",
        color: "$gray-90 !important",

        "&:last-child": {
          borderTopRightRadius: "8px",
          borderBottomRightRadius: "8px",
        },
      },
    },

    iconOnLeft: {
      true: {
        flexDirection: "row",
      },
      false: {
        flexDirection: "row-reverse",
      },
    },
  },
});

export const OptionsBarContainer = styled("div", {
  display: "flex",
  columnGap: "1px",

  width: "fit-content",

  borderRadius: "8px",
  border: "1px solid $gray-50",

  backgroundColor: "$gray-50",
});
