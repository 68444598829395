import { createColumn } from "../../../../components/Table";

export const appointmentsTableColumns = [
  createColumn({
    keyName: "title",
    header: () => "Title ( Patient / HCP )",
    cell: (info) => info.renderValue(),
    size: 300,
  }),
  createColumn({
    keyName: "date",
    header: () => "Appointment time",
    cell: (info) => info.renderValue(),
    size: 200,
  }),
  createColumn({
    keyName: "duration",
    header: () => "Duration",
    cell: (info) => info.renderValue(),
    size: 100,
  }),
  createColumn({
    keyName: "appointmentStatus",
    header: () => "",
    cell: (info) => info.renderValue(),
  }),
  createColumn({
    keyName: "deleteAppointment",
    header: () => "",
    cell: (info) => info.renderValue(),
    size: 30,
  }),
];
