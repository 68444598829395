export const colors = {
  // Primary Colors
  "primary-100": "#0E0B19",
  "primary-90": "#44396C",
  "primary-80": "#4D4279",
  "primary-70": "#5D508A",
  "primary-60": "#615492",

  primary: "#7562A8",

  "primary-40": "#9182C6",
  "primary-30": "#BDB4DD",
  "primary-20": "#E9E6F4",
  "primary-10": "#FBFBFD",

  // Secondary Colors
  "secondary-lightest": "#E6F3F3",
  "secondary-lighter": "#B5DADB",
  "secondary-light": "#84C1C3",

  secondary: "#54A6AA",

  "secondary-dark": "#3B787B",
  "secondary-darker": "#24484A",
  "secondary-darkest": "#0C1819",

  // Tertiary Colors
  "tertiary-lightest": "#F2E8EF",
  "tertiary-lighter": "#D8B9CE",
  "tertiary-light": "#BE8AAD",

  tertiary: "#A86392",

  "tertiary-dark": "#754164",
  "tertiary-darker": "#46273C",
  "tertiary-darkest": "#170D14",

  // Success Colors
  "success-lightest": "#CFEEDE",
  "success-lighter": "#A1DEBC",
  "success-light": "#58C488",

  success: "#0AAB51",

  "success-dark": "#07773B",
  "success-darker": "#064522",
  "success-darkest": "#032210",

  // Warning Colors
  "warning-lightest": "#FFF2E6",
  "warning-lighter": "#FFE8D4",
  "warning-light": "#FFDDBF",

  warning: "#EB9144",

  "warning-dark": "#F1801F",
  "warning-darker": "#F3811F",
  "warning-darkest": "#DF7A23",

  // Error Colors
  "error-lightest": "#FAD1D9",
  "error-lighter": "#F4A1B3",
  "error-light": "#EB5A7B",

  error: "#E11D3F",

  "error-dark": "#9E112C",
  "error-darker": "#5A0719",
  "error-darkest": "#2E030B",

  // Caution Colors
  "caution-lightest": "#FEF2D7",
  "caution-lighter": "#FDE5AC",
  "caution-light": "#FBD06F",

  caution: "#FBBC21",

  "caution-dark": "#B38419",
  "caution-darker": "#654D0D",
  "caution-darkest": "#33250A",

  // Brown Colors
  "brown-lightest": "#FFF0DA",
  "brown-lighter": "#F8E3C3",
  "brown-light": "#F2D7AE",

  brown: "#BB6B26",

  "brown-dark": "#9A602B",
  "brown-darker": "#844D1B",
  "brown-darkest": "#835328",

  // Gray Colors
  "gray-10": "#FEFEFE",
  "gray-20": "#FAFAFA",
  "gray-30": "#F4F4F4",
  "gray-40": "#ECECEC",
  "gray-50": "#E6E6E6",
  "gray-60": "#D6D6D6",
  "gray-70": "#B5B5B6",
  "gray-80": "#939393",
  "gray-90": "#727272",
  "gray-100": "#666666",

  // Red Colors
  "red-dark": "#800F25",

  red: "#E698A7",

  "red-light": "#F3B6C2",
  "red-lightest": "#FFF6F8",

  black: "#212121",
  charcoal: "#4B4B4B",

  white: "#FFFFFF",
};
