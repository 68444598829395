import { styled } from "../../../../../styles";

export const Container = styled("div", {
  width: "100%",
  height: "100%",

  display: "flex",
  flexDirection: "column",

  padding: "24px 10px 30px 52px",
});

export const Title = styled("p", {
  fontSize: "16px",
  fontWeight: "600",
  fontFamily: "$openSans",
  lineHeight: "22px",
  color: "$black",
});

export const Subtitle = styled("p", {
  fontSize: "14px",
  fontWeight: "500",
  fontFamily: "$openSans",
  lineHeight: "22px",
  color: "$gray-80",
  maxWidth: "334px",
  margin: "15px 0",

  variants: {
    larger: {
      true: {
        maxWidth: "405px",
      },
    },
  },
});

export const PropsChangesContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  maxHeight: "450px",
  overflowY: "auto",
});

export const PropContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  padding: "12px 0",

  p: {
    fontSize: "12px",
    fontFamily: "$openSans",
    fontWeight: "400",
    lineHeight: "16px",
    color: "$gray-90",
  },
});

export const PropTitle = styled("p", {
  fontSize: "12px",
  fontFamily: "$openSans",
  fontWeight: "600 !important",
  lineHeight: "16px",
  color: "$gray-90",
});

export const Content = styled("div", {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  marginTop: "6px",
});

export const SubmitContainer = styled("div", {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  marginTop: "36px",
});

export const SendEnrolmentEmailContainer = styled("div", {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",

  marginTop: "36px",
});

export const SendEnrolmentEmailText = styled("div", {
  font: "$openSans",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "22px",

  color: "$gray-90",

  variants: {
    highlighted: {
      true: {
        marginLeft: "8px",
        fontWeight: "600",
      },
    },
  },
});
