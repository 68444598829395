import { styled } from "../../styles";

export const Radio = styled("label", {
  content: "",
  height: "13px",
  width: "13px",

  "input[type=radio]": {
    accentColor: "#7562A8",
  },

  input: {
    "&:hover": {
      cursor: "pointer",
    },
  },
});
