import { styled } from "../../../../../../styles";

export const DevicesTableContainer = styled("div", {
  width: "100%",
  height: "420px",
  fontSize: "14px",
  minHeight: "420px",
  overflow: "auto",
});

export const DevicesTableInnerContainer = styled("div", {
  width: "100%",
  height: "420px",
  fontSize: "14px",
  minHeight: "420px",
  overflow: "auto",

  "@media screen and (max-width: 1100px)": {
    width: "1000px",
  },
});

export const NoContentMessage = styled("p", {
  marginTop: "10px !important",

  fontFamily: "$openSans",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "21px",
});
