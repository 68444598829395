import * as TogglePrimitive from "@radix-ui/react-switch";

import { styled } from "../../styles";

export const StyledToggleRoot = styled(TogglePrimitive.Root, {
  all: "unset",

  width: "28px",
  height: "12px",

  backgroundColor: "$gray-70",

  borderRadius: "6px",
  position: "relative",

  "-webkit-tap-highlight-color": "rgba(0, 0, 0, 0)",

  "&[data-state='checked']": {
    backgroundColor: "$primary-30",
  },
});

export const StyledToggleThumb = styled(TogglePrimitive.Thumb, {
  display: "block",

  width: "15px",
  height: "15px",

  backgroundColor: "white",

  borderRadius: "$round",

  transition: "transform 100ms",
  transform: "translateY(-1.5px)",

  willChange: "transform",

  border: "0.2px solid #E8E8E8",
  boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.16)",

  "&[data-state='checked']": {
    transform: "translateX(13px) translateY(-1.5px)",
    border: "0.2px solid $primary",

    backgroundColor: "$primary",

    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.12)",
  },
});
