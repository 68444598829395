import { styled } from "../../../../styles";

export const ImageContainer = styled("div", {
  overflowY: "overlay",
  maxHeight: "100%",

  zIndex: 1000,

  borderRadius: "8px",

  width: "100%",
  minHeight: "426px",

  border: "1px solid #ECECEC",
  backgroundColor: "$white",
  color: "$black",

  boxSizing: "border-box",

  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  rowGap: "10px",

  p: {
    width: "418px",
    marginTop: "30px",
    textAlign: "center",

    button: {
      background: "none",
      color: "#7562A8",
      border: "none",
      padding: 0,
      font: "inherit",
      cursor: "pointer",
      outline: "inherit",
      fontWeight: "600",
    },
  },
});

export const ManageMessagesTitle = styled("div", {
  color: "$charcoal",
  fontWeight: "600",
  fontSize: "12px",
  lineHeight: "17px",
  cursor: "pointer",
  position: "absolute",
  top: "8px",
  right: "10px",
  borderRadius: "6px",
  padding: "0 2px",

  "&:hover": {
    color: "$primary",
  },
});

export const TooltipContainer = styled("div", {
  display: "flex",
  justifyContent: "left",
  alignItems: "left",
});

export const tooltipStyles = {
  borderRadius: "4px !important",
  fontSize: "12px",
  padding: "12px 16px",

  display: "flex",

  backgroundColor: "$charcoal",

  h4: {
    color: "$white",
    fontFamily: "$openSans",
    fontSize: "$text-md",
    lineHeight: "16px",
    marginBottom: "4px",

    fontWeight: 500,

    "&:last-child": {
      marginBottom: "8px",
    },
  },

  p: {
    color: "$gray-60",

    fontWeight: 500,
    fontFamily: "$openSans",
    fontSize: "$text-md",

    lineHeight: "16px",
  },
};

export const MessagesTableContainer = styled("div", {
  width: "100%",
  height: "420px",
  fontSize: "14px",
  minHeight: "420px",
  overflow: "auto",
});

export const MessagesTableInnerContainer = styled("div", {
  width: "100%",
  height: "420px",
  fontSize: "14px",
  minHeight: "420px",
  overflow: "auto",

  "@media screen and (max-width: 1100px)": {
    width: "1000px",
  },
});

export const NoContentMessage = styled("p", {
  marginTop: "10px !important",

  fontFamily: "$openSans",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "21px",
});
