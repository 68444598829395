import { css } from "../../styles";

export const DefaultCss = css({
  ".ant-progress-inner": {
    height: "6px !important",

    ".ant-progress-bg": {
      height: "6px !important",
    },
  },

  ".ant-progress-text": {
    marginInlineStart: "12px !important",
    fontSize: "16px !important",

    span: {
      color: "$primary-30",
      fontSize: "16px !important",
    },
  },

  ".ant-progress-outer": {
    marginInlineEnd: "calc(-2em - 16px ) !important",
    paddingInlineEnd: "calc(2em + 16px ) !important",
  },
});
