import { styled } from "../../../styles";

export const Title = styled("h1", {
  fontFamily: "$nunito",

  fontWeight: "700",
  lineHeight: "34px",
  fontSize: "$text-xxl",

  color: "$gray-100",

  "@sm": {
    lineHeight: "28px",
  },
});

export const Container = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",

  backgroundColor: "$white",
  borderBottom: "1px solid $gray-50",

  padding: "16px 44px",
  height: "60px",
});
