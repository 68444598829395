import { styled } from "../../../../styles";

export const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
  height: "auto",
  width: "600px",
  gap: "12px",
});

export const ResolveTriggerContainer = styled("div", {
  height: "100%",
  backgroundColor: "#F8F8F8",
  padding: "24px 20px",
  margin: "0 20px 0 20px",
  borderRadius: "8px",

  display: "flex",
  flexDirection: "column",
  gap: "12px",
});

export const SubmitContainer = styled("div", {
  display: "flex",
  gap: "40px",
  padding: "24px 36px 24px 36px",
  alignItems: "center",

  variants: {
    noPadding: {
      true: {
        padding: "24px 12px 0px 12px",
      },
    },
  },
});

export const Row = styled("div", {
  display: "flex",
  justifyContent: "flex-start",
  lineHeight: "30px",
});

export const ComponentTitle = styled("div", {
  display: "flex",
  alignSelf: "flex-start",
  width: "32%",
  paddingLeft: "10px",
  color: "#757575",
  fontSize: "14px",
  fontWeight: "500",
});

export const Component = styled("div", {
  display: "flex",
  alignSelf: "flex-start",
  width: "68%",
  paddingLeft: "10px",
  color: "#4B4B4B",
  fontSize: "14px",
  fontWeight: "400",

  textArea: {
    width: "300px",
  },
});

export const Title = styled("h1", {
  fontWeight: 500,
  fontSize: "$text-xl",
  lineHeight: "22px",
  padding: "24px 36px 0 36px",
  color: "$black",
});
