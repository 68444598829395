import React from "react";

import { SmallX } from "../../assets/icons/SmallX";
import {
  CloseButton,
  Container,
  DisabledOverlay,
  StyledTag,
} from "./Tag.styles";

type TagProps = {
  children: React.ReactNode;
  variant?: "error" | "tertiary" | "success" | "warning" | "info";
  clickable?: boolean;
  disabled?: boolean;
  onCloseClick?: () => void;
  closeButtonLocation?: "left" | "right";
  onClick?: () => void;
};

export function Tag({
  onClick,
  variant,
  children,
  disabled,
  clickable,
  onCloseClick,
  closeButtonLocation,
}: TagProps) {
  const isOnClickEnabled = !disabled && clickable;

  return (
    <Container
      onClick={isOnClickEnabled ? onClick : undefined}
      role={clickable ? "button" : undefined}
    >
      {disabled && <DisabledOverlay />}

      <StyledTag
        tabIndex={0}
        variant={variant}
        clickable={clickable}
        closeButtonLocation={closeButtonLocation}
      >
        {onCloseClick && closeButtonLocation && (
          <CloseButton variant={variant} onClick={onCloseClick}>
            <SmallX />
          </CloseButton>
        )}

        <span>{children}</span>
      </StyledTag>
    </Container>
  );
}
