import { styled } from "../../styles";

export const SupportButtoncontainer = styled("div", {
  alignSelf: "center",

  height: "38px",
  width: "max-content",

  backgroundColor: "rgba(242, 242, 242, 0.60);",

  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  padding: "0 9px 0 9px",
  borderRadius: "20px",

  cursor: "pointer",

  border: "2px solid transparent",

  "&:hover": {
    backgroundColor: "$gray-40",
    border: "2px solid #E6F3F3",
  },
});
