import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

import {
  MeasurementTypes,
  SelectMeasurementPayloadType,
} from "../../../../@types/Measurements";
import { Notification } from "../../../../@types/Notification";
import {
  colapsedSectionsPropertyNames,
  ICollapsedSection,
  IPatientInfo,
} from "../../../../@types/Patient";
import { ChevronDown } from "../../../../assets/icons/ChevronDown";
import { ChevronUp } from "../../../../assets/icons/ChevronUp";
import { HorizontalDots } from "../../../../assets/icons/HorizontalDots";
import { CustomIconButton } from "../../../../components/CustomIconButton";
import { ToastNotification } from "../../../../components/ToastNotification";
import { Toggle } from "../../../../components/Toggle";
import { useMeasurementTypeConfig } from "../../../../hooks/queries/measurement-configs";
import useIsMobile from "../../../../hooks/useIsMobile";
import { useModal } from "../../../../hooks/useModal";
import { useUserPreferences } from "../../../../hooks/useUserPreferences";
import { userService } from "../../../../services/userService";
import { mixpanelActions } from "../../../../utils/mixpanel";
import { ChartsContainer } from "../../PatientPage.styles";
import { AddMeasurementModal } from "../AddMeasurementModal";
import { MeasurementSection } from "../MeasurementModal/components/MeasurementSection";
import { ModalSkeleton } from "../MeasurementModal/components/ModalSkeleton";
import { SpirometrySection } from "../MeasurementModal/components/SpirometrySection";
import {
  AddMeasurementButton,
  DotsContainer,
  NewDataContainer,
  OptionsContainer,
  PinnedSectionTitle,
  PinnedTile,
  PinnedToogleContainer,
  TitleContainer,
} from "./PinnedSection.styles";

type PinnedSectionProps = {
  patient: IPatientInfo;
  collapsedSection: ICollapsedSection;
  updateColapsedSections: (section: colapsedSectionsPropertyNames) => void;
  measurementType: MeasurementTypes;
};

export function PinnedSection({
  patient,
  collapsedSection,
  updateColapsedSections,
  measurementType,
}: PinnedSectionProps) {
  const { isMobile } = useIsMobile();
  const [searchParams] = useSearchParams();
  const { openModal, closeModal } = useModal();
  const { portalPreferences, changePortalPreferences } = useUserPreferences();
  const { config, isFetching } = useMeasurementTypeConfig({
    measurementType,
  });

  const [notification, setNotification] = useState<Notification>({
    show: false,
    message: "",
    type: "error",
    width: undefined,
    rightMargin: "25%",
  });

  const [
    selectedSpirometryMeasurementValue,
    setSelectedSpirometryMeasurementValue,
  ] = useState<SelectMeasurementPayloadType | null>(null);
  const [hideSpirometrySection, setHideSpirometrySection] = useState(false);

  const [isPinned, setIsPinned] = useState(true);
  const [areOptionsOpen, setAreOptionsOpen] = useState(false);
  const [showNewData, setShowNewData] = useState<boolean>(false);

  const optionsWrapperRef = useRef<any>(null);
  const optionsButtonRef = useRef<any>(null);

  useEffect(() => {
    const spirometryTestId = searchParams.get("spirometryTestId");
    if (spirometryTestId) {
      setSelectedSpirometryMeasurementValue({
        unformattedTime: "",
        time: "",
        fvc: 0,
        spirometryTestIds: [parseInt(spirometryTestId, 10)],
      });
    }
  }, [searchParams]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        areOptionsOpen &&
        optionsWrapperRef.current &&
        !optionsWrapperRef.current.contains(event.target) &&
        !optionsButtonRef.current.contains(event.target)
      ) {
        setAreOptionsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [areOptionsOpen]);

  const handlePinnedGraphChange = async (isPinnedGraph: boolean) => {
    setIsPinned(isPinnedGraph);

    setTimeout(() => {
      setAreOptionsOpen(false);
    }, 200);

    await userService.updatePinnedMeasurementType();

    const portalPrefenrecesToChange = { ...portalPreferences };
    portalPrefenrecesToChange.pinnedMeasurementType = undefined;
    changePortalPreferences(portalPrefenrecesToChange);
  };

  const handleCollapseSection = () => {
    updateColapsedSections("pinnedSection");
    if (collapsedSection?.value === true) {
      setShowNewData(false);
    }
  };

  const checkNewData = (mostRecentDate: dayjs.Dayjs) => {
    if (showNewData === false) {
      const collapsedDate = dayjs(collapsedSection?.collapsedDate);
      if (mostRecentDate > collapsedDate) {
        setShowNewData(true);
      }
    }
  };

  const handleOnModalClose = (notification?: Notification) => {
    if (notification !== undefined) {
      setNotification(notification);

      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
    closeModal();
  };

  const handleAddMeasurement = () => {
    mixpanelActions.track(`User action: Add Measurement Button`);
    openModal(
      <AddMeasurementModal
        section={config?.category ?? "measurement"}
        patientId={patient?.id.toString()}
        onClose={handleOnModalClose}
        measurementType={measurementType}
      />,
      {
        width: isMobile ? "98vw" : "620px",
        height: isMobile ? undefined : "auto",
        borderRadius: isMobile ? "8px" : undefined,
      }
    );
  };

  return (
    <>
      {!isMobile && (
        <TitleContainer>
          <PinnedSectionTitle>Pinned</PinnedSectionTitle>
          {showNewData && <NewDataContainer>(new data)</NewDataContainer>}
          <CustomIconButton
            style={{ marginLeft: "2px" }}
            onClick={handleCollapseSection}
          >
            {collapsedSection?.value ? <ChevronUp /> : <ChevronDown />}
          </CustomIconButton>
        </TitleContainer>
      )}

      <ChartsContainer
        style={{
          height: collapsedSection?.value ? "0" : "",
          overflow: collapsedSection?.value ? "hidden" : "",
        }}
      >
        <AddMeasurementButton onClick={handleAddMeasurement}>
          Add measurement
        </AddMeasurementButton>

        <PinnedTile>
          {isFetching ? (
            <ModalSkeleton isPinnedGraph />
          ) : (
            <>
              <MeasurementSection
                isPinnedGraph
                isSpirometry={config?.category === "spirometry"}
                cardTitle={`${config?.title} ${
                  config?.unit ? `(${config?.unit})` : ""
                }`}
                key={`${config?.title}_${config?.type}_${patient.id}`}
                minValue={config?.min}
                maxValue={config?.max}
                measurementType={measurementType}
                unit={config?.unit}
                patientId={patient.id.toString()}
                onSelectChartValue={(selectedChartValue) => {
                  setSelectedSpirometryMeasurementValue(selectedChartValue);
                }}
                onSelectedTableView={(showMeasurementsTable) => {
                  setHideSpirometrySection(showMeasurementsTable);
                }}
                checkNewData={checkNewData}
              />

              {selectedSpirometryMeasurementValue?.spirometryTestIds &&
                !hideSpirometrySection && (
                  <SpirometrySection
                    selectedMeasurement={selectedSpirometryMeasurementValue}
                    isPinnedGraph
                  />
                )}

              <OptionsContainer>
                <DotsContainer
                  onClick={() => setAreOptionsOpen((prevState) => !prevState)}
                  ref={optionsButtonRef}
                >
                  <HorizontalDots />
                </DotsContainer>

                {areOptionsOpen ? (
                  <PinnedToogleContainer ref={optionsWrapperRef}>
                    <span>Pin graph to patient page</span>
                    <Toggle
                      checked={isPinned}
                      onCheckedChange={handlePinnedGraphChange}
                    />
                  </PinnedToogleContainer>
                ) : null}
              </OptionsContainer>
            </>
          )}
        </PinnedTile>
      </ChartsContainer>

      {notification.show ? (
        <ToastNotification
          message={notification.message}
          type={notification.type}
          width={notification.width}
          rightMargin="25%"
        />
      ) : null}
    </>
  );
}
