export function maskDDMMYYYY(value: string) {
  const v = value.replace(/\D/g, "").slice(0, 10);

  if (v.length >= 5) {
    return `${v.slice(0, 2)}/${v.slice(2, 4)}/${v.slice(4)}`;
  }

  if (v.length >= 4) {
    return `${v.slice(0, 2)}/${v.slice(2, 4)}`;
  }

  if (v.length >= 3) {
    return `${v.slice(0, 2)}/${v.slice(2)}`;
  }

  return v;
}
