import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { IHospitalConfiguration, IUserDetails } from "../../@types/Login";
import { IPortalPreferences } from "../../@types/Preferences";
import { SearchBar } from "../../components/SearchBar";
import { SupportButton } from "../../components/SupportButton";
import { TopPanelMenuComponent } from "../../components/TopPanelMenu";
import { UserSettingsDesktop } from "../../components/UserSettings";
import {
  AUTH_HOSPITAL_CONFIGURATION,
  AUTH_PORTAL_PREFERENCES,
  AUTH_USER_DETAILS,
} from "../../constants/localStorageKeys";
import { PMP_EMAIL_DOMAIN } from "../../constants/pMpConstants";
import { useAuth } from "../../hooks/useAuth";
import {
  NavContentContainer,
  TopPanelItemsContainerDesktop,
} from "../../layout/components/DashboardLayout/DashboardLayout.styles";
import { PageLayout } from "../../layout/components/PageLayout";
import { mainRoutes } from "../../routes/main.routes";
import { mixpanelActions } from "../../utils/mixpanel";
import { MessagesPreferencesSection } from "./components/MessagesPreferencesSection";
import { PreferencesSection } from "./components/PreferencesSection";
import { SecuritySection } from "./components/SecuritySection";
import { StaffSection } from "./components/StaffSection";
import {
  ContentContainer,
  SettingsPageConatiner,
  SettingsPageContent,
} from "./SettingsPage.styles";

export function SettingsPage() {
  document.title = "Settings - patientMpower";

  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    mixpanelActions.track("Visited Screen: ProfileSettings");

    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated]);

  const portalPreferences = localStorage.getItem(AUTH_PORTAL_PREFERENCES);

  const parsedPortalPreferences = JSON.parse(
    portalPreferences ?? ""
  ) as IPortalPreferences;

  const renderTopBarMenuRoutes = () => {
    const tempRoutes = mainRoutes;

    const userDetails = localStorage.getItem(AUTH_USER_DETAILS) ?? "";
    const parsedUserDetails = JSON.parse(userDetails) as IUserDetails;

    if (parsedUserDetails.userEmail.includes(PMP_EMAIL_DOMAIN)) {
      const surveysIndex = tempRoutes.findIndex((x) => x.name === "Surveys");
      if (surveysIndex !== -1) {
        tempRoutes[surveysIndex].disabled = false;
      }

      const protocolsIndex = tempRoutes.findIndex((x) => x.name === "Protocol");
      if (protocolsIndex !== -1) {
        tempRoutes[protocolsIndex].disabled = false;
      }

      const careInfoIndex = tempRoutes.findIndex((x) => x.name === "Care Info");
      if (careInfoIndex !== -1) {
        tempRoutes[careInfoIndex].disabled = false;
      }

      const workflowsIndex = tempRoutes.findIndex(
        (x) => x.name === "Workflows"
      );
      if (careInfoIndex !== -1) {
        tempRoutes[workflowsIndex].disabled = false;
      }
    }

    const hospitalConfiguration =
      localStorage.getItem(AUTH_HOSPITAL_CONFIGURATION) ?? "{}";

    const parsedHospitalConfiguration = JSON.parse(
      hospitalConfiguration
    ) as IHospitalConfiguration;

    if (parsedHospitalConfiguration.enableAppointments) {
      const appointmentsIndex = tempRoutes.findIndex(
        (x) => x.name === "Appointments"
      );

      if (appointmentsIndex !== -1) {
        tempRoutes[appointmentsIndex].disabled = false;
      }
    }

    return tempRoutes
      .filter(({ disabled }) => !disabled)
      .map(({ path, name }) => (
        <Link
          key={`${Math.random()}-${path}`}
          to={path}
          style={{ whiteSpace: "nowrap" }}
        >
          {name}
        </Link>
      ));
  };

  return (
    <>
      <TopPanelMenuComponent onLogoClick={() => navigate("/")}>
        <NavContentContainer>
          <TopPanelItemsContainerDesktop>
            {renderTopBarMenuRoutes()}
          </TopPanelItemsContainerDesktop>
          <SearchBar />

          <SupportButton style={{ marginRight: "10px", marginLeft: "35px" }} />
        </NavContentContainer>

        <UserSettingsDesktop />
      </TopPanelMenuComponent>
      <ContentContainer>
        <SettingsPageConatiner>
          <SettingsPageContent>
            <PageLayout>
              {parsedPortalPreferences.isAdmin ? <StaffSection /> : null}

              <MessagesPreferencesSection />

              <SecuritySection />

              <PreferencesSection />
            </PageLayout>
          </SettingsPageContent>
        </SettingsPageConatiner>
      </ContentContainer>
    </>
  );
}
