import { styled } from "../../../styles";

export const ContentContainer = styled("div", {
  width: "100%",
  padding: "62px 0 0 88px",

  height: "100%",
  overflowY: "auto",

  backgroundColor: "$gray-20",
  touchAction: "auto",

  "@media screen and (max-width: 1100px)": {
    padding: "62px 0 0 0",
  },

  variants: {
    leftPanelHidden: {
      true: {
        padding: "62px 0 0 0",
      },
    },
  },
});

export const NavContentContainer = styled("div", {
  display: "flex",
  flexGrow: 1,

  "@media screen and (max-width: 1100px)": {
    width: "100%",
    justifyContent: "flex-end",
  },
});

export const TopPanelItemsContainerDesktop = styled("div", {
  display: "flex",

  "@media screen and (max-width: 1100px)": {
    display: "none",
  },
});

export const WardSelector = styled("div", {
  marginRight: "10px",
  marginLeft: "10px",
  marginTop: "auto",
  marginBottom: "auto",
  padding: "0 5px 0 20px",

  width: "max-content",
  height: "37px",
  borderRadius: "20px",

  backgroundColor: "$gray-30",

  color: "$black",
  fontFamily: "$openSans",
  fontSize: "12px",
  fontWeight: "500",
  lineHeight: "26px",
  textAlign: "center",
  textWrap: "nowrap",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",

  cursor: "pointer",

  border: "2px solid transparent",

  "&:hover": {
    backgroundColor: "$gray-40",
    border: "2px solid #E6F3F3 !important",
  },

  variants: {
    active: {
      true: {
        backgroundColor: "$primary-20",
        color: "#48397D",

        svg: {
          path: {
            fill: "#48397D !important",
          },
        },

        "&:hover": {
          backgroundColor: "rgba(234, 230, 244, 0.8)",
        },
      },
    },
  },
});

export const OptionsContainer = styled("div", {
  width: "fit-content",
  minWidth: "186px",
  height: "0px",
  maxHeight: "204px",
  overflowY: "auto",

  backgroundColor: "$white",
  borderRadius: "8px",

  position: "absolute",
  top: "54px",
  right: "0px",

  "&::-webkit-scrollbar-thumb": {
    border: "none",
    backgroundColor: "white",
  },

  variants: {
    open: {
      true: {
        padding: "14px 16px",
        border: "1px solid $gray-50",
        boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.12)",
        height: "max-content",
      },
      false: {
        padding: "0px 16px",
        height: "0px",
        border: "none",
        boxShadow: "none",

        "&::-webkit-scrollbar": {
          width: "$0",
        },
      },
    },
  },
});

export const WardOption = styled("div", {
  width: "100%",
  height: "16px",
  margin: "12px 0",

  fontFamily: "$openSans",
  fontSize: "12px",
  fontWeight: "500",
  lineHeight: "16px",

  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "8px",

  color: "$gray-100 !important",

  ".ant-checkbox-wrapper": {
    lineHeight: "0px !important",
  },

  ".ant-checkbox .ant-checkbox-inner": {
    width: "18px !important",
    height: "18px !important",
    borderWidth: "2px !important",

    "&::after": {
      borderWidth: "2px !important",
    },
  },

  variants: {
    selected: {
      true: {
        color: "#48397D !important",
      },
    },
  },
});

export const DisplayName = styled("p", {
  width: "max-content",
  marginRight: "5px",
  whiteSpace: "nowrap",
});
