import { css } from "@stitches/react";
import { TimePicker } from "antd";
import type { Dayjs } from "dayjs";

type TimePickerInputProps = {
  value?: Dayjs;
  format?: string;
  placeholder?: string;
  onChange(time: Dayjs, timeString: string | string[]): void;
};

export function TimePickerInput({
  value,
  format = "HH:mm",
  placeholder,
  onChange,
}: TimePickerInputProps) {
  const timePickerInputCss = css({
    height: "42px",
    fontFamily: "$openSans !important",
    border: "2px solid $gray-50 !important",

    "&:hover": {
      border: "2px solid #BDB4DD !important",
    },

    "&.ant-picker-focused": {
      border: "2px solid #BDB4DD !important",
      boxShadow: "0 0 0 2px rgba(189,180,221, 0.1) !important",
    },
  });

  const timePickerPopupCss = css({
    zIndex: 3100,
    pointerEvents: "all !important",
    fontFamily: "$openSans !important",

    span: {
      fontFamily: "$openSans !important",
    },

    ".ant-picker-time-panel-column": {
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },

    ".ant-picker-time-panel-cell-selected ": {
      ".ant-picker-time-panel-cell-inner": {
        background: "#E9E6F4 !important",
      },
    },

    ".ant-picker-time-panel-column::after": {
      height: "auto",
    },

    ".ant-picker-ranges": {
      marginTop: "0",
      fontWeight: "400",

      ".ant-picker-now-btn": {
        color: "#212121",

        "&:hover": {
          opacity: "0.8",
        },
      },

      ".ant-picker-ok": {
        button: {
          "&.ant-btn-primary": {
            backgroundColor: "#7562A8",

            "&:hover": {
              backgroundColor: "#615492",
            },
          },
        },
      },
    },
  });

  return (
    <TimePicker
      defaultValue={value}
      placeholder={placeholder}
      needConfirm={false}
      value={value}
      format={format}
      onChange={onChange}
      suffixIcon={null}
      popupClassName={timePickerPopupCss()}
      className={timePickerInputCss()}
    />
  );
}
