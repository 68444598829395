import { useFormik } from "formik";
import { useEffect, useMemo, useState, useRef } from "react";
import PhoneInput from "react-phone-input-2";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";

import { Notification } from "../../../../../../@types/Notification";
import { IPortalPreferences } from "../../../../../../@types/Preferences";
import {
  IAddEditStaffRequest,
  IGetStaffResponse,
} from "../../../../../../@types/Staff";
import { Button } from "../../../../../../components/Button";
import { Checkbox } from "../../../../../../components/Checkbox";
import {
  IOption,
  SelectDropdown,
} from "../../../../../../components/SelectDropdown";
import { SkeletonLoading } from "../../../../../../components/Skeleton";
import { TextField } from "../../../../../../components/TextField";
import { AUTH_PORTAL_PREFERENCES } from "../../../../../../constants/localStorageKeys";
import { usePatientDetailsDropdownItems } from "../../../../../../hooks/queries/patients";
import { useAvailableWards } from "../../../../../../hooks/queries/wards";
import { staffService } from "../../../../../../services/staffService";
import { mixpanelActions } from "../../../../../../utils/mixpanel";
import {
  CancelButton,
  ModalTitle,
} from "../../../SecurityModal/SecurityModal.styles";
import {
  CheckboxContainer,
  ContentContainer,
  ErrorMessage,
  FormField,
  inputCss,
  ModalContainer,
  Row,
  ServerErrorMessage,
  submitButtonCss,
  SubmitContainer,
  reactPhoneInputCss,
} from "./AddEditStaffModal.styles";
import "react-phone-input-2/lib/bootstrap.css";

type AddEditStaffModalProps = {
  staffId?: number;
  content?: IGetStaffResponse;
  onClose: (notification?: Notification, refetchTable?: boolean) => void;
};

export function AddEditStaffModal({
  staffId,
  content,
  onClose,
}: AddEditStaffModalProps) {
  const { dropdownItems, isFetching } = usePatientDetailsDropdownItems({
    patientId: "0",
  });
  const { availableWards, refetchWards } = useAvailableWards();

  const [conditionOptions] = useState<IOption[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [rerender, setRerender] = useState<boolean>(false);
  const [isMounted, setIsMounted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [phoneWithCountryCode, setPhoneWithCountryCode] = useState<string>("");
  const [countryCode, setCountryCode] = useState<string>();

  const emailRef = useRef<HTMLDivElement>(null);
  const confirmEmailRef = useRef<HTMLDivElement>(null);
  const firstNameRef = useRef<HTMLDivElement>(null);
  const lastNameRef = useRef<HTMLDivElement>(null);
  const conditionTypesRef = useRef<HTMLDivElement>(null);
  const phoneNumberRef = useRef<HTMLDivElement>(null);
  const permissionLevelRef = useRef<HTMLDivElement>(null);
  const wardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    refetchWards();
    setIsMounted(true);

    const portalPreferences = localStorage.getItem(AUTH_PORTAL_PREFERENCES);

    if (portalPreferences) {
      const parsedPortalPreferences = portalPreferences
        ? (JSON.parse(portalPreferences) as IPortalPreferences)
        : undefined;

      if (parsedPortalPreferences) {
        setCountryCode(parsedPortalPreferences.countryCode?.toLowerCase());
      }
    }

    return () => {
      setIsMounted(false);
    };
  }, []);

  const handleOnSubmitForm = async ({
    email,
    firstName,
    lastName,
    conditionTypes,
    phoneNumber,
    permissionLevel,
    ward,
    receiveAlerts,
    activeAccount,
    isAdmin,
  }: any) => {
    setIsLoading(true);
    setErrorMessage("");

    const staffData: IAddEditStaffRequest = {
      email,
      firstName,
      lastName,
      conditionTypeIds: conditionTypes,
      phoneNumber,
      permissionLevel,
      wardId: ward,
      receiveAlerts,
      activeAccount,
      isAdmin,
    };

    const notification: Notification = {
      show: true,
      message: "",
      type: "success",
      width: "max-content",
    };

    let refetchTable = false;

    if (staffId !== undefined && content !== undefined) {
      const result = await staffService.editHospitlStaff(staffId, staffData);

      if (result.status >= 200 && result.status < 300) {
        notification.message = "Staff successfully updated";
        refetchTable = true;
        onClose(notification, refetchTable);
      } else if (result.status === 400) {
        setErrorMessage((result.data as any).errors[0].message);
        setIsLoading(false);
        return;
      } else {
        notification.message = "Error updating staff, please try again";
        notification.type = "error";
        onClose(notification, refetchTable);
      }
    } else {
      const result = await staffService.addHospitlStaff(staffData);

      if (result.status >= 200 && result.status < 300) {
        notification.message = "Staff successfully created";
        refetchTable = true;
        onClose(notification, refetchTable);
      } else if (result.status === 400) {
        setErrorMessage((result.data as any).errors[0].message);
        setIsLoading(false);
        return;
      } else {
        notification.message = "Error creating staff, please try again";
        notification.type = "error";
        onClose(notification, refetchTable);
      }
    }

    mixpanelActions.track("User action: Save");
  };

  const basePhoneNumberSchema = yup
    .string()
    .min(8, "Please enter a valid phone number");

  const formValidationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Please enter a valid email address.")
      .required("Please enter a valid email address."),
    confirmEmail: yup
      .string()
      .oneOf([yup.ref("email"), null], "Email addresses don’t match")
      .required("Please enter a valid email address."),
    firstName: yup
      .string()
      .required("Please enter a value before you proceed."),
    lastName: yup.string().required("Please enter a value before you proceed."),
    permissionLevel: yup
      .string()
      .required("Please select a value before you proceed."),
    conditionTypes: yup
      .array()
      .min(1, "Please select at least one value before you proceed.")
      .required(),
    ward: yup.string().when("permissionLevel", {
      is: "hospital",
      then: yup.string().required("Please select a value before you proceed."),
    }),
    phoneNumber: yup.string().when("receiveAlerts", {
      is: true,
      then: basePhoneNumberSchema.required(
        "Please select a value before you proceed."
      ),
      otherwise: basePhoneNumberSchema,
    }),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      confirmEmail: "",
      firstName: "",
      lastName: "",
      conditionTypes: [],
      phoneNumber: "",
      permissionLevel: content?.permissionLevel,
      ward: content?.wardId?.toString(),
      receiveAlerts: false,
      activeAccount: true,
      isAdmin: false,
    },
    onSubmit: handleOnSubmitForm,
    validationSchema: formValidationSchema,
  });

  useEffect(() => {
    if (content) {
      setIsLoadingData(true);

      const parsedConditions = content.conditionTypes.map((conditionType) => {
        return conditionType.toString();
      });

      formik.setValues({
        email: content.email,
        confirmEmail: content.email,
        firstName: content.firstName,
        lastName: content.lastName,
        conditionTypes: parsedConditions as never[],
        phoneNumber: content.phoneNumber ?? "",
        permissionLevel: content.permissionLevel,
        ward: content.wardId?.toString(),
        receiveAlerts: content.receiveAlerts,
        activeAccount: content.activeAccount,
        isAdmin: content.isAdmin,
      });

      if (content.phoneNumber) {
        setPhoneWithCountryCode(content.phoneNumber);
      }

      formik.validateForm();

      setRerender(true);

      setTimeout(() => {
        setRerender(false);
        setIsLoadingData(false);
      }, 100);
    }
  }, [content]);

  const shouldShowErrorMessage = (
    field:
      | "email"
      | "confirmEmail"
      | "firstName"
      | "lastName"
      | "permissionLevel"
      | "phoneNumber"
      | "ward"
      | "conditionTypes"
  ) => {
    return formik.touched[field] ? formik.errors[field]?.toString() ?? "" : "";
  };

  const handlePhoneInput = (country: any, formattedValue: string) => {
    const formattedPhoneNumber = formattedValue.replace(/ /g, "");
    const formattedDialCode = `+${country.dialCode}`;

    if (formattedPhoneNumber !== formattedDialCode) {
      formik.setFieldValue("phoneNumber", formattedPhoneNumber);
    } else {
      formik.setFieldValue("phoneNumber", "");
    }

    setPhoneWithCountryCode(formattedValue);
  };

  const handleCountryChange = () => {
    const inputElement = document.getElementById("phone-input");
    if (inputElement) {
      inputElement.focus();
    }
  };

  const errorFieldRefs = {
    email: emailRef,
    confirmEmail: confirmEmailRef,
    firstName: firstNameRef,
    lastName: lastNameRef,
    conditionTypes: conditionTypesRef,
    phoneNumber: phoneNumberRef,
    permissionLevel: permissionLevelRef,
    ward: wardRef,
  };

  const scrollToFirstError = () => {
    const errorFields = Object.keys(formik.errors);

    const errorRef =
      errorFieldRefs[
        errorFields[0] as
          | "email"
          | "confirmEmail"
          | "firstName"
          | "lastName"
          | "permissionLevel"
          | "phoneNumber"
          | "ward"
          | "conditionTypes"
      ];

    if (errorRef.current) {
      errorRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (formik.errors && Object.keys(formik.errors).length > 0) {
      scrollToFirstError();
    }
  }, [formik.isSubmitting]);

  const conditionsDropdown = useMemo(() => {
    if (!isMounted) return null;

    if (dropdownItems?.conditionItems) {
      dropdownItems?.conditionItems?.forEach((item, index) => {
        conditionOptions.push({
          label: item.itemName,
          value: item.itemId.toString(),
          key: `${item.itemName}-${item.itemId}-${index}`,
        });
      });
    }

    return (
      <SelectDropdown
        options={conditionOptions}
        width={188}
        height={42}
        placeholder="Choose condition"
        multiple
        value={formik.values.conditionTypes}
        onValueChange={(value: string | string[]) => {
          if (typeof value !== "string") {
            formik.setFieldValue("conditionTypes", value);
          }
        }}
      />
    );
  }, [dropdownItems, isMounted]);

  const permissionsDropdown = useMemo(() => {
    if (!isMounted) return null;

    const permissionOptions: IOption[] = [
      {
        label: "Ward",
        value: "hospital",
        key: uuidv4(),
      },
      {
        label: "Hospital Group",
        value: "hospital-group",
        key: uuidv4(),
      },
    ];

    return (
      <SelectDropdown
        options={permissionOptions}
        width={188}
        height={42}
        placeholder="Permission level"
        value={formik.values.permissionLevel}
        onValueChange={(value: string | string[]) => {
          if (value === "hospital-group") {
            formik.setFieldValue("ward", "");
            formik.setFieldTouched("ward", false);
          }

          formik.setFieldValue("permissionLevel", value.toString());
        }}
      />
    );
  }, [content, isMounted]);

  const wardsDropdown = useMemo(() => {
    if (!isMounted) return null;

    const tempWards: IOption[] = [];

    if (availableWards) {
      availableWards.forEach((ward) => {
        tempWards.push({
          label: ward.hospitalName,
          value: ward.hospitalId.toString(),
          key: uuidv4(),
        });
      });
    }

    return (
      <SelectDropdown
        options={tempWards}
        width={188}
        height={42}
        placeholder="Ward"
        value={formik.values.ward}
        onValueChange={(value: string | string[]) => {
          formik.setFieldValue("ward", value.toString());
        }}
      />
    );
  }, [availableWards, isMounted]);

  if (isLoadingData || isFetching) {
    return (
      <ModalContainer>
        <div style={{ marginBottom: "40px" }}>
          <SkeletonLoading height="22px" width="100px" />
        </div>

        <SkeletonLoading height="370px" />

        <div style={{ marginTop: "26px", display: "flex", gap: "24px" }}>
          <SkeletonLoading height="42px" width="186px" />
          <SkeletonLoading height="42px" width="100px" />
        </div>
      </ModalContainer>
    );
  }

  return (
    <ModalContainer>
      <ModalTitle>{staffId ? "Edit Staff" : "Add staff"}</ModalTitle>

      {!rerender ? (
        <ContentContainer>
          <Row>
            <FormField ref={emailRef}>
              <p>Email address</p>
              <TextField
                label=""
                name="email"
                placeholder="Email address"
                value={formik.values.email}
                onChange={formik.handleChange}
                backgroudColor="white"
                className={inputCss()}
                errorMessage={shouldShowErrorMessage("email")}
              />
            </FormField>

            <FormField ref={confirmEmailRef}>
              <p>Confirm email address</p>
              <TextField
                label=""
                name="confirmEmail"
                placeholder="Email address"
                value={formik.values.confirmEmail}
                onChange={formik.handleChange}
                backgroudColor="white"
                className={inputCss()}
                errorMessage={shouldShowErrorMessage("confirmEmail")}
                onPaste={(e) => e.preventDefault()}
              />
            </FormField>

            <FormField ref={firstNameRef}>
              <p>First name</p>
              <TextField
                label=""
                name="firstName"
                placeholder="First name"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                backgroudColor="white"
                className={inputCss()}
                errorMessage={shouldShowErrorMessage("firstName")}
              />
            </FormField>

            <FormField ref={lastNameRef}>
              <p>Last name</p>
              <TextField
                label=""
                name="lastName"
                placeholder="Last name"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                backgroudColor="white"
                className={inputCss()}
                errorMessage={shouldShowErrorMessage("lastName")}
              />
            </FormField>
          </Row>

          <Row>
            <FormField ref={conditionTypesRef}>
              <p>Condition types</p>
              {conditionsDropdown}
              <ErrorMessage>
                {shouldShowErrorMessage("conditionTypes")}
              </ErrorMessage>
            </FormField>

            <FormField ref={permissionLevelRef}>
              <p>Permission level</p>
              {permissionsDropdown}
              <ErrorMessage>
                {shouldShowErrorMessage("permissionLevel")}
              </ErrorMessage>
            </FormField>

            {formik.values.permissionLevel === "hospital" ? (
              <FormField ref={wardRef}>
                <p>Wards</p>
                {wardsDropdown}
                <ErrorMessage>{shouldShowErrorMessage("ward")}</ErrorMessage>
              </FormField>
            ) : null}

            <FormField ref={phoneNumberRef}>
              <p>Phone number</p>
              <PhoneInput
                inputProps={{
                  autofocus: true,
                  id: "phone-input",
                }}
                searchPlaceholder="Search"
                country={countryCode}
                containerClass={reactPhoneInputCss()}
                enableSearch
                value={phoneWithCountryCode}
                onChange={(value, country, e, formattedValue) => {
                  handleCountryChange();
                  handlePhoneInput(country, formattedValue);
                }}
                countryCodeEditable={false}
              />
              <ErrorMessage>
                {shouldShowErrorMessage("phoneNumber")}
              </ErrorMessage>
            </FormField>
          </Row>

          <Row>
            <CheckboxContainer alignItemsStart>
              <Checkbox
                checked={formik.values.receiveAlerts}
                label=""
                onChange={(isChecked) => {
                  formik.setFieldValue("receiveAlerts", isChecked);

                  if (isChecked) {
                    mixpanelActions.track("User action: EnableAlerts");
                  } else {
                    mixpanelActions.track("User action: DisableAlerts");
                  }
                }}
              />
              <p>
                <b>Receive alerts.</b> Tick to send this user alerts when
                patients record measurements outside the defined threshold.
              </p>
            </CheckboxContainer>
          </Row>

          <Row>
            <CheckboxContainer>
              <Checkbox
                checked={formik.values.activeAccount}
                label=""
                onChange={(isChecked) => {
                  formik.setFieldValue("activeAccount", isChecked);

                  if (isChecked) {
                    mixpanelActions.track("User action: SetStaffActive");
                  } else {
                    mixpanelActions.track("User action: SetStaffInactive");
                  }
                }}
              />
              <p>
                <b>Active account.</b> Untick to deactivate account.
              </p>
            </CheckboxContainer>
          </Row>

          <Row>
            <CheckboxContainer>
              <Checkbox
                checked={formik.values.isAdmin}
                label=""
                onChange={(isChecked) => {
                  formik.setFieldValue("isAdmin", isChecked);

                  if (isChecked) {
                    mixpanelActions.track("User action: SetAdminStaff");
                  } else {
                    mixpanelActions.track("User action: RevokeAdminStaff");
                  }
                }}
              />
              <p>
                <b>Admin account.</b> Tick to grant admin permissions.
              </p>
            </CheckboxContainer>
          </Row>
        </ContentContainer>
      ) : null}

      <ServerErrorMessage>{errorMessage}</ServerErrorMessage>

      <SubmitContainer>
        <Button
          label="Save"
          type="submit"
          onClick={formik.submitForm}
          isLoading={isLoading}
          className={submitButtonCss()}
        />

        <CancelButton
          onClick={() => {
            mixpanelActions.track("User action: Cancel");
            onClose();
          }}
          style={{ marginLeft: "-35px", padding: "0 30px" }}
        >
          Cancel
        </CancelButton>
      </SubmitContainer>
    </ModalContainer>
  );
}
