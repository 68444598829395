import { styled, css } from "../../../../../../../styles";

// Components

export const ModalContentContainer = styled("div", {
  height: "100%",
  width: "100%",
  overflowY: "overlay",

  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "rgba(248, 248, 248, 0.8)",
  padding: "5px",

  "&::-webkit-scrollbar": {
    width: "$4",
  },

  "&:hover": {
    "&::-webkit-scrollbar": {
      width: "$4",
      backgroundColor: "transparent",
    },

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "$gray-50",
      borderRadius: "10px",
    },
  },
});

export const QuestionTypeContainer = styled("div", {
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: "20px",
  pointerEvents: "auto",

  ".ant-select": {
    height: "42px !important",
  },

  p: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "30px",

    color: "$charcoal",

    fontSize: "15px",
    lineHeight: "16px",
    fontFamily: "$openSans",
  },
});

export const ToggleStyleClass = css({
  "&:hover": {
    cursor: "pointer",
  },
  marginRight: "10px",
});

export const ConditionalJumpToggleContainer = styled("div", {
  marginLeft: "30px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const HandleQuestionFormContainer = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "30px",
  marginBottom: "30px",
});

export const SectionContainer = styled("div", {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  width: "100%",
  padding: "10px 0 0 10px",

  p: {
    marginRight: "10px",
  },
});

export const DomainContainer = styled("div", {
  width: "100%",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  padding: "20px",

  p: {
    width: "116px",
    marginRight: "30px !important",

    color: "$charcoal",

    fontSize: "15px",
    lineHeight: "16px",
    fontFamily: "$openSans",
  },

  ".ant-select-single": {
    height: "42px!important",
  },
});

// CSS

export const textInputCss = css({
  width: "60px",

  span: {
    margin: "0px",
  },

  p: {
    width: "100% !important",
  },

  input: {
    border: "2px solid $gray-50",
    borderRadius: "6px",
    paddingLeft: "11px",
    paddingRight: "11px",
  },
});
