import { createStitches, defaultThemeMap } from "@stitches/react";

import { colors } from "./colors";

export const { styled, css, globalCss, keyframes, theme, createTheme, config } =
  createStitches({
    themeMap: {
      ...defaultThemeMap,
      width: "space",
      height: "space",
      opacity: "opacity",
    },

    theme: {
      colors,

      fonts: {
        nunito: "'Nunito Sans', sans-serif", // change to nunito sans
        openSans: "'Open Sans', sans-serif",
      },

      fontSizes: {
        "heading-text-h1": "45px",
        "heading-text-h2": "37px",
        "heading-text-h3": "31px",
        "heading-text-h4": "26px",
        "heading-text-h5": "21px",

        "text-sm": "10px",
        "text-md": "12px",
        "text-lg": "14px",
        "text-xl": "16px",
        "text-xxl": "18px",
      },

      space: {
        1: "2px",
        2: "4px",
        3: "6px",
        4: "8px",
        5: "10px",
        6: "12px",
        7: "14px",
        8: "16px",
        9: "20px",
        10: "22px",
        11: "24px",
        12: "32px",
        13: "40px",
        14: "48px",
        15: "56px",
        16: "64px",
        17: "72px",
        18: "80px",
      },

      sizes: {
        1: "2px",
        2: "4px",
        3: "6px",
        4: "8px",
        5: "10px",
        6: "12px",
        7: "14px",
        8: "16px",
        9: "20px",
        10: "22px",
        11: "24px",
        12: "32px",
        13: "40px",
        14: "48px",
        15: "56px",
        16: "64px",
        17: "72px",
        18: "80px",
      },

      radii: {
        1: "4px",
        2: "6px",
        3: "8px",
        4: "12px",
        5: "14px",
        6: "16px",
        7: "18px",
        8: "20px",

        round: "50%",
        pill: "9999px",
      },

      zIndices: {
        1: "100",
        2: "200",
        3: "300",
        4: "400",
        max: "999",
      },
    },

    media: {
      lg: "(max-width: 1800px)",
      md: "(max-width: 1680px)",
      sm: "(max-width: 768px)",
    },
  });
