import { styled } from "../../../../../../styles";

// Components

export const NodeLibraryContainer = styled("div", {
  width: 230,
  padding: 20,
  height: "100%",
  margin: "0px 0px 40px -230px ",

  display: "flex",
  flexDirection: "column",
  gap: 24,

  backgroundColor: "$white",
  borderRight: "1px solid #CFCFCF",

  p: {
    fontFamily: "$openSans",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    color: "$black",

    width: 174,
  },
});

export const DraggableNodeContainer = styled("div", {
  height: 130,
  width: 130,

  padding: "10px 16px",
  borderRadius: 8,
  background: "$gray-40",
  cursor: "grab",

  fontFamily: "$openSans",
  fontSize: 16,
  fontWeight: 400,
  lineHeight: "22px",
  color: "$black",

  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: 14,

  variants: {
    isDragging: {
      true: {
        opacity: 0.5,
      },
      false: {
        opacity: 1,
      },
    },
  },
});

export const IconContainer = styled("div", {
  width: 40,
  height: 40,

  borderRadius: "50%",
  backgroundColor: "$white",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});
