import { styled, css } from "../../../styles";

export const MainContainer = styled("div", {
  paddingLeft: "300px",

  "@media (max-width: 1100px)": {
    paddingLeft: "150px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "left",
  },

  "@sm": {
    paddingLeft: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "left",
  },
});

export const TitleMessage = styled("h1", {
  color: "$gray-100",
  marginBottom: "32px",
  marginTop: "42px",
  lineHeight: "49px",
  fontSize: "36px",
  fontFamily: "$openSans",
  fontWeight: "bold",
});

export const InfoMessage = styled("p", {
  marginBottom: "32px",
  fontSize: "16px",
  color: "$gray-100",
  lineHeight: "28px",
  fontFamily: "$openSans",
  fontWeight: "400",
});

export const buttonCss = css({
  borderRadius: "5px",
  marginBottom: "20px",

  button: {
    borderRadius: "5px",
    backgroundColor: "$primary-40",
    border: "none",

    "&:hover": {
      borderRadius: "5px",
      backgroundColor: "$primary-40",
      border: "none",
    },
    "&:focus": {
      borderRadius: "5px",
      backgroundColor: "$primary-40",
      border: "none",
    },
  },
});
