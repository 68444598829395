import { styled, css } from "../../../../../../../../styles";

export const AddLinkModalContainer = styled("div", {
  padding: "32px 26px 52px 26px",
});

export const URLContainer = styled("div", {
  width: "646px",
  backgroundColor: "$gray-20",
  borderRadius: "8px",

  padding: "28px 26px 32px 26px",
  margin: "40px 0 32px 0",
});

export const NoteSection = styled("div", {
  width: "518px",
  height: "120px",
  borderRadius: "8px",
  backgroundColor: "rgba(233, 230, 244, 0.60)",
  padding: "20px 21px 27px 21px",
  fontSize: "14px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",

  p: {
    color: "$primary-70",
    lineHeight: "22px",
  },

  "@sm": {
    height: "150px",
  },
});

export const SubmitContainer = styled("div", {
  height: "70px",
  display: "flex",
  gap: "40px",
  padding: "40px 12px 0px 0px",
  alignItems: "center",

  p: {
    fontWeight: 500,
  },
});

export const CancelButton = styled("div", {
  height: "40px",
  width: "fit-content",

  color: "$black",
  fontSize: "14px",
  fontWeight: 500,

  display: "flex",
  alignItems: "center",

  cursor: "pointer",
});

// CSS

export const textFieldCss = css({
  width: "350px",
});
