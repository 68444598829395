import { useMemo, useState } from "react";

import { Notification } from "../../@types/Notification";
import {
  IProtocolResponse,
  IProtocolTableContent,
} from "../../@types/Protocol";
import { Dots } from "../../assets/icons/Dots";
import { CustomIconButton } from "../../components/CustomIconButton";
import { Dropdown, DropdownTextItem } from "../../components/Dropdown";
import { ActionContainer } from "../../components/TableActionDropdownButton/TableActionDropdownButton.styles";
import { ToastNotification } from "../../components/ToastNotification";
import { useProtocols } from "../../hooks/queries/protocols";
import { PageLayout } from "../../layout/components/PageLayout";
import { PageTitle } from "../../layout/components/PageTitle";
import { protocolService } from "../../services/protocol.service";
import { ProtocolListTable } from "./components/ProtocolListTable";
import {
  ProtocolListContainer,
  ProtocolListTableContainer,
} from "./ProtocolList.styles";

export function ProtocolList() {
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [notification, setNotification] = useState<Notification>({
    show: false,
    message: "",
    type: "error",
    width: "max-content",
    rightMargin: "50%",
  });

  const { protocols, isFetching, isRefetching, refetch } = useProtocols();

  const parsedProtocolsToTable = useMemo(() => {
    if (!protocols) return [];

    const options: any[] = ["Unpublish", "Publish", "Delete"];

    return protocols.map((protocol: IProtocolResponse) => {
      return {
        id: protocol.id,
        hospitalName: protocol.hospitalName,
        protocolName: protocol.protocolName,
        status: protocol.status,
        actionNames:
          protocol.actionNames.length > 36
            ? `${protocol.actionNames.substring(0, 36)}...`
            : protocol.actionNames,
        action: (
          <div>
            <ActionContainer
              className="actionContainer"
              style={{ justifyContent: "center" }}
              onClick={(e) => e.stopPropagation()}
            >
              <Dropdown
                trigger={
                  <CustomIconButton
                    style={{ display: isDropDownOpen ? "none" : undefined }}
                  >
                    <Dots />
                  </CustomIconButton>
                }
                onOpenChange={(value) => setIsDropDownOpen(value)}
                onValueChange={async (val: string) => {
                  if (val === options[0]) {
                    const result = await protocolService.updateProtocolStatus(
                      protocol.id,
                      false
                    );

                    if (result.status >= 200 && result.status < 300) {
                      setNotification({
                        show: true,
                        message: "Protocol successfully unpublished",
                        type: "success",
                        width: "max-content",
                      });
                      refetch();
                    } else {
                      setNotification({
                        show: true,
                        message:
                          "Error unpublishing protocol, please try again",
                        type: "error",
                        width: "max-content",
                      });
                    }
                  }
                  if (val === options[1]) {
                    const result = await protocolService.updateProtocolStatus(
                      protocol.id,
                      true
                    );

                    if (result.status >= 200 && result.status < 300) {
                      setNotification({
                        show: true,
                        message: "Protocol successfully published",
                        type: "success",
                        width: "max-content",
                      });
                      refetch();
                    } else {
                      setNotification({
                        show: true,
                        message: "Error publishing protocol, please try again",
                        type: "error",
                        width: "max-content",
                      });
                    }
                  }
                  if (val === options[2]) {
                    const result = await protocolService.archiveProtocol(
                      protocol.id
                    );

                    if (result.status >= 200 && result.status < 300) {
                      setNotification({
                        show: true,
                        message: "Protocol successfully deleted",
                        type: "success",
                        width: "max-content",
                      });
                      refetch();
                    } else {
                      setNotification({
                        show: true,
                        message: "Error deleting protocol, please try again",
                        type: "error",
                        width: "max-content",
                      });
                    }
                  }

                  setTimeout(() => {
                    setNotification({
                      show: false,
                      message: "notificationMessage",
                      type: "error",
                      width: undefined,
                    });
                  }, 3500);
                }}
              >
                {protocol.status === "Published" ? (
                  <DropdownTextItem key="Unpublish" text="Unpublish" />
                ) : (
                  <DropdownTextItem key="Publish" text="Publish" />
                )}
                <DropdownTextItem key="Delete" text="Delete" />
              </Dropdown>
            </ActionContainer>
          </div>
        ),
      } as IProtocolTableContent;
    });
  }, [protocols]);

  return (
    <PageLayout>
      <ProtocolListContainer>
        <PageTitle>Current protocols</PageTitle>

        <ProtocolListTableContainer>
          <ProtocolListTable
            content={parsedProtocolsToTable}
            isLoading={isFetching || isRefetching}
            refetch={refetch}
          />
        </ProtocolListTableContainer>
      </ProtocolListContainer>

      {notification.show ? (
        <ToastNotification
          message={notification.message}
          type={notification.type}
          width={notification.width}
          rightMargin="35%"
        />
      ) : null}
    </PageLayout>
  );
}
