import { Slider } from "antd";

import { SliderContainer, defaultCss } from "./SliderInput.styles";

type CustomSliderProps = {
  defaultValue?: number;
  max: number;
  min: number;
  valueVisible?: boolean;
  step?: number;
  value?: number;
  vertical?: boolean;
  className?: string;
  onValueChange?: (value: number) => void;
  onAfterChange?: (value: number) => void;
};

export function SliderInput({
  defaultValue,
  max,
  min,
  valueVisible,
  step,
  value,
  vertical,
  className,
  onValueChange,
  onAfterChange,
}: CustomSliderProps): any {
  return (
    <SliderContainer
      style={
        vertical
          ? {
              width: "auto",
              height: "90%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }
          : {}
      }
    >
      <Slider
        tooltip={{
          open: valueVisible,
          getPopupContainer: (triggerNode) => triggerNode,
          placement: vertical ? "right" : "top",
          autoAdjustOverflow: false,
        }}
        onAfterChange={onAfterChange}
        style={{ width: "100%", height: "100%" }}
        defaultValue={defaultValue ?? 0}
        max={max}
        min={min}
        step={step ?? 1}
        value={value}
        vertical={vertical ?? false}
        onChange={onValueChange}
        className={className ?? defaultCss()}
        trackStyle={{ display: "none" }}
      />
    </SliderContainer>
  );
}
