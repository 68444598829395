import { createColumn } from "../../../../components/Table";

export const articlesListColumns = [
  createColumn({
    keyName: "title",
    header: () => "Title",
    cell: (info) => info.renderValue(),
  }),
  // createColumn({
  //   keyName: "cardImage",
  //   header: () => "Card image",
  //   cell: (info) => info.renderValue(),
  // }),
  // createColumn({
  //   keyName: "numberOfReads",
  //   header: () => "No. of reads",
  //   cell: (info) => info.renderValue(),
  // }),
  // createColumn({
  //   keyName: "tags",
  //   header: () => "Tags",
  //   cell: (info) => info.renderValue(),
  // }),
  createColumn({
    keyName: "lastEdited",
    header: () => "Last edited",
    cell: (info) => info.renderValue(),
  }),
  createColumn({
    keyName: "author",
    header: () => "Author",
    cell: (info) => info.renderValue(),
  }),
  createColumn({
    keyName: "wards",
    header: () => "Wards",
    cell: (info) => info.renderValue(),
  }),
  createColumn({
    keyName: "published",
    header: () => "Published",
    cell: (info) => info.renderValue(),
    size: 50,
  }),
];
