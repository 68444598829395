import { useEffect, useState } from "react";

import { Notification } from "../../../../../../@types/Notification";
import { PatientMessagesTableContent } from "../../../../../../@types/Patient";
import { SwitchOff } from "../../../../../../assets/icons/SwitchOff";
import { SwitchOn } from "../../../../../../assets/icons/SwitchOn";
import { Button } from "../../../../../../components/Button";
import { TextArea } from "../../../../../../components/TextArea";
import { TextField } from "../../../../../../components/TextField";
import { messageService } from "../../../../../../services/messageService";
import { getLongFormattedDate } from "../../../../../../utils/dateFormatter";
import { mixpanelActions } from "../../../../../../utils/mixpanel";
import {
  Container,
  SubmitContainer,
  TextAreaContainer,
  Title,
  TextFieldContainer,
  OptionsContainer,
  OptionLabel,
  OptionsRowContainer,
  OptionsContainerScroll,
  optionTextFieldStyles,
  StyledSubtitle,
} from "./ViewMessageModal.styles";

type ViewMessageModalProps = {
  message: PatientMessagesTableContent;
  messageOptions: {
    index: number;
    selected: boolean;
    text: string;
  }[];
  onClose(notification?: Notification, refetchTable?: boolean): void;
};

export function ViewMessageModal({
  message,
  messageOptions,
  onClose,
}: ViewMessageModalProps) {
  const [response, setResponse] = useState("");

  useEffect(() => {
    if (!message.isRead) {
      messageService.readPatientMessage(message.id.toString());
    }
  }, []);

  const handleDoneClick = async () => {
    if (message.sentBy === "App" && !message.isAnswered) {
      const result = await messageService.respondToPatientMessage(
        message.id.toString(),
        response
      );

      if (result.status >= 200 && result.status < 300) {
        mixpanelActions.track("User Action: Send Message");

        onClose(
          {
            show: true,
            message: "Response sucessfully sent.",
            type: "success",
            width: "max-content",
            rightMargin: "25%",
          },
          true
        );
      } else {
        onClose(
          {
            show: true,
            message: "Something went wrong while responding, please try again.",
            type: "error",
            width: "max-content",
            rightMargin: "35%",
          },
          false
        );
      }
    }

    onClose();
  };

  const handleReponseDate = () => {
    if (message.sentBy === "App") return null;

    return message.responseDate === undefined ? (
      <StyledSubtitle>Responded: No</StyledSubtitle>
    ) : (
      <StyledSubtitle>
        Responded: {getLongFormattedDate(message.responseDate)}
      </StyledSubtitle>
    );
  };

  const handleButtonDisabledStatus = () => {
    if (message.sentBy === "Portal") {
      return false;
    }

    if (!message.isAnswered) {
      if (response.length) {
        return false;
      }
      return true;
    }

    return false;
  };

  return (
    <Container>
      <Title>View message</Title>

      {handleReponseDate()}
      <TextFieldContainer>
        <TextField
          label=""
          name="title"
          value={message.title}
          onChange={() => {}}
        />
      </TextFieldContainer>
      <TextAreaContainer>
        <TextArea
          cols={1000}
          rows={5}
          readonly
          value={message.body}
          onChange={() => {}}
        />
      </TextAreaContainer>
      {message.responseType === "Options" && (
        <OptionsContainer>
          <OptionsContainerScroll>
            {messageOptions
              .sort((a, b) => a.index - b.index)
              .map((option) => (
                <OptionsRowContainer key={option.text}>
                  <OptionLabel>
                    {option.selected ? (
                      <SwitchOn width={16} height={16} />
                    ) : (
                      <SwitchOff width={16} height={16} />
                    )}
                  </OptionLabel>
                  <TextField
                    label=""
                    value={option.text}
                    key={option.text}
                    className={optionTextFieldStyles()}
                    disabled
                    inputPadding="5px 20px"
                    inputBorder="0px"
                    onChange={() => {}}
                  />
                </OptionsRowContainer>
              ))}
          </OptionsContainerScroll>
        </OptionsContainer>
      )}
      {message.responseType === "FreeText" && message.sentBy === "Portal" && (
        <TextAreaContainer>
          <TextArea
            cols={1000}
            rows={5}
            readonly
            value={message.freeResponse}
            onChange={() => {}}
          />
        </TextAreaContainer>
      )}
      {message.responseType === "FreeText" && message.sentBy === "App" && (
        <>
          <StyledSubtitle>
            {message.isAnswered
              ? "Your response"
              : "Write your response below:"}
          </StyledSubtitle>
          <TextAreaContainer>
            <TextArea
              cols={1000}
              rows={5}
              readonly={message.isAnswered}
              backgroudColor={message.isAnswered ? undefined : "white"}
              value={message.isAnswered ? message.freeResponse : undefined}
              onChange={(event) => setResponse(event.target.value)}
            />
          </TextAreaContainer>
        </>
      )}
      <SubmitContainer>
        <Button
          label={
            message.sentBy === "App" && !message.isAnswered ? "Respond" : "Done"
          }
          type="button"
          disabled={handleButtonDisabledStatus()}
          onClick={handleDoneClick}
        />
      </SubmitContainer>
    </Container>
  );
}
