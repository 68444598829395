import { useFormik } from "formik";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import * as yup from "yup";

import { IPortalPreferences } from "../../../../@types/Preferences";
import { Button } from "../../../../components/Button";
import { Dropdown, DropdownTextItem } from "../../../../components/Dropdown";
import { TextField } from "../../../../components/TextField";
import { ToastNotification } from "../../../../components/ToastNotification";
import { AUTH_PORTAL_PREFERENCES } from "../../../../constants/localStorageKeys";
import useIsMobile from "../../../../hooks/useIsMobile";
import { useModal } from "../../../../hooks/useModal";
import { userService } from "../../../../services/userService";
import { mixpanelActions } from "../../../../utils/mixpanel";
import {
  ButtonContainer,
  CancelButton,
  CodeContainer,
  CodeSubtitle,
  CodeTitle,
  InputClass,
  ModalContainer,
  ModalTitle,
  NoteContainer,
  NoteSubtitle,
  NoteTitle,
  ResendCodeHyperlink,
  TypeContainer,
  TypeSelectContainer,
  TypeTitle,
  reactPhoneInputCss,
} from "./SecurityModal.styles";
import "react-phone-input-2/lib/bootstrap.css";

interface ISecurityModalProps {
  setIsMfaEnabled: any;
  onClose: () => void;
  email: string;
}

export function SecurityModal(props: ISecurityModalProps) {
  const { setIsMfaEnabled, onClose, email } = props;
  const { closeModal } = useModal();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [newOtpSent, setNewOtpSent] = useState(false);
  const [apiError, setApiError] = useState("");
  const [otpError, setOtpError] = useState("");
  const [mfaType, setMfaType] = useState("Email");
  const { isMobile } = useIsMobile();
  const [phoneWithCountryCode, setPhoneWithCountryCode] = useState<string>("");
  const [countryCode, setCountryCode] = useState<string>();

  useEffect(() => {
    const portalPreferences = localStorage.getItem(AUTH_PORTAL_PREFERENCES);

    if (portalPreferences) {
      const parsedPortalPreferences = portalPreferences
        ? (JSON.parse(portalPreferences) as IPortalPreferences)
        : undefined;

      if (parsedPortalPreferences) {
        setCountryCode(parsedPortalPreferences.countryCode?.toLowerCase());
      }
    }
  });

  const sendOtpValidationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Please enter a valid email address.")
      .required("Please enter your email address."),
  });

  const handleSendOtpSubmitForm = async ({ email, phoneNumber }: any) => {
    mixpanelActions.track("User Action: MFAModalConfirm");

    setSubmitLoading(true);

    const enableMfaResponse = await userService.enableUserMfa({
      email,
      phoneNumber,
      mfaType,
    });

    if (enableMfaResponse.status === 400) {
      setApiError(enableMfaResponse.data);
    }

    if (enableMfaResponse.status === 200) {
      setApiError("");
      setOtpSent(true);
    }

    setSubmitLoading(false);
  };

  const sendOtpFormik = useFormik({
    initialValues: {
      email,
      phoneNumber: "",
    },
    onSubmit: handleSendOtpSubmitForm,
    validationSchema: sendOtpValidationSchema,
  });

  const shouldShowErrorMessage = (field: "email" | "phoneNumber") => {
    if (apiError !== "") {
      return apiError;
    }
    return sendOtpFormik.touched[field] ? sendOtpFormik.errors[field] : "";
  };
  const handleOTPChange = (e: any) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setOtp(e.target.value);
    }
  };

  const checkOTPValidation = async () => {
    setSubmitLoading(true);
    mixpanelActions.track("User Action: MFAModalConfirm");
    const request = {
      email: sendOtpFormik.values.email,
      code: otp,
      mfaType,
    };

    const confirmMfaResponse = await userService.confirmEnableUserMfa(request);

    if (confirmMfaResponse.status === 200) {
      if (confirmMfaResponse.data.length > 1) {
        setOtpError(confirmMfaResponse.data);
      } else {
        setIsMfaEnabled(true);
        onClose();
        closeModal();
      }
    }

    setSubmitLoading(false);
  };

  const resendOtp = async () => {
    if (!newOtpSent) {
      await userService.resendOtp({
        email: sendOtpFormik.values.email,
        phoneNumber: sendOtpFormik.values.phoneNumber,
        mfaType,
      });
      setNewOtpSent(true);
      setTimeout(() => {
        setNewOtpSent(false);
      }, 3500);
    }
  };

  const handleCancelClick = () => {
    mixpanelActions.track("User Action: MFAModalCancel");
    closeModal();
  };

  const handleButtonDisabled = () => {
    if (!otpSent) {
      if (
        (mfaType.toLowerCase() === "sms" &&
          sendOtpFormik.values.phoneNumber === "") ||
        (mfaType.toLowerCase() === "email" && sendOtpFormik.values.email === "")
      ) {
        return true;
      }
    }

    if (otpSent && otp.length !== 6) {
      return true;
    }

    return false;
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter" && !handleButtonDisabled()) {
      if (!otpSent) {
        sendOtpFormik.submitForm();
      } else {
        checkOTPValidation();
      }
    }
  };

  const handleInputChange = (event: any) => {
    setApiError("");
    sendOtpFormik.handleChange(event);
  };

  const handlePhoneInput = (country: any, formattedValue: string) => {
    const formattedPhoneNumber = formattedValue.replace(/ /g, "");
    const formattedDialCode = `+${country.dialCode}`;

    if (formattedPhoneNumber !== formattedDialCode) {
      sendOtpFormik.setFieldValue("phoneNumber", formattedPhoneNumber);
    } else {
      sendOtpFormik.setFieldValue("phoneNumber", "");
    }

    setPhoneWithCountryCode(formattedValue);
  };

  const handleCountryChange = () => {
    const inputElement = document.getElementById("phone-input");
    if (inputElement) {
      inputElement.focus();
    }
  };

  return (
    <ModalContainer>
      <ModalTitle>MFA setup</ModalTitle>
      {!otpSent ? (
        <div>
          <TypeContainer>
            <TypeSelectContainer>
              <TypeTitle>Type</TypeTitle>
              <Dropdown
                trigger={mfaType}
                onValueChange={(e) => {
                  setMfaType(e);
                }}
                backgroundColor="#E6E6E6"
                fontColor="#212121"
                size="small"
              >
                <DropdownTextItem text="Email" value="Email" />
                <DropdownTextItem text="SMS" value="SMS" />
              </Dropdown>
            </TypeSelectContainer>
            {mfaType === "Email" ? (
              <TextField
                name="email"
                label=""
                value={sendOtpFormik.values.email}
                onChange={(event) => handleInputChange(event)}
                className={InputClass()}
                placeholder="jane@example.com"
                errorMessage={shouldShowErrorMessage("email")}
                disabled={email !== ""}
                backgroudColor="white"
              />
            ) : (
              <PhoneInput
                inputProps={{
                  autofocus: true,
                  id: "phone-input",
                }}
                searchPlaceholder="Search"
                country={countryCode?.toLowerCase()}
                containerClass={reactPhoneInputCss()}
                enableSearch
                value={phoneWithCountryCode}
                onChange={(value, country, e, formattedValue) => {
                  handleCountryChange();
                  handlePhoneInput(country, formattedValue);
                }}
                countryCodeEditable={false}
              />
            )}
          </TypeContainer>
          <NoteContainer>
            <NoteTitle>Please note</NoteTitle>
            <NoteSubtitle>
              You will only be sent a code to the{" "}
              {mfaType === "Email" ? "address" : "number"} above.
            </NoteSubtitle>
          </NoteContainer>
        </div>
      ) : (
        <CodeContainer>
          <CodeTitle>Enter code</CodeTitle>
          <TextField
            name="confirmationCode"
            label=""
            value={otp}
            onChange={handleOTPChange}
            onKeyDown={handleKeyDown}
            className={InputClass()}
            placeholder="00-00-00"
            errorMessage={otpError}
            backgroudColor="white"
            maxLength={6}
          />
          <div>
            <CodeSubtitle>
              Didn’t receive a code?
              <ResendCodeHyperlink onClick={resendOtp}>
                Resend it.
              </ResendCodeHyperlink>
            </CodeSubtitle>
          </div>
        </CodeContainer>
      )}
      <ButtonContainer>
        <Button
          onClick={!otpSent ? sendOtpFormik.submitForm : checkOTPValidation}
          type="submit"
          label="Continue"
          disabled={handleButtonDisabled()}
          isLoading={submitLoading}
          size={isMobile ? "small" : undefined}
        />
        <CancelButton onClick={handleCancelClick}>Cancel</CancelButton>
      </ButtonContainer>
      {newOtpSent && (
        <ToastNotification
          message="New code sent"
          type="success"
          width="190px"
          rightMargin="0"
        />
      )}
    </ModalContainer>
  );
}
