import { styled, css } from "../../../../styles";

export const ArticleViewContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",

  width: "100%",
  height: "100%",

  zIndex: "3000",
  backgroundColor: "$white",
});

export const ArticleTitle = styled("p", {
  fontSize: "18px",
  fontWeight: "600",
  fontFamily: "$openSans",
  lineHeight: "24px",
  color: "#212121",

  marginBottom: "12px",
});

export const ContentContainer = styled("div", {
  width: "100%",
  maxWidth: "650px",

  padding: "12px 20px 34px 20px",
  height: "100%",
  overflowY: "scroll",
});

export const HtmlContainer = styled("div", {
  color: "rgba(33, 33, 33, 0.70)",
  fontFamily: "$openSans",
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "24px",

  backgroundColor: "$white",

  marginBottom: "40px",
  padding: "0px 18px 0px 4px",

  img: {
    width: "100%",
  },
});

export const OtherArticleContainer = styled("div", {
  width: "100%",

  borderRadius: "20px",
  boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.16)",
});

export const ArticleSeparator = styled("div", {
  height: "1px",
  width: "94%",

  margin: "auto",

  backgroundColor: "rgba(0, 0, 0, 0.10)",
});

export const RelatedArticle = styled("div", {
  height: "64px",

  paddingLeft: "28px",

  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",

  color: "$black",
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "24px",

  cursor: "pointer",
});

export const AllArticlesButtton = styled("p", {
  color: "#48397D",
  fontSize: "12px",
  fontWeight: "500",
  lineHeight: "16px",

  width: "fit-content",

  marginTop: "20px !important",
  margin: "auto",

  cursor: "pointer",
});

// CSS

export const spinCss = css({
  backgroundColor: "white !important",

  ".ant-spin-dot": {
    ".ant-spin-dot-item": {
      backgroundColor: "#7562A8",
    },
  },
});
