import { styled } from "../../../../../../styles";

export const ImageLibraryContainer = styled("div", {
  width: "100vw",
  position: "relative",
  marginTop: "-62px",
  zIndex: 2001,
  backgroundColor: "$gray-20",

  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

export const Header = styled("div", {
  width: "100%",
  height: "62px",
  backgroundColor: "$primary-80",
  padding: "0 40px 0 68px",
  boxShadow: "0px 0px 4px 1px rgba(0, 0, 0, 0.16)",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  p: {
    color: "$white",
    fontSize: "20px",
    lineHeight: "22px",
    fontWeight: "500",
    fontFamily: "$openSans",

    textAlign: "center",
  },
});

export const CancelButton = styled("div", {
  width: "143px",
  height: "40px",

  borderRadius: "20px",
  backgroundColor: "$white",
  color: "$primary-70",
  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "22px",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  cursor: "pointer",

  position: "absolute",
  right: "48px",
  top: "11px",

  "&:hover": {
    backgroundColor: "$gray-30",
  },
});

export const BodyContainer = styled("div", {
  height: "calc(100vh - 62px)",

  display: "flex",
  justifyContent: "center",

  overflowY: "scroll",
});

export const ImagesContainer = styled("div", {
  width: "85%",
  height: "max-content",
  marginTop: "48px",
  paddingBottom: "48px",

  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",

  flexWrap: "wrap",

  gap: "20px",
});

export const ImageCard = styled("div", {
  width: "286px",
  height: "228px",
  borderRadius: "12px",

  backgroundColor: "$white",
  boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.16)",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  cursor: "pointer",

  "&:hover": {
    backgroundColor: "$gray-30",
  },
});
