import React, { useState, useEffect } from "react";

import {
  OptionButtonContainer,
  OptionsBarContainer,
} from "./OptionsBar.styles";

type Option = {
  text: string;
  selected?: boolean;
  icon?: React.ReactNode;
  iconPosition?: "Left" | "Right";
  onClick?: () => void;
  backgroundColor?: string;
  color?: string;
  borderRadius?: string;
  letterSpacing?: string;
  padding?: string;
  hoverBackgroundColor?: string;
  className?: string;
};

export function OptionButton({
  text,
  selected,
  icon,
  iconPosition,
  onClick,
  backgroundColor,
  className,
  color,
  borderRadius,
  letterSpacing,
  padding,
  hoverBackgroundColor,
}: Option) {
  const [localBackgroundColour, setLocalBackgroundColour] =
    useState(backgroundColor);

  const handleMouseEnter = () => {
    setLocalBackgroundColour(hoverBackgroundColor);
  };

  const handleMouseLeave = () => {
    setLocalBackgroundColour(backgroundColor);
  };

  useEffect(() => {
    setLocalBackgroundColour(backgroundColor);
  }, [backgroundColor]);

  return (
    <OptionButtonContainer
      className={className}
      selected={selected}
      iconOnLeft={iconPosition === "Right"}
      onClick={onClick}
      style={{
        backgroundColor: localBackgroundColour,
        color,
        borderRadius,
        letterSpacing,
        padding,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {text}
      {icon}
    </OptionButtonContainer>
  );
}

type OptionsBarProps = {
  // children: React.ReactElement<Option> | React.ReactElement<Option>[];
  children: React.ReactNode;
  border?: string;
  borderRadius?: string;
};

export function OptionsBar({
  children,
  border,
  borderRadius,
}: OptionsBarProps) {
  return (
    <OptionsBarContainer style={{ border, borderRadius }}>
      {children}
    </OptionsBarContainer>
  );
}
