import LoadingSpin from "react-loading-spin";

import { ButtonContainer, ButtonStyled, ButtonText } from "./Button.styles";

export type ButtonProps = {
  label: string;
  disabled?: boolean;
  className?: string;
  isLoading?: boolean;
  onClick?: () => void;
  variant?: "secondary";
  size?: "small";
  type: "button" | "submit";
  opacity?: number;
};

export function Button({
  type,
  size,
  label,
  onClick,
  variant,
  disabled,
  isLoading,
  className,
  opacity,
}: ButtonProps) {
  const getSpinnerColorByVariant = () => {
    return variant === "secondary" ? "#939393" : "#8F88A7";
  };

  const getSpinnerSizeByButtonSize = () => {
    return size === "small" ? "14px" : "20px";
  };

  return (
    <ButtonStyled className={className} style={{ opacity }}>
      <ButtonContainer
        onClick={onClick}
        type={type}
        disabled={isLoading || disabled}
        size={size}
        variant={variant}
        isLoading={isLoading}
        disabledStyle={disabled}
      >
        {isLoading && (
          <LoadingSpin
            size={getSpinnerSizeByButtonSize()}
            primaryColor={getSpinnerColorByVariant()}
            width="2px"
            secondaryColor="white"
          />
        )}

        <ButtonText>{label}</ButtonText>
      </ButtonContainer>
    </ButtonStyled>
  );
}
