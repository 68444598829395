import { styled } from "../../../../../../styles";

export const FibricheckChartDataContainer = styled("div", {
  width: "100%",
  height: "67%",

  padding: "18px 8px 0px 8px",

  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
  alignItems: "center",
});

export const FibricheckMeasurement = styled("div", {
  width: "100%",
  maxHeight: "24px",

  display: "flex",
  justifyContent: "space-start",
  alignItems: "center",
  gap: "16px",
  whiteSpace: "nowrap",

  p: {
    fontFamily: "$openSans",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "22px",
    color: "$black",
    width: "max-content !important",
  },

  div: {
    width: "max-content",
    height: 24,
    maxWidth: "150px",

    div: {
      width: "100% !important",
      height: "100% !important",

      span: {
        fontSize: "12px",

        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
      },
    },
  },

  "@md": {
    div: {
      maxWidth: "73px !important",
    },
  },
});

export const Divider = styled("div", {
  width: "100%",
  height: "1px",
  backgroundColor: "rgba(0, 0, 0, 0.12)",
  borderRadius: "0.5px",

  margin: "13px 0 13px 0",
});

export const StyledSubtitle = styled("p", {
  color: "$gray-90",
  marginTop: "8px",

  fontSize: "$text-md",
  lineHeight: "16px",

  fontFamily: "$openSans",
});
