import * as SelectPrimitive from "@radix-ui/react-select";

import { styled } from "../../styles";

export const StyledDropdownContainer = styled("div", {
  display: "inline-block",
});

export const StyledSelectTrigger = styled(SelectPrimitive.SelectTrigger, {
  all: "unset",

  cursor: "pointer",

  height: 34,
  padding: "0 20px",

  gap: "7px",

  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  fontFamily: "$openSans",
  fontSize: 14,
  fontWeight: 500,
  color: "$gray-100",

  border: "1px solid $gray-50",
  borderRadius: 18,

  backgroundColor: "$white",
  pointerEvents: "all !important",

  "&:hover": {
    backgroundColor: "$gray-30",
  },

  "&:active": {
    backgroundColor: "$gray-30",
    color: "$gray100",
  },

  "&:focus": {
    border: "1px solid $primary-30",
  },

  "@sm": {
    height: 24,
    padding: "0 12px",

    gap: "4px",
    fontSize: "12px",
  },

  variants: {
    styleDisabled: {
      true: {
        cursor: "unset",
        opacity: "0.38",

        "&:hover": {
          backgroundColor: "$white",
        },

        "&:active": {
          backgroundColor: "$white",
        },
      },
    },
    styleError: {
      true: {
        borderColor: "$error-lighter",
        backgroundColor: "$red-lightest",

        "&:hover": {
          borderColor: "$error-lighter",
          backgroundColor: "$red-lightest",
        },

        "&:active": {
          borderColor: "$error-lighter",
          backgroundColor: "$red-lightest",
        },

        "&:focus": {
          borderColor: "$error-lighter",
          backgroundColor: "$error-lightest",
        },
      },
    },
    styledSkeleton: {
      true: {
        cursor: "unset",
        color: "$gray-50",
        backgroundColor: "$gray-50",
        paddingRight: "38px",

        "&:hover": {
          color: "$gray-50",
          borderColor: "$gray-50",
          backgroundColor: "$gray-50",
        },

        "&:active": {
          color: "$gray-50",
          borderColor: "$gray-50",
          backgroundColor: "$gray-50",
        },

        "&:focus": {
          color: "$gray-50",
          borderColor: "$gray-50",
          backgroundColor: "$gray-50",
        },
      },
    },
    styledNodeWrapper: {
      true: {
        all: "unset",
        height: "auto",
        padding: "0",

        "&:hover": {
          all: "unset",
          cursor: "pointer",
        },
        "&:active": {
          all: "unset",
        },
        "&:focus": {
          all: "unset",
        },
      },
    },
  },
});

export const StyledContent = styled(SelectPrimitive.Content, {
  overflow: "hidden",
  backgroundColor: "white",
  borderRadius: 18,
  border: "1px solid $gray-50",

  marginTop: 4,
  zIndex: 3000,

  "@sm": {
    marginTop: 38,
  },
});

export const StyledSelectItem = styled(SelectPrimitive.Item, {
  all: "unset",

  display: "flex",
  alignItems: "center",

  color: "$gray-100",

  fontFamily: "$openSans",
  fontStyle: "normal",
  fontWeight: 400,

  fontSize: "14px",

  height: 44,
  minWidth: 220,

  boxSizing: "border-box",
  padding: "12px 35px",
  borderBottom: "1px solid $gray-50",

  "&:last-child": {
    borderBottom: "none",
  },

  "&[data-highlighted]": {
    cursor: "pointer",
    backgroundColor: "$gray-30",
  },

  "@sm": {
    fontSize: "12px",
    minWidth: 148,

    padding: "10px 32px",
  },
});

export const StyledItemIndicator = styled(SelectPrimitive.ItemIndicator, {
  position: "absolute",
  left: 0,
  width: 25,
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
});

const scrollButtonStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: 25,
  backgroundColor: "white",
  color: "$gray-100",
  cursor: "default",
};

export const StyledScrollUpButton = styled(
  SelectPrimitive.ScrollUpButton,
  scrollButtonStyles
);

export const StyledScrollDownButton = styled(
  SelectPrimitive.ScrollDownButton,
  scrollButtonStyles
);

export const StyledOutsidePlaceholder = styled("p", {
  all: "unset",

  display: "flex",
  justifyContent: "flex-start",

  paddingLeft: "$4",
  paddingTop: "$3",

  fontFamily: "$openSans",
  fontSize: 14,
  fontWeight: 600,
  color: "$gray-100",

  variants: {
    styleDisabled: {
      true: {
        opacity: "0.38",
      },
    },
    styleError: {
      true: {
        color: "$error-light",
      },
    },
    styleWarning: {
      true: {
        color: "$warning-darker",
      },
    },
  },
});
