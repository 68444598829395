import { useEffect, useState } from "react";

import { ISurveyQuestion } from "../../../../../../../../../@types/Surveys";
import { RichTextEditor } from "../../../../../../../../../components/RichTextEditor";
import { InformativeContainer } from "./InformativeQuestion.styles";

interface IInformativeQuestionProps {
  question?: ISurveyQuestion;
  onValueChange?: React.Dispatch<React.SetStateAction<string>>;
}

export function InformativeQuestion({
  question,
  onValueChange,
}: IInformativeQuestionProps) {
  const [value, setValue] = useState<string>("");

  useEffect(() => {
    if (onValueChange) {
      onValueChange(value);
    }
  }, [value]);

  useEffect(() => {
    if (question) {
      setValue(question.text);
    }
  }, []);

  return (
    <InformativeContainer>
      <RichTextEditor value={value} onValueChange={setValue} />
    </InformativeContainer>
  );
}
