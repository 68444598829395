import { Tile } from "../../components/Tile";
import { styled, css } from "../../styles";

// Components
export const Container = styled("div", {
  height: "100%",

  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",

  backgroundColor: "$gray-20",

  "@sm": {
    width: "100%",
  },
});

export const LoadingOverlay = styled("div", {
  width: "542px",
  borderRadius: "$4",

  zIndex: 200,
  opacity: 0.64,
  position: "absolute",

  backgroundColor: "$white",

  "@sm": {
    width: "100%",
    height: "100%",
  },
});

export const StyledTile = styled(Tile, {
  width: "547px",

  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  backgroundColor: "$white",

  "@sm": {
    width: "100%",
    height: "100%",

    padding: "0px 42px",

    justifyContent: "flex-start",
    flexDirection: "column",
  },
});

export const Logo = styled("img", {
  width: "228px",
  height: "54px",

  marginTop: "40px",
  marginBottom: "48px",

  "@sm": {
    margin: "$13 0 $14 0",

    width: "169px",
    height: "$13",
  },
});

export const ContentContainer = styled("form", {
  width: "430px",
  height: "100%",

  "@sm": {
    width: "100%",
    height: "auto",
  },
});

export const ErrorMessage = styled("p", {
  fontFamily: "$openSans",
  fontWeight: "600",
  fontSize: "$text-md",

  margin: "38px 0 34px 0",
  color: "$error-light",
});

export const InfoMessage = styled("p", {
  fontFamily: "$openSans",
  fontWeight: "400",
  fontSize: "$text-xl",
  fontStyle: "normal",
  lineHeight: "22px",
  color: "#212121",
  marginBottom: "48px",
  maxWidth: "351px",
});

// CSS Styles
export const emailTextFieldStyles = css({
  marginBottom: "$12",

  "@sm": {
    marginBottom: "28px",
  },
});

export const passwordTextFieldStyles = css({
  marginBottom: "$13",

  "@sm": {
    marginBottom: "$12",
  },
});

export const loginButtonStyles = css({
  zIndex: 300,
  position: "relative",
  marginBottom: "84px",
});

export const CodeSubtitle = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  color: "#666666",
  fontSize: "12px",
  fontWeight: "400",
  lineHeight: "22px",
  margin: "8px 0 0 8px",
});

export const CodeDescription = styled("div", {
  color: "#727272",
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "22px",
  margin: "0px 0 40px 0",
});

export const ResendCodeHyperlink = styled("span", {
  color: "#7562A8",
  fontSize: "12px",
  fontWeight: "600",
  lineHeight: "22px",
  cursor: "pointer",
  margin: "0 0 40px 5px",
});
