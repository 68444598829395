import { styled } from "../../../../styles";

export const DatepickerContainer = styled("div", {
  top: 63,
  zIndex: 3000,
  borderRadius: "16px",
  position: "absolute",
  boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.22)",
  border: "1px solid var(--colors-gray-50)",
});

export const OptionsContainer = styled("div", {
  marginBottom: "4px",
});
