import React from "react";

import { Radio } from "./RadioButton.styles";

interface IRadioButtonProps {
  checked: boolean;
  onClick: () => void;
  style?: React.CSSProperties;
  circleStyle?: React.CSSProperties;
}

export function RadioButton(props: IRadioButtonProps) {
  const { checked, onClick, style, circleStyle } = props;

  const handleClick = () => {
    onClick();
  };

  return (
    <Radio style={style}>
      <input
        style={circleStyle}
        id="id"
        type="radio"
        onClick={handleClick}
        checked={checked}
        readOnly
      />
    </Radio>
  );
}
